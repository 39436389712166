import React from "react";

function Info() {
    return (
        <div className="container">
            <div className="row">
                <div className="col s12 center-align">
                    <h3>
                        <p>Elite Taxi Cab is a Professional Phoenix Taxi Company.</p>
                    </h3>
                    <hr />
                    <h6>
                        <p>We provide rides services in the Phoenix Metropolitan Area, including Glendale, Peoria, Avondale, Goodyear, Tempe, Scottsdale, Mesa, Chandler, and all of Arizona & aim to give the best transportation service possible in Phoenix.</p>
                    </h6>
                </div>
            </div>
        </div>
    );
}

export { Info };