import React, { useState, useRef,useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from 'react-router-dom';

import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { ProgressBar } from "react-materialize"
import { login } from "../actions/auth";
import { Modal } from 'react-responsive-modal';

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

const Login = (props) => {
  const form = useRef();
  const checkBtn = useRef();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [rememberme, setRememberme] = useState(false);
  const [showPRModal, setShowPRModal] = useState(false);

  

  const { isLoggedIn } = useSelector(state => state.auth);
  const { message } = useSelector(state => state.message);
  const { user: currentUser } = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  const onChangeUsername = (e) => {
    const username = e.target.value;
    setUsername(username);
  };

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };

  const handleLogin = (e) => {
    e.preventDefault();
    setLoading(true);
    form.current.validateAll();
    if (checkBtn.current.context._errors.length === 0) {
      dispatch(login(username, password))
        .then(() => {
          if(rememberme){
            localStorage.setItem("username", username);
            localStorage.setItem("rememberme", rememberme);
          }else{
            localStorage.removeItem("username");
            localStorage.removeItem("rememberme");
          }

          props.history.push("/");
          window.location.reload();
        })
        .catch(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {

    console.log("rememberme",localStorage.getItem("rememberme"));

    if(localStorage.getItem("rememberme") == 'true'){

      console.log("username",localStorage.getItem("username"));


      setUsername(localStorage.getItem("username"));
      setRememberme(true)
    }
  }, []);

  if (isLoggedIn) {
      return <Redirect to="/" />;
  }

  return (
  <div id="main" class="main-full main-full-login">
        <div class="row">
          <div class="content-wrapper-before gradient-45deg-indigo-purple"></div>
          <div class="col s12 nopadding">
            <div class="container">
              <div id="login-page" class="row">
                <div class="col s12 m6 l4 z-depth-4 card-panel border-radius-6 login-card bg-opacity-8">
                  <Form class="login-form" onSubmit={handleLogin} ref={form}>
                    <div class="row">
                      <div class="input-field col s12">
                        <h5 class="ml-4">Sign in</h5>
                      </div>
                    </div>
                  {message && (
                    <div class="card-alert card red">
                      <div class="card-content white-text">
                        <p>{message}</p>
                      </div>
                      <button type="button" class="close white-text" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">×</span>
                      </button>
                    </div>
                  )}

                    <div class="row margin">
                      <div class="input-field col s12">
                        <i class="material-icons prefix pt-2">person_outline</i>
                        <input id="username" type="text" value={username} onChange={onChangeUsername} validations={[required]}/>
                        <label for="username" class="center-align">Username</label>
                      </div>
                    </div>
                    <div class="row margin">
                      <div class="input-field col s12">
                        <i class="material-icons prefix pt-2">lock_outline</i>
                        <input id="password" type="password" value={password} onChange={onChangePassword} validations={[required]}/>
                        <label for="password">Password</label>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col s12 m12 l12 ml-2 mt-1">
                        <p>
                          <label>
                            <input type="checkbox" checked={rememberme} onClick={(event)=>{
                              setRememberme(!rememberme);
                            }} />
                            <span>Remember Me</span>
                          </label>
                        </p>
                      </div>
                    </div>
                    <div class="row" >
                      <div class="input-field col s12">
                        <button disabled={loading} type="submit" class="btn waves-effect waves-light border-round gradient-45deg-purple-deep-orange col s12">Login</button>
                        <CheckButton style={{ display: "none" }} ref={checkBtn} />
                        <br/><br/>
                        { loading && (<ProgressBar />)}
                      </div>
                    </div>
                    <div class="row">
                      <div class="input-field col s6 m6 l6 offset-s6 offset-m6 offset-l6">
                        <p class="margin right-align medium-small" style={{cursor:'pointer'}}><a onClick={()=>{
                          console.log('warara');
                          setShowPRModal(true);
                        }} >Forgot password ?</a></p>
                      </div>
                    </div>
                  </Form>
                  <link rel="stylesheet" type="text/css" href="/app-assets/css/pages/login.css"/>
                </div>
              </div>
        </div>
      </div>
    </div>
    <Modal open={showPRModal} onClose={()=>{setShowPRModal(false);}} classNames={{modal: 'showPRModal'}} >
        <div class="row">
            <h6>Password Recovery</h6>
            <div class="col s12">
              <div class="row">
                  <form class="col s12">
                      <div class="row">
                          <div class="input-field col s12">
                          <textarea id="textarea2" class="materialize-textarea" onChange={(event)=>{
                              console.log("onchange cancelReason");
                              //setCancelReason(event.target.value);
                          }}></textarea>
                          <label for="textarea2">Username</label>
                          </div>
                      </div>
                  </form>
              </div>
              <div class="row">
                  <div class="col s12">
                      <a class="waves-effect waves-light btn-large mb-1" onClick={()=>{
                        
                      }}><i class='material-icons left'>send</i> Send</a>
                  </div>
              </div>
            </div>
        </div>
    </Modal>
</div>
  );
};

export default Login;