import {
    CREATE_NEW_SERVICE_START,
    CREATE_NEW_SERVICE_SUCCESS,
    CREATE_NEW_SERVICE_FAIL,

    GET_ACTIVATED_SERVICES_START,
    GET_ACTIVATED_SERVICES_SUCCESS,
    GET_ACTIVATED_SERVICES_FAIL,
    
    GET_CUSTOMER_BY_PHONE_START,
    GET_CUSTOMER_BY_PHONE_SUCCESS,
    GET_CUSTOMER_BY_PHONE_FAIL,

    UPDATE_FIELD_CUSTOMER,

    LOAD_NEW_SERVICE_START,
    LOAD_NEW_SERVICE_SUCCESS,
    LOAD_NEW_SERVICE_FAIL,

    GET_AVAILABLES_START,
    GET_AVAILABLES_SUCCESS,
    GET_AVAILABLES_FAIL,

    START_GET_COUNTIES,
    SUCCESS_GET_COUNTIES,
    FAIL_GET_COUNTIES,

    START_GET_ORIGIN_CITIES,
    SUCCESS_GET_ORIGIN_CITIES,
    FAIL_GET_ORIGIN_CITIES,

    START_GET_DESTINATION_CITIES,
    SUCCESS_GET_DESTINATION_CITIES,
    FAIL_GET_DESTINATION_CITIES,

    CALCULATE_RATE_START,
    CALCULATE_RATE_SUCCESS,
    CALCULATE_RATE_FAIL,

    CREATE_NEW_CUSTOMER_START,
    CREATE_NEW_CUSTOMER_SUCCESS,
    CREATE_NEW_CUSTOMER_FAIL,

    GET_TOPIC_COMMENTS_START,
    GET_TOPIC_COMMENTS_SUCCESS,
    GET_TOPIC_COMMENTS_FAIL,

    NEW_EMPTY_SERVICE_START,
    NEW_EMPTY_SERVICE_SUCCESS,
    NEW_EMPTY_SERVICE_FAIL,

    GET_VEHICLES_START,
    GET_VEHICLES_SUCCESS,
    GET_VEHICLES_FAIL,

    GET_BLACKLIST_START,
    GET_BLACKLIST_SUCCESS,
    GET_BLACKLIST_FAIL,

    CHANGE_STATUS_SERVICES_START,

    GET_ALLACTIVETAXIS_START,
    GET_ALLACTIVETAXIS_SUCCESS,
    GET_ALLACTIVETAXIS_FAIL,

    GET_LASTTENSERVECES_START,
    GET_LASTTENSERVECES_SUCCESS,
    GET_LASTTENSERVECES_FAIL,

    GET_ALLNUMBERSEARCH_START,
    GET_ALLNUMBERSEARCH_SUCCESS,
    GET_ALLNUMBERSEARCH_FAIL,

    UPDATE_FILTER_VALUE,

    ADD_BLACKLIST_START,
    ADD_BLACKLIST_SUCCESS,
    ADD_BLACKLIST_FAIL,

    GET_SERVICE_BY_ID_START,
    GET_SERVICE_BY_ID_SUCCESS,
    GET_SERVICE_BY_ID_FAIL,

    GET_DRIVERS_START,
    GET_DRIVERS_SUCCESS,
    GET_DRIVERS_FAIL,

    GET_BLACKLISTR_START,
    GET_BLACKLISTR_SUCCESS,
    GET_BLACKLISTR_FAIL,

    ACTIVEDRIVER_START,
    ACTIVEDRIVER_SUCCESS,
    ACTIVEDRIVER_FAIL,

    GET_DRIVERINFO_START,
    GET_DRIVERINFO_SUCCESS,
    GET_DRIVERINFO_FAIL,

    GET_MESSAGES_START,
    GET_MESSAGES_SUCCESS,
    GET_MESSAGES_FAIL,

    UPDATE_PAYMENTTYPE_START,
    UPDATE_PAYMENTTYPE_SUCCESS,
    UPDATE_PAYMENTTYPE_FAIL,

    UPDATE_RESERVATION_PAYMENTTYPE_START,
    UPDATE_RESERVATION_PAYMENTTYPE_SUCCESS,
    UPDATE_RESERVATION_PAYMENTTYPE_FAIL,

    CLEAR_NEW_SERVICE,

    NEW_RESERVATION_START,
    NEW_RESERVATION_SUCCESS,
    NEW_RESERVATION_FAIL,
  
    NEW_SERVICE_START,
    NEW_SERVICE_SUCCESS,
    NEW_SERVICE_FAIL,

    GET_CUSTOMERTYPES_START,
    GET_CUSTOMERTYPES_SUCCESS,
    GET_CUSTOMERTYPES_FAIL,
  
    GET_PAYMENTYPES_START,
    GET_PAYMENTYPES_SUCCESS,
    GET_PAYMENTYPES_FAIL,

    GET_SERVICELASTLOCATION_START,
    GET_SERVICELASTLOCATION_SUCCESS,
    GET_SERVICELASTLOCATION_FAIL,

    CANCEL_RESERVATION_START,
    CANCEL_RESERVATION_SUCCESS,
    CANCEL_RESERVATION_FAIL,
    
    SEND_MESSAGE_START,
    SEND_MESSAGE_SUCCESS,
    SEND_MESSAGE_FAIL,
    GET_INCIDENTS_START,
    GET_INCIDENTS_SUCCESS,
    GET_INCIDENTS_FAIL,

    GET_LPVEHICLE_START,
    GET_LPVEHICLE_SUCCESS,
    GET_LPVEHICLE_FAIL,

    GET_LASTORIGIN_START,
    GET_LASTORIGIN_SUCCESS,
    GET_LASTORIGIN_FAIL,

    GET_LASTODESTINATION_START,
    GET_LASTODESTINATION_SUCCESS,
    GET_LASTODESTINATION_FAIL,



  } from "../../actions/operator/types";
  import moment from 'moment';
  
  const initialState = {
    reservationPaymentTypeUpdated:{
        loading:false,
        item:null
    },
    servicePaymentTypeUpdated:{
        loading:false,
        item:null
    },
    servicesAllInfo:{
        loaging:false,
        item:null,
        nocache:Date.now()
    },
    availableDrivers:{
        loaging:false,
        items:[]
    },
    filters:{
        status:{
            loaging:false,
            items:[
                {value:0,label:'All Status'},
                {value:3,label:'In process'},
                {value:15,label:'Waiting'},
                {value:4,label:'Completed'},
                {value:5,label:'Cancelled'},
                {value:16,label:'Reservation'},
            ],
            value:{value:0,label:'All Status'}
        },
        customerType:{
            loading:false,
            items:[
                {value:0,label:'All Customer Types'},
                {value:1,label:'Standard'},
                {value:2,label:'App'},
                {value:3,label:'ADA Senior Cab (B)'},
                {value:4,label:'Senior Center (Y)'},
            ],
            value:{value:0,label:'All Types'}
        },
        paymentType:{
            loading:false,
            items:[
                {value:1,label:'CASH'},
                {value:2,label:'DEBIT/CREDIT CARD'},
                {value:3,label:'RETIRED YELLOW CARD'},
                {value:4,label:'RETIRED BLUE CARD'}
            ],
            value:{value:0,label:'All Types'}
        },
        blackListCab:{
            value:{value:0,label:'Select a Cab'},
        },
        blackListReason:{
            loading:false,
            items:[{value:0,label:'Select a Reason'}],
            value:{value:0,label:'Select a Reason'},
        },
        vehicleLastCallsCab:{
            value:{value:0,label:'Select a Cab'},
        },
        vehicleLastCallsLast:{
            value:10,
        },
        vehicles:{
            loading:false,
            items:[{value:0,label:'Select a Cab'}],
        },
        callNumberSearch:{
            value:''
        },
        activedDrivers:{
            loading:false,
            items:[{value:0,label:'Select a Driver'}],
            value:{value:0,label:'Select a Driver'},
        },
        
    },
    driverSelectedInfo:{
        loading:false,
        item:null
    },
    vehicle_last_call:{
        loading:false,
        items:null,
    },
    call_number_search:{
        loading:false,
        items:null,
    },
    blacklist:{
        statusId:0,
        loading:false,
        items:[]
    },
    vehicles:{
        statusId:0,
        loading:false,
        items:[]
    },
    activedDrivers:{
        loaging:false,
        items:[]
    },
    services:{
        statusId:0,
        loading:false,
        items:[]
    },
    newService:{
        loading:false,
        id:null
    },
    get_customer_by_phone:{
        loading:false,
        customer:null
    },
    customer:{
        loading:false,
        memberId:null,
        id:null,
        type:1,
        customertype:1,
        reservationdate:'',
        reservationtime:'',
        phone:'',
        firstname:'',
        lastname:'',
        phonetype:'',
        originNumber:'',
        originNSB:'',
        originStreet:'',
        originCounty:'',
        originCity:'',
        originPostalcode:'',
        originlat:'',
        originlng:'',
        destinationNumber:'',
        destinationNSB:'',
        destinationStreet:'',
        destinationCounty:'',
        destinationCity:'',
        destinationPostalcode:'',
        destinationlat:'',
        destinationlng:'',
        cab:'',
        assignatedDriver:'',
        vehicleFeatures:'',
        customerTypeId:1,
        paymentTypeId:1,
        cityId:null,
        countyId:null,
        roadmaps:[],
        roundTrip:false,
        roundTripPickupDate:null,
        roundTripPickupTime:null,
        days:false,
        monday:false,
        tuesday:false,
        wednesday:false,
        thursday:false,
        friday:false,
        saturday:false,
        sunday:false,
    },
    newservicesettings:{
    },
    availables:{
        loading:false,
        vehicles:[]
    },
    counties:[{value:'',label:'Select a County'}],
    arizonaCountyCitiesOrigin: [{value:'',label:'Select a City'}],
    arizonaCountyCitiesDestination: [{value:'',label:'Select a City'}],
    countiesLoading:false,
    citiesLoadingOrigin:false,
    citiesLoadingDestination:false,
    newServiceRate:'No Rate',
    newServiceRateLoading:false,
    newServiceTopicComments:[],
    messages:{
        loading:false,
        messages:[]
    },
    lastServiceLocations:{
        loading:false,
        item:null    
    },
    incident:{
        loading:false,
        item:null    
    },
    lpvehicle:{
        loading:false,
        item:null    
    },
    lastOrigin:{
        loading:false,
        items:[{value:'',label:'Select an Address'}]
    },
    lastDestination:{
        loading:false,
        items:[{value:'',label:'Select an Address'}]
    }
  };
  
  export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case GET_LASTORIGIN_START:
            var a = {...state};
            a.lastOrigin.loading = true;
            return a;
        case GET_LASTORIGIN_SUCCESS:
            var a = {...state};
            a.lastOrigin.loading = false;

            var temp = [{value:0,label:'Select an Address'}];
            for (var index in payload) {
                temp.push({value:payload[index].location,label:payload[index].location});
            }
            a.lastOrigin.items = temp;
            return a;
        case GET_LASTORIGIN_FAIL:
            var a = {...state};
            a.lastOrigin.loading = false;
            return a;
        case GET_LASTODESTINATION_START:
            var a = {...state};
            a.lastDestination.loading = true;
            return a;
        case GET_LASTODESTINATION_SUCCESS:
            var a = {...state};
            a.lastDestination.loading = false;
            var temp = [{value:0,label:'Select an Address'}];
            for (var index in payload) {
                temp.push({value:payload[index].location,label:payload[index].location});
            }
            a.lastDestination.items = temp;
            return a;
        case GET_LASTODESTINATION_FAIL:
            var a = {...state};
            a.lastDestination.loading = false;
            return a;


        case GET_LPVEHICLE_START:
            var a = {...state};
            a.lpvehicle.loading = true;
            return a;
        case GET_LPVEHICLE_SUCCESS:
            var a = {...state};
            a.lpvehicle.loading = true;
            if(payload.length >0){
                a.lpvehicle.item = payload[0];
            }else{
                a.lpvehicle.item = null;
            }
            return a;
        case GET_LPVEHICLE_FAIL:
            var a = {...state};
            a.lpvehicle.loading = true;
            //a.lpvehicle.item = null;
            return a;
        case GET_INCIDENTS_START:
            var a = {...state};
            a.incident.loading = true;
            a.incident.item = null;
            return a;
        case GET_INCIDENTS_SUCCESS:
            var a = {...state};
            a.incident.loading = true;
            if(payload.length >0){
                a.incident.item = payload[0];
            }else{
                a.incident.item = null;
            }
            
            return a;
        case GET_INCIDENTS_FAIL:
            var a = {...state};
            a.incident.loading = true;
            a.incident.item = null;
            return a;
        case GET_LASTTENSERVECES_START:
            var a = {...state};
            a.vehicle_last_call.loading = true;
            a.vehicle_last_call.items = null;
            return a;
        case GET_LASTTENSERVECES_SUCCESS:
            var a = {...state};
            a.vehicle_last_call.loading = true;
            //if(payload)
            a.vehicle_last_call.items = payload;
            return a;
        case GET_LASTTENSERVECES_FAIL:
            var a = {...state};
            a.vehicle_last_call.loading = true;
            a.vehicle_last_call.items = null;
            return a;
        case GET_SERVICELASTLOCATION_START:
            var a = {...state};
            a.lastServiceLocations.loading = true;
            a.lastServiceLocations.item = null;
            return a;
        case GET_SERVICELASTLOCATION_SUCCESS:
            var a = {...state};
            a.lastServiceLocations.loading = true;
            //if(payload)
            a.lastServiceLocations.item = payload[0];
            return a;
        case GET_SERVICELASTLOCATION_FAIL:
            var a = {...state};
            a.lastServiceLocations.loading = true;
            a.lastServiceLocations.item = null;
            return a;
        case GET_CUSTOMERTYPES_START:
            var a = {...state};
            a.filters.customerType.loading = true;
            a.filters.customerType.items = [];
            return a;
        case GET_CUSTOMERTYPES_SUCCESS:
            var a = {...state};
            a.filters.customerType.loading = false;
            var temp = [];
            for (var index in payload) {
                temp.push({value: payload[index].customerTypeId, label:payload[index].customerType});
            }
            //{customerTypeId: 1, customerType: "Standard", description: "Phone Standard Customer", fee: "GENERAL"}
            a.filters.customerType.items = temp;
            return a;
        case GET_CUSTOMERTYPES_FAIL:
            var a = {...state};
            a.filters.customerType.loading = false;
            a.filters.customerType.items = [];
            return a;
        case GET_PAYMENTYPES_START:
            var a = {...state};
            a.filters.paymentType.loading = true;
            a.filters.paymentType.items = [];
            return a;
        case GET_PAYMENTYPES_SUCCESS:
            var a = {...state};
            a.filters.paymentType.loading = false;
            var temp = [];
            for (var index in payload) {
                temp.push({value: payload[index].paymentTypeId, label:payload[index].name});
            }
            //{paymentTypeId: 1, name: "CASH", description: "CASH"}
            a.filters.paymentType.items = temp;
            return a;
        case GET_PAYMENTYPES_FAIL:
            var a = {...state};
            a.filters.paymentType.loading = false;
            a.filters.paymentType.items = [];
            return a;
        case GET_ALLNUMBERSEARCH_START:
            var a = {...state};
            a.call_number_search.loading = true;
            a.call_number_search.items = null;
            return a;
        case GET_ALLNUMBERSEARCH_SUCCESS:
            var a = {...state};
            a.call_number_search.loading = false;
            a.call_number_search.items = payload;
            return a;
        case GET_ALLNUMBERSEARCH_FAIL:
            var a = {...state};
            a.call_number_search.loading = false;
            a.call_number_search.items = null;
            return a;
        case NEW_RESERVATION_START:
            var a = {...state};
            a.newService.loading = true;
            a.newService.id = null;
            return a;
        case NEW_RESERVATION_SUCCESS:
            var a = {...state};
            a.newService.loading = false;
            if(payload && payload.id) a.newService.id = payload.id;
            return a;
        case NEW_RESERVATION_FAIL:
            var a = {...state};
            a.newService.loading = false;
            a.newService.id = null;
            return a;
        case NEW_SERVICE_START:
            var a = {...state};
            a.newService.loading = true;
            a.newService.id = null;
            return a;
        case NEW_SERVICE_SUCCESS:
            var a = {...state};
            a.newService.loading = false;
            //console.log(payload);

            a.newService.id = payload.id;
            return a;
        case NEW_SERVICE_FAIL:
            var a = {...state};
            a.newService.loading = false;
            a.newService.id = null;
            return a;
        case UPDATE_RESERVATION_PAYMENTTYPE_START:
            return {...state,reservationPaymentTypeUpdated:{loaging:true,item:null}};
        case UPDATE_RESERVATION_PAYMENTTYPE_SUCCESS:
            return {...state,reservationPaymentTypeUpdated:{loaging:false,item:payload}};
        case UPDATE_RESERVATION_PAYMENTTYPE_FAIL:
            return {...state,reservationPaymentTypeUpdated:{loaging:false,item:null}};
        case UPDATE_PAYMENTTYPE_START:
            return {...state,servicePaymentTypeUpdated:{loaging:true,item:null}};
        case UPDATE_PAYMENTTYPE_SUCCESS:
            return {...state,servicePaymentTypeUpdated:{loaging:false,item:payload}};
        case UPDATE_PAYMENTTYPE_FAIL:
            return {...state,servicePaymentTypeUpdated:{loaging:false,item:null}};
        case GET_MESSAGES_START:
            return {...state,messages:{loaging:true,messages:[]}};
        case GET_MESSAGES_SUCCESS:
            return {...state,messages:{loaging:false,messages:payload}};
        case GET_MESSAGES_FAIL:
            return {...state,messages:{loaging:false,messages:[]}};
        case GET_DRIVERINFO_START:
            return {...state,driverSelectedInfo:{loaging:true,item:null}};
        case GET_DRIVERINFO_SUCCESS:
            return {...state,driverSelectedInfo:{loaging:false,item:payload[0]}};
        case GET_DRIVERINFO_FAIL:
            return {...state,driverSelectedInfo:{loaging:false,item:null}};
        case GET_DRIVERS_START:
            return {...state,availableDrivers:{loaging:true,items:[]}};
        case GET_DRIVERS_SUCCESS:
            return {...state,availableDrivers:{loaging:false,items:payload}};
        case GET_DRIVERS_FAIL:
            return {...state,availableDrivers:{loaging:false,items:[]}};
        case GET_SERVICE_BY_ID_START:
            return {...state,servicesAllInfo:{item:null,loaging:true}};
        case GET_SERVICE_BY_ID_SUCCESS:
            //console.log("GET_SERVICE_BY_ID_SUCCESS 1111");
            var tempService = {...state};

            tempService.servicesAllInfo.loaging = false;
            console.log(payload);
            if(payload.length > 0){
                tempService.servicesAllInfo.item = payload[0];
                tempService.servicesAllInfo.nocache = Date.now();
            }else{
                tempService.servicesAllInfo.item = {};
                tempService.servicesAllInfo.nocache = Date.now();
            }
            return tempService;
        case GET_SERVICE_BY_ID_FAIL:
            return {...state,servicesAllInfo:{item:null,loaging:false}};
            
        case UPDATE_FILTER_VALUE:
            var a = {...state};
            a.filters[payload.field].value = payload.value;
            return a;
        case GET_ALLACTIVETAXIS_START:
            return {
                ...state,
                filters:{
                    ...state.filters,
                    vehicleLastCallsCab: {value:{value:0,label:'Select a Cab'}},
                    vehicles: {loading:true,items:[{value:0,label:'Select a Cab'}]},
                    activedDrivers: {...state.filters.activedDrivers,loading:true,items:[state.filters.activedDrivers.value]},
                },
                activedDrivers:{
                    loading:true,items:[]
                }
            };
        case GET_ALLACTIVETAXIS_SUCCESS:
            var temp = [{value:0,label:'Select a Cab'}];
            var temp2 = [{value:0,label:'Select a Driver'}];
            for (var index in payload) {
                temp.push({value: payload[index].driverId, label:"Cab:"+payload[index].cab+" Driver:"+payload[index].driverId});
                temp2.push({value: payload[index].driverId, label:payload[index].driverId});
            }
            return {
                ...state,
                filters:{
                    ...state.filters,
                    vehicleLastCallsCab: {value:{value:0,label:'Select a Cab'}},
                    vehicles: {loading:false,items:temp},
                    activedDrivers: {...state.filters.activedDrivers,loading:false,items:temp2}
                },
                activedDrivers:{
                    loading:false,items:payload
                }
            };
        case GET_ALLACTIVETAXIS_FAIL:
            return {
                ...state,
                filters:{
                    ...state.filters,
                    vehicleLastCallsCab: {value:{value:0,label:'Select a Cab'}},
                    vehicles: {loading:false,items:[{value:0,label:'Select a Cab'}]},
                    activedDrivers: {...state.filters.activedDrivers,loading:false,items:[state.filters.activedDrivers.value]},
                },
                activedDrivers:{
                    loading:false,items:[]
                }
            };
        case GET_TOPIC_COMMENTS_START:
            return {...state,newServiceTopicComments: [] };
        case GET_TOPIC_COMMENTS_SUCCESS:
            return {...state,newServiceTopicComments: payload };
        case GET_TOPIC_COMMENTS_FAIL:
            return {...state,newServiceTopicComments: [] };
        case CALCULATE_RATE_START:
            return {...state,newServiceRateLoading: true, newServiceRate:'No Rate' };
        case CALCULATE_RATE_SUCCESS:
            //console.log(payload);
            return {...state,newServiceRateLoading: false, newServiceRate:'$ '+payload.rate+' Dlls.' };
        case CALCULATE_RATE_FAIL:
            return {...state,newServiceRateLoading: false, newServiceRate:'No Rate' };
        case START_GET_COUNTIES:
            return {
                ...state,
                countiesLoading: true,
                counties: [{value:'',label:'Select a County'}],
                arizonaCountyCities: [{value:'',label:'Select a City'}],
            };
        case SUCCESS_GET_COUNTIES:
            var temp = [{value:'',label:'Select a County'}];
            for (var index in payload) {
                temp.push({value: payload[index].countyId, label:payload[index].county});
            }
            return {
                ...state,
                countiesLoading: false,
                counties: temp,
            };
        case FAIL_GET_COUNTIES:
            return {
                ...state,
                countiesLoading: false,
                counties: [{value:'',label:'Select a County'}],
                arizonaCountyCities: [{value:'',label:'Select a City'}],
            };
        case START_GET_ORIGIN_CITIES:
            return {
                ...state,
                citiesLoadingOrigin: true,
                arizonaCountyCitiesOrigin: [{value:'',label:'Select a City'}],
            };
        case SUCCESS_GET_ORIGIN_CITIES:
            var temp = [{value:'',label:'Select a City'}];
            for (var index in payload) {
                temp.push({value: payload[index].cityId, label:payload[index].city});
            }
            return {
                ...state,
                citiesLoadingOrigin: false,
                arizonaCountyCitiesOrigin: temp,
            };
        case FAIL_GET_ORIGIN_CITIES:
            return {
                ...state,
                citiesLoadingOrigin: false,
                arizonaCountyCitiesOrigin: [{value:'',label:'Select a City'}],
            };
        case START_GET_DESTINATION_CITIES:
            return {
                ...state,
                citiesLoadingDestination: true,
                arizonaCountyCitiesDestination: [{value:'',label:'Select a City'}],
            };
        case SUCCESS_GET_DESTINATION_CITIES:
            var temp = [{value:'',label:'Select a City'}];
            for (var index in payload) {
                temp.push({value: payload[index].cityId, label:payload[index].city});
            }
            return {
                ...state,
                citiesLoadingDestination: false,
                arizonaCountyCitiesDestination: temp,
            };
        case FAIL_GET_DESTINATION_CITIES:
            return {
                ...state,
                citiesLoadingDestination: false,
                arizonaCountyCitiesDestination: [{value:'',label:'Select a City'}],
            };
        case GET_VEHICLES_START:
            var a = {...state};
            a.vehicles.loading = true;
            a.vehicles.items = [];
            return a;
        case GET_VEHICLES_SUCCESS:
            var a = {...state};
            a.vehicles.loading = false;
            a.vehicles.items = payload;
            return a;
        case GET_VEHICLES_FAIL:
            var a = {...state};
            a.vehicles.loading = false;
            a.vehicles.items = [];
            return a;
        case GET_BLACKLISTR_START:
            var a = {...state};
            a.filters.blackListReason.loading = true;
            a.filters.blackListReason.items = [a.filters.blackListReason.value];
            return a;
        case GET_BLACKLISTR_SUCCESS:
            var a = {...state};
            var temp = [a.filters.blackListReason.value];
            for (var index in payload) {
                temp.push({value: payload[index].blackListReasonId, label:payload[index].reason});
            }
            a.filters.blackListReason.loading = false;
            a.filters.blackListReason.items = temp;
            return a;
        case GET_BLACKLISTR_FAIL:
            var a = {...state};
            a.filters.blackListReason.loading = false;
            a.filters.blackListReason.items = [];
            return a;
        case GET_BLACKLIST_START:
            var a = {...state};
            a.blacklist.loading = true;
            a.blacklist.items = [];
            return a;
        case GET_BLACKLIST_SUCCESS:
            var a = {...state};
            a.blacklist.loading = false;
            a.blacklist.items = payload;
            return a;
        case GET_BLACKLIST_FAIL:
            var a = {...state};
            a.blacklist.loading = false;
            a.blacklist.items = [];
            return a;
        case GET_ACTIVATED_SERVICES_START:
            var a = {...state};
            a.services.loading = true;
            a.services.items = [];
            return a;
        case GET_ACTIVATED_SERVICES_SUCCESS:
            var a = {...state};
            a.services.loading = false;
            a.services.items = payload;
            return a;
        case GET_ACTIVATED_SERVICES_FAIL:
            var a = {...state};
            a.services.loading = false;
            a.services.items = [];
            return a;

        case CHANGE_STATUS_SERVICES_START:
            var a = {...state};
            a.services.statusId = payload;
            return a;
        case CLEAR_NEW_SERVICE:
            var a = {...state};
            a.newService.id = null;
            a.customer.loading = false;
            a.customer.memberId = null;
            a.customer.id = null;
            a.customer.type = 1;
            a.customer.customerTypeId = 1;
            a.customer.paymentTypeId = 1;
            a.customer.cityId = null;
            a.customer.countyId = null;
            a.customer.reservationdate = '';
            a.customer.reservationtime = '';
            a.customer.firstname = '';
            a.customer.lastname = '';
            a.customer.phonetype = '';
            a.customer.phone = '';
            a.customer.originNumber = '';
            a.customer.originNSB = '';
            a.customer.originStreet = '';
            a.customer.originCounty = '';
            a.customer.originCity = '';
            a.customer.originPostalcode = '';
            a.customer.originBusinessName = '';
            a.customer.originlat = '';
            a.customer.originlng = '';
            a.customer.destinationNumber = '';
            a.customer.destinatioNSB = '';
            a.customer.destinatioStreet = '';
            a.customer.destinatioCounty = '';
            a.customer.destinatioCity = '';
            a.customer.destinationPostalcode = '';
            a.customer.destinatioBusinessName = '';
            a.customer.roundTrip = false;
            a.customer.roundTripPickupDate = null;
            a.customer.roundTripPickupTime = null;
            a.customer.days = false;
            a.customer.monday = false;
            a.customer.tuesday = false;
            a.customer.wednesday = false;
            a.customer.thursday = false;
            a.customer.friday = false;
            a.customer.saturday = false;
            a.customer.sunday = false;   
            
            return a;
        case GET_CUSTOMER_BY_PHONE_START:
            var a = {...state};
            a.customer.loading = true;
            a.customer.memberId = null;
            a.customer.id = null;
            a.customer.type = 1;
            a.customer.customerTypeId = 1;
            a.customer.paymentTypeId = 1;
            a.customer.cityId = null;
            a.customer.countyId = null;
            a.customer.reservationdate = '';
            a.customer.reservationtime = '';
            a.customer.firstname = '';
            a.customer.lastname = '';
            a.customer.phonetype = '';
            a.customer.originNumber = '';
            a.customer.originNSB = '';
            a.customer.originStreet = '';
            a.customer.originCounty = '';
            a.customer.originCity = '';
            a.customer.originPostalcode = '';
            a.customer.originBusinessName = '';
            a.customer.originlat = '';
            a.customer.originlng = '';
            a.customer.destinationNumber = '';
            a.customer.destinatioNSB = '';
            a.customer.destinatioStreet = '';
            a.customer.destinatioCounty = '';
            a.customer.destinatioCity = '';
            a.customer.destinationPostalcode = '';
            a.customer.destinatioBusinessName = '';
            a.customer.roundTrip = false;
            a.customer.roundTripPickupDate = null;
            a.customer.roundTripPickupTime = null;
            a.customer.days = false;
            a.customer.monday = false;
            a.customer.tuesday = false;
            a.customer.wednesday = false;
            a.customer.thursday = false;
            a.customer.friday = false;
            a.customer.saturday = false;
            a.customer.sunday = false;        
            
            return a;
        case GET_CUSTOMER_BY_PHONE_SUCCESS:
            var a = {...state};

            var temp =  payload['data'][0];
            //var temp2 =  payload['service'];
            //a.newService.id = temp2.id;
            a.customer.loading = false;
            a.customer.memberId = temp.memberId;
            a.customer.id = temp.customerId;
            a.customer.type = 1;
            a.customer.reservationdate = '';
            a.customer.reservationtime = '';
            a.customer.phone = temp.phone;
            a.customer.firstname = temp.firstName;
            a.customer.lastname = temp.lastName;
            a.customer.phonetype = '';
            a.customer.originNumber = temp.number;
            a.customer.originNSB = temp.nsew;
            a.customer.originStreet = temp.street;
            a.customer.originCounty = "";
            a.customer.originCity = "";
            a.customer.originPostalcode = temp.zipCode;
            a.customer.originBusinessName = '';
            a.customer.originlat = temp.latitude;
            a.customer.originlng = temp.longitude;
            a.customer.destinationNumber = '';
            a.customer.destinatioNSB = '';
            a.customer.destinatioStreet = '';
            a.customer.destinatioCounty = '';
            a.customer.destinatioCity = '';
            a.customer.paymentTypeId = temp.paymentTypeId;
            a.customer.customerTypeId = temp.customerTypeId;
            a.customer.cityId = temp.cityId;
            a.customer.countyId = temp.countyId;
            a.customer.destinatioBusinessName = '';
            a.customer.roundTrip = false;
            a.customer.roundTripPickupDate = null;
            a.customer.roundTripPickupTime = null;
            a.customer.days = false;
            a.customer.monday = false;
            a.customer.tuesday = false;
            a.customer.wednesday = false;
            a.customer.thursday = false;
            a.customer.friday = false;
            a.customer.saturday = false;
            a.customer.sunday = false;
            
            return a;
        case GET_CUSTOMER_BY_PHONE_FAIL:
            var a = {...state};
            a.customer.loading = false;
            a.customer.memberId = null;
            a.customer.id = null;
            a.customer.type = 1;
            a.customer.customerTypeId = 1;
            a.customer.paymentTypeId = 1;
            a.customer.cityId = null;
            a.customer.countyId = null;
            a.customer.reservationdate = '';
            a.customer.reservationtime = '';
            //a.customer.phone = '';
            a.customer.firstname = '';
            a.customer.lastname = '';
            a.customer.phonetype = '';
            a.customer.originNumber = '';
            a.customer.originNSB = '';
            a.customer.originStreet = '';
            a.customer.originCounty = '';
            a.customer.originCity = '';
            a.customer.originPostalcode = '';
            a.customer.originBusinessName = '';
            a.customer.originlat = '';
            a.customer.originlng = '';
            a.customer.destinationNumber = '';
            a.customer.destinatioNSB = '';
            a.customer.destinatioStreet = '';
            a.customer.destinatioCounty = '';
            a.customer.destinatioCity = '';
            a.customer.destinationPostalcode = '';
            a.customer.destinatioBusinessName = '';
            a.customer.roundTrip = false;
            a.customer.roundTripPickupDate = null;
            a.customer.roundTripPickupTime = null;
            a.customer.days = false;
            a.customer.monday = false;
            a.customer.tuesday = false;
            a.customer.wednesday = false;
            a.customer.thursday = false;
            a.customer.friday = false;
            a.customer.saturday = false;
            a.customer.sunday = false;           
            
            return a;
        case UPDATE_FIELD_CUSTOMER:
            var a = {...state};
            a.customer[payload.field] = payload.value;
            //console.log('------------------------------------');
            //console.log(payload);
            //console.log(a.customer);
            return a;
        case LOAD_NEW_SERVICE_START:
            var a = {...state};
            a.customer.loading = false;
            a.customer.memberId = null;
            a.customer.id = null;
            a.customer.type = 1;
            a.customer.customertype = 1;
            a.customer.paymentTypeId = 1;
            a.customer.cityId = null;
            a.customer.countyId = null;
            a.customer.reservationdate = '';
            a.customer.reservationtime = '';
            a.customer.phone = '';
            a.customer.firstname = '';
            a.customer.lastname = '';
            a.customer.phonetype = '';
            a.customer.originNumber = '';
            a.customer.originNSB = '';
            a.customer.originStreet = '';
            a.customer.originCounty = '';
            a.customer.originCity = '';
            a.customer.originlat = '';
            a.customer.originlng = '';
            a.customer.originPostalcode = '';
            a.customer.originBusinessName = '';
            a.customer.destinationNumber = '';
            a.customer.destinatioNSB = '';
            a.customer.destinatioStreet = '';
            a.customer.destinatioCounty = '';
            a.customer.destinatioCity = '';
            a.customer.destinationPostalcode = '';
            a.customer.destinatioBusinessName = '';
            a.customer.roundTrip = false;
            a.customer.roundTripPickupDate = null;
            a.customer.roundTripPickupTime = null;
            a.customer.days = false;
            a.customer.monday = false;
            a.customer.tuesday = false;
            a.customer.wednesday = false;
            a.customer.thursday = false;
            a.customer.friday = false;
            a.customer.saturday = false;
            a.customer.sunday = false;
            
            return a;
        case LOAD_NEW_SERVICE_SUCCESS:
            var a = {...state};    
            return a;
        case LOAD_NEW_SERVICE_FAIL:
            var a = {...state};
            return a;
        case GET_AVAILABLES_START:
            var a = {...state};
            a.availables.loading = true;
            a.availables.vehicles = [];
            return a;
        case GET_AVAILABLES_SUCCESS:
            var a = {...state};
            a.availables.loading = false;
            a.availables.vehicles = payload;
            return a;
        case GET_AVAILABLES_FAIL:
            var a = {...state};
            a.availables.loading = false;
            a.availables.vehicles = [];
            return a;
      default:
        return state;
    }
  }
  const getElement = (collection, id) => {
    let tempcollection = collection.filter(element => element.value == id);
    return tempcollection.length > 0 ?tempcollection[0]:{};
}