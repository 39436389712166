import React, { useEffect, useState } from "react";

function Table(props) {

    const [rows, setRows] = useState(null);
    const [filteredData, setFilteredData] = useState([]);

    useEffect(() => {
        if (props.data)
            getFilteredData(0, []);
    }, [props.data, props.searchValue, props.searchFields]);

    useEffect(() => {
        setRows(getRows(filteredData));
    }, [props.show, props.page]);

    function getRows(data) {
        let rows = <></>;
        const startRow = props.show * props.page,
            lastRow = (startRow + props.show) - 1;
        if (data) {
            for (let rowCounter = startRow; rowCounter <= lastRow; rowCounter++) {
                const element = data[rowCounter];
                if (!element)
                    break;
                rows = <>{rows}{props.row(element)}</>;
            }
        }
        return rows;
    }

    function getFilteredData(lastIndex, filteredData) {
        const endIndex = lastIndex + 10;
        for (; lastIndex < endIndex; lastIndex++) {
            const element = props.data[lastIndex];
            if (!element) {
                setFilteredData(filteredData);
                setRows(getRows(filteredData));
                break;
            }
            if (isElementInFilter(element))
                filteredData.push(element);
            if (lastIndex == (endIndex - 1))
                setTimeout(() => getFilteredData(lastIndex, filteredData));

        }
        return filteredData;
    }

    function isElementInFilter(element) {
        const searchValue = props.searchValue,
            fields = props.searchFields;
        if (searchValue) {
            if (fields.length) {
                for (const propertyKey of fields) {
                    let propertyValue = element[propertyKey];
                    if (propertyValue) {
                        propertyValue = `${propertyValue}`;
                        if (propertyValue.toLowerCase().includes(searchValue.toLowerCase()))
                            return true;
                    }
                }
                return false;
            }
        }
        return true;
    }

    function getPageRowAmount(data) {
        return Math.ceil(data.length / props.show);
    }

    return (
        <>
            <table style={props.style}>
                {props.tableHead}
                {props.tableBody(rows)}
                {props.tableFoot}
            </table>
            {props.pagination(getPageRowAmount(filteredData))}
        </>
    );
}
export default Table;