import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Switch, Route, Link, BrowserRouter as Router, withRouter } from "react-router-dom";
import { useLocation } from 'react-router-dom'
import axios from "axios";

import { NavBar } from "./components/NavBar";
import Login from "./components/Login";
import RegisterDriver from "./components/RegisterDriver";
import RegisterAssociate from "./components/RegisterAssociate";
import Home from "./components/Home";
import Profile from "./components/Profile";
import JoinUs from "./components/JoinUs";
import BoardPartner from "./components/BoardPartner";
import BoardDriver from "./components/BoardDriver";
import BoardCustomer from "./components/BoardCustomer";
import MenuDashboard from "./components/MenuAdmin";
import BoardOperator from "./components/BoardOperator";
import MenuOperator from "./components/MenuOperator";
import useScript from "./helpers/useScript.js";
import socketIOClient from "socket.io-client";
import { clearMessage } from "./actions/message";
import { history } from "./helpers/history";
import { ToastsContainer, ToastsStore, ToastsContainerPosition } from 'react-toasts';
import Dashboard from './components/Dashboard';
import { FAQ } from './components/FAQ';
import { Info } from './components/Info';
import Manager from './components/manager/Manager';
import { checkLogin, logout } from "./actions/auth";
import authHeader from "./services/auth-header";
import RegisterForm from "./components/RegisterForm";

const App = () => {
	const dispatch = useDispatch();
	const [settings, setSettings] = useState([]);

	useEffect(() => {
		dispatch(checkLogin()).catch(() => {
			dispatch(logout())
		});

		getSettings();
	}, []);

	useEffect(() => {
		history.listen((location) => {
			dispatch(clearMessage()); // clear message when changing location
		});
	}, [dispatch]);

	async function getSettings() {
		let result = await axios.get(process.env.REACT_APP_API_URL + 'api/catalog/settings', { headers: authHeader() })
		if (result) {
			setSettings(result.data);
		}
	}

	function getSettingValue(settingId) {
		--settingId;
		if (settings[settingId])
			return settings[settingId].value;
	}

	useScript('/app-assets/js/plugins.js');
	return (
		<Router history={history}>
			<ToastsContainer store={ToastsStore} position={ToastsContainerPosition.TOP_RIGHT} />
			<Switch>
			</Switch>
			<Switch>
				<Route exact path={["/", "/home"]}>
					<NavBar
						option={1}
						title="Book your taxi with Elite Taxi Cab"
						companyName={getSettingValue(8)}
						logoURL={getSettingValue(9)}
						appDownloadURLs={{ ios: getSettingValue(15), android: getSettingValue(16) }}
						phone={getSettingValue(14)} />
					<Home companyName={getSettingValue(8)} appDownloadURLs={{ ios: getSettingValue(15), android: getSettingValue(16) }} />
				</Route>
				<Route exact path="/login">
					<NavBar option={2} size="mini" companyName={getSettingValue(8)} logoURL={getSettingValue(9)} />
					<Login />
				</Route>
				<Route exact path="/faq">
					<NavBar
						option={5}
						title="Frequently Asked Questions (FAQs)"
						companyName={getSettingValue(8)}
						logoURL={getSettingValue(9)}
						appDownloadURLs={{ ios: getSettingValue(15), android: getSettingValue(16) }}
						phone={getSettingValue(14)} />
					<FAQ />
				</Route>
				<Route exact path="/info">
					<NavBar
						option={6}
						title="About Us"
						companyName={getSettingValue(8)}
						logoURL={getSettingValue(9)}
						appDownloadURLs={{ ios: getSettingValue(15), android: getSettingValue(16) }}
						phone={getSettingValue(14)} />
					<Info />
				</Route>
				<Route exact path='/register-form/:driverType'>
					<NavBar
						option={1}
						title="Join Us"
						companyName={getSettingValue(8)}
						logoURL={getSettingValue(9)}
						appDownloadURLs={{ ios: getSettingValue(15), android: getSettingValue(16) }}
						phone={getSettingValue(14)} />
					<RegisterForm companyName={getSettingValue(8)} />
				</Route>
				<Route exact path="/dashboard">
					<NavBar option={2} size="mini" companyName={getSettingValue(8)} logoURL={getSettingValue(9)} />
					<Dashboard />
				</Route>
				<Route exact path="/manager*">
					<NavBar option={3} size="mini" companyName={getSettingValue(8)} logoURL={getSettingValue(9)}>
						<li><a href="/manager/settings"><i className="material-icons">settings</i> SETTINGS</a></li>
						<li><a href="/manager/members"><i className="material-icons">person</i> USERS</a></li>
						<li><a href="/manager/customers"><i className="material-icons">person</i> CUSTOMERS</a></li>
						<li><a href="/manager/vehicles"><i className="material-icons">directions_car</i> VEHICLES</a></li>
						<li><a href="/manager/drivers"><i className="material-icons">directions_car</i> DRIVERS</a></li>
						<li><a href="/manager/applicants"><i className="material-icons">drafts</i> APPLICANTS</a></li>
						<ul className="collapsible">
							<li>
								<div className="collapsible-header grey-text text-darken-2"><i className="material-icons">card_membership</i>VOUCHER</div>
								<ul className="collapsible-body">
									<li><a href="/manager/voucher"><i className="material-icons" style={{ fontSize: '1.6rem' }}>keyboard_arrow_right</i> VOUCHERS LIST</a></li>
									<li><a href="/manager/voucher/codes"><i className="material-icons" style={{ fontSize: '1.6rem' }}>keyboard_arrow_right</i> REPONSE CODES</a></li>
								</ul>
							</li>
						</ul>
						<li><a href="/manager/bonus"><i className="material-icons">card_giftcard</i> BONUS</a></li>
						<li><a href="/manager/transactions"><i className="material-icons">attach_money</i> TRANSACTIONS</a></li>
						<li><a href="/manager/incidents"><i className="material-icons">search</i> INCIDENTS</a></li>
						<li><a href="/manager/documents"><i className="material-icons">attach_file</i> DOCUMENTS</a></li>
						{/* <li><a href="/manager/services"><i className="material-icons">build</i> SERVICES</a></li> */}
					</NavBar>
					<Manager />
				</Route>
				<Route exact path="/operator">
					<NavBar option={4} size="mini" companyName={getSettingValue(8)} logoURL={getSettingValue(9)} />
					<BoardOperator />
				</Route>
			</Switch>
		</Router>
	);
};

export default App;