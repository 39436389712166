import React, { useEffect, useState } from "react";
import ReactModal from 'react-modal';

import axios from "axios";

import Validate from '../../../helpers/validate';
import authHeader from '../../../services/auth-header';
import { TextControl } from '../Controls';

function EditDocumentModal(props) {

    const [documentName, setDocumentName] = useState('');
    const [documentDescription, setDocumentDescription] = useState('');
    const [belongsTo, setBelongsTo] = useState('');
    const [errorMessage, setErrorMessage] = useState({});

    useEffect(() => {
        if (props.isOpen) {
            setDocumentName(props.currentDocument.documentName);
            setDocumentDescription(props.currentDocument.documentDescription);
            setBelongsTo(props.currentDocument.belongsTo);
        }
    }, [props.isOpen]);

    async function handleSubmit() {
        const validate = new Validate();
        const message = {};
        message.documentName = validate.init(documentName, {
            maxLength: {
                value: 50,
                message: 'The maximum length possible is 50 characters'
            },
            minLength: {
                value: 1,
                message: 'The minimum length possible is 1 character'
            }
        }).getHTML();
        message.documentDescription = validate.init(documentDescription, {
            maxLength: {
                value: 150,
                message: 'The maximum length possible is 150 characters'
            },
            minLength: {
                value: 1,
                message: 'The minimum length possible is 1 character'
            }
        }).getHTML();
        message.belongsTo = validate.init(belongsTo, {
            maxLength: {
                value: 100,
                message: 'The maximum length possible is 100 characters'
            },
            minLength: {
                value: 1,
                message: 'The minimum length possible is 1 character'
            }
        }).getHTML();
        setErrorMessage(message);

        if (validate.status) {
            await axios.put(process.env.REACT_APP_API_URL + `api/admin/updateDocument/${props.currentDocument.documentId}`, {
                documentName, documentDescription, belongsTo
            }, {
                headers: authHeader()
            });
            props.updateTable();
            props.onClose();
        }
    }

    function handleFocus(event) {
        const element = event.target.id,
            messages = errorMessage;
        messages[element] = null;
        setErrorMessage(messages);
    }

    function clearControls() {
        setDocumentName('');
        setDocumentDescription('');
        setBelongsTo('');
    }

    return (
        <ReactModal isOpen={props.isOpen} style={{ overlay: { zIndex: '999' } }}>
            <div className="container">
                <div className="row">
                    <div className="col s11 left hide-on-small-only" style={{ fontVariant: 'small-caps', fontFamily: 'Raleway', fontSize: '2rem' }}>
                        Create New Document
                    </div>
                    <div className="col s1 right">
                        <a onClick={() => {
                            clearControls();
                            props.onClose();
                        }} className="btn-floating waves-effect waves-light black white-text"><i className="material-icons">close</i></a>
                    </div>
                </div>
                <hr />
                <CustomRow>
                    <div className="input-field col s8" style={{ marginTop: '35px' }}>
                        <TextControl id="documentName" name="documentName" value={documentName} inputEventHandler={setDocumentName} focusEventHandler={handleFocus} maxlength="254" />
                        <label className="active" htmlFor="documentName">Document Name</label>
                        <span data-assigned="documentName" className="helper-text red-text text-darken-1">{errorMessage.documentName}</span>
                    </div>
                </CustomRow>
                <CustomRow>
                    <div className="input-field col s8" style={{ marginTop: '35px' }}>
                        <TextControl id="documentDescription" name="documentDescription" value={documentDescription} inputEventHandler={setDocumentDescription} focusEventHandler={handleFocus} maxlength="254" />
                        <label className="active" htmlFor="documentDescription">Document Description</label>
                        <span data-assigned="documentDescription" className="helper-text red-text text-darken-1">{errorMessage.documentDescription}</span>
                    </div>
                </CustomRow>
                <CustomRow>
                    <div className="input-field col s8" style={{ marginTop: '35px' }}>
                        <TextControl id="belongsTo" name="belongsTo" value={belongsTo} inputEventHandler={setBelongsTo} focusEventHandler={handleFocus} maxlength="254" />
                        <label className="active" htmlFor="belongsTo">Belongs to role(s)</label>
                        <span data-assigned="belongsTo" className="helper-text red-text text-darken-1">{errorMessage.belongsTo}</span>
                    </div>
                </CustomRow>
                <CustomRow>
                    <div className="input-field col s8">
                        <a onClick={handleSubmit} style={{ width: '100%', fontFamily: 'Raleway', fontSize: '1rem', backgroundColor: 'black', marginTop: '30px' }} className="waves-effect waves-light btn z-depth-0 white-text">SAVE & CLOSE</a>
                    </div>
                </CustomRow>
            </div>
        </ReactModal>
    );
}

function CustomRow(props) {
    return (
        <div className={`row ${props.className}`}>
            <div className="col s2"></div>
            {props.children}
            <div className="col s2"></div>
        </div>
    );
}

export default EditDocumentModal;