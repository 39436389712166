import React from "react";

function FAQ() {
    return (
        <div className="container">
            <div className="row">
                <div className="col s12">
                    <ul class="collapsible">
                        <li>
                            <div class="collapsible-header"><i class="material-icons">location_city</i>Which cities do you service?</div>
                            <div class="collapsible-body">
                                <p>Our coverage area includes:</p>
                                <p className="valign-wrapper"><i class="material-icons">star</i>Phoenix, AZ</p>
                                <p className="valign-wrapper"><i class="material-icons">star</i>Goodyear, AZ</p>
                                <p className="valign-wrapper"><i class="material-icons">star</i>Avondale, AZ</p>
                                <p className="valign-wrapper"><i class="material-icons">star</i>Tempe, AZ</p>
                                <p className="valign-wrapper"><i class="material-icons">star</i>Mesa, AZ</p>
                                <p className="valign-wrapper"><i class="material-icons">star</i>Chandler, AZ</p>
                                <p className="valign-wrapper"><i class="material-icons">star</i>Scottsdale, AZ</p>
                                <p className="valign-wrapper"><i class="material-icons">star</i>Peoria, AZ</p>
                            </div>
                        </li>
                        <li>
                            <div class="collapsible-header"><i class="material-icons">local_offer</i>What do you offer?</div>
                            <div class="collapsible-body">
                                <p>Elite Taxi Cab offers service throughout the valley, the number of units that are being handled in the area allows us to provide excellent service to the Phoenix Metropolitan area, our average waiting time is 15 to 20 minutes.</p>
                            </div>
                        </li>
                        <li>
                            <div class="collapsible-header"><i class="material-icons">local_taxi</i>How do you book a taxi?</div>
                            <div class="collapsible-body">
                                <p>You can book an Elite Taxi Cab, by Calling Us at 602-307-5555</p>
                            </div>
                        </li>
                        <li>
                            <div class="collapsible-header"><i class="material-icons">search</i>I've lost an item, how do I recover it?</div>
                            <div class="collapsible-body">
                                <p>if you feel you have left property behind in one of our taxi cabs, please contact us, or give us a call at 602-307-5555 to file a claim.</p>
                                <p>When confirmed that your property was found, you can come by and pick up at our main offices, at 2040 W Buckeye RdPhoenix, AZ 85009</p>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

export { FAQ };