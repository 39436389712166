import React, { useState, useEffect } from "react";
import ReactModal from 'react-modal';
import axios from "axios";

import authHeader from '../../../services/auth-header';

function EventDetailsModal(props) {

    const [comments, setComments] = useState('');

    useEffect(() => {
        if (props.isOpen)
            setComments(props.currentEvent.comments);
    }, [props.isOpen])

    async function handleAccept() {
        await axios.put(process.env.REACT_APP_API_URL + `api/admin/updatApplicanteState/${props.currentEvent.driverId}`, {
            statusId: 9,
            comments: comments ? comments : null
        }, {
            headers: authHeader()
        });

        setComments('');
        props.updateFields();
        props.onClose();
    }

    async function handleDecline() {
        await axios.put(process.env.REACT_APP_API_URL + `api/admin/updatApplicanteState/${props.currentEvent.driverId}`, {
            statusId: 8,
            comments: comments ? comments : null
        }, {
            headers: authHeader()
        });

        setComments('');
        props.updateFields();
        props.onClose();
    }

    function handleReschedule() {
        const updateStates = props.updateStates;
        updateStates.setCurrentApplicant(props.currentEvent);
        updateStates.setReschedule({ comments });
        props.onClose();
        updateStates.openScheduleModal(true);
    }

    return (
        <ReactModal isOpen={props.isOpen} style={{ overlay: { zIndex: '999' } }}>
            <div className="container">
                <div className="row">
                    <div className="col s11 left hide-on-small-only" style={{ fontVariant: 'small-caps', fontFamily: 'Raleway', fontSize: '2rem' }}>
                        Event Details
                    </div>
                    <div className="col s1 right">
                        <a onClick={() => {
                            props.onClose();
                        }} className="btn-floating waves-effect waves-light black white-text"><i className="material-icons">close</i></a>
                    </div>
                </div>
                <hr />
                <CustomRow>
                    <div className="input-field col s8">
                        <input id='name' value={`${props.currentEvent.firstName} ${props.currentEvent.lastName}`} type="text" />
                        <label className='active' htmlFor="name">Applicant name</label>
                    </div>
                </CustomRow>
                <CustomRow>
                    <div className="input-field col s8">
                        <input id='email' value={props.currentEvent.email} type="text" />
                        <label className='active' htmlFor="email">Email</label>
                    </div>
                </CustomRow>
                <CustomRow>
                    <div className="input-field col s8">
                        <input id='phone' value={props.currentEvent.phone} type="text" />
                        <label className='active' htmlFor="phone">Phone</label>
                    </div>
                </CustomRow>
                <CustomRow>
                    <div className="input-field col s4">
                        <input id='date' value={props.currentEvent.date} type="text" />
                        <label className='active' htmlFor="date">Event date</label>
                    </div>
                    <div className="col s4 input-field">
                        <input id='time' value={`${props.currentEvent.hour}:${props.currentEvent.minutes}`} type="text" />
                        <label className='active' htmlFor="time">Event time</label>
                    </div>
                </CustomRow>
                <CustomRow>
                    <div className="input-field col s8">
                        <input id='comments' value={comments} onChange={(event) => setComments(event.target.value)} type="text" />
                        <label className={`${comments ? 'active' : ''}`} htmlFor="date">Comment (optional)</label>
                        <span className="helper-text">You may edit the comments for future actions</span>
                    </div>
                </CustomRow>
                <div className="row">
                    <div className="col s3"></div>
                    <div className="input-field col s2">
                        <a onClick={handleAccept} style={{ width: '100%', fontFamily: 'Raleway', fontSize: '1rem', marginTop: '30px' }} className="waves-effect waves-light btn z-depth-0 white-text">ACCEPT</a>
                    </div>
                    <div className="input-field col s2">
                        <a onClick={handleReschedule} style={{ width: '100%', fontFamily: 'Raleway', fontSize: '1rem', marginTop: '30px' }} className="waves-effect waves-light btn z-depth-0 black white-text">RESCHEDULE</a>
                    </div>
                    <div className="input-field col s2">
                        <a onClick={handleDecline} style={{ width: '100%', fontFamily: 'Raleway', fontSize: '1rem', marginTop: '30px' }} className="waves-effect waves-light btn z-depth-0 black white-text">DECLINE</a>
                    </div>
                    <div className="col s3"></div>
                </div>
            </div>
        </ReactModal>
    );
}

function CustomRow(props) {
    return (
        <div className={`row ${props.className}`}>
            <div className="col s2"></div>
            {props.children}
            <div className="col s2"></div>
        </div>
    );
}

export default EventDetailsModal;