import React from "react";

function TextControl(props) {
    return (
        <input name={props.name} maxlength={props.maxlength} type="text" value={props.value} onChange={(event) => props.inputEventHandler(event.target.value)} onFocus={(event) => props.focusEventHandler(event)} id={props.id} autocomplete="off" />
    );
}

function CheckBoxControl(props) {
    return (
        <div className="switch">
            <span className="left" style={{ marginRight: '20px' }}>{props.title}</span>
            <label className="right">
                Off
                <input name={props.name} type="checkbox" checked={props.checked} onChange={(event) => props.inputEventHandler(event.target.checked)} />
                <span class="lever"></span>
                On
            </label>
        </div>
    );
}

function SelectControl(props) {
    return (
        <select className="browser-default" value={props.value} style={props.style} onChange={(event) => {
            props.inputEventHandler(event.target.value);
            props.resetEventHandler && props.resetEventHandler(0);
        }} onFocus={(event) => props.focusEventHandler(event)} id={props.id}>
            {props.defaultOption && <option value="0">Select {props.title}...</option>}
            {props.children}
        </select>
    );
}

export { TextControl, CheckBoxControl, SelectControl };