import React from "react";
import { Switch, Route } from "react-router-dom";
import { useSelector } from "react-redux";

import Dashboard from "./operator/dashboard";
import { Redirect } from 'react-router-dom';

const BoardUser = () => {

  const { user: currentUser } = useSelector((state) => state.auth);
  if (currentUser) {
    if(!currentUser.roles.includes("ROLE_OPERATOR")){
      return <Redirect to="/login" />;
    }
  }else{
     return <Redirect to="/login" />;
  }

  return (
<div id="main" class="" style={{paddingLeft:'0px'}}>
  <link rel="stylesheet" type="text/css" href="/app-assets/css/custom/operator.css"/>
  <div class="section" style={{paddingTop:'0px'}} >
    <Switch>
      <Route exact path={["/operator", "/operator/"]} component={Dashboard} />
    </Switch>
  </div>
</div>
  );
};

export default BoardUser;



