import React, { useState, useEffect, useRef } from "react";

import L from 'leaflet';
import axios from "axios";

import { Driver } from '../helpers/driver';

function DashboardMap() {

    const divRef = useRef();
    let map, timer, drivers = {};

    const hqIcon = L.icon({
        iconUrl: 'app-assets/images/gallery/hq-icon.png',
        iconSize: [80, 80],
        iconAnchor: [22, 20],
        popupAnchor: [18, -20]
    });

    useEffect(() => {
        map = L.map(divRef.current, {
            center: [33.05811, -112.04764],
            zoom: 13
        })

        L.tileLayer('https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}', {
            attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
            maxZoom: 18,
            id: 'mapbox/streets-v11',
            tileSize: 512,
            zoomOffset: -1,
            accessToken: 'pk.eyJ1IjoiYXJ0dXJvY2xhcmsiLCJhIjoiY2trNjV2dWphMDB1ZzJycG1kbHRoMzV3ayJ9.mQQhBJmIZJZ4ZtFCn_zZoQ'
        }).addTo(map);

        const hqMarker = L.marker([33.4260903, -112.1179726], {
            title: 'Elite Taxi Cab Headquarters',
            icon: hqIcon
        }).addTo(map);

        hqMarker.bindPopup("<b>Elite Taxi Cab</b><br>Headquarters");

        Driver.map = map;
        fetchDriversCoords();
        timer = setInterval(fetchDriversCoords, 3000);
        return () => clearTimeout(timer);
    }, []);

    const fetchDriversCoords = async () => {
        const result = await fetch('api/dashboard/driverLocation', 'get');
        if (result) {
            for (const data of result.data) {
                if (!drivers[data.driverId]) {
                    if (data.lastLatitude) {
                        const driver = new Driver(data.driverId, data.cab, data.driver);
                        driver.setStatus(data.statusId);
                        driver.setOriginLocation(data.originLatitude, data.originLongitude, data.originLocation);
                        driver.setCurrentLocation(data.lastLatitude, data.lastLongitude);
                        driver.setDestinationLocation(data.destinationLatitude, data.destinationLongitude, data.destinationLocation);
                        driver.createMapMarker();
                        drivers[data.driverId] = driver;
                    }
                    continue;
                }
                const driver = drivers[data.driverId];
                driver.setStatus(data.statusId);
                driver.setCurrentLocation(data.lastLatitude, data.lastLongitude);
                driver.setOriginLocation(data.originLatitude || 0, data.originLongitude || 0, data.originLocation || '');
                driver.setDestinationLocation(data.destinationLatitude || 0, data.destinationLongitude || 0, data.destinationLocation || '');
                driver.updateMapMarker();
            }
        }
    };

    const fetch = async (route, httpVerb, data) => {
        try {
            const result = await axios[httpVerb](process.env.REACT_APP_API_URL + route, data);
            return result;
        } catch (error) {
            return null;
        }
    }

    return (
        <div ref={divRef} className="z-depth-2 animate fadeLeft delay-5" style={{ height: '500px' }}></div>
    );
}

export { DashboardMap };