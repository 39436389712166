import React, { useEffect, useState } from "react";
import ReactModal from 'react-modal';

import axios from "axios";

import Table from '../../Table';
import authHeader from '../../../services/auth-header';
import { TextControl } from '../Controls';

function DetailsVehicleModal(props) {

    const [drivers, setDrivers] = useState([]);

    const [tablePage, setTablePage] = useState(0);
    const [tableSearchValue, setTableSearchValue] = useState('');
    const [tableSearchFields, setTableSearchFields] = useState(['firstName', 'lastName']);

    useEffect(() => {
        if (props.isOpen) {
            getDrivers();
        }
    }, [props.isOpen]);

    useEffect(() => {
        setTablePage(0);
    }, [tableSearchValue, tableSearchFields]);

    async function getDrivers() {
        let result = await axios.get(process.env.REACT_APP_API_URL + 'api/admin/viewDrivers/1', { headers: authHeader() })
        if (result)
            setDrivers(result.data);
    }

    function getDriverRow(driver) {
        return (
            <tr key={driver.driverId}>
                <td className="center-align">{driver.firstName} {driver.lastName}</td>
                <td className="center-align">{(driver.vehicleId) ? `#${driver.cab}` : 'None'}</td>
                <td className="center-align">
                    <a className="waves-effect waves-light btn" disabled={driver.driverId == props.vehicle.driverId} onClick={() => handleAssignVehicle(driver)}>
                        <i class="material-icons left">check</i>{driver.driverId == props.vehicle.driverId ? 'Already assigned' : 'Assign vehicle'}
                    </a>
                </td>
            </tr>
        );
    }

    async function handleAssignVehicle(driver) {
        props.vehicle.driverId = driver.driverId;
        props.vehicle.firstName = driver.firstName;
        props.vehicle.lastName = driver.lastName;

        getDrivers();
        await axios.put(process.env.REACT_APP_API_URL + `api/admin/linkVehicle/${driver.driverId}`, {
            vehicleId: props.vehicle.vehicleId
        }, {
            headers: authHeader()
        });

        props.updateTable();
    }

    return (
        <ReactModal isOpen={props.isOpen} style={{ overlay: { zIndex: '999' } }}>
            <div className="container">
                <div className="row">
                    <div className="col s11 left hide-on-small-only" style={{ fontVariant: 'small-caps', fontFamily: 'Raleway', fontSize: '2rem' }}>
                        Details
                    </div>
                    <div className="col s1 right">
                        <a onClick={() => {
                            props.onClose();
                            setTableSearchValue('');
                        }} className="btn-floating waves-effect waves-light black white-text"><i className="material-icons">close</i></a>
                    </div>
                </div>
                <hr />
                <div className="row" style={{ marginTop: '15px' }}>
                    <div className="input-field col s6">
                        <input id="cab" type="text" value={props.vehicle.cab} />
                        <label className="active" htmlFor="cab">Cab #</label>
                    </div>
                    <div className="input-field col s6">
                        <input id="color" type="text" value={props.vehicle.color} />
                        <label className="active" htmlFor="color">Color</label>
                    </div>
                </div>
                <div className="row">
                    <div className="input-field col s6">
                        <input id="vehicleBrand" type="text" value={props.vehicle.vehicleBrand} />
                        <label className="active" htmlFor="vehicleBrand">Vehicle Brand</label>
                    </div>
                    <div className="input-field col s6">
                        <input id="vehicleEmissionTest" type="text" value={props.vehicle.vehicleEmissionTest} />
                        <label className="active" htmlFor="vehicleEmissionTest">Vehicle Emission Test</label>
                    </div>
                </div>
                <div className="row">
                    <div className="input-field col s6">
                        <input id="vehicleInsuranceData" type="text" value={props.vehicle.vehicleInsuranceData} />
                        <label className="active" htmlFor="vehicleInsuranceData">Vehicle Insurance Data</label>
                    </div>
                    <div className="input-field col s6">
                        <input id="vehicleModel" type="text" value={props.vehicle.vehicleModel} />
                        <label className="active" htmlFor="vehicleModel">Vehicle Model</label>
                    </div>
                </div>
                <div className="row">
                    <div className="input-field col s6">
                        <input id="vehiclePlates" type="text" value={props.vehicle.vehiclePlates} />
                        <label className="active" htmlFor="vehiclePlates">Vehicle Plates</label>
                    </div>
                    <div className="input-field col s6">
                        <input id="vehicleRegistration" type="text" value={props.vehicle.vehicleRegistration} />
                        <label className="active" htmlFor="vehicleRegistration">Vehicle Registration</label>
                    </div>
                </div>
                <div className="row">
                    <div className="input-field col s6">
                        <input id="vehicleTitle" type="text" value={props.vehicle.vehicleTitle} />
                        <label className="active" htmlFor="vehicleTitle">Vehicle Title</label>
                    </div>
                    <div className="input-field col s6">
                        <input id="vehicleType" type="text" value={props.vehicle.vehicleType} />
                        <label className="active" htmlFor="vehicleType">Vehicle Type</label>
                    </div>
                </div>
                {props.vehicle.driverId &&
                    <CustomRow>
                        <div className="col s8">
                            <div className="card green darken-1">
                                <div className="card-content white-text center-align">
                                    <p>This vehicle is currently assigned to: </p>
                                    <p>{props.vehicle.firstName} {props.vehicle.lastName}</p>
                                </div>
                            </div>
                        </div>
                    </CustomRow>
                }
                <div className="row" style={{ padding: '20px 0px' }}>
                    <div className="input-field col s12">
                        <TextControl id="tableSearchValue" value={tableSearchValue} inputEventHandler={setTableSearchValue} focusEventHandler={() => null} />
                        <label htmlFor="tableSearchValue">Search driver</label>
                    </div>
                </div>
                <div className="row z-depth-1 white" style={{ padding: '10px', margin: '20px 0px' }}>
                    <Table
                        style={{ tableLayout: 'auto' }}
                        tableHead={
                            <thead>
                                <tr className="center-align">
                                    <th className="center-align">Name</th>
                                    <th className="center-align">Cab assigned</th>
                                    <th className="center-align">Action</th>
                                </tr>
                            </thead>
                        }
                        tableBody={
                            (rows) => <tbody>{rows}</tbody>}
                        tableFoot={null}
                        row={getDriverRow}
                        data={drivers}
                        searchValue={tableSearchValue}
                        searchFields={tableSearchFields}
                        show={10}
                        page={tablePage}
                        pagination={(pageRowAmount) => {
                            return (
                                <div className="row" style={{ marginTop: '20px' }}>
                                    <div className="col s12 left">
                                        <a onClick={() => setTablePage(tablePage - 1)} disabled={!tablePage} className="waves-effect waves-black btn-flat">Previous</a>
                                        {(tablePage > 1 &&
                                            <>
                                                <a onClick={() => setTablePage(0)} className="waves-effect waves-black btn-flat">1</a>
                                                <span> ... </span>
                                            </>)}
                                        {(tablePage > 0 &&
                                            <a onClick={() => setTablePage(tablePage - 1)} className="waves-effect waves-black btn-flat">{tablePage}</a>)}
                                        <a disabled={true} className="waves-effect waves-black btn-flat">{tablePage + 1}</a>
                                        {(tablePage + 1 < pageRowAmount &&
                                            <a onClick={() => setTablePage(tablePage + 1)} className="waves-effect waves-black btn-flat">{tablePage + 2}</a>)}
                                        {(tablePage + 2 < pageRowAmount &&
                                            <>
                                                <span> ... </span>
                                                <a onClick={() => setTablePage(pageRowAmount - 1)} className="waves-effect waves-black btn-flat">{pageRowAmount}</a>
                                            </>)}
                                        <a onClick={() => setTablePage(tablePage + 1)} disabled={!(tablePage + 1 < pageRowAmount)} className="waves-effect waves-black btn-flat">Next</a>
                                    </div>
                                </div>
                            );
                        }}
                    />
                </div>
            </div>
        </ReactModal>
    );
}

function CustomRow(props) {
    return (
        <div className={`row ${props.className}`}>
            <div className="col s2"></div>
            {props.children}
            <div className="col s2"></div>
        </div>
    );
}

export default DetailsVehicleModal;