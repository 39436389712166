import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

const Home = (props) => {
	const [content, setContent] = useState("");
	const [showPartnerBoard, setShowPartnerBoard] = useState(false);
	const [showManagerBoard, setShowManagerBoard] = useState(false);
	const [showBoardDriverBoard, setShowBoardDriverBoard] = useState(false);
	const [showBoardCustomerBoard, setShowBoardCustomerBoard] = useState(false);
	const [showDashboardBoard, setShowDashboardBoard] = useState(false);
	const [showBoardOperator, setShowBoardOperator] = useState(false);
	const { user: currentUser } = useSelector((state) => state.auth);

	useEffect(() => {
		/*UserService.getPublicContent().then(
		  (response) => {
			setContent(response.data);
		  },
		  (error) => {
			const _content =
			  (error.response && error.response.data) ||
			  error.message ||
			  error.toString();
	
			setContent(_content);
		  }
		);*/
	}, []);

	useEffect(() => {

		if (currentUser) {
			setShowDashboardBoard(currentUser.roles.includes("ROLE_DASHBOARD"));
			setShowPartnerBoard(currentUser.roles.includes("ROLE_PARTNER"));
			setShowManagerBoard(currentUser.roles.includes("ROLE_MANAGER"));
			setShowBoardDriverBoard(currentUser.roles.includes("ROLE_DRIVER"));
			setShowBoardCustomerBoard(currentUser.roles.includes("ROLE_COSTUMER"));
			setShowBoardOperator(currentUser.roles.includes("ROLE_OPERATOR"));
		}
	}, [currentUser]);

	return (
		<div className="container">
			<link rel="stylesheet" href="/app-assets/css/custom/home.css" />
			<div className="row same-height">
				<div className="col s12 m12 l6 same-height">
					<div className="card same-height animate fadeLeft delay-1">
						<div className="card-content">
							<span className="card-title center-align black-text" style={{ fontWeight: 'bold', fontFamily: '"Raleway", sans serif', fontVariant: 'small-caps', fontSize: '1.4rem' }}><span className="new badge green darken-2" data-badge-caption="We are hiring!"></span>Jobs</span>
							<div>
								<p>Join us as a driver or as an independent contractor, and by working with us receive the following benefits:</p><br />
								<div className="valign-wrapper"><i className="green-text lighten-1 material-icons">check_circle</i>Become your own boss</div>
								<div className="valign-wrapper"><i className="green-text lighten-1 material-icons">check_circle</i>You decide your schedule</div>
								<div className="valign-wrapper"><i className="green-text lighten-1 material-icons">check_circle</i>Take control of your working conditions</div>
								<div className="valign-wrapper"><i className="green-text lighten-1 material-icons">check_circle</i>Variety in your work</div>
								<div className="valign-wrapper"><i className="green-text lighten-1 material-icons">check_circle</i>Excellent economical benefits</div>
								<p>You can find more information in the links below:</p>
							</div>
						</div>
						<div className="card-action center-align">
							<a href="/register-form/0">Become an Independent Contractor</a>
							<a href="/register-form/1">Become a Driver</a>
						</div>
					</div>
				</div>
				<div className="col s12 m12 l6 same-height">
					<div className="card same-height animate fadeRight delay-1">
						<div className="card-content">
							<span className="card-title center-align black-text" style={{ fontWeight: 'bold', fontFamily: '"Raleway", sans serif', fontVariant: 'small-caps', fontSize: '1.4rem' }} id='mobile-app'>Request on mobile</span>
							<p>You can book your cab on your mobile device.</p>
							<p>Using the app brings you the following benefits:</p><br />
							<div className="valign-wrapper"><i className="blue-text text-darken-3 material-icons">local_taxi</i>Easy and fast taxi booking</div>
							<div className="valign-wrapper"><i className="blue-text text-darken-3 material-icons">message</i>Receive an instant text when we are on our way</div>
							<div className="valign-wrapper"><i className="blue-text text-darken-3 material-icons">map</i>Once your driver arrives, you'll receive a second text notification</div>
							<p>Don't miss on the opportunity, request your taxi now!</p>
							<br />
							<p>Download the app:</p>
						</div>
						<div className="card-action center-align">
							<a href={props.appDownloadURLs.android}>Android</a>
							<a href={props.appDownloadURLs.ios}>iOS</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Home;