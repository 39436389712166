export const GET_ALL_APPLICANTS_DRIVERS_START = "GET_ALL_APPLICANTS_DRIVERS_START";
export const GET_ALL_APPLICANTS_DRIVERS_SUCCESS = "GET_ALL_APPLICANTS_DRIVERS_SUCCESS";
export const GET_ALL_APPLICANTS_DRIVERS_FAIL = "GET_ALL_APPLICANTS_DRIVERS_FAIL";

export const GET_ALL_APPLICANTS_ASSOCIATES_START = "GET_ALL_APPLICANTS_ASSOCIATES_START";
export const GET_ALL_APPLICANTS_ASSOCIATES_SUCCESS = "GET_ALL_APPLICANTS_ASSOCIATES_SUCCESS";
export const GET_ALL_APPLICANTS_ASSOCIATES_FAIL = "GET_ALL_APPLICANTS_ASSOCIATES_FAIL";

export const SAVE_DATE_DRIVERS_START = "SAVE_DATE_DRIVERS_START";
export const SAVE_DATE_DRIVERS_SUCCESS = "SAVE_DATE_DRIVERS_SUCCESS";
export const SAVE_DATE_DRIVERS_FAIL = "SAVE_DATE_DRIVERS_FAIL";

export const SAVE_DATE_ASSOCIATES_START = "SAVE_DATE_ASSOCIATES_START";
export const SAVE_DATE_ASSOCIATES_SUCCESS = "SAVE_DATE_ASSOCIATES_SUCCESS";
export const SAVE_DATE_ASSOCIATES_FAIL = "SAVE_DATE_ASSOCIATES_FAIL";


export const SAVE_APPLICANTS_START = "SAVE_APPLICANTS_START";
export const SAVE_APPLICANTS_SUCCESS = "SAVE_APPLICANTS_SUCCESS";
export const SAVE_APPLICANTS_FAIL = "SAVE_APPLICANTS_FAIL";

export const GET_DATE_TODAY_START = "GET_DATE_TODAY_START";
export const GET_DATE_TODAY_SUCCESS = "GET_DATE_TODAY_SUCCESS";
export const GET_DATE_TODAY_FAIL = "GET_DATE_TODAY_FAIL";

export const GET_EVENTS_BY_MONTH_START = "GET_EVENTS_BY_MONTH_START";
export const GET_EVENTS_BY_MONTH_SUCCESS = "GET_EVENTS_BY_MONTH_SUCCESS";
export const GET_EVENTS_BY_MONTH_FAIL = "GET_EVENTS_BY_MONTH_FAIL";

export const GET_USERS_START = "GET_USERS_START";
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
export const GET_USERS_FAIL = "GET_USERS_FAIL";