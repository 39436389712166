import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

import Settings from './Settings';
import Users from './Users';
import Vehicles from './Vehicles';
import Bonus from './Bonus';
import Voucher from './Voucher';
import VoucherCodes from './VoucherCodes';
import Applicants from './applicants/Applicants';
import EventStatistics from './EventStatistics';
import VehiclePlateExpiration from './VehiclePlateExpiration';
import Transactions from "./Transactions";
import Incidents from "./Incidents";
import Customers from "./Customers";
import Drivers from "./Drivers";
import Documents from "./Documents";

function Manager() {

    const { user: currentUser } = useSelector((state) => state.auth);
    if (currentUser) {
        if (!currentUser.roles.includes("ROLE_MANAGER"))
            return <Redirect to="/login" />;
    }
    else
        return <Redirect to="/login" />;

    return (
        <>
            <Route exact path="/manager">
                <div className="row">
                    <div className="col l6 s12">
                        <EventStatistics />
                        <VehiclePlateExpiration />
                    </div>
                    <div className="col l6 s12">
                        <div className="row center-align">
                            <LinkCard href='/manager/members' title='MEMBERS' icon='person' animationDelay='1' />
                            <LinkCard href='/manager/customers' title='CUSTOMERS' icon='person' animationDelay='2' />
                            <LinkCard href='/manager/vehicles' title='VEHICLES' icon='directions_car' animationDelay='3' />
                            <LinkCard href='/manager/drivers' title='DRIVERS' icon='directions_car' animationDelay='4' />
                        </div>
                        <div className="row center-align">
                            <LinkCard href='/manager/bonus' title='BONUS' icon='card_giftcard' animationDelay='1' />
                            <LinkCard href='/manager/applicants' title='APPLICANTS' icon='drafts' animationDelay='2' />
                            <LinkCard href='/manager/voucher' title='VOUCHER' icon='card_membership' animationDelay='3' />
                            <LinkCard href='/manager/voucher/codes' title='RESPONSE CODES' icon='note' animationDelay='4' />
                        </div>
                        <div className="row center-align">
                            <LinkCard href='/manager/settings' title='SETTINGS' icon='settings' animationDelay='1' />
                            <LinkCard href='/manager/incidents' title='INCIDENTS' icon='search' animationDelay='2' />
                            <LinkCard href='/manager/transactions' title='TRANSACTIONS' icon='attach_money' animationDelay='3' />
                            <LinkCard href='/manager/documents' title='DOCUMENTS' icon='attach_file' animationDelay='4' />
                        </div>
                    </div>
                </div>
            </Route>
            <Route exact path="/manager/settings">
                <Settings />
            </Route>
            <Route exact path="/manager/members">
                <Users />
            </Route>
            <Route exact path="/manager/customers">
                <Customers />
            </Route>
            <Route exact path="/manager/vehicles">
                <Vehicles />
            </Route>
            <Route exact path="/manager/drivers">
                <Drivers />
            </Route>
            <Route exact path="/manager/bonus">
                <Bonus />
            </Route>
            <Route exact path="/manager/voucher">
                <Voucher />
            </Route>
            <Route exact path="/manager/voucher/codes">
                <VoucherCodes />
            </Route>
            <Route exact path="/manager/applicants">
                <Applicants />
            </Route>
            <Route exact path="/manager/transactions">
                <Transactions />
            </Route>
            <Route exact path="/manager/incidents">
                <Incidents />
            </Route>
            <Route exact path="/manager/documents">
                <Documents />
            </Route>
        </>
    );
}
export default Manager;

function LinkCard(props) {
    return (
        <a href={props.href} className={`col l6 m6 s12 animate fadeLeft delay-${props.animationDelay}`} style={{ marginTop: '15px', marginBottom: '15px' }}>
            <div className="z-depth-1 white" style={{ padding: '15px', maxHeight: '180px', height: '180px', minHeight: '180px' }}>
                <div className="row">
                    <i className="material-icons col s12" style={{ fontSize: '3rem' }}>{props.icon}</i>
                </div>
                <div className="row">
                    <p className="col s12" style={{ fontSize: '1.3rem', fontWeight: 'bold', fontVariant: 'small-caps', fontFamily: 'Raleway' }}>{props.title}</p>
                </div>
            </div>
        </a>
    );
}