export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const REGISTER_VEHICLE_SUCCESS = "REGISTER_VEHICLE_SUCCESS";
export const REGISTER_VEHICLE_FAIL = "REGISTER_VEHICLE_FAIL";

export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";

export const SET_MESSAGE = "SET_MESSAGE";
export const SET_DRIVER = "SET_DRIVER";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";

export const START_GET_COUNTIES = "START_GET_COUNTIES";
export const SUCCESS_GET_COUNTIES = "SUCCESS_GET_COUNTIES";
export const FAIL_GET_COUNTIES = "FAIL_GET_COUNTIES";

export const START_GET_CITIES = "START_GET_CITIES";
export const SUCCESS_GET_CITIES = "SUCCESS_GET_CITIES";
export const FAIL_GET_CITIES = "FAIL_GET_CITIES";