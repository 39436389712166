import axios from "axios";
import authHeader from "./auth-header";

const getArizonaCounties = () => {
	return axios.get(process.env.REACT_APP_API_URL + "api/catalog/counties")
	.then((response) => {
    	return response;
  	});
};

const getArizonaCitiesByCounty = (countyId) => {
	return axios.get(process.env.REACT_APP_API_URL + "api/catalog/cities/"+countyId)
	.then((response) => {
    	return response;
  	});
};

const register = (type,firstName, lastName, email, phone, howfindus, number, nsew, street, zipCode, cityId, workExperience) => {
  return axios.post(process.env.REACT_APP_API_URL + "api/driver/", {
    type,
    firstName,
    lastName,
    email,
    phone,
    howfindus,
    number, 
    nsew, 
    street, 
    zipCode,
    cityId,
    workExperience
  })
  .then((response) => {
    return response;
  });
};

const registerVehicle = (type, firstName, lastName, email, phone, howfindus, number, nsew, street, zipCode,cityId, vehicleBrand, vehicleModel, vehicleInsuranceData, vehicleEmissionTest, vehicleTitle, vehiclePlates, workExperience) => {
  return axios.post(process.env.REACT_APP_API_URL + "api/driver/", {
    type,
    firstName,
    lastName,
    email,
    phone,
    howfindus,
    number, 
    nsew, 
    street, 
    zipCode, 
    cityId,
    vehicleBrand,
    vehicleModel,
    vehicleInsuranceData,
    vehicleEmissionTest,
    vehicleTitle,
    vehiclePlates,
    workExperience
  })
  .then((response) => {
    return response;
  });
};


const login = (username, password) => {
  return axios.post(process.env.REACT_APP_API_URL + "api/auth/signin",{
      username,
      password,
    })
    .then((response) => {

      console.log("response");
      console.log(response);

      if (response.data.token) {
        localStorage.setItem("user", JSON.stringify(response.data));
      }

      return response.data;
    });
};

const logout = () => {
  localStorage.removeItem("user");
};

const checkLogin = (username, password) => {
  return axios({
    method: 'get',
		url: process.env.REACT_APP_API_URL + "api/auth/check",
		headers: authHeader(),
		data: {}
	  }).then((response) => {
    	return response;
  	});
};

export default {
  register,
  registerVehicle,
  login,
  logout,
  getArizonaCounties,
  getArizonaCitiesByCounty,
  checkLogin
};