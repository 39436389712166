import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  REGISTER_VEHICLE_SUCCESS,
  REGISTER_VEHICLE_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  SET_MESSAGE,
  SET_DRIVER,
  START_GET_COUNTIES,
  SUCCESS_GET_COUNTIES,
  FAIL_GET_COUNTIES,
  START_GET_CITIES,
  SUCCESS_GET_CITIES,
  FAIL_GET_CITIES,
} from "./types";

import AuthService from "../services/auth.service";

export const getArizonaCounties = () => (dispatch) => {
	dispatch({
		type: START_GET_COUNTIES,
  });
	return AuthService.getArizonaCounties()
	.then(
    (response) => {
		dispatch({
			type: SUCCESS_GET_COUNTIES,
			payload: response.data,
		});
		return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: FAIL_GET_COUNTIES,
        payload: message,
      });

      return Promise.reject();
    }
  );
}

export const getArizonaCitiesByCounty = (countyId) => (dispatch) => {
	dispatch({
		type: START_GET_CITIES,
  });
	return AuthService.getArizonaCitiesByCounty(countyId)
	.then(
    (response) => {
		dispatch({
			type: SUCCESS_GET_CITIES,
			payload: response.data,
		});
		return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: FAIL_GET_CITIES,
        payload: message,
      });

      return Promise.reject();
    }
  );
}

export const register = (type, firstname, lastname, email, phone, howfindus, number, nsew, street, zipCode, cityId, workExperience) => (dispatch) => {
  return AuthService.register(type, firstname, lastname, email, phone, howfindus, number, nsew, street, zipCode, cityId, workExperience).then(
    (response) => {
      dispatch({
        type: REGISTER_SUCCESS,
        payload: { newuser: response.data},
      });
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: REGISTER_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const registerVehicle = (type,firstname, lastname, email, phone, howfindus, number, nsew, street, zipCode, cityId, vehicleBrand, vehicleModel, vehicleInsuranceData, vehicleEmissionTest, vehicleTitle, vehiclePlates, workExperience) => (dispatch) => {
  return AuthService.registerVehicle(type,firstname, lastname, email, phone, howfindus, number, nsew, street, zipCode, cityId, vehicleBrand, vehicleModel, vehicleInsuranceData, vehicleEmissionTest, vehicleTitle, vehiclePlates, workExperience).then(
    (response) => {
      if(!isVehicleEmpty(vehicleBrand, vehicleModel, vehicleInsuranceData, vehicleEmissionTest, vehicleTitle, vehiclePlates))
      {
        dispatch({
          type: SET_MESSAGE,
          payload: 'The vehicle information must be filled in',
        });
        return Promise.reject();
      }

      dispatch({
        type: REGISTER_VEHICLE_SUCCESS,
        payload: { newvehicle: response.data} ,
      });
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: REGISTER_VEHICLE_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const login = (username, password) => (dispatch) => {
  return AuthService.login(username, password).then(
    (data) => {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: { user: data },
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: LOGIN_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const logout = () => (dispatch) => {
  AuthService.logout();

  dispatch({
    type: LOGOUT,
  });
};

export const checkLogin = () => (dispatch) => {
  return AuthService.checkLogin().then(
    (data) => {
      return Promise.resolve();
    },
    (error) => {
      return Promise.reject();
    }
  );
};

function isVehicleEmpty(...values)
{
  for (const value of values) {
    if(value == '')
      return false;
  }
  return true;
}
