import React, { useEffect, useState } from "react";
import ReactModal from 'react-modal';

import axios from "axios";

import Validate from '../../../helpers/validate';
import authHeader from '../../../services/auth-header';
import { TextControl } from '../Controls';

function UserModal(props) {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState({});
    const [confirmation, setDisplayConfirmation] = useState(false);

    function handleSubmit() {
        if (!props.member.userId) {
            insertUser();
            return;
        }
        if (confirmation) {
            updateUser();
            setDisplayConfirmation(false);
            clearControls();
            props.onClose();
            return;
        }
        setDisplayConfirmation(true);
    }

    function validateForm(validateUsername, validatePassword) {
        const validate = new Validate();
        const message = {};

        if (validateUsername) {
            message.username = validate.init(username, {
                maxLength: {
                    value: 100,
                    message: 'The maximum length possible of the first name is 100 characters'
                },
                minLength: {
                    value: 1,
                    message: 'The minimum length possible of the first name is 1 character'
                }
            }).getHTML();
        }
        if (validatePassword) {
            message.password = validate.init(password, {
                maxLength: {
                    value: 255,
                    message: 'The maximum length possible of the first name is 255 characters'
                },
                minLength: {
                    value: 1,
                    message: 'The minimum length possible of the first name is 1 character'
                }
            }).getHTML();
        }
        setErrorMessage(message);
        return validate.status;
    }

    function clearControls() {
        setPassword('');
        setUsername('');
        setErrorMessage({});
    }

    async function insertUser() {
        if (validateForm(true, true)) {
            const user = JSON.parse(localStorage.getItem('user'));
            if (user) {
                try {
                    let result = await axios.post(process.env.REACT_APP_API_URL + 'api/admin/newUser', {
                        memberId: props.member.memberId, username, password, createdBy: user.id
                    }, {
                        headers: authHeader()
                    });
                    result = result.data.insertId;
                    props.member.userId = result;

                    clearControls();
                    props.updateTable();
                    props.onClose();
                } catch (error) {
                    const serverError = JSON.parse(error.request.responseText).message;
                    setErrorMessage({ serverError: <span>{serverError}</span> });
                }
            }
        }
    }

    async function updateUser() {
        if (validateForm(false, true)) {
            const user = JSON.parse(localStorage.getItem('user'));
            if (user) {
                await axios.put(process.env.REACT_APP_API_URL + `api/admin/updatePassword/${props.member.userId}`, {
                    password
                }, {
                    headers: authHeader()
                });
            }
        }
    }

    function handleFocus(event) {
        const element = event.target.id,
            messages = errorMessage;
        messages[element] = null;
        setErrorMessage(messages);
    }

    return (
        <ReactModal isOpen={props.isOpen} style={{ overlay: { zIndex: '999' } }}>
            <div className="container">
                <div className="row">
                    <div className="col s11 left hide-on-small-only" style={{ fontVariant: 'small-caps', fontFamily: 'Raleway', fontSize: '2rem' }}>
                        {(props.member && props.member.role) ? "Edit existing user" : "Create New User"}
                    </div>
                    <div className="col s1 right">
                        <a onClick={props.onClose} className="btn-floating waves-effect waves-light black white-text"><i className="material-icons">close</i></a>
                    </div>
                </div>
                <hr />
                <CustomRow>
                    <div className="input-field col s8">
                        <TextControl id="name" value={`${props.member.firstName} ${props.member.lastName}`} inputEventHandler={() => null} focusEventHandler={() => null} maxlength="100" />
                        <label className="active" htmlFor="name">Name</label>
                    </div>
                </CustomRow>
                <CustomRow>
                    <div className="input-field col s8">
                        <TextControl name="username" id="username" value={props.member.userId ? props.member.username : username} inputEventHandler={!props.member.userId ? setUsername : () => null} focusEventHandler={handleFocus} maxlength="100" />
                        <label className="active" htmlFor="username">Username</label>
                        <span data-assigned="username" className="helper-text red-text text-darken-1">{errorMessage.username}</span>
                    </div>
                </CustomRow>
                <CustomRow>
                    <div className="input-field col s8">
                        <TextControl name="password" id="password" value={password} inputEventHandler={setPassword} focusEventHandler={handleFocus} maxlength="255" />
                        <label htmlFor="password">Set password</label>
                        <span data-assigned="password" className="helper-text red-text text-darken-1">{errorMessage.password}</span>
                    </div>
                </CustomRow>
                {confirmation &&
                    <CustomRow>
                        <div className="col s8">
                            <div className="card orange darken-4">
                                <div className="card-content white-text center-align">
                                    To confirm the modification of this user's password, click "Save & Close" again
                                </div>
                            </div>
                        </div>
                    </CustomRow>
                }
                <CustomRow>
                    <span className="col s8 center-align helper-text red-text text-darken-1">{errorMessage.serverError}</span>
                </CustomRow>
                <CustomRow>
                    <div className="input-field col s8">
                        <a onClick={handleSubmit} style={{ width: '100%', fontFamily: 'Raleway', fontSize: '1rem', backgroundColor: 'black', marginTop: '30px' }} className="waves-effect waves-light btn z-depth-0 white-text">SAVE & CLOSE</a>
                    </div>
                </CustomRow>
            </div>
        </ReactModal>
    );
}

function CustomRow(props) {
    return (
        <div className={`row ${props.className}`}>
            <div className="col s2"></div>
            {props.children}
            <div className="col s2"></div>
        </div>
    );
}


export default UserModal;