import axios from "axios";
import authHeader from "../auth-header";

const getNewServiceRate = () => {
	return axios.get(process.env.REACT_APP_API_URL + "api/service/estimaterate", { headers: authHeader() })
	.then((response) => {
    	return response;
  	});
};

const getArizonaCounties = () => {
	return axios.get(process.env.REACT_APP_API_URL + "api/catalog/counties")
	.then((response) => {
    	return response;
  	});
};

const getArizonaCitiesByCounty = (countyId) => {
	return axios.get(process.env.REACT_APP_API_URL + "api/catalog/cities/"+countyId)
	.then((response) => {
    	return response;
  	});
};

const loadCustomerByPhone = (phone) => {
	console.log('service');
	return axios.get(process.env.REACT_APP_API_URL + "api/service/customersbyphone/"+phone, { headers: authHeader() })
	.then((response) => {
    	return response;
  	});
};

const loadNewService = () => {
	console.log('service');
	return axios.get(process.env.REACT_APP_API_URL + "api/service/topics/", { headers: authHeader() })
	.then((response) => {
    	return response;
  	});
};

const getTopicsComments = () => {
	console.log('getTopicsComments');
	return axios.get(process.env.REACT_APP_API_URL + "api/service/topics/", { headers: authHeader() })
	.then((response) => {
    	return response;
  	});
};

const getAvailables = () => {
	console.log('getAvailables');
	return axios.get(process.env.REACT_APP_API_URL + "api/service/available", { headers: authHeader() })
	.then((response) => {
    	return response;
  	});
};

/*const saveService = (customer,comments) => {
	console.log('saveService');
	return axios.post(process.env.REACT_APP_API_URL + "api/service/request", { headers: authHeader() })
	.then((response) => {
    	return response;
  	});
};*/

const createNewCustomer = (newCustomer) => {
	return axios({
		method: 'post',
		url: process.env.REACT_APP_API_URL + "api/customer/",
		headers: authHeader(),
		data: newCustomer
	  }).then((response) => {
    	return response;
  	});
};

const createEmptyService = (customerId,userId) => {
	return axios({
		method: 'post',
		url: process.env.REACT_APP_API_URL + "api/service/emptyservice/",
		headers: authHeader(),
		data: {
			customerId:customerId,
			userId:userId
		}
	  }).then((response) => {
    	return response;
  	});
};

const saveService = (updateService) => {
	return axios({
		method: 'post',
		url: process.env.REACT_APP_API_URL + "api/service/update/",
		headers: authHeader(),
		data: updateService
	  }).then((response) => {
    	return response;
  	});
};

const createNewReservation = (data) => {
	return axios({
		method: 'post',
		url: process.env.REACT_APP_API_URL + "api/service/emptyreservation/",
		headers: authHeader(),
		data: data
	  }).then((response) => {
    	return response;
  	});
};

const createNewService = (customerId,paymentTypeId) => {
	return axios({
		method: 'post',
		url: process.env.REACT_APP_API_URL + "api/service/emptyservice/",
		headers: authHeader(),
		data: {
			customerId:customerId,
			paymentTypeId:paymentTypeId
		}
	  }).then((response) => {
    	return response;
  	});
};
const updateReservation = (arResevation) => {
	return axios({
		method: 'put',
		url: process.env.REACT_APP_API_URL + "api/operator/updatereservation/"+arResevation.reservationId,
		headers: authHeader(),
		data: arResevation
	  }).then((response) => {
    	return response;
  	});
};

const getLastOrigin = (customerId) => {
	return axios.get(process.env.REACT_APP_API_URL + "api/operator/lastorigins/"+customerId, { headers: authHeader() })
	.then((response) => {
    	return response;
  	});
};

const getLastDestination = (customerId) => {
	return axios.get(process.env.REACT_APP_API_URL + "api/operator/lastdestinations/"+customerId, { headers: authHeader() })
	.then((response) => {
    	return response;
  	});
};

export default {
	loadCustomerByPhone,
	loadNewService,
	getAvailables,
	saveService,
	getArizonaCounties,
	getArizonaCitiesByCounty,
	getNewServiceRate,
	createNewCustomer,
	getTopicsComments,
	createEmptyService,
	createNewReservation,
	createNewService,
	updateReservation,
	getLastOrigin,
	getLastDestination,
};