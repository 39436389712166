import React, { useEffect, useState } from "react";
import ReactModal from 'react-modal';

import axios from "axios";

import Validate from '../../../helpers/validate';
import authHeader from '../../../services/auth-header';
import { TextControl, SelectControl } from '../Controls';

function EditIncidentModal(props) {

    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [requeryAction, setRequeryAction] = useState('');
    const [errorMessage, setErrorMessage] = useState({});

    useEffect(() => {
        if (props.isOpen) {
            setName(props.currentIncident.name);
            setDescription(props.currentIncident.description);
            setRequeryAction(props.currentIncident.requeryAction);
        }
    }, [props.isOpen])

    async function handleSubmit() {
        const validate = new Validate();
        const message = {};
        message.name = validate.init(name, {
            maxLength: {
                value: 254,
                message: 'The maximum length possible is 254 characters'
            },
            minLength: {
                value: 1,
                message: 'The minimum length possible is 1 character'
            }
        }).getHTML();
        message.description = validate.init(description, {
            maxLength: {
                value: 254,
                message: 'The maximum length possible is 254 characters'
            },
            minLength: {
                value: 1,
                message: 'The minimum length possible is 1 character'
            }
        }).getHTML();
        setErrorMessage(message);

        if (validate.status) {
            await axios.put(process.env.REACT_APP_API_URL + `api/admin/updateIncident/${props.currentIncident.incId}`, {
                name, description, requeryAction
            }, {
                headers: authHeader()
            });
            props.updateTable();
            props.onClose();
        }
    }

    function handleFocus(event) {
        const element = event.target.id,
            messages = errorMessage;
        messages[element] = null;
        setErrorMessage(messages);
    }

    function clearControls() {
        setName('');
        setDescription('');
        setRequeryAction('');
    }

    return (
        <ReactModal isOpen={props.isOpen} style={{ overlay: { zIndex: '999' } }}>
            <div className="container">
                <div className="row">
                    <div className="col s11 left hide-on-small-only" style={{ fontVariant: 'small-caps', fontFamily: 'Raleway', fontSize: '2rem' }}>
                        Edit Incident Type
                    </div>
                    <div className="col s1 right">
                        <a onClick={() => {
                            clearControls();
                            props.onClose();
                        }} className="btn-floating waves-effect waves-light black white-text"><i className="material-icons">close</i></a>
                    </div>
                </div>
                <hr />
                <CustomRow>
                    <div className="input-field col s8" style={{ marginTop: '35px' }}>
                        <TextControl id="name" name="name" value={name} inputEventHandler={setName} focusEventHandler={handleFocus} maxlength="254" />
                        <label className="active" htmlFor="name">Name</label>
                        <span data-assigned="name" className="helper-text red-text text-darken-1">{errorMessage.name}</span>
                    </div>
                </CustomRow>
                <CustomRow>
                    <div className="input-field col s8">
                        <TextControl id="description" name="description" value={description} inputEventHandler={setDescription} focusEventHandler={handleFocus} maxlength="254" />
                        <label className="active" htmlFor="description">Description</label>
                        <span data-assigned="description" className="helper-text red-text text-darken-1">{errorMessage.description}</span>
                    </div>
                </CustomRow>
                <CustomRow>
                    <div className="input-field col s8" style={{ fontSize: '0.8em' }}>Action</div>
                </CustomRow>
                <CustomRow>
                    <div className="input-field col s8">
                        <SelectControl value={requeryAction} inputEventHandler={setRequeryAction} focusEventHandler={handleFocus} id="requeryAction" name="requeryAction">
                            <option value="0" selected>0</option>
                            <option value="1" selected>1</option>
                        </SelectControl>
                        <span data-assigned="requeryAction" className="helper-text red-text text-darken-1">{errorMessage.requeryAction}</span>
                    </div>
                </CustomRow>
                <CustomRow>
                    <div className="input-field col s8">
                        <a onClick={handleSubmit} style={{ width: '100%', fontFamily: 'Raleway', fontSize: '1rem', backgroundColor: 'black', marginTop: '30px' }} className="waves-effect waves-light btn z-depth-0 white-text">SAVE & CLOSE</a>
                    </div>
                </CustomRow>
            </div>
        </ReactModal>
    );
}

function CustomRow(props) {
    return (
        <div className={`row ${props.className}`}>
            <div className="col s2"></div>
            {props.children}
            <div className="col s2"></div>
        </div>
    );
}

export default EditIncidentModal;