import React, { useState, useEffect } from "react";
import { Redirect } from 'react-router-dom';
import { useSelector } from "react-redux";
import authHeader from "../../services/auth-header";

import axios from "axios";

function Settings() {
    const { user: currentUser } = useSelector((state) => state.auth);

    const [settings, setSettings] = useState([]);
    const [settingsValue, setSettingsValue] = useState({});
    const [errorMessage, setErrorMessage] = useState({});
    const [currentlyModifying, setCurrentlyModifying] = useState({});

    function isUserAllowed() {
        if (currentUser) {
            if (!currentUser.roles.includes("ROLE_MANAGER"))
                return <Redirect to="/login" />;
        }
        else
            return <Redirect to="/login" />;
    }
    isUserAllowed();

    useEffect(() => {
        getSettings();
    }, []);

    async function getSettings() {
        let result = await axios.get(process.env.REACT_APP_API_URL + 'api/catalog/settings', { headers: authHeader() })
        if (result) {
            const values = {};
            result.data.forEach((value) => values[value.setting] = value.value);
            setSettings(result.data);
            setSettingsValue(values);
        }
    }

    function getSettingsView() {
        return (
            <>
                {settings.map((value, index) => {
                    return (
                        <CustomRow key={value.settingId}>
                            <div className="input-field col s6">
                                {getInput(value, index)}
                            </div>
                        </CustomRow>
                    );
                })}
            </>
        );
    }

    function handleInput(value, setting, index) {
        const currentTimeout = currentlyModifying[setting.setting];
        if (currentTimeout) {
            clearTimeout(currentTimeout);
            setCurrentlyModifying((prevState) => ({
                ...prevState,
                [setting.setting]: null
            }));
        }
        setCurrentlyModifying((prevState) => ({
            ...prevState,
            [setting.setting]: setTimeout(() => submit(value, setting), 2000)
        }));
        setSettingsValue((prevState) => ({ ...prevState, [setting.setting]: value }))
    }

    async function submit(value, setting) {
        switch (setting.type) {
            case 'image': {
                const formData = new FormData();
                formData.append('file', value);
                formData.append('type', 'logo');
                await axios.post(process.env.REACT_APP_API_URL + 'api/file/upload', formData, {
                    headers: authHeader()
                });

                getSettings();
                break;
            }
            default: {
                await axios.put(process.env.REACT_APP_API_URL + `api/admin/settings/${setting.settingId}`, {
                    value
                }, {
                    headers: authHeader()
                });
            }
        }
    }

    function handleFocus(event) {
        const element = event.target.id,
            messages = errorMessage;
        messages[element] = null;
        setErrorMessage(messages);
    }

    function getInput(value, index) {
        switch (value.type) {
            case 'number': return getNumberInput(value, index);
            case 'boolean': return getBooleanInput(value, index);
            case 'image': return getImageInput(value, index);
            default: return getStringInput(value, index);
        }
    }

    function getStringInput(value, index) {
        return (
            <>
                <input
                    type="text" id={value.setting} value={settingsValue[value.setting]}
                    onChange={(event) => handleInput(event.target.value, value, index)}
                    onFocus={handleFocus}
                    maxLength={255}
                />
                <label className={(value.value) ? 'active' : ''} htmlFor={value.setting}>{value.viewName}</label>
                <span className="helper-text">{value.comment}</span>
                <span className="helper-text yellow-text text-darken-4">Value type: {value.type}</span>
                <span data-assigned={value.setting} className="helper-text red-text text-darken-1">{errorMessage[value.setting]}</span>
            </>
        );
    }

    function getImageInput(value, index) {
        return (
            <>
                <p>{value.viewName}{value.value.length > 0 && <> | <a target="_blank" href={process.env.REACT_APP_API_URL + value.value}>View current image</a></>}</p>
                <div className="file-field input-field">
                    <div className="btn">
                        <span>File</span>
                        <input onChange={(event) => handleInput(event.target.files[0], value, index)} type="file" id={value.setting} accept="image/*" />
                    </div>
                    <div className="file-path-wrapper">
                        <input className="file-path validate" type="text" placeholder="Upload an image" />
                    </div>
                </div>
                <span className="helper-text">{value.comment}</span>
                <span data-assigned="companyImage" className="helper-text red-text text-darken-1">{errorMessage[value.setting]}</span>
            </>
        );
    }

    function getNumberInput(value, index) {
        return (
            <>
                <input value={settingsValue[value.setting]} onChange={(event) => handleInput(event.target.value, value, index)} max={255} min={1} type="number" id={value.setting} />
                <label className={(value.value) ? 'active' : ''} htmlFor={value.setting}>{value.viewName}</label>
                <span className="helper-text">{value.comment}</span>
                <span className="helper-text yellow-text text-darken-4">Value type: {value.type}</span>
                <span data-assigned={value.setting} className="helper-text red-text text-darken-1">{errorMessage[value.setting]}</span>
            </>
        );
    }

    function getBooleanInput(value, index) {
        return (
            <>
                <div className="row">
                    <div className="col s12">
                        <div className="switch">
                            <span className="left" style={{ marginRight: '20px' }}>{value.viewName}</span>
                            <label className="right">
                                Off
                                <input id={value.setting} type="checkbox" checked={!(settingsValue[value.setting] == '0')} onChange={(event) => handleInput(+event.target.checked, value, index)} />
                                <span class="lever"></span>
                                On
                            </label>
                        </div>
                    </div>
                </div>
                <div className="row" style={{ marginBottom: '15px' }}>
                    <div className="col s12">
                        <span className="helper-text">{value.comment}</span>
                    </div>
                </div>
            </>
        );
    }

    return (
        <div>
            <div className="row valign-wrapper">
                <div className="col s12 center-align" style={{ fontVariant: 'small-caps', fontFamily: 'Raleway', fontSize: '3rem' }}>
                    Settings
                </div>
            </div>
            <hr />
            {getSettingsView()}
        </div>
    );
}

function CustomRow(props) {
    return (
        <div className={`row ${props.className}`}>
            <div className="col s3"></div>
            {props.children}
            <div className="col s3"></div>
        </div>
    );
}

export default Settings;