import React, { useState, useEffect } from "react";
import axios from "axios";

import { SelectControl } from './Controls';
import authHeader from '../../services/auth-header';

function DocumentManager(props) {

    const [documents, setDocuments] = useState([]);
    const [currentDocument, setCurrentDocument] = useState(0);
    const [currentFile, setCurrentFile] = useState();
    const [uploadedFile, setUploadedFile] = useState();
    const [uploadingState, setUploadingState] = useState();

    useEffect(() => {
        getDocuments();
    }, []);

    useEffect(() => {
        if (currentDocument)
            getUploadedFile();
    }, [currentDocument])

    async function getDocuments() {
        const result = await axios.get(process.env.REACT_APP_API_URL + 'api/admin/viewDocuments/1', { headers: authHeader() })
        if (result)
            setDocuments(result.data);
    }

    async function getUploadedFile() {
        const result = await axios.get(process.env.REACT_APP_API_URL + `api/admin/getAttachments/${currentDocument}/driverId/${props.driverId}`, { headers: authHeader() })
        if (result && result.data.length > 0) {
            setUploadedFile(result.data[0]);
            return;
        }
        setUploadedFile(null);
    }

    function onDocumentChange(documentId) {
        setCurrentDocument(documentId);
    }

    async function handleSubmit() {
        if (!currentFile)
            return;
        setUploadingState(true)

        const formData = new FormData();
        formData.append('file', currentFile);
        formData.append('documentId', currentDocument);
        formData.append('id', props.id);
        formData.append('driverId', props.driverId);

        const result = await axios.post(process.env.REACT_APP_API_URL + 'api/file/uploadAttachment', formData, {
            headers: authHeader()
        });
        setUploadingState(false);
        if (result.data)
            setUploadedFile({ url: result.data.file });
    }

    return (
        <>
            <div className="row">
                <div className="col s12 left hide-on-small-only" style={{ fontVariant: 'small-caps', fontFamily: 'Raleway', fontSize: '2rem' }}>
                    File manager
                </div>
            </div>
            <hr />
            <CustomRow>
                <div className="input-field col s8">
                    <SelectControl id="documents" value={currentDocument} inputEventHandler={onDocumentChange} focusEventHandler={() => null}>
                        <option value="0">Select document...</option>
                        {documents.map((value, index) => <option key={document.documentId} value={value.documentId}>{value.documentName}</option>)}
                    </SelectControl>
                </div>
            </CustomRow>
            {currentDocument != 0 &&
                <>
                    <CustomRow>
                        <div className="input-field col s8">
                            <p>Upload a file</p>
                        </div>
                    </CustomRow>
                    <CustomRow>
                        <div className="input-field col s5">
                            <div className="file-field input-field">
                                <div className="btn">
                                    <span>Choose</span>
                                    <input onChange={(event) => setCurrentFile(event.target.files[0])} type="file" />
                                </div>
                                <div className="file-path-wrapper">
                                    <input className="file-path validate" type="text" placeholder="File" />
                                </div>
                            </div>
                        </div>
                        <div className="input-field col s3">
                            <a onClick={handleSubmit} style={{ width: '100%', fontFamily: 'Raleway', fontSize: '1rem', backgroundColor: 'black', marginTop: '30px' }} className="waves-effect waves-light btn z-depth-0 white-text">
                                {uploadingState ? 'UPLOADING FILE...' : 'UPLOAD'}
                            </a>
                        </div>
                    </CustomRow>
                </>
            }
            {uploadedFile != null &&
                <CustomRow>
                    <div className="col s8">
                        <a target="_blank" href={process.env.REACT_APP_API_URL + uploadedFile.url}>Open current file</a>
                    </div>
                </CustomRow>
            }
        </>
    );
}

function CustomRow(props) {
    return (
        <div className={`row ${props.className}`}>
            <div className="col s2"></div>
            {props.children}
            <div className="col s2"></div>
        </div>
    );
}

export default DocumentManager;