import React, { useEffect, useState } from "react";
import ReactModal from 'react-modal';
import { DatePicker, TimePicker } from "react-materialize"
import axios from "axios";

import Validate from '../../../helpers/validate';
import authHeader from '../../../services/auth-header';
import { TextControl } from '../Controls';

function ScheduleModal(props) {

    const [comments, setComments] = useState('');
    const [errorMessage, setErrorMessage] = useState({});

    useEffect(() => {
        if (props.isOpen && props.reschedule)
            setComments(props.reschedule.comments || '');
    }, [props.isOpen])

    async function handleSubmit() {
        const validate = new Validate();
        const message = {}, date = getDate(), time = getTime();
        message.comments = validate.init(comments, {
            maxLength: {
                value: 100,
                message: 'The maximum length possible of the comments is 100 characters'
            },
            minLength: {
                value: 1,
                message: 'The minimum length possible of the comments is 1 character'
            }
        }).getHTML();
        message.date = validate.init(date, {
            maxLength: {
                value: 10,
                message: 'The date must be only 10 characters'
            },
            minLength: {
                value: 10,
                message: 'The date must be only 10 characters'
            }
        }).getHTML();
        message.time = validate.init(time, {
            maxLength: {
                value: 5,
                message: 'The date must be only 5 characters'
            },
            minLength: {
                value: 5,
                message: 'The date must be only 5 characters'
            }
        }).getHTML();
        setErrorMessage(message);

        if (validate.status) {
            await axios.put(process.env.REACT_APP_API_URL + `api/driver/interview/${props.currentApplicant.driverId}`, {
                bookDate: date,
                bookTime: time,
                comments: comments
            }, {
                headers: authHeader()
            });

            props.updateFields();
            props.onClose();
        }
    }

    function handleFocus(event) {
        const element = event.target.id,
            messages = errorMessage;
        messages[element] = null;
        setErrorMessage(messages);
    }

    function clearControls() {
        document.querySelector('#TimePicker-13').value = '';
        document.querySelector('#DatePicker-5').value = '';
        setComments('');
        setErrorMessage({});
    }

    function getTime() {
        return document.querySelector('#TimePicker-13').value;
    }

    function getDate() {
        return document.querySelector('#DatePicker-5').value;
    }

    return (
        <ReactModal isOpen={props.isOpen} style={{ overlay: { zIndex: '999' } }}>
            <div className="container">
                <div className="row">
                    <div className="col s11 left hide-on-small-only" style={{ fontVariant: 'small-caps', fontFamily: 'Raleway', fontSize: '2rem' }}>
                        {props.reschedule ? 'Reschedule' : 'Schedule'} Applicant - {props.currentApplicant.firstName} {props.currentApplicant.lastName}
                    </div>
                    <div className="col s1 right">
                        <a onClick={() => {
                            clearControls();
                            props.onClose();
                        }} className="btn-floating waves-effect waves-light black white-text"><i className="material-icons">close</i></a>
                    </div>
                </div>
                <hr />
                <CustomRow>
                    <div className="col s8 center-align">Date:</div>
                </CustomRow>
                <div className="center-align">
                    <DatePicker
                        style={{ width: '64.5%' }}
                        id="DatePicker-5"
                        options={{
                            autoClose: false,
                            container: null,
                            defaultDate: null,
                            disableDayFn: null,
                            disableWeekends: false,
                            events: [],
                            firstDay: 0,
                            format: 'yyyy-mm-dd',
                            i18n: {
                                cancel: 'Cancel',
                                clear: 'Clear',
                                done: 'Ok',
                                months: [
                                    'January',
                                    'February',
                                    'March',
                                    'April',
                                    'May',
                                    'June',
                                    'July',
                                    'August',
                                    'September',
                                    'October',
                                    'November',
                                    'December'
                                ],
                                monthsShort: [
                                    'Jan',
                                    'Feb',
                                    'Mar',
                                    'Apr',
                                    'May',
                                    'Jun',
                                    'Jul',
                                    'Aug',
                                    'Sep',
                                    'Oct',
                                    'Nov',
                                    'Dec'
                                ],
                                nextMonth: '›',
                                previousMonth: '‹',
                                weekdays: [
                                    'Sunday',
                                    'Monday',
                                    'Tuesday',
                                    'Wednesday',
                                    'Thursday',
                                    'Friday',
                                    'Saturday'
                                ],
                                weekdaysAbbrev: [
                                    'S',
                                    'M',
                                    'T',
                                    'W',
                                    'T',
                                    'F',
                                    'S'
                                ],
                                weekdaysShort: [
                                    'Sun',
                                    'Mon',
                                    'Tue',
                                    'Wed',
                                    'Thu',
                                    'Fri',
                                    'Sat'
                                ]
                            },
                            isRTL: false,
                            maxDate: null,
                            minDate: null,
                            onClose: null,
                            onDraw: null,
                            onOpen: null,
                            onSelect: null,
                            parse: null,
                            setDefaultDate: false,
                            showClearBtn: false,
                            showDaysInNextAndPreviousMonths: false,
                            showMonthAfterYear: false,
                            yearRange: 10
                        }} />
                </div>
                <CustomRow>
                    <div className="col s8">
                        <span data-assigned="date" className="helper-text red-text text-darken-1" style={{ fontSize: '0.8em' }}>{errorMessage.date}</span>
                    </div>
                </CustomRow>
                <CustomRow>
                    <div className="col s8 center-align">Time:</div>
                </CustomRow>
                <div className="center-align">
                    <TimePicker
                        style={{ width: '64.5%' }}
                        id="TimePicker-13"
                        options={{
                            autoClose: false,
                            container: null,
                            defaultTime: 'now',
                            duration: 350,
                            fromNow: 0,
                            i18n: {
                                cancel: 'Cancel',
                                clear: 'Clear',
                                done: 'Ok'
                            },
                            onCloseEnd: null,
                            onCloseStart: null,
                            onOpenEnd: null,
                            onOpenStart: null,
                            onSelect: null,
                            showClearBtn: false,
                            twelveHour: false,
                            vibrate: true
                        }}
                    />
                </div>
                <CustomRow>
                    <div className="col s8">
                        <span data-assigned="time" className="helper-text red-text text-darken-1" style={{ fontSize: '0.8em' }}>{errorMessage.time}</span>
                    </div>
                </CustomRow>
                <CustomRow>
                    <div className="input-field col s8" style={{ marginTop: '35px' }}>
                        <TextControl id="comments" name="comments" value={comments} inputEventHandler={setComments} focusEventHandler={handleFocus} maxlength="100" />
                        <label htmlFor="comments">Comment</label>
                        <span data-assigned="comments" className="helper-text red-text text-darken-1">{errorMessage.comments}</span>
                    </div>
                </CustomRow>
                <CustomRow>
                    <div className="input-field col s8">
                        <a onClick={handleSubmit} style={{ width: '100%', fontFamily: 'Raleway', fontSize: '1rem', backgroundColor: 'black', marginTop: '30px' }} className="waves-effect waves-light btn z-depth-0 white-text">SAVE & CLOSE</a>
                    </div>
                </CustomRow>
            </div>
        </ReactModal>
    );
}

function CustomRow(props) {
    return (
        <div className={`row ${props.className}`}>
            <div className="col s2"></div>
            {props.children}
            <div className="col s2"></div>
        </div>
    );
}

export default ScheduleModal;