import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import axios from "axios";
import ReactModal from 'react-modal';

import authHeader from "../../services/auth-header";
import Table from '../Table';
import { TextControl, SelectControl } from './Controls';
import CreateBonusModal from './modals/CreateBonusModal';
import EditBonusModal from './modals/EditBonusModal';

ReactModal.setAppElement('#root');

function Bonus() {

    const { user: currentUser } = useSelector((state) => state.auth);

    const [bonus, setBonus] = useState([]);
    const [currentBonus, setCurrentBonus] = useState({});

    const [statusFilter, setStatusFilter] = useState(1);

    const [tablePage, setTablePage] = useState(0);
    const [tableSearchValue, setTableSearchValue] = useState('');
    const [tableSearchFields, setTableSearchFields] = useState(['description', 'valueType', 'value', 'startDateTime', 'endDateTime', 'numTimesApp', 'memberType', 'servicesQty']);

    const [isCreateModalOpened, openCreateModal] = useState(false);
    const [isEditModalOpened, openEditModal] = useState(false);

    function isUserAllowed() {
        if (currentUser) {
            if (!currentUser.roles.includes("ROLE_MANAGER"))
                return <Redirect to="/login" />;
        }
        else
            return <Redirect to="/login" />;
    }
    isUserAllowed();

    useEffect(() => {
        getBonus();
    }, []);

    useEffect(() => {
        getBonus();
    }, [statusFilter])

    useEffect(() => {
        setTablePage(0);
    }, [tableSearchValue, tableSearchFields, statusFilter]);

    async function getBonus() {
        let result = await axios.get(process.env.REACT_APP_API_URL + `api/admin/viewBonus/${statusFilter}`, { headers: authHeader() })
        if (result)
            setBonus(result.data);
    }

    function getBonusRow(bonus) {
        const startDateTime = new Date(bonus.startDateTime);
        const endDateTime = new Date(bonus.endDateTime);
        const startDateTimeStr = `${startDateTime.toISOString().substr(0, 10)} ${startDateTime.toTimeString().substr(0, 8)}`;
        const endDateTimeStr = `${endDateTime.toISOString().substr(0, 10)} ${endDateTime.toTimeString().substr(0, 8)}`;

        return (
            <tr key={bonus.bonusId}>
                <td className="center-align">{bonus.description}</td>
                <td className="center-align">{bonus.valueType}</td>
                <td className="center-align">{bonus.value}</td>
                <td className="center-align">{startDateTimeStr}</td>
                <td className="center-align">{endDateTimeStr}</td>
                <td className="center-align">{bonus.numTimesApp}</td>
                <td className="center-align">{bonus.memberType}</td>
                <td className="center-align">{bonus.servicesQty}</td>
                <td className="center-align">
                    <div className="switch">
                        <label>
                            Inactive
                            <input type="checkbox" checked={bonus.statusId == 1 ? true : false} onChange={(event) => handleStatusChange(bonus, event.target.checked)} />
                            <span className="lever"></span>
                            Active
                        </label>
                    </div>
                </td>
                <td className="center-align">
                    <a onClick={() => {
                        setCurrentBonus(bonus);
                        openEditModal(true);
                    }} href="#modal-edit" className="btn-floating waves-effect waves-light black white-text">
                        <i className="material-icons">create</i>
                    </a>
                </td>
            </tr>
        );
    }

    async function handleStatusChange(bonus, statusId) {
        statusId = (!statusId) ? 2 : 1;
        try {
            await axios.put(process.env.REACT_APP_API_URL + `api/admin/toggleBonus/${bonus.bonusId}`, {
                statusId
            }, {
                headers: authHeader()
            });
            getBonus();
        } catch (error) {
            getBonus();
        }
    }

    return (
        <div className="container">
            <div className="row valign-wrapper">
                <div className="hide-on-small-only col m9" style={{ fontVariant: 'small-caps', fontFamily: 'Raleway', fontSize: '3rem' }}>
                    Bonus
                </div>
                <a href="#add-vehicle-member" onClick={() => openCreateModal(true)} className="col s12 m3 waves-effect waves-light btn z-depth-0 white-text" style={{ fontFamily: 'Raleway', fontSize: '1rem', backgroundColor: 'black', marginTop: '10px', marginRight: '10px' }}>
                    ADD BONUS
                </a>
            </div>
            <hr />
            <div className="row" style={{ padding: '20px 0px' }}>
                <div className="input-field col s8">
                    <TextControl id="tableSearchValue" value={tableSearchValue} inputEventHandler={setTableSearchValue} focusEventHandler={() => null} />
                    <label htmlFor="tableSearchValue">Search</label>
                </div>
                <div className="input-field col s4">
                    <SelectControl value={statusFilter} inputEventHandler={setStatusFilter} focusEventHandler={() => null}>
                        <option value="1" selected>Active</option>
                        <option value="2">Inactive</option>
                    </SelectControl>
                </div>
            </div>
            <div className="row z-depth-1 white" style={{ padding: '10px', margin: '20px 0px' }}>
                <Table
                    style={{ tableLayout: 'auto' }}
                    tableHead={
                        <thead>
                            <tr className="center-align">
                                <th className="center-align">Description</th>
                                <th className="center-align">Value Type</th>
                                <th className="center-align">Value</th>
                                <th className="center-align">Start Date</th>
                                <th className="center-align">End Date</th>
                                <th className="center-align">Times</th>
                                <th className="center-align">Member Type</th>
                                <th className="center-align">Amount of Services</th>
                                <th className="center-align">Status</th>
                                <th className="center-align">Edit</th>
                            </tr>
                        </thead>
                    }
                    tableBody={
                        (rows) => <tbody>{rows}</tbody>}
                    tableFoot={null}
                    row={getBonusRow}
                    data={bonus}
                    searchValue={tableSearchValue}
                    searchFields={tableSearchFields}
                    show={10}
                    page={tablePage}
                    pagination={(pageRowAmount) => {
                        return (
                            <div className="row" style={{ marginTop: '20px' }}>
                                <div className="col s12 left">
                                    <a onClick={() => setTablePage(tablePage - 1)} disabled={!tablePage} className="waves-effect waves-black btn-flat">Previous</a>
                                    {(tablePage > 1 &&
                                        <>
                                            <a onClick={() => setTablePage(0)} className="waves-effect waves-black btn-flat">1</a>
                                            <span> ... </span>
                                        </>)}
                                    {(tablePage > 0 &&
                                        <a onClick={() => setTablePage(tablePage - 1)} className="waves-effect waves-black btn-flat">{tablePage}</a>)}
                                    <a disabled={true} className="waves-effect waves-black btn-flat">{tablePage + 1}</a>
                                    {(tablePage + 1 < pageRowAmount &&
                                        <a onClick={() => setTablePage(tablePage + 1)} className="waves-effect waves-black btn-flat">{tablePage + 2}</a>)}
                                    {(tablePage + 2 < pageRowAmount &&
                                        <>
                                            <span> ... </span>
                                            <a onClick={() => setTablePage(pageRowAmount - 1)} className="waves-effect waves-black btn-flat">{pageRowAmount}</a>
                                        </>)}
                                    <a onClick={() => setTablePage(tablePage + 1)} disabled={!(tablePage + 1 < pageRowAmount)} className="waves-effect waves-black btn-flat">Next</a>
                                </div>
                            </div>
                        );
                    }}
                />
            </div>

            <CreateBonusModal isOpen={isCreateModalOpened} onClose={() => openCreateModal(false)} updateTable={getBonus} />
            <EditBonusModal isOpen={isEditModalOpened} onClose={() => openEditModal(false)} updateTable={getBonus} bonus={currentBonus} />
        </div>
    );
}
export default Bonus;