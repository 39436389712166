import React, { useEffect, useState } from "react";
import ReactModal from 'react-modal';

import axios from "axios";

import Validate from '../../../helpers/validate';
import authHeader from '../../../services/auth-header';
import { TextControl, SelectControl } from '../Controls';

function CreateVehicleModal(props) {

    const [vehicleBrand, setVehicleBrand] = useState('');
    const [vehicleModel, setVehicleModel] = useState('');
    const [vehicleInsurance, setVehicleInsurance] = useState('');
    const [vehicleEmissionTest, setVehicleEmissionTest] = useState('');
    const [vehicleTitle, setVehicleTitle] = useState('');
    const [vehiclePlates, setVehiclePlates] = useState('');
    const [vehicleRegistration, setVehicleRegistration] = useState('');
    const [vehicleColor, setVehicleColor] = useState('');
    const [vehicleCab, setVehicleCab] = useState('');
    const [vehicleType, setVehicleType] = useState('');
    const [plateExpiration, setPlateExpiration] = useState('');
    const [errorMessage, setErrorMessage] = useState({});

    useEffect(() => {
    }, []);

    async function handleSubmit() {
        const validate = new Validate();
        const message = {};
        message.vehicleBrand = validate.init(vehicleBrand, {
            maxLength: {
                value: 50,
                message: 'The maximum length possible of the first name is 50 characters'
            },
            minLength: {
                value: 1,
                message: 'The minimum length possible of the first name is 1 character'
            }
        }).getHTML();
        message.vehicleModel = validate.init(vehicleModel, {
            maxLength: {
                value: 4,
                message: 'The maximum length possible of the first name is 4 characters'
            },
            minLength: {
                value: 1,
                message: 'The minimum length possible of the first name is 1 character'
            }
        }).getHTML();
        message.vehicleInsurance = validate.init(vehicleInsurance, {
            maxLength: {
                value: 150,
                message: 'The maximum length possible of the first name is 150 characters'
            },
            minLength: {
                value: 1,
                message: 'The minimum length possible of the first name is 1 character'
            }
        }).getHTML();
        message.vehicleEmissionTest = validate.init(vehicleEmissionTest, {
            maxLength: {
                value: 150,
                message: 'The maximum length possible of the first name is 150 characters'
            },
            minLength: {
                value: 1,
                message: 'The minimum length possible of the first name is 1 character'
            }
        }).getHTML();
        message.vehicleTitle = validate.init(vehicleTitle, {
            maxLength: {
                value: 150,
                message: 'The maximum length possible of the first name is 150 characters'
            },
            minLength: {
                value: 1,
                message: 'The minimum length possible of the first name is 1 character'
            }
        }).getHTML();
        message.vehiclePlates = validate.init(vehiclePlates, {
            maxLength: {
                value: 150,
                message: 'The maximum length possible of the first name is 150 characters'
            },
            minLength: {
                value: 1,
                message: 'The minimum length possible of the first name is 1 character'
            }
        }).getHTML();
        message.vehicleRegistration = validate.init(vehicleRegistration, {
            maxLength: {
                value: 150,
                message: 'The maximum length possible of the first name is 150 characters'
            },
            minLength: {
                value: 1,
                message: 'The minimum length possible of the first name is 1 character'
            }
        }).getHTML();
        message.vehicleColor = validate.init(vehicleColor, {
            maxLength: {
                value: 20,
                message: 'The maximum length possible of the first name is 20 characters'
            },
            minLength: {
                value: 1,
                message: 'The minimum length possible of the first name is 1 character'
            }
        }).getHTML();
        message.vehicleCab = validate.init(vehicleCab, {
            maxLength: {
                value: 3,
                message: 'The maximum length possible of the first name is 3 characters'
            },
            minLength: {
                value: 1,
                message: 'The minimum length possible of the first name is 1 character'
            },
            integer: {
                message: 'The vehicle cab can only be a only be a number'
            }
        }).getHTML();
        message.vehicleType = validate.init(vehicleType, {
            maxLength: {
                value: 50,
                message: 'The maximum length possible of the first name is 50 characters'
            },
            minLength: {
                value: 1,
                message: 'The minimum length possible of the first name is 1 character'
            }
        }).getHTML();
        message.plateExpiration = validate.init(plateExpiration, {
            notEmpty: {
                message: 'A plate expiration date must be selected'
            }
        }).getHTML();
        setErrorMessage(message);

        const plateExpirationDate = parseDate(plateExpiration);

        if (validate.status) {
            const plateExpirationDateStr = plateExpirationDate.toISOString().substr(0, 10);
            try {
                await axios.post(process.env.REACT_APP_API_URL + 'api/admin/newVehicle', {
                    vehicleBrand, vehicleModel,
                    vehicleInsuranceData: vehicleInsurance, vehicleEmissionTest,
                    vehicleRegistration, vehicleTitle,
                    vehiclePlates, vehicleType,
                    color: vehicleColor, cab: vehicleCab,
                    plateExpiration: plateExpirationDateStr
                }, {
                    headers: authHeader()
                });

                clearControls();
                props.updateTable();
                props.onClose();
            } catch (error) {
                const serverError = JSON.parse(error.request.responseText).message;
                setErrorMessage({ serverError: <span>{serverError}</span> });
            }
        }
    }

    function parseDate(value) {
        const b = value.split(/\D/);
        return new Date(b[0], --b[1], b[2]);
    }

    function handleFocus(event) {
        const element = event.target.id,
            messages = errorMessage;
        messages[element] = null;
        setErrorMessage(messages);
    }

    function clearControls() {
        setVehicleBrand('');
        setVehicleModel('');
        setVehicleInsurance('');
        setVehicleEmissionTest('');
        setVehiclePlates('');
        setVehicleTitle('');
        setVehicleRegistration('');
        setVehicleColor('');
        setVehicleCab('');
        setVehicleType('');
        setPlateExpiration('');
        setErrorMessage({});
    }

    return (
        <ReactModal isOpen={props.isOpen} style={{ overlay: { zIndex: '999' } }}>
            <div className="container">
                <div className="row">
                    <div className="col s11 left hide-on-small-only" style={{ fontVariant: 'small-caps', fontFamily: 'Raleway', fontSize: '2rem' }}>
                        Create New Vehicle
                    </div>
                    <div className="col s1 right">
                        <a onClick={() => {
                            clearControls();
                            props.onClose();
                        }} className="btn-floating waves-effect waves-light black white-text"><i className="material-icons">close</i></a>
                    </div>
                </div>
                <hr />
                <CustomRow>
                    <div className="input-field col s8" style={{ marginTop: '35px' }}>
                        <TextControl id="vehicleBrand" name="vehicleBrand" value={vehicleBrand} inputEventHandler={setVehicleBrand} focusEventHandler={handleFocus} maxlength="50" />
                        <label htmlFor="vehicleBrand">Vehicle Brand</label>
                        <span data-assigned="vehicleBrand" className="helper-text red-text text-darken-1">{errorMessage.vehicleBrand}</span>
                    </div>
                </CustomRow>
                <CustomRow>
                    <div className="input-field col s8">
                        <TextControl id="vehicleModel" name="vehicleModel" value={vehicleModel} inputEventHandler={setVehicleModel} focusEventHandler={handleFocus} maxlength="4" />
                        <label htmlFor="vehicleModel">Vehicle Model</label>
                        <span data-assigned="vehicleModel" className="helper-text red-text text-darken-1">{errorMessage.vehicleModel}</span>
                    </div>
                </CustomRow>
                <CustomRow>
                    <div className="input-field col s8">
                        <TextControl id="vehicleInsurance" name="vehicleInsurance" value={vehicleInsurance} inputEventHandler={setVehicleInsurance} focusEventHandler={handleFocus} maxlength="150" />
                        <label htmlFor="vehicleInsurance">Vehicle Insurance</label>
                        <span data-assigned="vehicleInsurance" className="helper-text red-text text-darken-1">{errorMessage.vehicleInsurance}</span>
                    </div>
                </CustomRow>
                <CustomRow>
                    <div className="input-field col s8">
                        <TextControl id="vehicleEmissionTest" name="vehicleEmissionTest" value={vehicleEmissionTest} inputEventHandler={setVehicleEmissionTest} focusEventHandler={handleFocus} maxlength="150" />
                        <label htmlFor="vehicleEmissionTest">Vehicle Emission Test</label>
                        <span data-assigned="vehicleEmissionTest" className="helper-text red-text text-darken-1">{errorMessage.vehicleEmissionTest}</span>
                    </div>
                </CustomRow>
                <CustomRow>
                    <div className="input-field col s8">
                        <TextControl id="vehicleTitle" name="vehicleTitle" value={vehicleTitle} inputEventHandler={setVehicleTitle} focusEventHandler={handleFocus} maxlength="150" />
                        <label htmlFor="vehicleTitle">Vehicle Title</label>
                        <span data-assigned="vehicleTitle" className="helper-text red-text text-darken-1">{errorMessage.vehicleTitle}</span>
                    </div>
                </CustomRow>
                <CustomRow>
                    <div className="input-field col s8">
                        <TextControl id="vehiclePlates" name="vehiclePlates" value={vehiclePlates} inputEventHandler={setVehiclePlates} focusEventHandler={handleFocus} maxlength="150" />
                        <label htmlFor="vehiclePlates">Vehicle Plates</label>
                        <span data-assigned="vehiclePlates" className="helper-text red-text text-darken-1">{errorMessage.vehiclePlates}</span>
                    </div>
                </CustomRow>
                <CustomRow>
                    <div className="input-field col s8">
                        <input value={plateExpiration} onChange={(event) => setPlateExpiration(event.target.value)} type="date" id="plateExpiration" name="plateExpiration" />
                        <label htmlFor="plateExpiration">Plate Expiration Date</label>
                        <span data-assigned="plateExpiration" className="helper-text red-text text-darken-1">{errorMessage.plateExpiration}</span>
                    </div>
                </CustomRow>
                <CustomRow>
                    <div className="input-field col s8">
                        <TextControl id="vehicleRegistration" name="vehicleRegistration" value={vehicleRegistration} inputEventHandler={setVehicleRegistration} focusEventHandler={handleFocus} maxlength="150" />
                        <label htmlFor="vehicleRegistration">Vehicle Registration</label>
                        <span data-assigned="vehicleRegistration" className="helper-text red-text text-darken-1">{errorMessage.vehicleRegistration}</span>
                    </div>
                </CustomRow>
                <CustomRow>
                    <div className="input-field col s8">
                        <TextControl id="vehicleColor" name="vehicleColor" value={vehicleColor} inputEventHandler={setVehicleColor} focusEventHandler={handleFocus} maxlength="20" />
                        <label htmlFor="vehicleColor">Vehicle Color</label>
                        <span data-assigned="vehicleColor" className="helper-text red-text text-darken-1">{errorMessage.vehicleColor}</span>
                    </div>
                </CustomRow>
                <CustomRow>
                    <div className="input-field col s8">
                        <TextControl id="vehicleCab" name="vehicleCab" value={vehicleCab} inputEventHandler={setVehicleCab} focusEventHandler={handleFocus} maxlength="3" />
                        <label htmlFor="vehicleCab">Vehicle Cab</label>
                        <span data-assigned="vehicleCab" className="helper-text red-text text-darken-1">{errorMessage.vehicleCab}</span>
                    </div>
                </CustomRow>
                <CustomRow>
                    <div className="input-field col s8">
                        <TextControl id="vehicleType" name="vehicleType" value={vehicleType} inputEventHandler={setVehicleType} focusEventHandler={handleFocus} maxlength="50" />
                        <label htmlFor="vehicleType">Vehicle Type</label>
                        <span data-assigned="vehicleType" className="helper-text red-text text-darken-1">{errorMessage.vehicleType}</span>
                    </div>
                </CustomRow>
                <CustomRow>
                    <span className="col s8 center-align helper-text red-text text-darken-1">{errorMessage.serverError}</span>
                </CustomRow>
                <CustomRow>
                    <div className="input-field col s8">
                        <a onClick={handleSubmit} style={{ width: '100%', fontFamily: 'Raleway', fontSize: '1rem', backgroundColor: 'black', marginTop: '30px' }} className="waves-effect waves-light btn z-depth-0 white-text">SAVE & CLOSE</a>
                    </div>
                </CustomRow>
            </div>
        </ReactModal>
    );
}

function CustomRow(props) {
    return (
        <div className={`row ${props.className}`}>
            <div className="col s2"></div>
            {props.children}
            <div className="col s2"></div>
        </div>
    );
}

export default CreateVehicleModal;