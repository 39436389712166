import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import XLSX from 'xlsx';
import axios from "axios";

import Validate from '../../helpers/validate';
import { SelectControl } from './Controls';
import authHeader from "../../services/auth-header";

function Voucher() {

    const { user: currentUser } = useSelector((state) => state.auth);

    const [file, setFile] = useState();
    const [isImporting, setIsImporting] = useState(false);
    const [exportType, setExportType] = useState(0);
    const [exportTypesList, setExportTypesList] = useState([]);
    const [exportStartDate, setExportStartDate] = useState();
    const [exportEndDate, setExportEndDate] = useState();
    const [exportData, setExportEndData] = useState();
    const [errorMessage, setErrorMessage] = useState({});

    function isUserAllowed() {
        if (currentUser) {
            if (!currentUser.roles.includes("ROLE_MANAGER"))
                return <Redirect to="/login" />;
        }
        else
            return <Redirect to="/login" />;
    }
    isUserAllowed();

    useEffect(() => {
        getExportTypesList();
    }, []);

    async function getExportTypesList() {
        let result = await axios.get(process.env.REACT_APP_API_URL + 'api/admin/programs', { headers: authHeader() })
        if (result)
            setExportTypesList(result.data);
    }

    function handleImport() {
        let reader = new FileReader();
        reader.onload = function (event) {
            let data = event.target.result;
            let workbook = XLSX.read(data, { type: 'binary' });

            workbook.SheetNames.forEach(sheet => {
                let rowObject = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheet]);
                submit(rowObject, file.name);
            });
        };
        setIsImporting('loading');
        reader.readAsBinaryString(file);
    }

    async function submit(values, fileName) {
        await axios.post(process.env.REACT_APP_API_URL + 'api/admin/loadVouchers', {
            values, originFile: fileName
        }, {
            headers: authHeader()
        });

        setIsImporting('finished');
        setTimeout(() => setIsImporting(null), 3000);
    }

    async function handleExport() {
        const validate = new Validate();
        const message = {};
        message.exportType = validate.init(exportType, {
            notCero: { message: 'An export type must be selected' }
        }).getHTML();
        message.exportStartDate = validate.init(exportStartDate, {
            notEmpty: {
                message: 'A start date must be selected'
            }
        }).getHTML();
        message.exportEndDate = validate.init(exportEndDate, {
            notEmpty: {
                message: 'An end date must be selected'
            }
        }).getHTML();

        setErrorMessage(message);
        if (validate.status) {
            const startDate = parseDate(exportStartDate);
            const endDate = parseDate(exportEndDate);

            const startDateStr = `${startDate.toISOString().substr(0, 10)}`;
            const endDateStr = `${endDate.toISOString().substr(0, 10)}`;

            const result = await axios.get(process.env.REACT_APP_API_URL + `api/admin/invoice/${startDateStr}/${endDateStr}/${exportType}`, {
                headers: authHeader()
            });

            setExportEndData(result.data);
        }
    }

    function handleFocus(event) {
        const element = event.target.id,
            messages = errorMessage;
        messages[element] = null;
        setErrorMessage(messages);
    }

    function getSubmitText() {
        switch (isImporting) {
            case 'loading': return 'PROCESSING FILE. PLEASE WAIT...';
            case 'finished': return 'FILE IMPORTED SUCCESSFULLY';
            default: return 'IMPORT';
        }
    }

    function parseDate(value) {
        const b = value.split(/\D/);
        return new Date(b[0], --b[1], b[2]);
    }

    return (
        <div className="container">
            <div className="row valign-wrapper">
                <div className="col s12 center-align" style={{ fontVariant: 'small-caps', fontFamily: 'Raleway', fontSize: '3rem' }}>
                    Voucher
                </div>
            </div>
            <hr />
            <div className="row" style={{ padding: '20px 0px' }}>
                <CustomRow>
                    <div className="col s8 center-align" style={{ fontVariant: 'small-caps', fontFamily: 'Raleway', fontSize: '2rem' }}>
                        Import a Spreadsheet
                    </div>
                </CustomRow>
                <CustomRow>
                    <div className="file-field input-field col s8">
                        <div className="btn">
                            <span>File</span>
                            <input onChange={(event) => setFile(event.target.files[0])} type="file" />
                        </div>
                        <div className="file-path-wrapper">
                            <input className="file-path validate" type="text" placeholder="Upload spreadsheet" />
                        </div>
                    </div>
                </CustomRow>
                <CustomRow>
                    <div className="input-field col s8">
                        <a onClick={handleImport} style={{ width: '100%', fontFamily: 'Raleway', fontSize: '1rem', backgroundColor: 'black' }} className="waves-effect waves-light btn z-depth-0 white-text">
                            {getSubmitText()}
                        </a>
                    </div>
                </CustomRow>
                <div className="row">
                    <div className="col s1"></div>
                    <hr className="col s10" />
                    <div className="col s1"></div>
                </div>
                <CustomRow>
                    <div className="col s8 center-align" style={{ fontVariant: 'small-caps', fontFamily: 'Raleway', fontSize: '2rem' }}>
                        Export Voucher Spreadsheet
                    </div>
                </CustomRow>
                <CustomRow>
                    <div className="input-field col s8">
                        <SelectControl value={exportType} inputEventHandler={setExportType} focusEventHandler={handleFocus} id="exportType">
                            <option value="0" selected>Select type report</option>
                            {exportTypesList.map((value) => <option key={value.programId} value={value.programId}>{value.program}</option>)}
                        </SelectControl>
                        <span data-assigned="numTimesApp" className="helper-text red-text text-darken-1">{errorMessage.exportType}</span>
                    </div>
                </CustomRow>
                <CustomRow>
                    <div className="input-field col s4">
                        <input value={exportStartDate} onChange={(event) => setExportStartDate(event.target.value)} type="date" id="exportStartDate" />
                        <label htmlFor="exportStartDate">Start Date</label>
                        <span data-assigned="exportStartDate" className="helper-text red-text text-darken-1">{errorMessage.exportStartDate}</span>
                    </div>
                    <div className="input-field col s4">
                        <input value={exportEndDate} onChange={(event) => setExportEndDate(event.target.value)} type="date" id="exportEndDate" />
                        <label htmlFor="exportEndDate">End Date</label>
                        <span data-assigned="exportEndDate" className="helper-text red-text text-darken-1">{errorMessage.exportEndDate}</span>
                    </div>
                </CustomRow>
                {(exportData != null && exportData.length <= 0) &&
                    <>
                        <CustomRow>
                            <div className="input-field col s8 center-align">
                                <span className="helper-text red-text text-darken-1" style={{ fontSize: '1em' }}>No export data could be found</span>
                            </div>
                        </CustomRow>
                    </>}
                <CustomRow>
                    <div className="input-field col s8">
                        <a onClick={handleExport} style={{ width: '100%', fontFamily: 'Raleway', fontSize: '1rem', backgroundColor: 'black' }} className="waves-effect waves-light btn z-depth-0 white-text">EXPORT</a>
                    </div>
                </CustomRow>
            </div>
        </div>
    );
}
export default Voucher;

function CustomRow(props) {
    return (
        <div className={`row ${props.className}`}>
            <div className="col s2"></div>
            {props.children}
            <div className="col s2"></div>
        </div>
    );
}