import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import axios from "axios";

import authHeader from "../../services/auth-header";
import Table from '../Table';
import MemberModal from './modals/CreateMemberModal';
import UserModal from './modals/UserModal';
import RolesModal from './modals/MemberRolesModal';
import EditModal from './modals/EditMemberModal';
import { TextControl, SelectControl } from './Controls';

function Users() {

    const { user: currentUser } = useSelector((state) => state.auth);

    const [currentMember, setCurrentMember] = useState({ memberId: null, firstName: ' ', lastName: ' ', phone: ' ', email: ' ', role: ' ' });
    const [members, setMembers] = useState(null);

    const [isUserModalOpened, openUserModal] = useState(false);
    const [isMemberModalOpened, openMemberModal] = useState(false);
    const [isRolesModalOpened, openRolesModal] = useState(false);
    const [isEditModalOpened, openEditModal] = useState(false);

    const [tablePage, setTablePage] = useState(0);
    const [tableSearchValue, setTableSearchValue] = useState('');
    const [tableSearchFields, setTableSearchFields] = useState(['completeName', 'phone', 'email']);
    const [rolesList, setRolesList] = useState([]);
    const [roleFilter, setRoleFilter] = useState(0);
    const [statusFilter, setStatusFilter] = useState(1);

    function isUserAllowed() {
        if (currentUser) {
            if (!currentUser.roles.includes("ROLE_MANAGER"))
                return <Redirect to="/login" />;
        }
        else
            return <Redirect to="/login" />;
    }
    isUserAllowed();

    useEffect(() => {
        getRoles();
    }, []);

    useEffect(() => {
        getMembers();
    }, [roleFilter, statusFilter]);

    useEffect(() => {
        setTablePage(0);
    }, [tableSearchValue, tableSearchFields, roleFilter, statusFilter]);

    async function getMembers() {
        let result = await axios.get(process.env.REACT_APP_API_URL + `api/admin/activeMembers/${statusFilter}/1/${roleFilter}`, { headers: authHeader() })
        if (result)
            setMembers(result.data);
    }

    async function getRoles() {
        let result = await axios.get(process.env.REACT_APP_API_URL + 'api/catalog/roles/1', { headers: authHeader() })
        if (result)
            setRolesList(result.data);
    }

    function getMemberRoleChips(roles) {
        const rolesStr = roles || '',
            memberRoles = [];
        let currentRole = '';
        for (let i = 0; i < rolesStr.length; i++) {
            const char = rolesStr[i];
            if (char == '/') {
                if (currentRole.length) {
                    memberRoles.push(currentRole.replace(/\s/g, ''));
                    currentRole = '';
                }
                continue;
            }
            currentRole += char;
        }
        if (currentRole.length)
            memberRoles.push(currentRole);
        return memberRoles;
    }

    async function handleStatusChange(member, statusId) {
        statusId = (!statusId) ? 2 : 1;
        await axios.put(process.env.REACT_APP_API_URL + `api/admin/toggleMember/${member.memberId}`, {
            statusId
        }, {
            headers: authHeader()
        });
        getMembers();
    }

    function getMemberRow(member) {
        return (
            <tr key={member.memberId}>
                <td>{member.firstName} {member.lastName}</td>
                {/* <td>{getMemberRoleChips(member.role).map((value, index) => <div key={index} className="chip" style={{ textTransform: 'capitalize' }}>{value}</div>)}</td> */}
                {/* <td>{member.phone}</td>
                <td>{member.email}</td> */}
                <td className="center-align">
                    <div className="switch">
                        <label>
                            Inactive
                            <input type="checkbox" checked={member.statusId == 1 ? true : false} onChange={(event) => handleStatusChange(member, event.target.checked)} />
                            <span className="lever"></span>
                            Active
                        </label>
                    </div>
                </td>
                <td className="center-align">
                    <a onClick={() => {
                        setCurrentMember(member);
                        openUserModal(true);
                    }} href="#modal-user" className="btn-floating waves-effect waves-light black white-text">
                        {member.userId ? <i className="material-icons">create</i> : <i className="material-icons">add</i>}
                    </a>
                </td>
                <td className="center-align">
                    <a onClick={() => {
                        setCurrentMember(member);
                        openRolesModal(true);
                    }} href="#modal-roles" className="btn-floating waves-effect waves-light black white-text">
                        <i className="material-icons">remove_red_eye</i>
                    </a>
                </td>
                <td className="center-align"><a onClick={() => {
                    setCurrentMember(member);
                    openEditModal(true);
                }} href="#modal-edit" className="btn-floating waves-effect waves-light black white-text"><i className="material-icons">create</i></a></td>
            </tr>
        );
    }

    return (
        <div className="container">
            <div className="row valign-wrapper">
                <div className="hide-on-small-only col m12" style={{ fontVariant: 'small-caps', fontFamily: 'Raleway', fontSize: '3rem' }}>
                    Users
                </div>
            </div>
            <hr />
            <div className="row" style={{ padding: '20px 0px' }}>
                <div className="input-field col s12">
                    <TextControl id="tableSearchValue" value={tableSearchValue} inputEventHandler={setTableSearchValue} focusEventHandler={() => null} />
                    <label htmlFor="tableSearchValue">Search</label>
                </div>
            </div>
            <div className="row">
                <div className="col s12">
                    <div className="row">
                        <div className="col s6 center-align">
                            <span>Roles</span>
                        </div>
                        <div className="col s6 center-align">
                            <span>Status</span>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col s6">
                            <SelectControl value={roleFilter} inputEventHandler={setRoleFilter} focusEventHandler={() => null} id="role" name="role">
                                <option value="0" selected>All</option>
                                {rolesList.map((value) => <option key={value.roleId} value={value.roleId}>{value.name}</option>)}
                            </SelectControl>
                        </div>
                        <div className="col s6">
                            <SelectControl value={statusFilter} inputEventHandler={setStatusFilter} focusEventHandler={() => null}>
                                <option value="1" selected>Active</option>
                                <option value="2" selected>Inactive</option>
                            </SelectControl>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row z-depth-1 white" style={{ padding: '10px', margin: '20px 0px' }}>
                <Table
                    style={{ tableLayout: 'auto' }}
                    tableHead={
                        <thead>
                            <tr className="center-align">
                                <th className="center-align">Name</th>
                                {/* <th className="center-align" style={{ width: '200px' }}>Roles List</th> */}
                                {/* <th className="center-align">Phone</th>
                                <th className="center-align">Email</th> */}
                                <th className="center-align">Status</th>
                                <th className="center-align">User</th>
                                <th className="center-align">Roles</th>
                                <th className="center-align">General</th>
                            </tr>
                        </thead>
                    }
                    tableBody={
                        (rows) => <tbody>{rows}</tbody>}
                    tableFoot={null}
                    row={getMemberRow}
                    data={members}
                    searchValue={tableSearchValue}
                    searchFields={tableSearchFields}
                    show={10}
                    page={tablePage}
                    pagination={(pageRowAmount) => {
                        return (
                            <div className="row" style={{ marginTop: '20px' }}>
                                <div className="col s12 left">
                                    <a onClick={() => setTablePage(tablePage - 1)} disabled={!tablePage} className="waves-effect waves-black btn-flat">Previous</a>
                                    {(tablePage > 1 &&
                                        <>
                                            <a onClick={() => setTablePage(0)} className="waves-effect waves-black btn-flat">1</a>
                                            <span> ... </span>
                                        </>)}
                                    {(tablePage > 0 &&
                                        <a onClick={() => setTablePage(tablePage - 1)} className="waves-effect waves-black btn-flat">{tablePage}</a>)}
                                    <a disabled={true} className="waves-effect waves-black btn-flat">{tablePage + 1}</a>
                                    {(tablePage + 1 < pageRowAmount &&
                                        <a onClick={() => setTablePage(tablePage + 1)} className="waves-effect waves-black btn-flat">{tablePage + 2}</a>)}
                                    {(tablePage + 2 < pageRowAmount &&
                                        <>
                                            <span> ... </span>
                                            <a onClick={() => setTablePage(pageRowAmount - 1)} className="waves-effect waves-black btn-flat">{pageRowAmount}</a>
                                        </>)}
                                    <a onClick={() => setTablePage(tablePage + 1)} disabled={!(tablePage + 1 < pageRowAmount)} className="waves-effect waves-black btn-flat">Next</a>
                                </div>
                            </div>
                        );
                    }}
                />
            </div>

            <MemberModal isOpen={isMemberModalOpened} onClose={() => openMemberModal(false)} updateTable={getMembers} />
            <UserModal member={currentMember} isOpen={isUserModalOpened} onClose={() => openUserModal(false)} updateTable={getMembers} />
            <RolesModal member={currentMember} isOpen={isRolesModalOpened} onClose={() => openRolesModal(false)} updateTable={getMembers} />
            <EditModal member={currentMember} isOpen={isEditModalOpened} onClose={() => openEditModal(false)} updateTable={getMembers} />
        </div>
    );
}
export default Users;