import { useSelector } from "react-redux";
import { Redirect } from 'react-router-dom';
import React, { useState, useEffect } from "react";

import axios from "axios";

import { ValueChart, BarChart, LineChart, ScoresTable } from './Chart';
import { DashboardMap } from "./DashboardMap";

function Dashboard() {

    const { user: currentUser } = useSelector((state) => state.auth);
    const [customers, setCustomers] = useState({ value: 0, comparison: '0% from yesterday', chartValues: [] });
    const [earnings, setEarnings] = useState({ value: 0, comparison: '0% from yesterday', chartValues: [] });
    const [services, setServices] = useState([]);
    const [scores, setScores] = useState([]);
    const [drivers, setDrivers] = useState(0);

    useEffect(() => {
        if (currentUser && currentUser.roles.includes("ROLE_MANAGER")) {
            getCustomers();
            getEarnings();
            getServices();
            getDrivers();
            getScores();
        }
    }, []);

    if (currentUser) {
        if (!currentUser.roles.includes("ROLE_MANAGER"))
            return <Redirect to="/login" />;
    }
    else
        return <Redirect to="/login" />;

    const getCustomers = async () => {
        const result = await fetch('api/dashboard/customers', 'get');
        if (result) {
            let values = result.data.map((value) => {
                return value.total;
            });
            let valuesLength = values.length;

            setCustomers({
                value: values[valuesLength - 1],
                comparison: getComparisonText(values, valuesLength, 'from last month'), chartValues: (valuesLength >= 1) ? values : []
            });
        }
    };

    const getEarnings = async () => {
        const result = await fetch('api/dashboard/earnings', 'get');
        if (result) {
            let values = result.data.map((value) => {
                return value.TOTAL;
            });
            let valuesLength = values.length;

            setEarnings({
                value: values[valuesLength - 1],
                comparison: getComparisonText(values, valuesLength, 'from last month'), chartValues: (valuesLength >= 1) ? values : []
            });
        }
    };

    const getServices = async () => {
        const result = await fetch('api/dashboard/dailyServices', 'get');
        if (result)
            setServices(result.data);
    };

    const getDrivers = async () => {
        const result = await fetch('api/dashboard/driversCurrentlyWorking', 'get');
        if (result)
            setDrivers(result.data[0].total);
    };

    const getScores = async () => {
        const result = await fetch('api/dashboard/topTenDrivers', 'get');
        if (result)
            setScores(result.data);
    };

    const getComparisonText = (values, valuesLength, text) => {
        if (valuesLength > 1) {
            let previousMonth = values[valuesLength - 2],
                currentMonth = values[valuesLength - 1],
                comparisonValue = ((currentMonth / previousMonth) * 100) - 100;
            if (comparisonValue > 0)
                return <><i className="material-icons text-sm">trending_up</i> {`+${comparisonValue.toFixed(2)}% ${text}`}</>;
            if (comparisonValue < 0)
                return <><i className="material-icons text-sm">trending_down</i> {`${comparisonValue.toFixed(2)}% ${text}`}</>;
        }
        return <><i className="material-icons text-sm">trending_flat</i> {`0% ${text}`}</>;
    };

    const fetch = async (route, httpVerb, data) => {
        try {
            const result = await axios[httpVerb](process.env.REACT_APP_API_URL + route, data);
            return result;
        } catch (error) {
            return null;
        }
    }

    return (
        <div className="dashboard">
            <link rel="stylesheet" href="app-assets/css/custom/dashboard.css" />
            <link rel="stylesheet" href="https://unpkg.com/leaflet@1.7.1/dist/leaflet.css" integrity="sha512-xodZBNTC5n17Xt2atTPuE1HxjVMSvLVW9ocqUKLsCC5CXdbqCmblAshOMAS6/keqq/sMZMZ19scR4PsZChSR7A==" crossorigin="" />

            <div className="db-cards">
                <BarChart
                    title="New customers" cardType='customers'
                    value={customers.value} comparison={customers.comparison} chartValues={customers.chartValues}
                    icon="person" animationDelay='1'
                />
                <BarChart
                    title="Total earnings" cardType='earnings'
                    value={earnings.value} comparison={earnings.comparison} chartValues={earnings.chartValues}
                    icon="attach_money" animationDelay='2'
                />
                <ValueChart
                    title="Drivers in duty" cardType='drivers' value={drivers}
                    icon="drive_eta" animationDelay='3'
                />
                <LineChart
                    title="Total Services" cardType='services' value={drivers}
                    chartValues={services}
                    icon="room_service" animationDelay='4'
                />
                <ScoresTable
                    title="Drivers' best scores" cardType='scores'
                    values={scores}
                    icon="local_library" animationDelay='5'
                />
            </div>
            <div className="db-map z-depth-2 animate fadeLeft delay-6">
                <DashboardMap />
            </div>
        </div>
    );
}

export default Dashboard;