import React from "react";
import L from 'leaflet';

export class Driver {
    cabNumber;
    driverId;
    driverName;
    lastLatitude;
    lastLongitude;
    statusId;
    originLatitude;
    originLongitude;
    originLocation;
    destinationLatitude;
    destinationLongitude;
    destinationLocation;
    mapMarker;
    mapPopup;
    originMarker;
    destinationMarker;

    static map;
    static carIcon;
    static markerIcon;

    constructor(driverId, cabNumber, driverName) {
        this.driverId = driverId;
        this.cabNumber = cabNumber;
        this.driverName = driverName;
    }

    createMapMarker() {
        this.mapMarker = L.marker([this.lastLatitude, this.lastLongitude], {
            title: 'Driver',
            icon: Driver.carIcon
        });
        this.mapMarker.addTo(Driver.map);
        this.mapMarker.bindPopup(this.getDriverPopupText());
        return this.mapMarker;
    }

    updateMapMarker() {
        this.mapMarker.setLatLng([this.lastLatitude, this.lastLongitude]);
    }

    getDriverPopupText() {
        const containerDiv = document.createElement('div'),
            titlePgraph = document.createElement('p'),
            linksPgraph = document.createElement('p');

        containerDiv.append(titlePgraph, document.createElement('hr'), linksPgraph);

        titlePgraph.innerHTML = `${this.driverName}<br />Cab #${this.cabNumber}`;

        if (this.originLocation) {
            this.addEvent('Show origin location', linksPgraph, (event) => {
                Driver.map.setView([this.originLatitud, this.originLongitude]);
                if (!this.originMarker) {
                    this.originMarker = L.marker([this.originLatitud, this.originLongitude], {
                        title: 'Origin location',
                        icon: Driver.markerIcon
                    });
                    this.originMarker.addTo(Driver.map);
                    this.originMarker.bindPopup(this.getMarkerPopup('originMarker',
                        `Origin location of ${this.driverName}<br />Cab #${this.cabNumber}`));
                    return;
                }
                this.originMarker.setLatLng([this.originLatitud, this.originLongitude]);
            });
        }
        if (this.destinationLocation) {
            this.addEvent('Show destination', linksPgraph, (event) => {
                Driver.map.setView([this.destinationLatitude, this.destinationLongitude]);
                if (!this.destinationMarker) {
                    this.destinationMarker = L.marker([this.destinationLatitude, this.destinationLongitude], {
                        title: 'Destination',
                        icon: Driver.markerIcon
                    });
                    this.destinationMarker.addTo(Driver.map);
                    this.destinationMarker.bindPopup(this.getMarkerPopup('destinationMarker',
                        `Destination of ${this.driverName}<br />Cab #${this.cabNumber}`));
                    return;
                }
                this.destinationMarker.setLatLng([this.destinationLatitude, this.destinationLongitude]);
            });
        }
        return containerDiv;
    }

    getMarkerPopup(marker, text) {
        const containerDiv = document.createElement('div'),
            titlePgraph = document.createElement('p'),
            linksPgraph = document.createElement('p');

        containerDiv.append(titlePgraph, document.createElement('hr'), linksPgraph);

        titlePgraph.innerHTML = text;

        this.addEvent('Remove this marker', linksPgraph, (event) => {
            Driver.map.removeLayer(this[marker]);
            this[marker] = null;
        });
        return containerDiv;
    }

    addEvent(text, container, fn) {
        const pgraph = document.createElement('p'),
            anchor = document.createElement('a');
        anchor.textContent = text;
        anchor.href = '#';

        anchor.addEventListener('click', (event) => {
            event.preventDefault();
            fn(event);
        });

        pgraph.append(anchor);
        container.append(pgraph);
    }

    setCurrentLocation(latitude, longitude) {
        this.lastLatitude = latitude;
        this.lastLongitude = longitude;
    }

    setOriginLocation(latitude, longitude, address) {
        this.originLatitud = latitude;
        this.originLongitude = longitude;
        this.originLocation = address;
    }

    setDestinationLocation(latitude, longitude, address) {
        this.destinationLatitude = latitude;
        this.destinationLongitude = longitude;
        this.destinationLocation = address;
    }

    setStatus(statusId) {
        this.statusId = statusId;
    }
}

Driver.carIcon = L.icon({
    iconUrl: 'app-assets/images/gallery/car-icon.svg',
    iconSize: [80, 38],
    iconAnchor: [22, 20],
    popupAnchor: [0, 0]
});

Driver.markerIcon = L.icon({
    iconUrl: 'app-assets/images/gallery/marker-icon.png',
    iconSize: [50, 50],
    iconAnchor: [22, 20],
    popupAnchor: [0, 0]
});