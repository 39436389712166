import React, { useEffect, useRef, useState } from "react";
import ReactModal from 'react-modal';

import axios from "axios";

import Validate from '../../../helpers/validate';
import authHeader from '../../../services/auth-header';
import { TextControl, SelectControl } from '../Controls';

function EditBonusModal(props) {

    const [description, setDescription] = useState('');
    const [typeId, setTypeId] = useState('');
    const [value, setValue] = useState('');
    const [startDate, setStartDate] = useState('');
    const [startHour, setStartHour] = useState('');
    const [startMinute, setStartMinute] = useState('');
    const [endDate, setEndDate] = useState('');
    const [endHour, setEndHour] = useState('');
    const [endMinute, setEndMinute] = useState('');
    const [numTimesApp, setNumTimesApp] = useState('');
    const [typeOfMember, setTypeOfMember] = useState('');
    const [servicesQty, setServicesQty] = useState('');
    const [errorMessage, setErrorMessage] = useState({});

    useEffect(() => {
        if (props.isOpen) {
            const startDateTime = new Date(props.bonus.startDateTime);
            const endDateTime = new Date(props.bonus.endDateTime);

            setDescription(props.bonus.description);
            setValue(props.bonus.value);
            setStartDate(`${startDateTime.toISOString().substring(0, 10)}`);
            setStartHour(`${startDateTime.toTimeString().substring(0, 2)}`);
            setStartMinute(`${startDateTime.toTimeString().substring(3, 5)}`);
            setEndDate(`${endDateTime.toISOString().substring(0, 10)}`);
            setEndHour(`${endDateTime.toTimeString().substring(0, 2)}`);
            setEndMinute(`${endDateTime.toTimeString().substring(3, 5)}`);
            setNumTimesApp(props.bonus.numTimesApp);
            setServicesQty(props.bonus.servicesQty);

            let memberType = 0;
            switch (props.bonus.memberType) {
                case 'Customer':
                    memberType = 1;
                    break;
                case 'Driver':
                    memberType = 2;
                    break;
            }
            setTypeOfMember(memberType);

            switch (props.bonus.valueType) {
                case 'Percentage':
                    setTypeId(1);
                    break;
                case 'Static':
                    setTypeId(2);
                    break;
                default:
                    setTypeId(0);
            }
        }
    }, [props.isOpen]);

    async function handleSubmit() {
        const validate = new Validate();
        const message = {};
        message.description = validate.init(description, {
            maxLength: {
                value: 100,
                message: 'The maximum length possible of the description is 100 characters'
            },
            minLength: {
                value: 1,
                message: 'The minimum length possible of the description is 1 character'
            }
        }).getHTML();
        message.typeId = validate.init(typeId, {
            notCero: { message: 'A type must be selected' }
        }).getHTML();
        if (typeId == 2) // Percentage
        {
            message.value = validate.init(value, {
                maxLength: {
                    value: 15,
                    message: 'The maximum length possible of the value is 150 characters'
                },
                minLength: {
                    value: 1,
                    message: 'The minimum length possible of the value is 1 character'
                },
                decimal: {
                    message: 'The value can only be a decimal number'
                }
            }).getHTML();
        }
        else if (typeId == 1) { // Static
            message.value = validate.init(value, {
                maxLength: {
                    value: 15,
                    message: 'The maximum length possible of the value is 150 characters'
                },
                minLength: {
                    value: 1,
                    message: 'The minimum length possible of the value is 1 character'
                },
                integer: {
                    message: 'The value can only be an integer number'
                },
                minValue: {
                    value: 1,
                    message: 'The times number can only be greater than 0'
                },
                maxValue: {
                    value: 100,
                    message: 'The times number can only be less or equal than 100'
                }
            }).getHTML();
        }
        message.numTimesApp = validate.init(numTimesApp, {
            maxLength: {
                value: 2,
                message: 'The maximum length possible of the times number is 2 characters'
            },
            minLength: {
                value: 1,
                message: 'The minimum length possible of the times number is 1 character'
            },
            integer: {
                message: 'The times number can only be a only be an integer number'
            },
            minValue: {
                value: 1,
                message: 'The times number can only be greater than 0'
            }
        }).getHTML();
        message.startDate = validate.init(startDate, {
            notEmpty: {
                message: 'A start date must be selected'
            }
        }).getHTML();
        message.startHour = validate.init(startHour, {
            notEmpty: {
                message: 'A start date hour must be selected'
            },
            maxValue: {
                value: 23,
                message: 'The start hour cannot exceed 23 hours'
            },
            minValue: {
                value: 0,
                message: 'The start hour cannot be less than 0'
            },
        }).getHTML();
        message.startMinute = validate.init(startMinute, {
            notEmpty: {
                message: 'A start date minute must be selected'
            },
            maxValue: {
                value: 59,
                message: 'The start minute cannot exceed 59 minutes'
            },
            minValue: {
                value: 0,
                message: 'The start minute cannot be less than 0'
            },
        }).getHTML();
        message.endDate = validate.init(endDate, {
            notEmpty: {
                message: 'An end date must be selected'
            }
        }).getHTML();
        message.endHour = validate.init(endHour, {
            notEmpty: {
                message: 'A end date hour must be selected'
            },
            maxValue: {
                value: 23,
                message: 'The end hour cannot exceed 23 hours'
            },
            minValue: {
                value: 0,
                message: 'The end hour cannot be less than 0'
            },
        }).getHTML();
        message.endMinute = validate.init(endMinute, {
            notEmpty: {
                message: 'A end date minute must be selected'
            },
            maxValue: {
                value: 59,
                message: 'The end minute cannot exceed 59 minutes'
            },
            minValue: {
                value: 0,
                message: 'The end minute cannot be less than 0'
            },
        }).getHTML();
        message.typeOfMember = validate.init(typeOfMember, {
            notCero: { message: 'A type must be selected' }
        }).getHTML();
        message.servicesQty = validate.init(servicesQty, {
            maxLength: {
                value: 2,
                message: 'The maximum length possible of the amount of services to consider is 2 characters'
            },
            minLength: {
                value: 1,
                message: 'The minimum length possible of the amount of services to consider is 1 character'
            },
            integer: {
                message: 'The amount of services to consider can only be a only be an integer number'
            },
            minValue: {
                value: 1,
                message: 'The times number can only be greater than 0'
            }
        }).getHTML();

        const startDateTime = parseDate(startDate);
        const endDateTime = parseDate(endDate);
        startDateTime.setHours(startHour, startMinute);
        endDateTime.setHours(endHour, endMinute);

        if (endDateTime <= startDateTime) {
            message.endDate = <>{message.endDate}<span>The end date must be after the start date</span><br /></>;
            validate.status = false;
        }
        setErrorMessage(message);

        if (validate.status) {
            const startDateTimeStr = `${startDateTime.toISOString().substr(0, 10)} ${startDateTime.toTimeString().substr(0, 8)}`;
            const endDateTimeStr = `${endDateTime.toISOString().substr(0, 10)} ${endDateTime.toTimeString().substr(0, 8)}`;

            await axios.put(process.env.REACT_APP_API_URL + `api/admin/updateBonus/${props.bonus.bonusId}`, {
                description, typeId, value, numTimesApp, typeOfMember, servicesQty,
                startDateTime: startDateTimeStr, endDateTime: endDateTimeStr, statusId: 1
            }, {
                headers: authHeader()
            });
            props.updateTable();
            props.onClose();
        }
    }

    function handleFocus(event) {
        const element = event.target.id,
            messages = errorMessage;
        messages[element] = null;
        setErrorMessage(messages);
    }

    function clearControls() {
        setDescription();
        setTypeId();
        setValue();
        setStartDate();
        setStartHour();
        setStartMinute();
        setEndDate();
        setEndHour();
        setEndMinute();
        setNumTimesApp();
        setTypeOfMember();
        setServicesQty();
        setErrorMessage({});
    }

    function parseDate(value) {
        const b = value.split(/\D/);
        return new Date(b[0], --b[1], b[2]);
    }

    return (
        <ReactModal isOpen={props.isOpen} style={{ overlay: { zIndex: '999' } }}>
            <div className="container">
                <div className="row">
                    <div className="col s11 left hide-on-small-only" style={{ fontVariant: 'small-caps', fontFamily: 'Raleway', fontSize: '2rem' }}>
                        Edit New Bonus
                    </div>
                    <div className="col s1 right">
                        <a onClick={() => {
                            clearControls();
                            props.onClose();
                        }} className="btn-floating waves-effect waves-light black white-text"><i className="material-icons">close</i></a>
                    </div>
                </div>
                <hr />
                <CustomRow>
                    <div className="input-field col s8" style={{ marginTop: '35px' }}>
                        <TextControl id="description" name="description" value={description} inputEventHandler={setDescription} focusEventHandler={handleFocus} maxlength="100" />
                        <label className={(description ? 'active' : '')} htmlFor="description">Description</label>
                        <span data-assigned="description" className="helper-text red-text text-darken-1">{errorMessage.description}</span>
                    </div>
                </CustomRow>
                <CustomRow>
                    <div className="input-field col s5">
                        <TextControl id="value" name="value" value={value} inputEventHandler={setValue} focusEventHandler={handleFocus} maxlength="100" />
                        <label className={(value ? 'active' : '')} htmlFor="value">Value</label>
                        <span data-assigned="value" className="helper-text red-text text-darken-1">{errorMessage.value}</span>
                    </div>
                    <div className="input-field col s3">
                        <SelectControl value={typeId} inputEventHandler={setTypeId} focusEventHandler={handleFocus} id="typeId" name="typeId">
                            <option value="0" selected>Select the type of bonus</option>
                            <option value="1" selected>Percentage</option>
                            <option value="2" selected>Static</option>
                        </SelectControl>
                        <span data-assigned="typeId" className="helper-text red-text text-darken-1">{errorMessage.typeId}</span>
                    </div>
                </CustomRow>
                <CustomRow>
                    <div className="input-field col s4">
                        <input value={startDate} onChange={(event) => setStartDate(event.target.value)} type="date" id="startDate" name="startDate" />
                        <label htmlFor="startDate">Start Date</label>
                        <span data-assigned="startDate" className="helper-text red-text text-darken-1">{errorMessage.startDate}</span>
                    </div>
                    <div className="input-field col s2">
                        <input value={startHour} onChange={(event) => setStartHour(event.target.value)} max={23} min={0} type="number" id="startHour" name="startHour" />
                        <label className="active" htmlFor="startHour">HH</label>
                        <span data-assigned="startHour" className="helper-text red-text text-darken-1">{errorMessage.startHour}</span>
                    </div>
                    <div className="input-field col s2">
                        <input className="active" value={startMinute} onChange={(event) => setStartMinute(event.target.value)} max={59} min={0} type="number" id="startMinute" name="startMinute" />
                        <label className="active" htmlFor="startMinute">MM</label>
                        <span data-assigned="startMinute" className="helper-text red-text text-darken-1">{errorMessage.startMinute}</span>
                    </div>
                </CustomRow>
                <CustomRow>
                    <div className="input-field col s4">
                        <input value={endDate} onChange={(event) => setEndDate(event.target.value)} type="date" id="endDate" name="endDate" />
                        <label htmlFor="endDate">Start Date</label>
                        <span data-assigned="endDate" className="helper-text red-text text-darken-1">{errorMessage.endDate}</span>
                    </div>
                    <div className="input-field col s2">
                        <input value={endHour} onChange={(event) => setEndHour(event.target.value)} max={23} min={0} type="number" id="endHour" name="endHour" />
                        <label className="active" htmlFor="endHour">HH</label>
                        <span data-assigned="endHour" className="helper-text red-text text-darken-1">{errorMessage.endHour}</span>
                    </div>
                    <div className="input-field col s2">
                        <input value={endMinute} onChange={(event) => setEndMinute(event.target.value)} max={59} min={0} type="number" id="endMinute" name="endMinute" />
                        <label className="active" htmlFor="endMinute">MM</label>
                        <span data-assigned="endMinute" className="helper-text red-text text-darken-1">{errorMessage.endMinute}</span>
                    </div>
                </CustomRow>
                <CustomRow>
                    <div className="input-field col s8">
                        <input value={numTimesApp} onChange={(event) => setNumTimesApp(event.target.value)} onFocus={handleFocus} type="number" id="numTimesApp" name="numTimesApp" />
                        <label className={(numTimesApp ? 'active' : '')} htmlFor="numTimesApp">Times</label>
                        <span data-assigned="numTimesApp" className="helper-text red-text text-darken-1">{errorMessage.numTimesApp}</span>
                    </div>
                </CustomRow>
                <CustomRow>
                    <div className="input-field col s8">
                        <SelectControl value={typeOfMember} inputEventHandler={setTypeOfMember} focusEventHandler={handleFocus} id="typeOfMember" name="typeOfMember">
                            <option value="0" selected>Select type of the member</option>
                            <option value="1" selected>Customer</option>
                            <option value="2" selected>Driver</option>
                        </SelectControl>
                        <span data-assigned="numTimesApp" className="helper-text red-text text-darken-1">{errorMessage.typeOfMember}</span>
                    </div>
                </CustomRow>
                <CustomRow>
                    <div className="input-field col s8" style={{ marginTop: '35px' }}>
                        <TextControl id="servicesQty" name="servicesQty" value={servicesQty} inputEventHandler={setServicesQty} focusEventHandler={handleFocus} maxlength="100" />
                        <label className='active' htmlFor="servicesQty">Amount of services to consider</label>
                        <span className="helper-text">Setting it to 0 determines that the bonus will span from start to end</span>
                        <span data-assigned="servicesQty" className="helper-text red-text text-darken-1">{errorMessage.servicesQty}</span>
                    </div>
                </CustomRow>
                <CustomRow>
                    <div className="input-field col s8">
                        <a onClick={handleSubmit} style={{ width: '100%', fontFamily: 'Raleway', fontSize: '1rem', backgroundColor: 'black', marginTop: '30px' }} className="waves-effect waves-light btn z-depth-0 white-text">SAVE & CLOSE</a>
                    </div>
                </CustomRow>
            </div>
        </ReactModal>
    );
}

function CustomRow(props) {
    return (
        <div className={`row ${props.className}`}>
            <div className="col s2"></div>
            {props.children}
            <div className="col s2"></div>
        </div>
    );
}

export default EditBonusModal;