import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import axios from "axios";
import ReactModal from 'react-modal';
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'

import authHeader from "../../../services/auth-header";
import Table from '../../Table';
import { TextControl } from '../Controls';
import ScheduleModal from '../modals/ScheduleModal';
import EventDetailsModal from '../modals/EventDetailsModal';

ReactModal.setAppElement('#root');

function Associates(props) {
    const { user: currentUser } = useSelector((state) => state.auth);

    const [applicants, setApplicants] = useState([]);
    const [events, setEvents] = useState([]);
    const [currentApplicant, setCurrentApplicant] = useState({});
    const [currentEvent, setCurrentEvent] = useState({});
    const [reschedule, setReschedule] = useState();

    const [tablePage, setTablePage] = useState(0);
    const [tableSearchValue, setTableSearchValue] = useState('');
    const [tableSearchFields, setTableSearchFields] = useState(['type', 'firstName', 'lastName', 'email', 'phone']);

    const [isScheduleModalOpened, openScheduleModal] = useState(false);
    const [isEventDetailModalOpened, openEventDetailModal] = useState(false);
    const calendarRef = useRef();

    function isUserAllowed() {
        if (currentUser) {
            if (!currentUser.roles.includes("ROLE_MANAGER"))
                return <Redirect to="/login" />;
        }
        else
            return <Redirect to="/login" />;
    }
    isUserAllowed();

    useEffect(() => {
        const today = new Date();

        getApplicants();
        getCalendarEvents(today.getMonth() + 1, today.getFullYear());
    }, []);

    useEffect(() => {
        setTablePage(0);
    }, [tableSearchValue, tableSearchFields]);

    async function getApplicants() {
        let result = [],
            resultAssociates = await axios.get(process.env.REACT_APP_API_URL + `api/driver/recorded/listAssociate/2/6`, { headers: authHeader() }),
            resultDrivers = await axios.get(process.env.REACT_APP_API_URL + `api/driver/recorded/listDriver/1/6`, { headers: authHeader() });

        if (resultAssociates) {
            resultAssociates = resultAssociates.data;
            for (const associate of resultAssociates)
                associate.type = 'Associate';
            result.push(...resultAssociates);
        }
        if (resultDrivers) {
            resultDrivers = resultDrivers.data;
            for (const driver of resultDrivers)
                driver.type = 'Driver';
            result.push(...resultDrivers);
        }
        setApplicants(result);
    }

    async function getCalendarEvents(month, year) {
        let result = await axios.get(process.env.REACT_APP_API_URL + `api/driver/recorded/interviewByMonth/${month}/${year}`, { headers: authHeader() })
        if (result)
            setEvents(result.data);
    }

    function getApplicantsRow(applicant) {
        return (
            <tr key={applicant.driverId}>
                <td>{applicant.type}</td>
                <td>{applicant.firstName} {applicant.lastName}</td>
                <td>{applicant.email}</td>
                <td>{applicant.phone}</td>
                <td className="center-align">
                    <a className="btn-floating waves-effect waves-light btn black white-text" onClick={() => {
                        setCurrentApplicant(applicant);
                        openScheduleModal(true);
                    }}>
                        <i class="material-icons left">schedule</i>
                    </a>
                </td>
            </tr>
        );
    }

    function setCalendarDate(navigation) {
        setEvents([]);
        const api = calendarRef.current.getApi();
        if (navigation == 'prev')
            api.prev();
        else
            api.next();

        const currentDate = api.getDate();
        getCalendarEvents(currentDate.getMonth() + 1, currentDate.getFullYear());
    }

    function transformEventData(event) {
        const startDate = new Date(event.bookDate);
        startDate.setHours(event.bookTime.substring(0, 2), event.bookTime.substring(3, 5));

        const endDate = new Date(startDate);
        endDate.setMinutes(endDate.getMinutes() + 30);

        return {
            title: `${event.firstName} ${event.lastName}`,
            start: startDate,
            end: endDate,
            extendedProps: {
                ...event,
                date: event.bookDate.substr(0, 10),
                hour: event.bookTime.substring(0, 2),
                minutes: event.bookTime.substring(3, 5)
            }
        };
    }

    function updateFields() {
        getApplicants();

        const api = calendarRef.current.getApi(), currentDate = api.getDate();
        getCalendarEvents(currentDate.getMonth() + 1, currentDate.getFullYear());
    }

    function handleEventClick(event) {
        setCurrentEvent({ ...event.event.extendedProps });
        openEventDetailModal(true);
    }

    return (
        <div className="container">
            <div className="row valign-wrapper">
                <div className="center-align col s12" style={{ fontVariant: 'small-caps', fontFamily: 'Raleway', fontSize: '3rem' }}>
                    Applicants
                </div>
            </div>
            <div className="row z-depth-1 white">
                <div className="col s6" style={{ padding: '10px', margin: '20px 0px' }}>
                    <div className="row">
                        <div className="input-field col s12">
                            <TextControl id="tableSearchValue" value={tableSearchValue} inputEventHandler={setTableSearchValue} focusEventHandler={() => null} />
                            <label htmlFor="tableSearchValue">Search</label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col s12">
                            <Table
                                style={{ tableLayout: 'auto' }}
                                tableHead={
                                    <thead>
                                        <tr>
                                            <th>Type</th>
                                            <th>Name</th>
                                            <th>Email</th>
                                            <th>Phone</th>
                                            <th className="center-align">Details</th>
                                        </tr>
                                    </thead>
                                }
                                tableBody={
                                    (rows) => <tbody>{rows}</tbody>}
                                tableFoot={null}
                                row={getApplicantsRow}
                                data={applicants}
                                searchValue={tableSearchValue}
                                searchFields={tableSearchFields}
                                show={10}
                                page={tablePage}
                                pagination={(pageRowAmount) => {
                                    return (
                                        <div className="row" style={{ marginTop: '20px' }}>
                                            <div className="col s12 left">
                                                <a onClick={() => setTablePage(tablePage - 1)} disabled={!tablePage} className="waves-effect waves-black btn-flat">Previous</a>
                                                {(tablePage > 1 &&
                                                    <>
                                                        <a onClick={() => setTablePage(0)} className="waves-effect waves-black btn-flat">1</a>
                                                        <span> ... </span>
                                                    </>)}
                                                {(tablePage > 0 &&
                                                    <a onClick={() => setTablePage(tablePage - 1)} className="waves-effect waves-black btn-flat">{tablePage}</a>)}
                                                <a disabled={true} className="waves-effect waves-black btn-flat">{tablePage + 1}</a>
                                                {(tablePage + 1 < pageRowAmount &&
                                                    <a onClick={() => setTablePage(tablePage + 1)} className="waves-effect waves-black btn-flat">{tablePage + 2}</a>)}
                                                {(tablePage + 2 < pageRowAmount &&
                                                    <>
                                                        <span> ... </span>
                                                        <a onClick={() => setTablePage(pageRowAmount - 1)} className="waves-effect waves-black btn-flat">{pageRowAmount}</a>
                                                    </>)}
                                                <a onClick={() => setTablePage(tablePage + 1)} disabled={!(tablePage + 1 < pageRowAmount)} className="waves-effect waves-black btn-flat">Next</a>
                                            </div>
                                        </div>
                                    );
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className="col s6" style={{ padding: '10px', margin: '20px 0px' }}>
                    <FullCalendar
                        ref={calendarRef}
                        customButtons={{
                            back: {
                                text: <i className="material-icons" style={{ paddingTop: '4px' }}>chevron_left</i>,
                                click: () => setCalendarDate('prev')
                            },
                            forward: {
                                text: <i className="material-icons" style={{ paddingTop: '4px' }}>chevron_right</i>,
                                click: () => setCalendarDate('next')
                            }
                        }}
                        headerToolbar={{
                            start: 'back',
                            center: 'title',
                            end: 'forward'
                        }}
                        plugins={[dayGridPlugin]}
                        initialView="dayGridMonth"
                        events={events}
                        eventDataTransform={transformEventData}
                        eventClick={handleEventClick}
                    />
                </div>
            </div>

            <EventDetailsModal isOpen={isEventDetailModalOpened} onClose={() => openEventDetailModal(false)}
                updateFields={updateFields} currentEvent={currentEvent}
                updateStates={{ setCurrentApplicant, setReschedule, openScheduleModal }} />
            <ScheduleModal isOpen={isScheduleModalOpened} onClose={() => openScheduleModal(false)}
                updateFields={updateFields} currentApplicant={currentApplicant}
                reschedule={reschedule} />
        </div>
    );
}
export default Associates;