import React, { useState, useEffect, useRef, Item } from "react";
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import { useDispatch, useSelector } from "react-redux";
import HumanTimer from 'react-human-timer';
import swal from 'sweetalert';
import { capitalize } from "./../../helpers/functions";
import CreatableSelect from 'react-select/creatable';
import { Select,  DatePicker, TimePicker, ProgressBar,Button,Tabs,Tab } from "react-materialize"
import Form from "react-validation/build/form";
import Input from 'react-validation/build/input';
import { getLastOrigin, getLastDestination, updateReservation, createNewReservation, createNewService, clearService, createEmptyService, getTopicsComments, createNewCustomer,loadCustomerByPhone,updateField,getAvailables,saveService,getOriginArizonaCitiesByCounty,getDestinationArizonaCitiesByCounty, getArizonaCounties, getNewServiceRate } from "../../actions/operator/dashboard";
import GoogleMapReact from "google-map-react";
import Geocode from "react-geocode";
import DateFnsUtils from '@date-io/date-fns';
import { TextField } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import {MuiPickersUtilsProvider,KeyboardTimePicker,KeyboardDatePicker} from '@material-ui/pickers';


const OperatorNewService = (props) => {
    const dispatch = useDispatch();
    
    const newCustomerselectCitiesOrigin = useRef();
    const newCustomerselectCitiesDestination = useRef();
    const newCustomerselectCountyOrigin = useRef();
    const newCustomerselectCountyDestination = useRef();
    
    const [timeColor, setTimeColor] = useState('green');
    const [comments, setComments] = useState({});
    const { lastDestination, lastOrigin, filters, newService, newServiceTopicComments, newServiceRate,newServiceRateLoading,customer,availables,counties,countiesLoading,arizonaCountyCitiesOrigin,arizonaCountyCitiesDestination,citiesLoadingOrigin,citiesLoadingDestination } = useSelector(state => state.operator);
    const [showGPS, setShowGPS] = useState(false);
    const [showGPS2, setShowGPS2] = useState(false);
    const [showRoadmap, setShowRoadmap] = useState(false);
    const [roadmaps , setRoadmaps] = useState([]);
    const [center , serCenter] = useState({lat: 33.5722,lng: -112.088});
    const [centerOrigin , setCenterOrigin] = useState({});
    const [zoom , setZoom] = useState(11);
    const [places , setPlaces] = useState([]);
    const [showNewUser, setShowNewUser] = useState(false);
    const [showAvaliable , setShowAvaliable] = useState(false);

    const [customerTypeValue , setCustomerTypeValue] = useState(null);

    const [paymentTypeValue , setPaymentTypeValue] = useState(null);
    const [defaultCityOrigin , setDefaultCityOrigin] = useState(55);
    const [defaultCountyOrigin , setDefaultCountyOrigin] = useState(8);
    const [defaultCityDestination , setDefaultCityDestination] = useState(55);
    const [defaultCountyDestination , setDefaultCountyDestination] = useState(8);
    const [selectCitiesOrigin , setSelectCitiesOrigin] = useState(null);
    const [selectCitiesDestination , setSelectCitiesDestination] = useState(null);
    const [selectCountyOrigin , setSelectCountyOrigin] = useState(null);

    const [selectLastOrigin , setSelectLastOrigin] = useState(null);
    const [selectLastDestination , setSelectLastDestination] = useState(null);

    const [selectCountyDestination , setSelectCountyDestination] = useState(null);
    const [newCustomer , setNewCustomer] = useState({locationTypeValue:'',customerTypeId:1,email:'',phone:'',customertype:'',firstname:'',lastname:'',destinationNumber:'',destinatioNSB:'',destinationStreet:'',destinationCounty:null,destinationCity:null,originNumber:'',originNSB:'',originStreet:'',originCounty:null,originCity:null});

    const { user: currentUser } = useSelector((state) => state.auth);
    const { isLoggedIn } = useSelector(state => state.auth);
    
    const [customerTypeValueN , setCustomerTypeValueN] = useState(null);
    const [paymentTypeValueN , setPaymentTypeValueN] = useState(null);


    /* select multiples zipcodes */

    const [currentMZModal , setCurrentMZModal] = useState(false);
    const [currentMZFunction , setCurrentMZFunction] = useState(null);
    const [currentMZOptions , setCurrentMZOptions] = useState([]);

    /* select multiples zipcodes */

    const [locationTypes , setLocationTypes] = useState([
        {value:1,label:'Home'},
        {value:2,label:'Apt'},
        {value:3,label:'Office'},
        {value:4,label:'Trailer'},
        {value:5,label:'Hospital'},
        {value:6,label:'Clinic'},
        {value:7,label:'Business'},
        {value:8,label:'Exterior'},
        {value:9,label:'Hotel'},
    ]);
    const [locationType , setLocationType] = useState({value:1,label:'Home'});
    

    useEffect(() => {
        if(newServiceRate != 'No Rate'){
            swal("Rate: "+ newServiceRate );
        }
        return () => {}
      }, [newServiceRate])

    useEffect(() => {
        dispatch(clearService());
        setNewCustomer({customerTypeId:1,email:'',phone:'',customertype:'',firstname:'',lastname:'',destinationNumber:'',destinatioNSB:'',destinationStreet:'',destinationCounty:null,destinationCity:null,originNumber:'',originNSB:'',originStreet:'',originCounty:null,originCity:null});
        dispatch(updateField('destinationNSB','N'));
        dispatch(updateField('destinationStreet',''));
        dispatch(getArizonaCounties());
        //dispatch(getTopicsComments());
        Geocode.setApiKey("AIzaSyCAoQ5Jml2jW_sOCP0kKFAJLQXBmmuEr-k");
        Geocode.setLanguage("en");
        Geocode.setRegion("us");
        Geocode.enableDebug();
        
    },[]);
    
    useEffect(() => {
         if(counties.length > 8){

            //console.log('counties',counties);
            //console.log('defaultCountyOrigin',defaultCountyOrigin);

            let elementoc = getElement(counties,defaultCountyOrigin);
            setSelectCountyOrigin(elementoc);
            dispatch(updateField('originCounty',elementoc));

            let elementdc = getElement(counties,defaultCountyDestination);
            setSelectCountyDestination(elementdc);
            dispatch(updateField('destinationCounty',elementdc));
            
            if(newCustomerselectCountyOrigin.current != undefined){
                setNewCustomer({...newCustomer,originCounty:counties[8]});
                newCustomerselectCountyOrigin.current.select.select.setValue(counties[8]);
            } 
            if(newCustomerselectCountyDestination.current != undefined){
                setNewCustomer({...newCustomer,destinationCounty:counties[8]});
                newCustomerselectCountyDestination.current.select.select.setValue(counties[8]);
            }
            dispatch(getOriginArizonaCitiesByCounty(defaultCountyOrigin));
            dispatch(getDestinationArizonaCitiesByCounty(defaultCountyDestination));
        }

    },[counties]);

    useEffect(() => {
        if(arizonaCountyCitiesDestination.length > 17){
            if(newCustomerselectCitiesDestination.current != undefined && newCustomer.destinationCounty.value == 8){
                setNewCustomer({...newCustomer,destinationCity:arizonaCountyCitiesDestination[17]});
                newCustomerselectCitiesDestination.current.select.select.setValue(arizonaCountyCitiesDestination[17]);
            }
            if(customer.destinationCounty.value == defaultCountyDestination){
                let elementcd = getElement(arizonaCountyCitiesDestination,defaultCityOrigin);
                setSelectCitiesDestination(elementcd);
                dispatch(updateField('destinationCity',elementcd));
            }
        }
    },[arizonaCountyCitiesDestination]);

    useEffect(() => {
        //console.log('arizonaCountyCitiesOrigin',arizonaCountyCitiesOrigin);
        if(arizonaCountyCitiesOrigin.length > 1){
            if(newCustomerselectCitiesOrigin.current != undefined && newCustomer.originCounty.value == 8){
                setNewCustomer({...newCustomer,originCity:arizonaCountyCitiesOrigin[17]});
                newCustomerselectCitiesOrigin.current.select.select.setValue(arizonaCountyCitiesOrigin[17]);
            }

            //console.log('customer.originCounty',customer.originCounty);
            //console.log('defaultCountyOrigin',defaultCountyOrigin);

            if(customer.originCounty.value == defaultCountyOrigin){
                let elementco = getElement(arizonaCountyCitiesOrigin,defaultCityOrigin);
                setSelectCitiesOrigin(elementco);
                dispatch(updateField('originCity',elementco));
            }

            console.log("customer.cityId ----- ",customer.cityId);
            if(customer.cityId != null){
                console.log("customer.cityId true");
                let elementco = getElement(arizonaCountyCitiesOrigin,customer.cityId);
                console.log("elementco",elementco);
                setSelectCitiesOrigin(elementco);
                dispatch(updateField('originCity',elementco));
                
            }

        }
    },[arizonaCountyCitiesOrigin]);

    useEffect(() => {
        //console.log(customer);
        if(customer.originlat != ''){
            setCenterOrigin({lat: customer.originlat,lng: customer.originlng})
        }
    },[customer.originlat]);

    
    useEffect(() => {
        if(customer.countyId != null){
            let elementoc = getElement(counties,customer.countyId);
            setSelectCountyOrigin(elementoc);
            dispatch(updateField('originCounty',elementoc));
            dispatch(getOriginArizonaCitiesByCounty(customer.countyId));
        }
    },[customer.countyId]);

    const getPostalCode = (n,o,s,r) => {
        let address = n+" "+o+" "+s;
        Geocode.fromAddress(address).then(
            response => {
                console.log("response",response);
                /*const { lat, lng } = response.results[0].geometry.location;

                if(lat == null || lng == null){
                    swal("Postal Code not Found.");
                    return;
                }*/

            },
            error => {
              console.error(error);
            }
        );
    }


    const createNewCustomeronClick = () =>{
    
        //console.log(newCustomer);

        if(!newCustomer.originNumber){
            swal("The Origin number is required.");
            return;
        }
        if(!newCustomer.originStreet){
            swal("The Origin street is required.");
            return;
        }
        if(!newCustomer.originPostalcode){
            swal("The Origin zip code is required.");
            return;
        }


        let address = newCustomer.originNumber
            +' '+newCustomer.originNSB
            +' '+newCustomer.originStreet
            +','+newCustomer.originCity.label
            +','+newCustomer.originCounty.label
            +',Arizona'
            +','+newCustomer.originPostalcode
            +',USA';

        Geocode.fromAddress(address).then(
            response => {
                const { lat, lng } = response.results[0].geometry.location;

                if(lat == null || lng == null){
                    swal("The origin address is not valid");
                    return;
                }

                dispatch(createNewCustomer({firstName:newCustomer.firstname,
                    lastName:newCustomer.lastname,
                    /*email:newCustomer.email,*/
                    phone:newCustomer.phone,
                    street:newCustomer.originStreet,
                    number:newCustomer.originNumber,
                    nsew:newCustomer.originNSB,
                    /*customerTypeId:newCustomer.customerTypeId,
                    paymentTypeId:newCustomer.paymentTypeId,*/
                    zipCode:newCustomer.originPostalcode,
                    countyId:newCustomer.originCounty.value,
                    cityId:newCustomer.originCity.value,
                    latitude: lat,
                    longitude: lng,
                })).then((val) => {
                    swal("Customer created successful.");
                    setShowNewUser(false);
                    searchCustomer();
                }).catch(() => {
                    swal("Error to create the customer.");
                });

              
            },
            error => {
              console.error(error);
            }
        );
    }

    const resetSelects = () => {
    }

    const onTypeSelectService = (element) => {
        dispatch(updateField('type',1));
    }

    const onTypeSelectReservation = (element) => {
        dispatch(updateField('type',2));
    }

    const onDatePickerOnClose = () => { 
        dispatch(updateField('reservationdate',document.getElementById("reservationdate").value));
    }

    const onTimePickerOnClose = (event) => {
        dispatch(updateField('reservationtime',document.getElementById("reservationtime").value));
    }

    const onTick = (val) => {
        if(val.durationLeft > 900){
            if(timeColor != 'green'){
                setTimeColor('green');
            }
        }else{ 
            if(val.durationLeft <= 600 && val.durationLeft > 300){
                if(timeColor != 'orange'){
                    setTimeColor('orange');
                }
            }else{ 
                if(val.durationLeft <= 300){
                    if(timeColor != 'red'){
                        setTimeColor('red');
                    }
                }
            }
        }
    }

    const searchCustomer = () => {
        dispatch(loadCustomerByPhone(customer.phone)).then((val) => {
            //console.log('loadCustomerByPhone');
            if(customer.id == null){
                swal("Customer not found.");
            }else{
                setPaymentTypeValue(getElement(filters.paymentType.items,customer.paymentTypeId));
                setCustomerTypeValue(getElement(filters.customerType.items,customer.customerTypeId));

                setSelectCountyOrigin(getElement(counties,customer.countyId));
                setSelectCitiesOrigin(getElement(arizonaCountyCitiesOrigin,customer.cityId));
                
                dispatch(getLastOrigin(customer.id));
                dispatch(getLastDestination(customer.id));

                if(newCustomer.destinationNumber) dispatch(updateField('destinationNumber',newCustomer.destinationNumber));
                if(newCustomer.destinationNSB) dispatch(updateField('destinationNSB',newCustomer.destinationNSB));
                if(newCustomer.destinationStreet) dispatch(updateField('destinationStreet',newCustomer.destinationStreet));
                if(newCustomer.destinationCity) dispatch(updateField('destinationCity',newCustomer.destinationCity));
                if(newCustomer.destinationCounty) dispatch(updateField('destinationCounty',newCustomer.destinationCounty));
                if(newCustomer.destinationPostalcode) dispatch(updateField('destinationPostalcode',newCustomer.destinationPostalcode));

            }
        }).catch((e) => {
            console.log(e);

            let tempcustomer = newCustomer;
            tempcustomer.paymentTypeId = 1;
            tempcustomer.customerTypeId = 1;
            tempcustomer.phone = customer.phone;
            tempcustomer.destinationCity = arizonaCountyCitiesDestination[17];
            tempcustomer.originCity = arizonaCountyCitiesOrigin[17];
            tempcustomer.destinationCounty = counties[8];
            tempcustomer.originCounty = counties[8];

            setNewCustomer(tempcustomer);
            setShowNewUser(true);
        });
    }

    const getElement = (collection, id) => {
        let tempcollection = collection.filter(element => element.value == id);
        return tempcollection.length > 0 ?tempcollection[0]:{};
    }

    const closeGPS = () => {
        //console.log("closeGPS");
        setShowGPS(false);
    }

    const closeAvaliable = () => {
        setShowAvaliable(false);
    }

    const handleApiLoaded = (map, maps) => {

    }

    const AnyReactComponent = ({ text }) => {
        return <i  class="material-icons">location_on</i>
    };
    const Vehicle = ({ text }) => {
        return <i  class="material-icons">local_taxi</i>
    };
    const Origin = ({ text }) => {
        return <i class="material-icons medium">my_location</i>
    };
    
    const parseLocation = (location) => {

        var response = {}
        response.street = "";
        response.number = "";
        response.nsew = "";
        response.zipcode = "";
        response.city = "";
        response.cityId = "";
        response.county = "";
        response.countyId = "";
        response.state = "Arizona";
        response.stateId = 3;
        response.country = "USA";
        response.countryId = 35;

        if(location){
            var t1 = location.split(',').map(l=>l.trim());
            if(t1.length == 5){
                var t2 = /^(.+)[ ]([N|S|E|W])[ ](.+)$/g.exec(t1[0]);
                if(t2 != null && t2.length == 4){
                    response.number = t2[1];
                    response.nsew = t2[2];
                    response.street = t2[3];
                }
                response.city = t1[1];
                response.county = t1[2];
                response.zipcode = t1[4];
            }else if(t1.length == 6){
                var t2 = /^(.+)[ ]([N|S|E|W])[ ](.+)$/g.exec(t1[0]);
                if(t2 != null && t2.length == 4){
                    response.number = t2[1];
                    response.nsew = t2[2];
                    response.street = t2[3];
                }
                response.city = t1[1];
                response.county = t1[2];
                response.zipcode = t1[4];
            }
        }
        return response;
    }

    const clickAvailables = () => {
        if(customer.originlat != null && customer.originlng != null){
            var temp = customer.originNumber+' '+customer.originNSB+ ' '+customer.originStreet+','+customer.originCity.label+','+customer.originCounty.label+',Arizona,'+customer.originPostalcode+',USA';
            Geocode.fromAddress(temp).then(
                response => {
                    const { lat, lng } = response.results[0].geometry.location;
                    dispatch(updateField('originlat',lat));
                    dispatch(updateField('originlng',lng));
                    dispatch(getAvailables()).then(() => {
                        setShowAvaliable(true);
                    }).catch(() => {
                        swal("Error loading drivers.");
                    });
                },
                error => {
                  console.error(error);
                }
            );
        }else{
            dispatch(getAvailables()).then(() => {
                setShowAvaliable(true);
            }).catch(() => {
                swal("Error loading drivers.");
            });
        }
    }

    const selectDriver = (DriverId,Name) => {
        dispatch(updateField('cab',DriverId));
        dispatch(updateField('assignatedDriver',Name));
        dispatch(updateField('vehicleFeatures','vehicleFeatures Test'));
        setShowAvaliable(false);
    }

    const saveServiceClick = () => {

        if(!customer.originNumber){
            swal("The Origin number is required.");
            return;
        }
        if(!customer.originStreet){
            swal("The Origin street is required.");
            return;
        }
        if(!customer.originPostalcode){
            swal("The Origin zip code is required.");
            return;
        }

        if(!customer.destinationNumber){
            swal("The Destination number is required.");
            return;
        }
        if(!customer.destinationStreet){
            swal("The Destination street is required.");
            return;
        }
        if(!customer.destinationPostalcode){
            swal("The OrDestinationigin zip code is required.");
            return;
        }

        var originAddress = 
        customer.originNumber+' '+customer.originNSB+ ' '+customer.originStreet+','+
        customer.originCity.label+','+customer.originCounty.label+',Arizona,'+customer.originPostalcode+',USA';

        var destinationAddress = 
        customer.destinationNumber+' '+customer.destinationNSB+ ' '+customer.destinationStreet+','+
        customer.destinationCity.label+','+customer.destinationCounty.label+',Arizona,'+customer.destinationPostalcode+',USA';

        Geocode.fromAddress(originAddress).then(
            response => {
                const { lat, lng } = response.results[0].geometry.location;
                var olat = lat;
                var olng = lng;

                if(olat == null || olng == null){
                    swal("The origin address is not valid");
                    return;
                }

                Geocode.fromAddress(destinationAddress).then(
                    response => {
                        const { lat, lng } = response.results[0].geometry.location;

                        if(lat == null || lng == null){
                            swal("The destination address is not valid");
                            return;
                        }

                        if(customer.type == 1){

                            let updateService = {};

                            updateService.serviceId = newService.id;
                            updateService.paymentTypeId = customer.paymentTypeId;
                            updateService.destinationLocation = destinationAddress;
                            updateService.originLocation = originAddress;
                            updateService.originLatitude = olat;
                            updateService.originLongitude = olng;
                            updateService.destinationLatitude = lat;
                            updateService.destinationLongitude = lng;

                            updateService.type = customer.type;
                            //updateService.comments = comments;

                            updateService.adInfServiceTopicId = locationType.value;
                            updateService.detail = customer.locationTypeValue;

                            dispatch(saveService(updateService)).then(() => {
                                swal("Service updated success.");
                                props.setshowNewService(false);
                            }).catch(() => {
                                swal("Error to save the service.");
                            });
                        }else{
                            let uR = {};
                            uR.reservationId = newService.id;
                            uR.paymentTypeId = customer.paymentTypeId;
                            uR.destinationLocation = destinationAddress;
                            uR.destinationLatitude = lat;
                            uR.destinationLongitude = lng;
                            uR.originLocation = originAddress;
                            uR.originLatitude = olat;
                            uR.originLongitude = olng;
                            
                            uR.adInfServiceTopicId = locationType.value;
                            uR.detail = customer.locationTypeValue;

                            dispatch(updateReservation(uR)).then(() => {
                                swal("Reservation updated success.");
                                props.setshowNewService(false);
                            }).catch(() => {
                                swal("Error to save the reservation.");
                            });
                        }
                    },error => {console.error(error);}
                );
            },error => {console.error(error);}
        );
    }

    const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectedDate2, setSelectedDate2] = useState(new Date());

    const handleDateChange = (date) => {
      setSelectedDate(date);
    };
    const handleDateChange2 = (date) => {
        setSelectedDate2(date);
    };
    
    const getZipCode = (n,o,s,r) => {

        let address = n+" "+o+" "+s;

        var zipCodesCount = 0;
        var zipCodes = {};

        console.log("address",address);
        console.log("n",n);
        console.log("o",o);
        console.log("s",s);

        Geocode.fromAddress(address).then(
            response => {
                
                if(response != null && response.results != null && response.results.length > 0){
                    for (const r in response.results){
                        for(const a in response.results[r].address_components){
                            if(response.results[r].address_components[a].types.includes("postal_code")){
                                zipCodes[response.results[r].address_components[a].long_name] = {zipcode:response.results[r].address_components[a].long_name,value:response.results[r]}
                                zipCodesCount += 1;
                            }
                        }
                    }
                }

                if(zipCodesCount == 0){
                    swal("Invalid address.");
                    return;
                }
                if(zipCodesCount == 1){
                    swal("The postalcode "+Object.keys(zipCodes)[0]);
                    r(Object.keys(zipCodes)[0]);
                    return;
                }else{
                    setCurrentMZFunction(()=>r);
                    setCurrentMZOptions(zipCodes);
                    setCurrentMZModal(true);
                }

                console.log('zipCodes',zipCodes);
                console.log('zipCodesCount',zipCodesCount);

                // 1234 S Washington St

            },
            error => {
                swal("Invalid address.");
                return;
            }
        );
    }

return (
    
<Modal open={true} onClose={props.closeButton} >
<Modal open={currentMZModal} onClose={()=>{setCurrentMZModal(false);}} classNames={{modal: ''}}>
            <div class="row">
                <h6>ZipCodes</h6>
                <div class="col s12">
                    <table class="responsive-table highlight">
                        <thead>
                            <tr>
                                <th>Address</th>
                                <th>ZipCode</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {(currentMZOptions != undefined) && Object.values(currentMZOptions).map((value) => (
                                <tr>
                                    <td>{value.value.formatted_address}</td>
                                    <td>{value.zipcode}</td>
                                    <td>                        
                                        <a class="waves-effect waves-light btn-large mb-1" onClick={()=>{
                                            currentMZFunction(value.zipcode);
                                            setCurrentMZModal(false);
                                        }}><i class='material-icons left'>send</i></a>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                            </div>
                <div class="col s12">
                    
                    {/*<div class="" style={{ height: 800, width: '100%', display: 'flex', flexFlow: 'row nowrap', justifyContent: 'center', padding: 0 }}>
                        {activedDrivers.items != null && 
                        (<GoogleMapReact
                            bootstrapURLKeys="AIzaSyCAoQ5Jml2jW_sOCP0kKFAJLQXBmmuEr-k"
                            defaultCenter={{lat: 33.5722,lng: -112.088}}
                            center={{lat: 33.5722,lng: -112.088}}
                            defaultZoom={10}
                            zoom={10}
                            onGoogleApiLoaded={({ map, maps }) => loadAllDrivers(map, maps)} >
                            {activedDrivers.items.map((marker) => {
                                if(marker.lastLatitude != null && marker.lastLongitude != null){
                                    return (<Driver lat={marker.lastLatitude} lng={marker.lastLongitude} color={marker.color} />)
                                }
                            })}
                        </GoogleMapReact>)}
                        </div>*/}
                </div>
            </div>
        </Modal>
    <Modal open={showNewUser} onClose={() => {setShowNewUser(false)}}  >
            <div >
            <h5>New Customer</h5>
            <div class="row">
                    <div class="card">
                        <div class="card-content">
                            <span class="card-title">Personal</span>
                            <div class="row">
                                <div class="input-field col s4">
                                        <input 
                                            id="newCustomerphone" 
                                            class="col s12" 
                                            type="tel"
                                            maxlength="10"
                                            defaultValue={newCustomer.phone} 
                                            onChange={(event) => {
                                                setNewCustomer({...newCustomer,phone:event.target.value});
                                            }}
                                        />
                                        <label for="newCustomerphone">Phone</label>
                                </div>
                                {/*<div class="input-field col s4">
                                        <input id="newCustomeremail" class="col s12" type="email" defaultValue={newCustomer.email} onChange={(event) => {
                                            setNewCustomer({...newCustomer,email:event.target.value});
                                            }}  />
                                        <label for="newCustomeremail">Email</label>
                                </div>
                                <CreatableSelect
                                    id="newCustomercustomertype"
                                    placeholder={"Customer Type"}
                                    value={customerTypeValueN}
                                    styles={{menu: provided => ({ ...provided, zIndex: 9999 })}}
                                    className="input-field col s4"
                                    classNamePrefix="select"
                                    defaultValue={filters.customerType.items[0]}
                                    isRtl={false}
                                    name="customertype"
                                    options={filters.customerType.items}
                                    onChange={(value)=>{
                                        setCustomerTypeValueN(value);
                                        setNewCustomer({...newCustomer,customerTypeId:value.value});
                                    }}
                                />
                                <CreatableSelect
                                    id="newCustomerPaymentType"
                                    placeholder={"Payment Type"}
                                    value={paymentTypeValueN}
                                    styles={{menu: provided => ({ ...provided, zIndex: 9999 })}}
                                    className="input-field col s4"
                                    classNamePrefix="select"
                                    defaultValue={filters.paymentType.items[0]}
                                    isRtl={false}
                                    name="customertype"
                                    options={filters.paymentType.items}
                                    onChange={(value)=>{
                                        setPaymentTypeValueN(value);
                                        setNewCustomer({...newCustomer,paymentTypeId:value.value});
                                    }}
                                />*/}
                                <div class="input-field col s4">
                                    <input id="newCustomerfirstname" type="text" class="validate" value={newCustomer.firstname} onChange={(event) => {
                                        setNewCustomer({...newCustomer,firstname:event.target.value});
                                        }}/>
                                    <label for="newCustomerfirstname">First name</label>
                                </div>
                                <div class="input-field col s4">
                                    <input id="newCustomerlastname" type="text" class="validate" value={newCustomer.lastname} onChange={(event) => {
                                        setNewCustomer({...newCustomer,lastname:event.target.value});
                                        }}/>
                                    <label for="newCustomerlastname">Last name</label>
                                </div>
                                { customer.loading && (<div class="col s12"><ProgressBar /></div>)}
                            </div>
                        </div>
                    </div>
            </div>
            <div class="row">
                    <div class="card">
                        <div class="card-content">
                                <span class="card-title">Origin Address</span>
                                <div class="row">
                                    <div class="input-field col s2">
                                        <input id="newCustomeroriginNumber" type="text" class="validate" value={newCustomer.originNumber} onChange={(event) => {
                                                setNewCustomer({...newCustomer,originNumber:event.target.value});
                                            }}/>
                                        <label for="newCustomeroriginNumber" class="active" >Number</label>
                                    </div>
                                    <div class="input-field col s2">
                                        <select style={{display:'block'}} id="newCustomeroriginNSB" onChange={(event)=>{
                                                setNewCustomer({...newCustomer,originNSB:event.target.value});
                                            }}
                                        >
                                            <option value='N' selected={newCustomer.originNSB=='N'?'selected':''} >N</option>
                                            <option value='S' selected={newCustomer.originNSB=='S'?'selected':''} >S</option>
                                            <option value='E' selected={newCustomer.originNSB=='E'?'selected':''} >E</option>
                                            <option value='W' selected={newCustomer.originNSB=='W'?'selected':''} >W</option>
                                        </select>
                                        <label for="newCustomeroriginNSB" class="active" >N,S,E,W</label>
                                    </div>
                                    <div class="input-field col s5">
                                        <input id="newCustomeroriginStreet" type="text" class="validate" value={newCustomer.originStreet} onChange={(event) => {
                                                setNewCustomer({...newCustomer,originStreet:event.target.value});                                       
                                            }}/>
                                        <label for="newCustomeroriginStreet" class="active" >Street</label>
                                    </div>
                                    <div class="input-field col s3">
                                        <input id="newCustomeroriginPostalcode" type="text" class="validate col s6" value={newCustomer.originPostalcode} onChange={(event) => {
                                            setNewCustomer({...newCustomer,originPostalcode:event.target.value});                                             
                                            }}/>
                                        <label for="newCustomeroriginPostalcode" class="active" >Zip Code</label>
                                        <a class="waves-effect waves-light btn col s6" onClick={()=>{
                                            getZipCode(newCustomer.originNumber,newCustomer.originNSB,newCustomer.originStreet,(zip)=>{
                                                setNewCustomer({...newCustomer,originPostalcode:zip});   
                                            });
                                        }} >
                                            <i class="material-icons">search</i>
                                        </a>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="input-field col s4">
                                        <CreatableSelect
                                            placeholder={"Location Type"}
                                            value={locationType}
                                            styles={{menu: provided => ({ ...provided, zIndex: 9999 })}}
                                            className="countySelect"
                                            classNamePrefix="select"
                                            isLoading={false}
                                            isRtl={false}
                                            isSearchable={true}
                                            name="color"
                                            options={locationTypes}
                                            onChange={(value)=>{
                                                console.log("onchange locationType");
                                                setLocationType(value);
                                            }}
                                        />
                                    </div>

                                    <div class="input-field col s4">
                                        <input id="locationTypeValue" type="text" class="validate" value={newCustomer.locationTypeValue} onChange={(event) => {
                                            setNewCustomer({...newCustomer,locationTypeValue:event.target.value});          
                                        }}/>
                                        <label for="locationTypeText" class="active" >Value</label>
                                    </div>
                                    {/*<div class="input-field col s4">
                                        <CreatableSelect
                                            ref={newCustomerselectCountyOrigin}
                                            styles={{menu: provided => ({ ...provided, zIndex: 9999 })}}
                                            className="countySelect"
                                            classNamePrefix="select"
                                            defaultValue={counties[8]}
                                            isLoading={countiesLoading}
                                            isRtl={false}
                                            isSearchable={true}
                                            name="color"
                                            options={counties}
                                            onChange={(value)=>{
                                                //console.log("onchange newCustomerselectCountyOrigin");
                                                newCustomerselectCitiesOrigin.current.select.select.setValue(arizonaCountyCitiesOrigin[0]);
                                                setNewCustomer({...newCustomer,originCounty:value});
                                                dispatch(getOriginArizonaCitiesByCounty(value.value));
                                            }}
                                        />
                                        </div>*/}
                                    <div class="input-field col s4">
                                        <CreatableSelect
                                            ref={newCustomerselectCitiesOrigin}
                                            styles={{menu: provided => ({ ...provided, zIndex: 9999 })}}
                                            className="countySelect"
                                            classNamePrefix="select"
                                            defaultValue={arizonaCountyCitiesOrigin[17]}
                                            isLoading={citiesLoadingOrigin}
                                            isRtl={false}
                                            isSearchable={true}
                                            name="color"
                                            options={arizonaCountyCitiesOrigin}
                                            onChange={(value)=>{
                                                //console.log("onchange newCustomerselectCitiesOrigin");
                                                setNewCustomer({...newCustomer,originCity:value});
                                            }}
                                        />
                                    </div>
                                </div>
                                <hr/>
                                <span class="card-title">Destination Address</span>
                                <div class="row">
                                    <div class="input-field col s2">
                                        <input id="newCustomerdestinationNumber" type="text" class="validate" value={newCustomer.destinationNumber} onChange={(event) => {
                                            setNewCustomer({...newCustomer,destinationNumber:event.target.value});                                      
                                            }}/>
                                        <label for="newCustomerdestinationNumber" class="active" >Number</label>
                                    </div>
                                    <div class="input-field col s2">
                                        <select style={{display:'block'}} id="newCustomerdestinationNSB" onChange={(event)=>{
                                                setNewCustomer({...newCustomer,destinationNSB:event.target.value});
                                            }}
                                        >
                                            <option value='N' selected={newCustomer.destinationNSB=='N'?'selected':''} >N</option>
                                            <option value='S' selected={newCustomer.destinationNSB=='S'?'selected':''} >S</option>
                                            <option value='E' selected={newCustomer.destinationNSB=='E'?'selected':''} >E</option>
                                            <option value='W' selected={newCustomer.destinationNSB=='W'?'selected':''} >W</option>
                                        </select>
                                        <label for="newCustomerdestinationNSB" class="active" >N,S,E,W</label>
                                    </div>
                                    <div class="input-field col s5">
                                        <input id="newCustomerdestinationStreet" type="text" class="validate" value={newCustomer.destinationStreet} onChange={(event) => {
                                            setNewCustomer({...newCustomer,destinationStreet:event.target.value});                                           
                                            }}/>
                                        <label for="newCustomerdestinationStreet" class="active" >Street</label>
                                    </div>
                                    <div class="input-field col s3">
                                        <input id="newCustomerdestinationPostalcode" type="text" class="validate col s6" value={newCustomer.destinationPostalcode} onChange={(event) => {
                                            setNewCustomer({...newCustomer,destinationPostalcode:event.target.value});                                            
                                            }}/>
                                        <label for="newCustomerdestinationPostalcode" class="active">Zip Code</label>
                                        <a class="waves-effect waves-light btn col s6" onClick={()=>{
                                            getZipCode(newCustomer.destinationNumber,newCustomer.destinationNSB,newCustomer.destinationStreet,(zip)=>{
                                                setNewCustomer({...newCustomer,destinationPostalcode:zip});   
                                            });
                                        }} >
                                            <i class="material-icons">search</i>
                                        </a>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="input-field col s1">
                                        <label>USA</label>
                                    </div>
                                    <div class="input-field col s1">
                                        <label>Arizona</label>
                                    </div>
                                    <div class="input-field col s5">
                                        <CreatableSelect
                                            ref={newCustomerselectCountyDestination}
                                            styles={{menu: provided => ({ ...provided, zIndex: 9999 })}}
                                            className="countySelect"
                                            classNamePrefix="select"
                                            defaultValue={counties[8]}
                                            isLoading={countiesLoading}
                                            isRtl={false}
                                            isSearchable={true}
                                            name="color"
                                            options={counties}
                                            onChange={(value)=>{
                                                //console.log("onchange newCustomerselectCountyDestination");
                                                newCustomerselectCitiesDestination.current.select.select.setValue(arizonaCountyCitiesDestination[0]);
                                                setNewCustomer({...newCustomer,destinationCounty:value});
                                                dispatch(getDestinationArizonaCitiesByCounty(value.value));
                                            }}
                                        />
                                    </div>
                                    <div class="input-field col s5">
                                        <CreatableSelect
                                            ref={newCustomerselectCitiesDestination}
                                            styles={{menu: provided => ({ ...provided, zIndex: 9999 })}}
                                            className="countySelect"
                                            classNamePrefix="select"
                                            defaultValue={arizonaCountyCitiesDestination[17]}
                                            isLoading={citiesLoadingDestination}
                                            isRtl={false}
                                            isSearchable={true}
                                            name="color"
                                            options={arizonaCountyCitiesDestination}
                                            onChange={(value)=>{
                                                //console.log("onchange newCustomerselectCitiesDestination");
                                                setNewCustomer({...newCustomer,destinationCity:value});
                                            }}
                                        />
                                    </div>
                                </div>
                    </div>
                    <div class="card-action">
                        <a class="waves-effect waves-light btn mb-1 mr-1" href="#" onClick={()=>{
                            let temp = newCustomer;

                            temp.phone='';
                            temp.customertype='';
                            temp.firstname='';
                            temp.lastname='';
                            temp.destinationNumber='';
                            temp.destinatioNSB='';
                            temp.destinationStreet='';
                            temp.destinationCounty='';
                            temp.destinationCity='';
                            temp.originNumber='';
                            temp.originNSB='';
                            temp.originStreet='';
                            temp.originCounty='';
                            temp.originCity='';

                            resetSelects();

                            setNewCustomer(temp);
                            setShowNewUser(false);
                        }} >Cancel</a>
                        <a class="waves-effect waves-light btn mb-1 mr-1" href="#" onClick={createNewCustomeronClick} >Create New Customer</a>
                        { newServiceRateLoading && (<ProgressBar />)}
                    </div>
                    </div>
            </div>
            
            </div>
        </Modal>
    <Modal open={showAvaliable} onClose={closeAvaliable}  >
            <div class="row">
                <div class="col s12" >
                    <table class="subscription-table responsive-table highlight">
                        <thead>
                            <tr>
                                <th>Cab</th>
                                <th>Distancia</th>
                                <th>First Name</th>
                                <th>Last Name</th>
                                <th>Phone</th>
                                <th>Score</th>
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>
                            <tbody>
                            {availables.vehicles.map((marker) => (
                                <tr>
                                    <td>{marker.cab}</td>
                                    <td>{parseFloat(marker.distancia).toFixed(2)}</td>
                                    <td>{marker.firstName}</td>
                                    <td>{marker.lastName}</td>
                                    <td>{marker.phone}</td>
                                    <td>{marker.scoreLevel}</td>
                                    <td><i class="material-icons">search</i></td>
                                    <td><i class="material-icons" onClick={() => { selectDriver(marker.driverId,marker.firstName); }}  >send</i></td>
                                </tr>
                            ))}
                            </tbody>
                    </table>
                </div>
                <div class="col s12">
                    <div class="" style={{ height: 500, width: '100%', display: 'flex', flexFlow: 'row nowrap', justifyContent: 'center', padding: 0 }}>
                        <GoogleMapReact
                            bootstrapURLKeys={{ key: 'AIzaSyCAoQ5Jml2jW_sOCP0kKFAJLQXBmmuEr-k' }}
                            defaultCenter={{lat: customer.originlat,lng: customer.originlng}}
                            defaultZoom={15.25}
                            onClick={(event) => {
                                    let centerTemp = [{lat:event.lat,lng:event.lng}];
                                    setPlaces(centerTemp);
                            }}
                            onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}>
                                <Origin lat={customer.originlat} lng={customer.originlng}/> 
                                {availables.vehicles.map((marker) => (
                                    <Vehicle
                                        style={{marginTop:'-50px'}}
                                        lat={marker.latitude}
                                        lng={marker.longitude}
                                />
                            ))}
                        </GoogleMapReact>
                    </div>
                </div>
            </div>
        </Modal>
    <Modal open={showGPS} onClose={closeGPS}  >
            <div class="row">
                <div class="col s12" >
                    <span >Origin</span>
                </div>
            </div>
            <div class="row">
                <div class="col s12">
                    <div class="" style={{ height: '60vh', width: '60vh' }}>
                            <GoogleMapReact
                                bootstrapURLKeys={{ key: 'AIzaSyCAoQ5Jml2jW_sOCP0kKFAJLQXBmmuEr-k' }}
                                defaultCenter={center}
                                defaultZoom={zoom}
                                            
                                onClick={(event) => {
                                    let centerTemp = [{lat:event.lat,lng:event.lng}];
                                    setPlaces(centerTemp);
                                }}
                                onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}>
                                    {places.map((marker) => (
                                        <AnyReactComponent
                                            style={{marginTop:'-50px'}}
                                            lat={marker.lat}
                                            lng={marker.lng}
                                        />
                                    ))}
                            </GoogleMapReact>
                    </div>
                </div>
            </div>
            <hr/>
            <div class="row">
                <div class="col s12" >
                    <a class="waves-effect waves-light btn col s3" onClick={ () => { 
                        //console.log('search location');
                        let location = places[0];
                        Geocode.fromLatLng(location.lat, location.lng).then(
                            response => {
                                if(response.status == 'OK' && (0 in response.results)){
                                    var tempResul = response.results[0].address_components;
                                    for (var i in tempResul) {
                                        var t = tempResul[i];
                                        //console.log(t.types);
                                        if(t.types.includes('street_number')){
                                            dispatch(updateField('originNumber',t.long_name));
                                        }else if(t.types.includes('route')){
                                            dispatch(updateField('originStreet',t.long_name));
                                        }else if(t.types.includes('administrative_area_level_2')){
                                            dispatch(updateField('originCounty',t.long_name));
                                        }else if(t.types.includes('locality')){
                                            dispatch(updateField('originCity',t.long_name));
                                        }
                                        setShowGPS(false);
                                    }


                                }
                            },
                            error => {
                              console.error(error);
                            }
                          );
                     }} >
                    Search location
                    </a>
                </div>
            </div>
        </Modal>
    <Modal open={showGPS2} onClose={() => { setShowGPS2(false); }}  >
            <div class="row">
                <div class="col s12" >
                    <span >Destination</span>
                </div>
            </div>
            <div class="row">
                <div class="col s12">
                    <div class="" style={{ height: '60vh', width: '60vh' }}>
                            <GoogleMapReact
                                bootstrapURLKeys={{ key: 'AIzaSyCAoQ5Jml2jW_sOCP0kKFAJLQXBmmuEr-k' }}
                                defaultCenter={center}
                                defaultZoom={zoom}
                                            
                                onClick={(event) => {
                                    let centerTemp = [{lat:event.lat,lng:event.lng}];
                                    setPlaces(centerTemp);
                                }}
                                onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}>
                                    {places.map((marker) => (
                                        <AnyReactComponent
                                            style={{marginTop:'-50px'}}
                                            lat={marker.lat}
                                            lng={marker.lng}
                                        />
                                    ))}
                            </GoogleMapReact>
                    </div>
                </div>
            </div>
            <hr/>
            <div class="row">
                <div class="col s12" >
                    <a class="waves-effect waves-light btn col s3" onClick={ () => { 
                        //console.log('search location');
                        let location = places[0];
                        Geocode.fromLatLng(location.lat, location.lng).then(
                            response => {
                                if(response.status == 'OK' && (0 in response.results)){
                                    var tempResul = response.results[0].address_components;
                                    for (var i in tempResul) {
                                        var t = tempResul[i];
                                        //console.log(t.types);
                                        if(t.types.includes('street_number')){
                                            dispatch(updateField('destinationNumber',t.long_name));
                                        }else if(t.types.includes('route')){
                                            dispatch(updateField('destinationStreet',t.long_name));
                                        }else if(t.types.includes('administrative_area_level_2')){
                                            dispatch(updateField('destinationCounty',t.long_name));
                                        }else if(t.types.includes('locality')){
                                            dispatch(updateField('destinationCity',t.long_name));
                                        }
                                        setShowGPS2(false);
                                    }


                                }
                            },
                            error => {
                              console.error(error);
                            }
                          );
                     }} >
                    Search location
                    </a>
                </div>
            </div>
        </Modal>
    <Modal open={showRoadmap} onClose={() => { setShowRoadmap(false); }}  >
            <div class="row">
                <div class="col s12" >
                    <span >Roadmap</span>
                </div>
            </div>
            <div class="row">
                <div class="col s12">
                    <div class="" style={{ height: '60vh', width: '60vh' }}>
                            <GoogleMapReact
                                bootstrapURLKeys={{ key: 'AIzaSyCAoQ5Jml2jW_sOCP0kKFAJLQXBmmuEr-k' }}
                                defaultCenter={{lat: customer.originlat,lng: customer.originlng}}
                                defaultZoom={zoom}
                                onClick={(event) => {
                                    let centerTemp = [{lat:event.lat,lng:event.lng}];
                                    setPlaces(centerTemp);
                                }}
                                onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}>
                                    <AnyReactComponent
                                        style={{marginTop:'-50px'}}
                                        lat={customer.originlat}
                                        lng={customer.originlng}
                                    />
                                    <AnyReactComponent
                                        style={{marginTop:'-50px'}}
                                        lat={customer.destinatiolat}
                                        lng={customer.destinatiolng}
                                    />
                            </GoogleMapReact>
                    </div>
                </div>
            </div>
            <hr/>
            <div class="row">
                <div class="col s12" >
                    <a class="waves-effect waves-light btn col s3" onClick={ () => { setShowRoadmap(false); }} >
                    Close
                    </a>
                </div>
            </div>
        </Modal>

    <div class="row">
        <div class="col s8">
            {newService.id != null && customer.type == 1 && (<h5>Service Number: {newService.id} </h5>)}
            {newService.id != null && customer.type == 2 && (<h5>Reservation Number: {newService.id} </h5>)}
            {newService.id == null && (<h5>New Service</h5>)}
        </div>
        <div class="col s4">
            <h4 style={{color:timeColor}}> 
                <HumanTimer onTick={onTick} onEnd={null} active={true} zeroes={true}  seconds={900}>
                    {(timer) => (<strong>{timer.hours}:{timer.minutes}:{timer.seconds}</strong>)}
                </HumanTimer>
            </h4>
        </div>
    </div>
    <div class="row">  
        <Form class="col s12" autocomplete="off">
            <div class="row">
                <div class="input-field col s6">
                    <input 
                        id="phone" 
                        class="validate col s9" 
                        type="tel" 
                        maxlength="10"
                        value={customer.phone} 
                        onChange={(event) => {
                            dispatch(updateField('phone',event.target.value));
                        }}  
                        onKeyPress={(event) => {
                            if(event.key === 'Enter'){
                                searchCustomer();
                            }
                        }} 
                    />
                    <label for="phone">Phone</label>
                    <a disabled={customer.phone == ''} class="waves-effect waves-light btn col s3" onClick={searchCustomer} >
                        <i class="material-icons">search</i>
                    </a>
                </div>
                <CreatableSelect
                    placeholder={"Customer Type"}
                    value={customerTypeValue}
                    styles={{menu: provided => ({ ...provided, zIndex: 9999 })}}
                    className="input-field col s6"
                    classNamePrefix="select"
                    defaultValue={filters.customerType.items[0]}
                    isRtl={false}
                    name="customertype"
                    options={filters.customerType.items}
                    onChange={(value)=>{
                        //console.log("onchange customertypeId");
                        setCustomerTypeValue(value);
                        dispatch(updateField('customerTypeId',value.value));
                    }}
                />
                <CreatableSelect
                    placeholder={"Payment Type"}
                    value={paymentTypeValue}
                    styles={{menu: provided => ({ ...provided, zIndex: 9999 })}}
                    className="input-field col s4"
                    classNamePrefix="select"
                    defaultValue={filters.paymentType.items[0]}
                    isRtl={false}
                    name="customertype"
                    options={filters.paymentType.items}
                    onChange={(value)=>{
                        //console.log("onchange paymentTypeId");
                        setPaymentTypeValue(value);
                        dispatch(updateField('paymentTypeId',value.value));
                    }}
                    />
                <div class="input-field col s4">
                    <input id="firstname" type="text" class="validate" value={customer.firstname} onChange={(event) => {
                        dispatch(updateField('firstname',event.target.value));
                    }}/>
                    <label for="firstname" class="active">First name</label>
                </div>
                <div class="input-field col s4">
                    <input id="lastname" type="text" class="validate" value={customer.lastname} onChange={(event) => {
                        dispatch(updateField('lastname',event.target.value));
                        }}/>
                    <label for="lastname" class="active">Last name</label>
                </div>
                { customer.loading && (<div class="col s12"><ProgressBar /></div>)}
            </div>
            <br/>
            <div class="row">
                <div class="col s3">
                    <label>
                        <input name="type" type="radio" checked={customer.type === 1} onChange={onTypeSelectService} disabled={customer.id == null || newService.id != null} />
                        <span>Service</span>
                    </label>
                </div>
                <div class="col s3">
                    <label>
                        <input name="type" type="radio" checked={customer.type === 2} onChange={onTypeSelectReservation} disabled={customer.id == null || newService.id != null} />
                        <span>Reservation</span>
                    </label>
                    <label>
                        <input name="type" type="checkbox" checked={customer.roundTrip} onChange={(value)=>{
                            dispatch(updateField('roundTrip',!customer.roundTrip));
                        }} disabled={customer.type !== 2} />
                        <span>Round Trip</span>
                    </label>
                    <label>
                        <input name="type" type="checkbox" checked={customer.days} onChange={(value)=>{
                            dispatch(updateField('days',!customer.days));
                        }} disabled={customer.type !== 2} />
                        <span>Days</span>
                    </label>
                </div>
                <div class="col s6">
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Grid container>
                            <Grid item xs={6}>
                                <KeyboardDatePicker
                                    margin="normal"
                                    disabled={(customer.type==1 || customer.id == null || newService.id != null)} 
                                    id="reservationdate"
                                    label="Date"
                                    format="yyyy-MM-dd"
                                    value={selectedDate}
                                    onChange={handleDateChange}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <KeyboardTimePicker
                                    margin="normal"
                                    disabled={(customer.type == 1 || customer.id == null || newService.id != null)} 
                                    id="reservationtime"
                                    label="Time"
                                    format="HH:mm"
                                    value={selectedDate}
                                    onChange={handleDateChange}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change time',
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </MuiPickersUtilsProvider>
                    {customer.type === 2 && customer.roundTrip == true && (<MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Grid container>
                            <Grid item xs={6}>
                                <KeyboardDatePicker
                                    margin="normal"
                                    disabled={(customer.type==1 || customer.id == null || newService.id != null)} 
                                    id="reservationdate2"
                                    label="Date"
                                    format="yyyy-MM-dd"
                                    value={selectedDate2}
                                    onChange={handleDateChange2}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <KeyboardTimePicker
                                    margin="normal"
                                    disabled={(customer.type == 1 || customer.id == null || newService.id != null)} 
                                    id="reservationtime2"
                                    label="Time"
                                    format="HH:mm"
                                    value={selectedDate2}
                                    onChange={handleDateChange2}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change time',
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </MuiPickersUtilsProvider>)}
                    {customer.type === 2 && customer.days == true && (<><label>
                        <input name="type" type="checkbox" checked={customer.sunday} onChange={(value)=>{
                            dispatch(updateField('sunday',!customer.sunday));
                        }} disabled={customer.type !== 2} />
                        <span>Sunday</span>
                    </label><br/>
                    <label>
                        <input name="type" type="checkbox" checked={customer.monday} onChange={(value)=>{
                            dispatch(updateField('monday',!customer.monday));
                        }} disabled={customer.type !== 2} />
                        <span>Monday</span>
                    </label><br/>
                    <label>
                        <input name="type" type="checkbox" checked={customer.tuesday} onChange={(value)=>{
                            dispatch(updateField('tuesday',!customer.tuesday));
                        }} disabled={customer.type !== 2} />
                        <span>Tuesday</span>
                    </label><br/>
                    <label>
                        <input name="type" type="checkbox" checked={customer.wednesday} onChange={(value)=>{
                            dispatch(updateField('wednesday',!customer.wednesday));
                        }} disabled={customer.type !== 2} />
                        <span>Wednesday</span>
                    </label><br/>
                    <label>
                        <input name="type" type="checkbox" checked={customer.thursday} onChange={(value)=>{
                            dispatch(updateField('thursday',!customer.thursday));
                        }} disabled={customer.type !== 2} />
                        <span>Thursday</span>
                    </label><br/>
                    <label>
                        <input name="type" type="checkbox" checked={customer.friday} onChange={(value)=>{
                            dispatch(updateField('friday',!customer.friday));
                        }} disabled={customer.type !== 2} />
                        <span>Friday</span>
                    </label><br/>
                    <label>
                        <input name="type" type="checkbox" checked={customer.saturday} onChange={(value)=>{
                            dispatch(updateField('saturday',!customer.saturday));
                        }} disabled={customer.type !== 2} />
                        <span>Saturday</span>
                    </label></>)}
                </div>
            </div>
            {newService.id == null && (
            <div class="row">
                <div class="col s12">
                    {/*<pre>{'originCounty:'+JSON.stringify(customer.originCounty, null, 2)}</pre>*/}
                    {/*<pre>{'originCity:'+JSON.stringify(customer.originCity, null, 2)}</pre>*/}
                    {/*<pre>{'destinationCounty:'+JSON.stringify(customer.destinationCounty, null, 2)}</pre>*/}
                    {/*<pre>{'destinationCity:'+JSON.stringify(customer.destinationCity, null, 2)}</pre>*/}
                    {/*<pre>{JSON.stringify(arizonaCountyCitiesOrigin, null, 2)}</pre>*/}
                    {/*<pre>{JSON.stringify(paymentTypeValue, null, 2)}</pre>*/}
                    {/*<pre>{JSON.stringify(customer.reservationdate, null, 2)}</pre>*/}
                    {/*<pre>{JSON.stringify(customer.reservationtime, null, 2)}</pre>*/}
                    {/*<pre>{JSON.stringify(newService, null, 2)}</pre>*/}

                    
                    <a disabled={customer.id == null} class="waves-effect waves-light btn-large" onClick={()=>{
                            if(customer.type == 1){
                                dispatch(createNewService(customer.id,paymentTypeValue.value)).then(()=>{
                                    console.log("createNewService success");
                                }).catch(()=>{
                                    console.log("createNewService fail");
                                });
                            }else{

                                /*console.log(moment(selectedDate).format('yyyy-mm-DD'));
                                console.log(moment(selectedDate).format('HH:mm:00'));*/
                                
                                let d3 = moment(selectedDate).format('yyyy-MM-DD 00:00:00');
                                let d4 = moment(selectedDate).format('HH:mm:00');

                                let data = {};

                                data.customerId = customer.id;
                                data.pickupDate = moment(selectedDate).format('yyyy-MM-DD 00:00:00');
                                data.pickupTime = moment(selectedDate).format('HH:mm:00');
                                data.paymentTypeId = paymentTypeValue.value;
                                data.roundTrip = customer.roundTrip;
                                data.roundTripPickupDate = (customer.roundTrip?moment(selectedDate2).format('yyyy-MM-DD 00:00:00'):null);
                                data.roundTripPickupTime = (customer.roundTrip?moment(selectedDate2).format('HH:mm:00'):null);
                                data.days = customer.days;
                                data.monday = customer.monday;
                                data.tuesday = customer.tuesday;
                                data.wednesday = customer.wednesday;
                                data.thursday = customer.thursday;
                                data.friday = customer.friday;
                                data.saturday = customer.saturday;
                                data.sunday = customer.sunday;

                                dispatch(createNewReservation(data)).then(()=>{
                                    console.log("createNewReservation success");
                                }).catch((error)=>{
                                    console.log("createNewReservation fail",error);
                                });

                                
                            }
                    }} ><i class="material-icons"> </i>Create New Service</a>
                </div>
            </div>
            )}
            <hr/>
            {/*<pre>{JSON.stringify(newService, null, 2)}</pre>*/}
                {newService.id != null && (
                <>
                <div class="row">
                    {/*<pre>{JSON.stringify(customer, null, 2)}</pre>*/}
                    <div class="card">
                        <div class="card-content">
                            <span class="card-title">Address</span>
                    <Tabs className="tab-demo z-depth-1">
                        <Tab active options={{duration: 300,onShow: null,responsiveThreshold: Infinity,swipeable: false}} title="Origin">
                                <br/>
                                <div class="row">
                                    <div class="col s12">
                                        <CreatableSelect
                                            placeholder={"Last Address"}
                                            value={selectLastOrigin}
                                            styles={{menu: provided => ({ ...provided, zIndex: 9999 })}}
                                            className="countySelect"
                                            classNamePrefix="select"
                                            isLoading={lastOrigin.loading}
                                            isRtl={false}
                                            isSearchable={true}
                                            name="color"
                                            options={lastOrigin.items}
                                            onChange={(value)=>{


                                                var lparse = parseLocation(value.value);

                                                /*
                                                city: "PHOENIX"
                                                cityId: ""
                                                country: "USA"
                                                countryId: 35
                                                county: "MARICOPA"
                                                countyId: ""
                                                nsew: "E"
                                                number: "1934"
                                                state: "Arizona"
                                                stateId: 3
                                                street: "HALE ST."
                                                zipcode: "85203"
                                                */
                                                dispatch(updateField('originNumber',lparse.number));
                                                dispatch(updateField('originNSB',lparse.nsew));
                                                dispatch(updateField('originStreet',lparse.street));
                                                dispatch(updateField('originPostalcode',lparse.zipcode));
                                                console.log(lparse);
                                                
                                                setSelectLastOrigin(value);
                                            }}
                                        />
                                    </div>
                                </div>
                                <br/>
                                <div class="row">
                                    <div class="input-field col s2">
                                        <input id="originNumber" type="text" class="validate" value={customer.originNumber} onChange={(event) => {
                                            dispatch(updateField('originNumber',event.target.value));
                                            }}/>
                                        <label for="originNumber" class="active" >Number</label>
                                    </div>
                                    <div class="input-field col s2">
                                        <select style={{display:'block'}} id="originNSB" onChange={(event)=>{
                                                dispatch(updateField('originNSB',event.target.value));
                                            }}
                                        >
                                            <option value='N' selected={customer.originNSB=='N'?'selected':''} >N</option>
                                            <option value='S' selected={customer.originNSB=='S'?'selected':''} >S</option>
                                            <option value='E' selected={customer.originNSB=='E'?'selected':''} >E</option>
                                            <option value='W' selected={customer.originNSB=='W'?'selected':''} >W</option>
                                        </select>
                                        <label for="originNSB" class="active" >N,S,E,W</label>
                                    </div>
                                    <div class="input-field col s5">
                                        <input id="originStreet" type="text" class="validate" value={customer.originStreet} onChange={(event) => {
                                            dispatch(updateField('originStreet',event.target.value));
                                            }}/>
                                        <label for="originStreet" class="active" >Street</label>
                                    </div>
                                    <div class="input-field col s3">
                                        <input id="originPostalcode" type="text" class="validate col s6" value={customer.originPostalcode} onChange={(event) => {
                                            dispatch(updateField('originPostalcode',event.target.value));
                                        }}/>
                                        <label for="originPostalcode" class="active" >Zip Code</label>
                                        <a class="waves-effect waves-light btn col s6" onClick={()=>{
                                            getZipCode(customer.originNumber,customer.originNSB,customer.originStreet,(zip)=>{
                                                dispatch(updateField('originPostalcode',zip));
                                            });
                                        }} >
                                            <i class="material-icons">search</i>
                                        </a>
                                    </div>
                                </div>
                                <div class="row">
                                <div class="input-field col s4">
                                        <CreatableSelect
                                            placeholder={"Location Type"}
                                            value={locationType}
                                            styles={{menu: provided => ({ ...provided, zIndex: 9999 })}}
                                            className="countySelect"
                                            classNamePrefix="select"
                                            isLoading={false}
                                            isRtl={false}
                                            isSearchable={true}
                                            name="color"
                                            options={locationTypes}
                                            onChange={(value)=>{
                                                console.log("onchange locationTypeValue");
                                                setLocationType(value);
                                            }}
                                        />
                                    </div>
                                    <div class="input-field col s4">
                                        <input id="locationTypeValue" type="text" class="validate" value={customer.locationTypeValue} onChange={(event) => {
                                            dispatch(updateField('locationTypeValue',event.target.value));
                                        }}/>
                                        <label for="locationTypeValue" class="active" >Value</label>
                                    </div>
                                    {/*<div class="input-field col s4">
                                        <CreatableSelect
                                            placeholder={"Origin County"}
                                            value={selectCountyOrigin}
                                            styles={{menu: provided => ({ ...provided, zIndex: 9999 })}}
                                            className="countySelect"
                                            classNamePrefix="select"
                                            isLoading={countiesLoading}
                                            isRtl={false}
                                            isSearchable={true}
                                            name="color"
                                            options={counties}
                                            onChange={(value)=>{
                                                //console.log("onchange selectCountyOrigin");
                                                setSelectCountyOrigin(value);
                                                setSelectCitiesOrigin(getElement(arizonaCountyCitiesOrigin,''));
                                                dispatch(updateField('originCounty',value));
                                                dispatch(getOriginArizonaCitiesByCounty(value.value));
                                            }}
                                        />
                                        </div>*/}
                                    <div class="input-field col s4">
                                        <CreatableSelect
                                            placeholder={"Origin City"}
                                            value={selectCitiesOrigin}
                                            styles={{menu: provided => ({ ...provided, zIndex: 9999 })}}
                                            className="countySelect"
                                            classNamePrefix="select"
                                            isLoading={citiesLoadingOrigin}
                                            isRtl={false}
                                            isSearchable={true}
                                            name="color"
                                            options={arizonaCountyCitiesOrigin}
                                            onChange={(value)=>{
                                                //console.log("onchange selectCitiesOrigin");
                                                setSelectCitiesOrigin(value);
                                                dispatch(updateField('originCity',value));
                                            }}
                                        />
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col s12">
                                        <a class="waves-effect waves-light btn mb-1" href="#" onClick={()=>{
                                            dispatch(updateField('originNumber',''));
                                            dispatch(updateField('originNSB',''));
                                            dispatch(updateField('originStreet',''));
                                            dispatch(updateField('originCounty',getElement(counties,defaultCountyOrigin)));
                                            dispatch(updateField('originCity',getElement(arizonaCountyCitiesOrigin,defaultCityOrigin)));
                                            dispatch(updateField('originPostalcode',''));

                                            setSelectCitiesOrigin(getElement(arizonaCountyCitiesOrigin,defaultCityOrigin))
                                            setSelectCountyOrigin(getElement(counties,defaultCountyOrigin));



                                            if(customer.originCounty.value == defaultCountyOrigin){
                                                dispatch(getOriginArizonaCitiesByCounty(defaultCountyOrigin));
                                            }
                                            
                                        }}>Clear</a>
                                    </div>
                                </div>
                        </Tab>
                        <Tab options={{duration: 300,onShow: null,responsiveThreshold: Infinity,swipeable: false}} title="Destination">
                            <br/>
                            <div class="row">
                                    <div class="col s12">
                                        <CreatableSelect
                                            placeholder={"Last Address"}
                                            value={selectLastDestination}
                                            styles={{menu: provided => ({ ...provided, zIndex: 9999 })}}
                                            className="countySelect"
                                            classNamePrefix="select"
                                            isLoading={lastDestination.loading}
                                            isRtl={false}
                                            isSearchable={true}
                                            name="color"
                                            options={lastDestination.items}
                                            onChange={(value)=>{

                                                var lparse = parseLocation(value.value);

                                                /*
                                                city: "PHOENIX"
                                                cityId: ""
                                                country: "USA"
                                                countryId: 35
                                                county: "MARICOPA"
                                                countyId: ""
                                                nsew: "E"
                                                number: "1934"
                                                state: "Arizona"
                                                stateId: 3
                                                street: "HALE ST."
                                                zipcode: "85203"
                                                */
                                                dispatch(updateField('destinationNumber',lparse.number));
                                                dispatch(updateField('destinationNSB',lparse.nsew));
                                                dispatch(updateField('destinationStreet',lparse.street));
                                                dispatch(updateField('destinationPostalcode',lparse.zipcode));
                                                console.log(lparse);


                                                setSelectLastDestination(value);
                                            }}
                                        />
                                    </div>
                                </div>
                                <br/>
                                <div class="row">
                                    <div class="input-field col s2">
                                        <input id="destinationNumber" type="text" class="validate" value={customer.destinationNumber} onChange={(event) => {
                                            dispatch(updateField('destinationNumber',event.target.value));
                                            }}/>
                                        <label for="destinationNumber" class="active" >Number</label>
                                    </div>
                                    <div class="input-field col s2">
                                        <select style={{display:'block'}} id="destinationNSB" onChange={(event)=>{
                                                dispatch(updateField('destinationNSB',event.target.value));
                                            }}
                                        >
                                            <option value='N' selected={customer.destinationNSB=='N'?'selected':''} >N</option>
                                            <option value='S' selected={customer.destinationNSB=='S'?'selected':''} >S</option>
                                            <option value='E' selected={customer.destinationNSB=='E'?'selected':''} >E</option>
                                            <option value='W' selected={customer.destinationNSB=='W'?'selected':''} >W</option>
                                        </select>
                                        <label for="destinationNSB" class="active" >N,S,E,W</label>
                                    </div>
                                    <div class="input-field col s5">
                                        <input id="destinationStreet" type="text" class="validate" value={customer.destinationStreet} onChange={(event) => {
                                            dispatch(updateField('destinationStreet',event.target.value));
                                            }}/>
                                        <label for="destinationStreet" class="active" >Street</label>
                                    </div>
                                    <div class="input-field col s3">
                                        <input id="destinationPostalcode" type="text" class="validate col s6" value={customer.destinationPostalcode} onChange={(event) => {
                                            dispatch(updateField('destinationPostalcode',event.target.value));
                                            }}/>
                                        <label for="destinationPostalcode" class="active" >Zip Code</label>
                                        <a class="waves-effect waves-light btn col s6" onClick={()=>{
                                            getZipCode(customer.destinationNumber,customer.destinationNSB,customer.destinationStreet,(zip)=>{
                                                dispatch(updateField('destinationPostalcode',zip));
                                            });
                                        }} >
                                            <i class="material-icons">search</i>
                                        </a>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="input-field col s1">
                                        <label>USA</label>
                                    </div>
                                    <div class="input-field col s1">
                                        <label>Arizona</label>
                                    </div>
                                    {/*<div class="input-field col s5">
                                        <CreatableSelect
                                            placeholder={"Destination County"}
                                            value={selectCountyDestination}
                                            styles={{menu: provided => ({ ...provided, zIndex: 9999 })}}
                                            className="countySelect"
                                            classNamePrefix="select"
                                            isLoading={countiesLoading}
                                            isRtl={false}
                                            isSearchable={true}
                                            name="color"
                                            options={counties}
                                            onChange={(value)=>{
                                                //console.log("onchange selectCountyDestination");
                                                setSelectCountyDestination(value);
                                                setSelectCitiesDestination(getElement(arizonaCountyCitiesDestination,''));
                                                dispatch(updateField('destinationCounty',value));
                                                dispatch(getDestinationArizonaCitiesByCounty(value.value));
                                            }}
                                        />
                                        </div>*/}
                                    <div class="input-field col s5">
                                        <CreatableSelect
                                            placeholder={"Destination City"}
                                            value={selectCitiesDestination}
                                            styles={{menu: provided => ({ ...provided, zIndex: 9999 })}}
                                            className="countySelect"
                                            classNamePrefix="select"
                                            isLoading={citiesLoadingDestination}
                                            isRtl={false}
                                            isSearchable={true}
                                            name="color"
                                            options={arizonaCountyCitiesDestination}
                                            onChange={(value)=>{
                                                //console.log("onchange selectCitiesDestination");
                                                setSelectCitiesDestination(value);
                                                dispatch(updateField('destinationCity',value));
                                            }}
                                        />
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col s12">
                                        <a class="waves-effect waves-light btn mb-1" href="#" onClick={()=>{
                                            dispatch(updateField('destinationNumber',''));
                                            dispatch(updateField('destinatioNSB',''));
                                            dispatch(updateField('destinationStreet',''));
                                            dispatch(updateField('destinationCounty',getElement(counties,defaultCountyDestination)));
                                            dispatch(updateField('destinationCity',getElement(arizonaCountyCitiesDestination,defaultCityDestination)));
                                            dispatch(updateField('destinationPostalcode',''));

                                            setSelectCitiesDestination(getElement(arizonaCountyCitiesDestination,defaultCityDestination));
                                            setSelectCountyDestination(getElement(counties,defaultCountyDestination));
                                            if(customer.destinationCounty.value == defaultCountyDestination){
                                                dispatch(getDestinationArizonaCitiesByCounty(defaultCountyDestination));
                                            }
                                            
                                        }}>Clear</a>
                                    </div>
                                </div>
                        </Tab>
                    </Tabs>
                    </div>
                    <div class="card-action">
                    {customer.type == 1 && (
                        <>
                        <a class="waves-effect waves-light btn mb-1 mr-1" href="#" onClick={()=>{
                            Geocode.fromAddress(customer.originNumber+' '+customer.originNSB+ ' '+customer.originStreet+', '+customer.originCity.label+' AZ '+customer.originPostalcode+'  USA').then(
                                response => {
                                  const { lat, lng } = response.results[0].geometry.location;
                                  dispatch(updateField('originlat',lat));
                                  dispatch(updateField('originlng',lng));
                                  Geocode.fromAddress(customer.destinationNumber+' '+customer.destinationNSB+ ' '+customer.destinationStreet+', '+customer.destinationCity.label+' AZ '+customer.destinationPostalcode+'  USA').then(
                                    response => {
                                      const { lat, lng } = response.results[0].geometry.location;
                                      dispatch(updateField('destinatiolat',lat));
                                      dispatch(updateField('destinatiolng',lng));

                                      dispatch(getNewServiceRate()).then(() => {
                                        }).catch(() => {
                                            swal("Error to get the rate.");
                                        });
                                      
                                    },
                                    error => {
                                      console.error(error);
                                    }
                                );
                                },
                                error => {
                                  console.error(error);
                                }
                            );

                        }} >Calculate Rate</a>
                        { newServiceRateLoading && (<ProgressBar />)}
                        </>
                    )}
                    </div>
                    </div>
                </div>
                {customer.type == 1 && (
                <>
                {/*
                <div class="row">
                    <div class="card">
                        <div class="card-content">
                            <span class="card-title">Comments</span>
                            <div class="row" >
                            {newServiceTopicComments.length == 0 && (<div class="progress"><div class="indeterminate"></div></div>)}
                            {newServiceTopicComments.length > 0 && newServiceTopicComments.map((comment,index) => {
                                return (<div class="col s4">
                                    <div class="">
                                        <label>
                                            <input type="checkbox" defaultChecked={false} onChange={(event) => {
                                                if(event.target.checked == true){
                                                    setComments({...comments,["comment"+comment.adinfServiceTopicId]: {id:comment.adinfServiceTopicId,text:''}});
                                                }else{
                                                    let c = {...comments};
                                                    delete c["comment"+comment.adinfServiceTopicId];
                                                    setComments(c);
                                                }
                                                
                                            }}/>
                                            <span >{capitalize(comment.name)}</span>
                                        </label>
                                    </div>
                                    <div class="input-field">
                                        <input type="text" class="validate"  onChange={(event) => {
                                            setComments({...comments,["comment"+comment.adinfServiceTopicId]: {id:comment.adinfServiceTopicId,text:event.target.value}});
                                        }}/>
                                    </div>
                                </div>
                            )})}
                            </div>
                        </div>
                    </div>
                </div>
                                    */}

                    {/*<div class="row">
                    <div class="card">
                        <div class="card-content">
                            <span class="card-title">Vehicle Information</span>
                                {customer.cab != '' && (<div class="row">
                                    <div class="input-field col s3">
                                        <input id="cab" type="email" class="validate" value={customer.cab} onChange={(event) => {
                                                dispatch(updateField('cab',event.target.value));
                                                }} />
                                        <label for="cab">Cab</label>
                                    </div>
                                    <div class="input-field col s4">
                                        <input id="assignatedDriver" type="email" class="validate" value={customer.assignatedDriver} onChange={(event) => {
                                                dispatch(updateField('assignatedDriver',event.target.value));
                                                }} />
                                        <label for="assignatedDriver">Assignated driver</label>
                                    </div>
                                    <div class="input-field col s5">
                                        <input id="vehicleFeatures" type="email" class="validate" value={customer.vehicleFeatures} onChange={(event) => {
                                                dispatch(updateField('vehicleFeatures',event.target.value));
                                                }} />
                                        <label for="vehicleFeatures">Vehicle features</label>
                                    </div>
                                </div>)}
                        </div>
                        <div class="card-action">
                            <a class="waves-effect waves-light btn mb-1 mr-1" href="#" onClick={clickAvailables} >Check Avaliable</a>
                        </div>
                    </div>
                </div>*/}
                    <div class="row">
                    <div class="card">
                        <div class="card-content">
                        <span class="card-title">Estimate Rate</span>
                        <p>
                            {newServiceRate}
                        </p>
                        </div>
                        <div class="card-action">
                            <a class="waves-effect waves-light btn mb-1 mr-1" href="#" onClick={() => { setShowRoadmap(true); }} >View Route Map</a>
                        </div>
                    </div>
                </div>
                </>
                )}
                <div class="row">
                    
                    <div class="col s12">
                        <a class="waves-effect waves-light btn-large mb-1 mr-1"><i class='material-icons left'>credit_card</i> Cancel</a>
                        <a class="waves-effect waves-light btn-large mb-1" onClick={saveServiceClick}><i class='material-icons left'>save</i> Save</a>
                        
                    </div>
                </div>
                </>
                )}
            </Form>
        </div>
</Modal>
);


};

export default OperatorNewService;