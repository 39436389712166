import {
    GET_ACTIVATED_SERVICES_START,
    GET_ACTIVATED_SERVICES_SUCCESS,
    GET_ACTIVATED_SERVICES_FAIL,

    CHANGE_STATUS_SERVICES_START,

    CREATE_NEW_SERVICE_START,
    CREATE_NEW_SERVICE_SUCCESS,
    CREATE_NEW_SERVICE_FAIL,

    GET_CUSTOMER_BY_PHONE_START,
    GET_CUSTOMER_BY_PHONE_SUCCESS,
    GET_CUSTOMER_BY_PHONE_FAIL,

    UPDATE_FIELD_CUSTOMER,

    LOAD_NEW_SERVICE_START,
    LOAD_NEW_SERVICE_SUCCESS,
    LOAD_NEW_SERVICE_FAIL,

    GET_AVAILABLES_START,
    GET_AVAILABLES_SUCCESS,
    GET_AVAILABLES_FAIL,

    START_GET_COUNTIES,
    SUCCESS_GET_COUNTIES,
    FAIL_GET_COUNTIES,

    START_GET_ORIGIN_CITIES,
    SUCCESS_GET_ORIGIN_CITIES,
    FAIL_GET_ORIGIN_CITIES,

    START_GET_DESTINATION_CITIES,
    SUCCESS_GET_DESTINATION_CITIES,
    FAIL_GET_DESTINATION_CITIES,

    CALCULATE_RATE_START,
    CALCULATE_RATE_SUCCESS,
    CALCULATE_RATE_FAIL,

    CREATE_NEW_CUSTOMER_START,
    CREATE_NEW_CUSTOMER_SUCCESS,
    CREATE_NEW_CUSTOMER_FAIL,

    GET_TOPIC_COMMENTS_START,
    GET_TOPIC_COMMENTS_SUCCESS,
    GET_TOPIC_COMMENTS_FAIL,

    NEW_EMPTY_SERVICE_START,
    NEW_EMPTY_SERVICE_SUCCESS,
    NEW_EMPTY_SERVICE_FAIL,

    GET_VEHICLES_START,
    GET_VEHICLES_SUCCESS,
    GET_VEHICLES_FAIL,

    GET_BLACKLIST_START,
    GET_BLACKLIST_SUCCESS,
    GET_BLACKLIST_FAIL,

    GET_CALLNUMBERSEARCH_START,
    GET_CALLNUMBERSEARCH_SUCCESS,
    GET_CALLNUMBERSEARCH_FAIL,

    GET_VEHICLESLASTCALL_START,
    GET_VEHICLESLASTCALL_SUCCESS,
    GET_VEHICLESLASTCALL_FAIL,

    GET_ALLACTIVETAXIS_START,
    GET_ALLACTIVETAXIS_SUCCESS,
    GET_ALLACTIVETAXIS_FAIL,

    GET_LASTTENSERVECES_START,
    GET_LASTTENSERVECES_SUCCESS,
    GET_LASTTENSERVECES_FAIL,

    GET_ALLNUMBERSEARCH_START,
    GET_ALLNUMBERSEARCH_SUCCESS,
    GET_ALLNUMBERSEARCH_FAIL,

    UPDATE_FILTER_VALUE,

    ADD_BLACKLIST_START,
    ADD_BLACKLIST_SUCCESS,
    ADD_BLACKLIST_FAIL,

    GET_SERVICE_BY_ID_START,
    GET_SERVICE_BY_ID_SUCCESS,
    GET_SERVICE_BY_ID_FAIL,

    ASSIGN_DRIVER_START,
    ASSIGN_DRIVER_SUCCESS,
    ASSIGN_DRIVER_FAIL,

    CANCEL_SERVICE_START,
    CANCEL_SERVICE_SUCCESS,
    CANCEL_SERVICE_FAIL,

    GET_DRIVERS_START,
    GET_DRIVERS_SUCCESS,
    GET_DRIVERS_FAIL,
  
    GET_BLACKLISTR_START,
    GET_BLACKLISTR_SUCCESS,
    GET_BLACKLISTR_FAIL,

    ACTIVEDRIVER_START,
    ACTIVEDRIVER_SUCCESS,
    ACTIVEDRIVER_FAIL,

    GET_DRIVERINFO_START,
    GET_DRIVERINFO_SUCCESS,
    GET_DRIVERINFO_FAIL,

    GET_MESSAGES_START,
    GET_MESSAGES_SUCCESS,
    GET_MESSAGES_FAIL,

    UPDATE_PAYMENTTYPE_START,
    UPDATE_PAYMENTTYPE_SUCCESS,
    UPDATE_PAYMENTTYPE_FAIL,

    UPDATE_RESERVATION_PAYMENTTYPE_START,
    UPDATE_RESERVATION_PAYMENTTYPE_SUCCESS,
    UPDATE_RESERVATION_PAYMENTTYPE_FAIL,

    UPDATE_SERVICEORIGINLOCATION_START,
    UPDATE_SERVICEORIGINLOCATION_SUCCESS,
    UPDATE_SERVICEORIGINLOCATION_FAIL,
  
    UPDATE_RESERVATIONORIGINLOCATION_START,
    UPDATE_RESERVATIONORIGINLOCATION_SUCCESS,
    UPDATE_RESERVATIONORIGINLOCATION_FAIL,

    UPDATE_SERVICEDESTINATIONLOCATION_START,
    UPDATE_SERVICEDESTINATIONLOCATION_SUCCESS,
    UPDATE_SERVICEDESTINATIONLOCATION_FAIL,
  
    UPDATE_RESERVATIONDESTINATIONLOCATION_START,
    UPDATE_RESERVATIONDESTINATIONLOCATION_SUCCESS,
    UPDATE_RESERVATIONDESTINATIONLOCATION_FAIL,

    CLEAR_NEW_SERVICE,

    NEW_RESERVATION_START,
    NEW_RESERVATION_SUCCESS,
    NEW_RESERVATION_FAIL,
  
    NEW_SERVICE_START,
    NEW_SERVICE_SUCCESS,
    NEW_SERVICE_FAIL,

    UPDATE_RESERVATION_START,
    UPDATE_RESERVATION_SUCCESS,
    UPDATE_RESERVATION_FAIL,

    GET_CUSTOMERTYPES_START,
    GET_CUSTOMERTYPES_SUCCESS,
    GET_CUSTOMERTYPES_FAIL,
  
    GET_PAYMENTYPES_START,
    GET_PAYMENTYPES_SUCCESS,
    GET_PAYMENTYPES_FAIL,

    GET_SERVICELASTLOCATION_START,
    GET_SERVICELASTLOCATION_SUCCESS,
    GET_SERVICELASTLOCATION_FAIL,

    CANCEL_RESERVATION_START,
    CANCEL_RESERVATION_SUCCESS,
    CANCEL_RESERVATION_FAIL,

    SEND_MESSAGE_START,
    SEND_MESSAGE_SUCCESS,
    SEND_MESSAGE_FAIL,

    DELETE_MESSAGE_START,
    DELETE_MESSAGE_SUCCESS,
    DELETE_MESSAGE_FAIL,

    SEND_MESSAGE_START2,
    SEND_MESSAGE_SUCCESS2,
    SEND_MESSAGE_FAIL2,


    RESPONSE_MESSAGE_START,
    RESPONSE_MESSAGE_SUCCESS,
    RESPONSE_MESSAGE_FAIL,

    ACK_MESSAGE_START,
    ACK_MESSAGE_SUCCESS,
    ACK_MESSAGE_FAIL,

    GET_INCIDENTS_START,
    GET_INCIDENTS_SUCCESS,
    GET_INCIDENTS_FAIL,

    GET_LPVEHICLE_START,
    GET_LPVEHICLE_SUCCESS,
    GET_LPVEHICLE_FAIL,

    GET_LASTORIGIN_START,
    GET_LASTORIGIN_SUCCESS,
    GET_LASTORIGIN_FAIL,

    GET_LASTODESTINATION_START,
    GET_LASTODESTINATION_SUCCESS,
    GET_LASTODESTINATION_FAIL,

  } from "./types";
  
  import OperatorNewService from "../../services/operator/newservice.service";
  import OperatorDashboard from "../../services/operator/dashboard.service";
  
  
  export const clearService = () => (dispatch) => {
    dispatch({
      type: CLEAR_NEW_SERVICE,
    });
  };

  export const updateReservationPaymentType = (reservationId,paymentTypeId) => (dispatch) => {
    dispatch({
      type: UPDATE_RESERVATION_PAYMENTTYPE_START,
    });
    return OperatorDashboard.updateReservationPaymentType(reservationId,paymentTypeId)
    .then(
      (response) => {
      dispatch({
        type: UPDATE_RESERVATION_PAYMENTTYPE_SUCCESS,
        payload: response.data,
      });
      return Promise.resolve();
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        dispatch({
          type: UPDATE_RESERVATION_PAYMENTTYPE_FAIL,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
  }

  export const updateServiceOriginLocation = (serviceId,location,lat,lng,ltv,ltt) => (dispatch) => {
    dispatch({
      type: UPDATE_SERVICEORIGINLOCATION_START,
    });
    return OperatorDashboard.updateServiceOriginLocation(serviceId,location,lat,lng,ltv,ltt)
    .then(
      (response) => {
      dispatch({
        type: UPDATE_SERVICEORIGINLOCATION_SUCCESS,
        payload: response.data,
      });
      return Promise.resolve();
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        dispatch({
          type: UPDATE_SERVICEORIGINLOCATION_FAIL,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
  }

  export const updateReservationOriginLocation = (reservationId,location,lat,lng,ltv,ltt) => (dispatch) => {
    dispatch({
      type: UPDATE_RESERVATIONORIGINLOCATION_START,
    });
    return OperatorDashboard.updateReservationOriginLocation(reservationId,location,lat,lng,ltv,ltt)
    .then(
      (response) => {
      dispatch({
        type: UPDATE_RESERVATIONORIGINLOCATION_SUCCESS,
        payload: response.data,
      });
      return Promise.resolve();
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        dispatch({
          type: UPDATE_RESERVATIONORIGINLOCATION_FAIL,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
  }
  export const updateServiceDestinationLocation = (serviceId,location,lat,lng) => (dispatch) => {
    dispatch({
      type: UPDATE_SERVICEDESTINATIONLOCATION_START,
    });
    return OperatorDashboard.updateServiceDestinationLocation(serviceId,location,lat,lng)
    .then(
      (response) => {
      dispatch({
        type: UPDATE_SERVICEDESTINATIONLOCATION_SUCCESS,
        payload: response.data,
      });
      return Promise.resolve();
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        dispatch({
          type: UPDATE_SERVICEDESTINATIONLOCATION_FAIL,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
  }

  export const updateReservationDestinationLocation = (reservationId,location,lat,lng) => (dispatch) => {
    dispatch({
      type: UPDATE_RESERVATIONDESTINATIONLOCATION_START,
    });
    return OperatorDashboard.updateReservationDestinationLocation(reservationId,location,lat,lng)
    .then(
      (response) => {
      dispatch({
        type: UPDATE_RESERVATIONDESTINATIONLOCATION_SUCCESS,
        payload: response.data,
      });
      return Promise.resolve();
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        dispatch({
          type: UPDATE_RESERVATIONDESTINATIONLOCATION_FAIL,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
  }
  export const updateServicePaymentType = (serviceId,paymentTypeId) => (dispatch) => {
    dispatch({
      type: UPDATE_PAYMENTTYPE_START,
    });
    return OperatorDashboard.updateServicePaymentType(serviceId,paymentTypeId)
    .then(
      (response) => {
      dispatch({
        type: UPDATE_PAYMENTTYPE_SUCCESS,
        payload: response.data,
      });
      return Promise.resolve();
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        dispatch({
          type: UPDATE_PAYMENTTYPE_FAIL,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
  }


  export const updateFilterValue = (field,value) => (dispatch) => {
    dispatch({
      type: UPDATE_FILTER_VALUE,
      payload: {'field':field,'value':value}
    });
  };

  export const sendmessage2 = (message,to) => (dispatch) => {
    dispatch({
      type: SEND_MESSAGE_START2,
    });
    return OperatorDashboard.sendmessage2(message,to)
    .then(
      (response) => {
      dispatch({
        type: SEND_MESSAGE_SUCCESS2,
        payload: response.data,
      });
      return Promise.resolve();
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        dispatch({
          type: SEND_MESSAGE_FAIL2,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
  }
  export const sendmessage = (message,to,from) => (dispatch) => {
    dispatch({
      type: SEND_MESSAGE_START,
    });
    return OperatorDashboard.sendmessage(message,to,from)
    .then(
      (response) => {
      dispatch({
        type: SEND_MESSAGE_SUCCESS,
        payload: response.data,
      });
      return Promise.resolve();
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        dispatch({
          type: SEND_MESSAGE_FAIL,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
  }

  export const cancelReservation = (reservationId,reason) => (dispatch) => {
    dispatch({
      type: CANCEL_RESERVATION_START,
    });
    return OperatorDashboard.cancelReservation(reservationId,reason)
    .then(
      (response) => {
      dispatch({
        type: CANCEL_RESERVATION_SUCCESS,
        payload: response.data,
      });
      return Promise.resolve();
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        dispatch({
          type: CANCEL_RESERVATION_FAIL,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
  }

  export const cancelService = (serviceId,reason) => (dispatch) => {
    dispatch({
      type: CANCEL_SERVICE_START,
    });
    return OperatorDashboard.cancelService(serviceId,reason)
    .then(
      (response) => {
      dispatch({
        type: CANCEL_SERVICE_SUCCESS,
        payload: response.data,
      });
      return Promise.resolve();
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        dispatch({
          type: CANCEL_SERVICE_FAIL,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
  }

  export const assignDriver = (vehicleId,driverId,serviceId) => (dispatch) => {
    dispatch({
      type: ASSIGN_DRIVER_START,
    });
    return OperatorDashboard.assignDriver(vehicleId,driverId,serviceId)
    .then(
      (response) => {
        
        dispatch({
          type: ASSIGN_DRIVER_SUCCESS,
          payload: response.data,
        });
        return Promise.resolve();
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        dispatch({
          type: ASSIGN_DRIVER_FAIL,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
  }


  export const updateAck = (messageId) => (dispatch) => {
    dispatch({
      type: ACK_MESSAGE_START,
    });
    return OperatorDashboard.updateAck(messageId)
    .then(
      (response) => {
      dispatch({
        type: ACK_MESSAGE_SUCCESS,
        payload: response.data,
      });
      return Promise.resolve();
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        dispatch({
          type: ACK_MESSAGE_FAIL,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
  }

  export const getVehicleLastPosition = (vehicleId) => (dispatch) => {
    dispatch({
      type: GET_LPVEHICLE_START,
    });
    return OperatorDashboard.getVehicleLastPosition(vehicleId)
    .then(
      (response) => {
      dispatch({
        type: GET_LPVEHICLE_SUCCESS,
        payload: response.data,
      });
      return Promise.resolve();
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        dispatch({
          type: GET_LPVEHICLE_FAIL,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
  }

  export const getIncident = (serviceId) => (dispatch) => {
    dispatch({
      type: GET_INCIDENTS_START,
    });
    return OperatorDashboard.getIncident(serviceId)
    .then(
      (response) => {
      dispatch({
        type: GET_INCIDENTS_SUCCESS,
        payload: response.data,
      });
      return Promise.resolve();
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        dispatch({
          type: GET_INCIDENTS_FAIL,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
  }
  export const responsemessage = (message,messageId) => (dispatch) => {
    dispatch({
      type: RESPONSE_MESSAGE_START,
    });
    return OperatorDashboard.responsemessage(message,messageId)
    .then(
      (response) => {
      dispatch({
        type: RESPONSE_MESSAGE_SUCCESS,
        payload: response.data,
      });
      return Promise.resolve();
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        dispatch({
          type: RESPONSE_MESSAGE_FAIL,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
  }

  export const removemessage = (messageId) => (dispatch) => {
    dispatch({
      type: DELETE_MESSAGE_START,
    });
    return OperatorDashboard.removemessage(messageId)
    .then(
      (response) => {
      dispatch({
        type: DELETE_MESSAGE_SUCCESS,
        payload: response.data,
      });
      return Promise.resolve();
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        dispatch({
          type: DELETE_MESSAGE_FAIL,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
  }


  export const getDriverLastPosition = (serviceId) => (dispatch) => {
    dispatch({
      type: GET_SERVICELASTLOCATION_START,
    });
    return OperatorDashboard.getDriverLastPosition(serviceId)
    .then(
      (response) => {
      dispatch({
        type: GET_SERVICELASTLOCATION_SUCCESS,
        payload: response.data,
      });
      return Promise.resolve();
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        dispatch({
          type: GET_SERVICELASTLOCATION_FAIL,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
  }

  export const getCustomerTypes = () => (dispatch) => {
    dispatch({
      type: GET_CUSTOMERTYPES_START,
    });
    return OperatorDashboard.getCustomerTypes()
    .then(
      (response) => {
      dispatch({
        type: GET_CUSTOMERTYPES_SUCCESS,
        payload: response.data,
      });
      return Promise.resolve();
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        dispatch({
          type: GET_CUSTOMERTYPES_FAIL,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
  }

  export const getPaymentTypes = () => (dispatch) => {
    dispatch({
      type: GET_PAYMENTYPES_START,
    });
    return OperatorDashboard.getPaymentTypes()
    .then(
      (response) => {
      dispatch({
        type: GET_PAYMENTYPES_SUCCESS,
        payload: response.data,
      });
      return Promise.resolve();
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        dispatch({
          type: GET_PAYMENTYPES_FAIL,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
  }


export const getMessages = () => (dispatch) => {
  /*dispatch({
    type: GET_MESSAGES_START,
  });*/
  return OperatorDashboard.getMessages()
  .then(
    (response) => {
    dispatch({
      type: GET_MESSAGES_SUCCESS,
      payload: response.data,
    });
    return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: GET_MESSAGES_FAIL,
        payload: message,
      });

      return Promise.reject();
    }
  );
}

  export const getDriverInfo = (driverId) => (dispatch) => {
    dispatch({
      type: GET_DRIVERINFO_START,
    });
    return OperatorDashboard.getDriverInfo(driverId)
    .then(
      (response) => {
      dispatch({
        type: GET_DRIVERINFO_SUCCESS,
        payload: response.data,
      });
      return Promise.resolve();
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        dispatch({
          type: GET_DRIVERINFO_FAIL,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
  }

  export const getBlackListReasons = () => (dispatch) => {
    dispatch({
      type: GET_BLACKLISTR_START,
    });
    return OperatorDashboard.getBlackListReasons()
    .then(
      (response) => {
      dispatch({
        type: GET_BLACKLISTR_SUCCESS,
        payload: response.data,
      });
      return Promise.resolve();
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        dispatch({
          type: GET_BLACKLISTR_FAIL,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
  }

  export const getavailablesDrivers = (serviceId) => (dispatch) => {
    dispatch({
      type: GET_DRIVERS_START,
    });
    return OperatorDashboard.getavailablesDrivers(serviceId)
    .then(
      (response) => {
      dispatch({
        type: GET_DRIVERS_SUCCESS,
        payload: response.data,
      });
      return Promise.resolve();
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        dispatch({
          type: GET_DRIVERS_FAIL,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
  }



  export const getServiceInfo = (sType,serviceId) => (dispatch) => {
    dispatch({
      type: GET_SERVICE_BY_ID_START,
    });
    if(sType == 1){
      return OperatorDashboard.getServiceInfo(serviceId)
      .then(
        (response) => {
          //console.log("GET_SERVICE_BY_ID_SUCCESS 000");
        dispatch({
          type: GET_SERVICE_BY_ID_SUCCESS,
          payload: response.data,
        });
        return Promise.resolve();
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
    
          dispatch({
            type: GET_SERVICE_BY_ID_FAIL,
            payload: message,
          });
    
          return Promise.reject();
        }
      );
    }else{
      return OperatorDashboard.getReservationInfo(serviceId)
      .then(
        (response) => {
          //console.log("GET_SERVICE_BY_ID_SUCCESS 000 a");
        dispatch({
          type: GET_SERVICE_BY_ID_SUCCESS,
          payload: response.data,
        });
        return Promise.resolve();
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
    
          dispatch({
            type: GET_SERVICE_BY_ID_FAIL,
            payload: message,
          });
    
          return Promise.reject();
        }
      );
    }
  }

  export const activeDriver = (driverId) => (dispatch) => {
    dispatch({
      type: ACTIVEDRIVER_START,
    });
    return OperatorDashboard.activeDriver(driverId)
    .then(
      (response) => {
      dispatch({
        type: ACTIVEDRIVER_SUCCESS,
        payload: response.data,
      });
      return Promise.resolve();
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        dispatch({
          type: ACTIVEDRIVER_FAIL,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
  }

  export const addToBackList = (driverId,blackListReasonId) => (dispatch) => {
    dispatch({
      type: ADD_BLACKLIST_START,
    });
    return OperatorDashboard.addToBackList(driverId,blackListReasonId)
    .then(
      (response) => {
      dispatch({
        type: ADD_BLACKLIST_SUCCESS,
        payload: response.data,
      });
      return Promise.resolve();
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        dispatch({
          type: ADD_BLACKLIST_FAIL,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
  }

  export const getAllActiveTaxis = (statusId) => (dispatch) => {
    /*dispatch({
      type: GET_ALLACTIVETAXIS_START,
    });*/
    return OperatorDashboard.getAllActiveTaxis(statusId)
    .then(
      (response) => {
      dispatch({
        type: GET_ALLACTIVETAXIS_SUCCESS,
        payload: response.data,
      });
      return Promise.resolve();
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        dispatch({
          type: GET_ALLACTIVETAXIS_FAIL,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
  }

  export const getLastTenServices = (cab,last) => (dispatch) => {
    dispatch({
      type: GET_LASTTENSERVECES_START,
    });
    return OperatorDashboard.getLastTenServices(cab,last)
    .then(
      (response) => {
      dispatch({
        type: GET_LASTTENSERVECES_SUCCESS,
        payload: response.data,
      });
      return Promise.resolve();
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        dispatch({
          type: GET_LASTTENSERVECES_FAIL,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
  }

  export const getAllNumberSearch = (phone) => (dispatch) => {
    dispatch({
      type: GET_ALLNUMBERSEARCH_START,
    });
    return OperatorDashboard.getAllNumberSearch(phone)
    .then(
      (response) => {
      dispatch({
        type: GET_ALLNUMBERSEARCH_SUCCESS,
        payload: response.data,
      });
      return Promise.resolve();
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        dispatch({
          type: GET_ALLNUMBERSEARCH_FAIL,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
  }

  export const getLastOrigin = (customerId) => (dispatch) => {
    dispatch({
      type: GET_LASTORIGIN_START,
    });
    return OperatorNewService.getLastOrigin(customerId)
    .then(
      (response) => {
        //console.log("response.data",response.data);
        dispatch({
          type: GET_LASTORIGIN_SUCCESS,
          payload: response.data,
        });
        return Promise.resolve();
      },
      (error) => {
        //console.log("response.error",error);
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        dispatch({
          type: GET_LASTORIGIN_FAIL,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
  }

  export const getLastDestination = (customerId) => (dispatch) => {
    dispatch({
      type: GET_LASTODESTINATION_START,
    });
    return OperatorNewService.getLastDestination(customerId)
    .then(
      (response) => {
        //console.log("response.data",response.data);
        dispatch({
          type: GET_LASTODESTINATION_SUCCESS,
          payload: response.data,
        });
        return Promise.resolve();
      },
      (error) => {
        //console.log("response.error",error);
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        dispatch({
          type: GET_LASTODESTINATION_FAIL,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
  }






























  export const updateReservation = (arReservation) => (dispatch) => {
    dispatch({
      type: UPDATE_RESERVATION_START,
    });
    return OperatorNewService.updateReservation(arReservation)
    .then(
      (response) => {
        //console.log("response.data",response.data);
        dispatch({
          type: UPDATE_RESERVATION_SUCCESS,
          payload: response.data,
        });
        return Promise.resolve();
      },
      (error) => {
        //console.log("response.error",error);
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        dispatch({
          type: UPDATE_RESERVATION_FAIL,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
  }


  export const createNewReservation = (data) => (dispatch) => {
    dispatch({
      type: NEW_RESERVATION_START,
    });
    return OperatorNewService.createNewReservation(data)
    .then(
      (response) => {
        //console.log("response.data",response.data);
        dispatch({
          type: NEW_RESERVATION_SUCCESS,
          payload: response.data,
        });
        return Promise.resolve();
      },
      (error) => {
        //console.log("response.error",error);
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        dispatch({
          type: NEW_RESERVATION_FAIL,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
  }

  export const createNewService = (customerId,paymentTypeId) => (dispatch) => {
    dispatch({
      type: NEW_SERVICE_START,
    });
    return OperatorNewService.createNewService(customerId,paymentTypeId)
    .then(
      (response) => {
      dispatch({
        type: NEW_SERVICE_SUCCESS,
        payload: response.data,
      });
      return Promise.resolve();
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        dispatch({
          type: NEW_SERVICE_FAIL,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
  }

  export const createEmptyService = (customerId,userId) => (dispatch) => {
    dispatch({
      type: NEW_EMPTY_SERVICE_START,
    });
    return OperatorNewService.createEmptyService(customerId,userId)
    .then(
      (response) => {
      dispatch({
        type: NEW_EMPTY_SERVICE_SUCCESS,
        payload: response,
      });
      return Promise.resolve();
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        dispatch({
          type: NEW_EMPTY_SERVICE_FAIL,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
  }

  export const getTopicsComments = () => (dispatch) => {
    dispatch({
      type: GET_TOPIC_COMMENTS_START,
    });
    return OperatorNewService.getTopicsComments()
    .then(
      (response) => {
      dispatch({
        type: GET_TOPIC_COMMENTS_SUCCESS,
        payload: response.data,
      });
      return Promise.resolve();
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        dispatch({
          type: GET_TOPIC_COMMENTS_FAIL,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
  }

  export const createNewCustomer = (newCustomer) => (dispatch) => {
    dispatch({
      type: CREATE_NEW_CUSTOMER_START,
    });
    return OperatorNewService.createNewCustomer(newCustomer)
    .then(
      (response) => {
      dispatch({
        type: CREATE_NEW_CUSTOMER_SUCCESS,
        payload: response.data,
      });
      return Promise.resolve();
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        dispatch({
          type: CREATE_NEW_CUSTOMER_FAIL,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
  }

  export const getNewServiceRate = () => (dispatch) => {
    dispatch({
      type: CALCULATE_RATE_START,
    });
    return OperatorNewService.getNewServiceRate()
    .then(
      (response) => {
      dispatch({
        type: CALCULATE_RATE_SUCCESS,
        payload: response.data,
      });
      return Promise.resolve();
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        dispatch({
          type: CALCULATE_RATE_FAIL,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
  }

  export const getArizonaCounties = () => (dispatch) => {
    dispatch({
      type: START_GET_COUNTIES,
    });
    return OperatorNewService.getArizonaCounties()
    .then(
      (response) => {
      dispatch({
        type: SUCCESS_GET_COUNTIES,
        payload: response.data,
      });
      return Promise.resolve();
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        dispatch({
          type: FAIL_GET_COUNTIES,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
  }
  
  export const getOriginArizonaCitiesByCounty = (countyId) => (dispatch) => {
    dispatch({
      type: START_GET_ORIGIN_CITIES,
    });
    return OperatorNewService.getArizonaCitiesByCounty(countyId)
    .then(
      (response) => {
      dispatch({
        type: SUCCESS_GET_ORIGIN_CITIES,
        payload: response.data,
      });
      return Promise.resolve();
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        dispatch({
          type: FAIL_GET_ORIGIN_CITIES,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
  }
  
  export const getDestinationArizonaCitiesByCounty = (countyId) => (dispatch) => {
    dispatch({
      type: START_GET_DESTINATION_CITIES,
    });
    return OperatorNewService.getArizonaCitiesByCounty(countyId)
    .then(
      (response) => {
      dispatch({
        type: SUCCESS_GET_DESTINATION_CITIES,
        payload: response.data,
      });
      return Promise.resolve();
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        dispatch({
          type: FAIL_GET_DESTINATION_CITIES,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
  }


  export const saveService = (updateService) => (dispatch) => {
    dispatch({
      type: CREATE_NEW_SERVICE_START,
    });
    return OperatorNewService.saveService(updateService).then(
    (response) => {
          dispatch({
              type: CREATE_NEW_SERVICE_SUCCESS,
              payload: response.data,
          });
          return Promise.resolve();
    },
    (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch({
          type: CREATE_NEW_SERVICE_FAIL,
          payload: message,
        });

        return Promise.reject();
      }
    );
  }
  
  export const getAvailables = () => (dispatch) => {
    /*dispatch({
      type: GET_AVAILABLES_START,
    });*/
    return OperatorNewService.getAvailables().then(
    (response) => {
          dispatch({
              type: GET_AVAILABLES_SUCCESS,
              payload: response.data,
          });
          return Promise.resolve();
    },
    (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch({
          type: GET_AVAILABLES_FAIL,
          payload: message,
        });

        return Promise.reject();
      }
    );
  }

  export const updateField = (field,value) => (dispatch) => {
    dispatch({
      type: UPDATE_FIELD_CUSTOMER,
      payload: {'field':field,'value':value}
    });
  };

  export const loadNewService = () => (dispatch) => {
    dispatch({
      type: LOAD_NEW_SERVICE_START,
    });
    return OperatorNewService.loadNewService().then(
    (response) => {
          dispatch({
              type: LOAD_NEW_SERVICE_SUCCESS,
              payload: response.data,
          });
          return Promise.resolve();
    },
    (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch({
          type: LOAD_NEW_SERVICE_FAIL,
          payload: message,
        });

        return Promise.reject();
      }
);


  }
  
  export const loadCustomerByPhone = (phone) => (dispatch) => {
    dispatch({
          type: GET_CUSTOMER_BY_PHONE_START,
    });
    return OperatorNewService.loadCustomerByPhone(phone).then(
    (response) => {
      if('fatal' in response.data){
        dispatch({
          type: GET_CUSTOMER_BY_PHONE_FAIL,
          payload: 'Fatal error',
        });
      }else{
        //console.log("actions loadCustomerByPhone");
        //console.log(response);
        dispatch({
          type: GET_CUSTOMER_BY_PHONE_SUCCESS,
          payload: response.data,
        });
      }
      return Promise.resolve();
    },
    (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        dispatch({
          type: GET_CUSTOMER_BY_PHONE_FAIL,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
  }

  /*export const createNewService = () => (dispatch) => {
    dispatch({
          type: CREATE_NEW_SERVICE_START,
    });
    return OperatorService.createNewService()
    .then(
    (response) => {
          dispatch({
              type: CREATE_NEW_SERVICE_SUCCESS,
              payload: response.data,
          });
          return Promise.resolve();
    },
    (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        dispatch({
          type: CREATE_NEW_SERVICE_FAIL,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
  }*/

  export const getActivatedServices = (Case,Switch) => (dispatch) => {
    /*dispatch({
      type: CHANGE_STATUS_SERVICES_START,
      payload:statusId
    });*/
    /*dispatch({
          type: GET_ACTIVATED_SERVICES_START,
    });*/
    return OperatorDashboard.getActivatedServices(Case,Switch)
    .then(
    (response) => {
          dispatch({
              type: GET_ACTIVATED_SERVICES_SUCCESS,
              payload: response.data,
          });
          return Promise.resolve();
    },
    (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        dispatch({
          type: GET_ACTIVATED_SERVICES_FAIL,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
  }

  export const getVehicles = (statusId) => (dispatch) => {
    /*dispatch({
          type: GET_VEHICLES_START,
    });*/
    return OperatorDashboard.getVehicles(statusId)
    .then(
    (response) => {
          dispatch({
              type: GET_VEHICLES_SUCCESS,
              payload: response.data,
          });
          return Promise.resolve();
    },
    (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        dispatch({
          type: GET_VEHICLES_FAIL,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
  }

  export const getBlacklist = () => (dispatch) => {
    /*dispatch({
          type: GET_BLACKLIST_START,
    });*/
    return OperatorDashboard.getBlacklist()
    .then(
    (response) => {
          dispatch({
              type: GET_BLACKLIST_SUCCESS,
              payload: response.data,
          });
          return Promise.resolve();
    },
    (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        dispatch({
          type: GET_BLACKLIST_FAIL,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
  }

