import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { isEmail } from "validator";
import Select from 'react-select'
import { register, registerVehicle, getArizonaCounties, getArizonaCitiesByCounty } from "../actions/auth";

const required = (value) => {
	if (!value) {
		return (
			<div className="alert alert-danger" role="alert">
				This field is required!
			</div>
		);
	}
};

const validEmail = (value) => {
	if (!isEmail(value)) {
		return (
			<div className="alert alert-danger" role="alert">
				This is not a valid email.
			</div>
		);
	}
};

const vusername = (value) => {
	if (value.length < 3 || value.length > 20) {
		return (
			<div className="alert alert-danger" role="alert">
				The username must be between 3 and 20 characters.
			</div>
		);
	}
};

const vpassword = (value) => {
	if (value.length < 6 || value.length > 40) {
		return (
			<div className="alert alert-danger" role="alert">
				The password must be between 6 and 40 characters.
			</div>
		);
	}
};

const Register = (props) => {
	const form = useRef();
	const checkBtn = useRef();
	const selectCities = useRef();
	const selectCounties = useRef();
	const [firstname, setFirstname] = useState("");
	const [lastname, setLastname] = useState("");
	const [number, setNumber] = useState("");
	const [nsew, setNSEW] = useState("");
	const [street, setStreet] = useState("");
	const [zipCode, setZipCode] = useState("");
	const [email, setEmail] = useState("");
	const [phone, setPhone] = useState("");
	const [howfindus, setHowfindus] = useState("");
	const [username, setUsername] = useState("");


	const [vehiclebrand, setVehiclebrand] = useState("");
	const [vehiclemodel, setVehiclemodel] = useState("");
	const [vehicleinsurancedata, setVehicleinsurancedata] = useState("");
	const [vehicleemissiontest, setVehicleemissiontest] = useState("");
	const [vehicletitle, setVehicletitle] = useState("");
	const [vehicleplates, setVehicleplates] = useState("");
	const [workExperience, setWorkExperience] = useState("");

	const [cityId, setcityId] = useState(null);

	const [successful, setSuccessful] = useState(false);
	const [loading, setLoading] = useState(false);

	const { message } = useSelector(state => state.message);
	const { userRegistered, counties, arizonaCountyCities, countiesLoading, citiesLoading } = useSelector(state => state.auth);

	const dispatch = useDispatch();

	const nsewValues = [{ value: '', label: 'Select NSEW' }, { value: 'N', label: 'North' },
	{ value: 'S', label: 'South' }, { value: 'W', label: 'West' }, { value: 'E', label: 'East' }];

	const onChangeFirstname = (e) => {
		const firstname = e.target.value;
		setFirstname(firstname);
	};

	const onChangeLastname = (e) => {
		const lastname = e.target.value;
		setLastname(lastname);
	};

	const onChangeNumber = (e) => {
		const number = e.target.value;
		setNumber(number);
	};

	const onChangeStreet = (e) => {
		const street = e.target.value;
		setStreet(street);
	};

	const onChangeZipCode = (e) => {
		const zipCode = e.target.value;
		setZipCode(zipCode);
	};

	const onChangeEmail = (e) => {
		const email = e.target.value;
		setEmail(email);
	};

	const onChangePhone = (e) => {
		const phone = e.target.value;
		setPhone(phone);
	};

	const onChangeHowfindus = (e) => {
		const howfindus = e.target.value;
		setHowfindus(howfindus);
	};

	const onVehiclebrand = (e) => {
		const vehiclebrand = e.target.value;
		setVehiclebrand(vehiclebrand);
	};

	const onVehiclemodel = (e) => {
		const vehiclemodel = e.target.value;
		setVehiclemodel(vehiclemodel);
	};

	const onVehicleinsurancedata = (e) => {
		const vehicleinsurancedata = e.target.value;
		setVehicleinsurancedata(vehicleinsurancedata);
	};

	const onVehicleemissiontest = (e) => {
		const vehicleemissiontest = e.target.value;
		setVehicleemissiontest(vehicleemissiontest);
	};

	const onVehicletitle = (e) => {
		const vehicletitle = e.target.value;
		setVehicletitle(vehicletitle);
	};

	const onVehicleplates = (e) => {
		const vehicleplates = e.target.value;
		setVehicleplates(vehicleplates);
	};

	function onWorkExperienceChange(event) {
		const value = event.target.value;
		setWorkExperience(value);
	}

	const handleRegister = (e) => {
		e.preventDefault();

		setLoading(true);
		setSuccessful(false);

		form.current.validateAll();

		if (checkBtn.current.context._errors.length === 0) {
			dispatch(registerVehicle(2, firstname, lastname, email, phone, howfindus, number, nsew.value, street, zipCode, cityId.value, vehiclebrand, vehiclemodel, vehicleinsurancedata, vehicleemissiontest, vehicletitle, vehicleplates, workExperience))
				.then(() => {
					setLoading(false);
					setSuccessful(true);
				})
				.catch((e) => {
					setSuccessful(false);
					setLoading(false);
				});
		} else {
			setLoading(false);
		}
	};

	useEffect(() => {
		dispatch(getArizonaCounties()).then(() => { }).catch(() => { });
	}, [getArizonaCounties, dispatch]);

	useEffect(() => {
		dispatch(getArizonaCitiesByCounty(8)).then(() => { }).catch(() => { });
		selectCities.current.select.setValue({ value: 55, label: 'Phoenix' });
	}, []);

	return (
		<div class="z-depth-2 card-panel border-radius-2 register-card">
			{successful && (
				<div class="card-alert card green">
					<div class="card-content white-text">
						<p>Driver Added Successfully. We will contact you for more details</p>
					</div>
					<button type="button" class="close white-text" data-dismiss="alert" aria-label="Close">
						<span aria-hidden="true">×</span>
					</button>
				</div>
			)}
			{!successful && (
				<Form onSubmit={handleRegister} ref={form} class="login-form">
					<div class="col s12">
						<div class="row">
							<div class="input-field col s12">
								<h5 class="ml-4">Register as an Independent Contractor</h5>
								<p class="ml-4">And join our community now!</p>
							</div>
						</div>
					</div>
					<div class="col s6">
						<div class="row margin">
							<div class="input-field col s12">
								<i class="material-icons prefix pt-2">person_outline</i>
								<input id="firstname" type="text" value={firstname} onChange={onChangeFirstname} />
								<label for="firstname" class="center-align">First Name</label>
							</div>
						</div>
						<div class="row margin">
							<div class="input-field col s12">
								<i class="material-icons prefix pt-2">person_outline</i>
								<input id="lastname" type="text" value={lastname} onChange={onChangeLastname} />
								<label for="lastname" class="center-align">Last Name</label>
							</div>
						</div>
						<div class="row margin">
							<div class="input-field col s12">
								<i class="material-icons prefix pt-2" style={{ top: '1.0rem' }}>home</i>
								<div style={{ width: 'calc(100% - 3rem)', marginLeft: '3rem' }} >
									<Select
										ref={selectCities}
										className="countySelect"
										classNamePrefix="select"
										defaultValue={arizonaCountyCities[0]}
										isLoading={citiesLoading}
										isClearable={true}
										isRtl={false}
										isSearchable={true}
										options={arizonaCountyCities}
										onChange={(value) => {
											setcityId(value);
										}}
									/>
								</div>
							</div>
						</div>
						<div className="row margin">
							<div className="input-field col s12">
								<i className="material-icons prefix pt-2">home</i>
								<input id="street" type="text" value={street} onChange={onChangeStreet} />
								<label for="street" className="center-align">Street</label>
							</div>
						</div>
						<div className="row margin">
							<div className="input-field col s12">
								<i className="material-icons prefix pt-2">assignment</i>
								<input id="number" type="text" value={number} onChange={onChangeNumber} />
								<label for="number" className="center-align">Street number</label>
							</div>
						</div>
						<div class="row margin">
							<div class="input-field col s12">
								<i class="material-icons prefix pt-2" style={{ top: '1.0rem' }}>directions</i>
								<div style={{ width: 'calc(100% - 3rem)', marginLeft: '3rem' }} >
									<Select
										className="countySelect"
										classNamePrefix="select"
										defaultValue={{ value: '', label: 'Select NSEW' }}
										isClearable={true}
										isRtl={false}
										isSearchable={true}
										name="color"
										options={nsewValues}
										onChange={(value) => {
											setNSEW(value);
										}}
									/>
								</div>
							</div>
						</div>
						<div className="row margin">
							<div className="input-field col s12">
								<i className="material-icons prefix pt-2">local_post_office</i>
								<input id="zipCode" type="text" value={zipCode} onChange={onChangeZipCode} />
								<label for="zipCode" className="center-align">Zip Code</label>
							</div>
						</div>
						<div class="row margin">
							<div class="input-field col s12">
								<i class="material-icons prefix pt-2">mail_outline</i>
								<input id="email" type="email" value={email} onChange={onChangeEmail} />
								<label for="email">Email</label>
							</div>
						</div>
						<div class="row margin">
							<div class="input-field col s12">
								<i class="material-icons prefix pt-2">smartphone</i>
								<input id="phone" type="text" value={phone} onChange={onChangePhone} />
								<label for="phone">Phone</label>
							</div>
						</div>
						<div class="row margin">
							<div class="input-field col s12">
								<i class="material-icons prefix pt-2">search</i>
								<input id="howfindus" type="text" value={howfindus} onChange={onChangeHowfindus} />
								<label for="howfindus">How did you find us</label>
							</div>
						</div>
					</div>
					<div class="col s6">
						<div class="row margin">
							<div class="input-field col s12">
								<i class="material-icons prefix pt-2">drive_eta</i>
								<input id="vehiclebrand" type="text" value={vehiclebrand} onChange={onVehiclebrand} />
								<label for="vehiclebrand">Vehicle Brand</label>
							</div>
						</div>
						<div class="row margin">
							<div class="input-field col s12">
								<i class="material-icons prefix pt-2">drive_eta</i>
								<input id="vehiclemodel" type="text" value={vehiclemodel} onChange={onVehiclemodel} />
								<label for="vehiclemodel">Vehicle Model</label>
							</div>
						</div>
						<div class="row margin">
							<div class="input-field col s12">
								<i class="material-icons prefix pt-2">drive_eta</i>
								<input id="vehicleinsurancedata" type="text" value={vehicleinsurancedata} onChange={onVehicleinsurancedata} />
								<label for="vehicleinsurancedata">Vehicle Insurance Data</label>
							</div>
						</div>
						<div class="row margin">
							<div class="input-field col s12">
								<i class="material-icons prefix pt-2">drive_eta</i>
								<input id="vehicleemissiontest" type="text" value={vehicleemissiontest} onChange={onVehicleemissiontest} />
								<label for="vehicleemissiontest">Vehicle Emission Test</label>
							</div>
						</div>
						<div class="row margin">
							<div class="input-field col s12">
								<i class="material-icons prefix pt-2">drive_eta</i>
								<input id="vehicletitle" type="text" value={vehicletitle} onChange={onVehicletitle} />
								<label for="vehicletitle">Vehicle Title</label>
							</div>
						</div>
						<div class="row margin">
							<div class="input-field col s12">
								<i class="material-icons prefix pt-2">drive_eta</i>
								<input id="vehicleplates" type="text" value={vehicleplates} onChange={onVehicleplates} />
								<label for="vehicleplates">Vehicle Plates</label>
							</div>
						</div>
						<div class="row margin">
							<div class="input-field col s12">
								<i className="material-icons prefix pt-2">work</i>
								<input id="workExperience" type="text" value={workExperience} onChange={onWorkExperienceChange} />
								<label for="workExperience">Work Experience</label>
								<span className="helper-text">Tell us about your work experience, e.g., current job, referrals, personal history, etc.</span>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col s12">
							{message && (
								<div class="card-alert card red">
									<div class="card-content white-text">
										<p>{message}</p>
									</div>
									<button type="button" class="close white-text" data-dismiss="alert" aria-label="Close">
										<span aria-hidden="true">×</span>
									</button>
								</div>
							)}
						</div>
					</div>
					<div class="row">
						<div class="input-field col s12">
							<button disabled={loading} type="submit" class="btn waves-effect waves-light border-round gradient-45deg-purple-deep-orange col s12">Register</button>
							<CheckButton style={{ display: "none" }} ref={checkBtn} />
							{loading && (
								<div class="preloader-wrapper big active">
									<div class="spinner-layer spinner-blue-only">
										<div class="circle-clipper left">
											<div class="circle"></div>
										</div>
										<div class="gap-patch">
											<div class="circle"></div>
										</div>
										<div class="circle-clipper right">
											<div class="circle"></div>
										</div>
									</div>
								</div>
							)}
						</div>
					</div>
				</Form>
			)}
			<link rel="stylesheet" type="text/css" href="/app-assets/css/pages/register.css" />
		</div>
	);
};

export default Register;