const tooltipsOptions = {
    enabled: false,

    custom: function (tooltipModel) {
        var tooltipEl = document.getElementById('chartjs-tooltip');

        if (!tooltipEl) {
            tooltipEl = document.createElement('div');
            tooltipEl.id = 'chartjs-tooltip';
            tooltipEl.innerHTML = '<div class="center-align"></div>';
            tooltipEl.style.backgroundColor = "rgba(0, 0, 0, 0.5)";
            tooltipEl.style.color = "white";
            document.body.appendChild(tooltipEl);
        }

        if (tooltipModel.opacity === 0) {
            tooltipEl.style.opacity = 0;
            return;
        }

        tooltipEl.classList.remove('above', 'below', 'no-transform');
        if (tooltipModel.yAlign) {
            tooltipEl.classList.add(tooltipModel.yAlign);
        } else {
            tooltipEl.classList.add('no-transform');
        }

        function getBody(bodyItem) {
            return bodyItem.lines;
        }

        if (tooltipModel.body) {
            var titleLines = tooltipModel.title || [];
            var bodyLines = tooltipModel.body.map(getBody);

            var innerHtml = '<p>';

            titleLines.forEach(function (title) {
                innerHtml += title;
            });
            innerHtml += '</p><p>';

            bodyLines.forEach(function (body, i) {
                var colors = tooltipModel.labelColors[i];
                var style = 'background:' + colors.backgroundColor;
                var span = '<span style="' + style + '"></span>';
                innerHtml += span + body;
            });
            innerHtml += '</p>';

            var tableRoot = tooltipEl.querySelector('div');
            tableRoot.innerHTML = innerHtml;
        }

        var position = this._chart.canvas.getBoundingClientRect();

        tooltipEl.style.opacity = 1;
        tooltipEl.style.position = 'absolute';
        tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
        tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
        tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
        tooltipEl.style.fontSize = '8px';
        tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
        tooltipEl.style.margin = '-30px 0px 0px 0px';
        tooltipEl.style.padding = '0px 4px';
        tooltipEl.style.pointerEvents = 'none';
    }
};

const commonChartOptions =
{
    responsive: !0,
    maintainAspectRatio: false,
    legend: {
        display: !1
    },
    hover: {
        mode: "label"
    },
    scales: {
        xAxes: [{
            beginAtZero: true,
            display: false
        }],
        yAxes: [{
            beginAtZero: true,
            display: false
        }]
    },
    tooltips: tooltipsOptions
};

export { commonChartOptions, tooltipsOptions };