import {
  REGISTER_SUCCESS,
  REGISTER_VEHICLE_SUCCESS,
  REGISTER_FAIL,
  REGISTER_VEHICLE_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  SET_DRIVER,
  START_GET_COUNTIES,
  SUCCESS_GET_COUNTIES,
  FAIL_GET_COUNTIES,
  START_GET_CITIES,
  SUCCESS_GET_CITIES,
  FAIL_GET_CITIES,
} from "../actions/types";

const user = JSON.parse(localStorage.getItem("user"));

const initialState = user ? { 
  isLoggedIn: true, 
  user, 
  userRegistered: null, 
  vehicleRegistered: null, 
  counties:[{value:'',label:'Select a County'}],
  arizonaCountyCities: [{value:'',label:'Select a City'}],
  countiesLoading:false,
  citiesLoading:false  
} : { 
  isLoggedIn: false, 
  user: null,
  userRegistered: null,
  vehicleRegistered: null, 
  counties:[{value:'',label:'Select a County'}],
  arizonaCountyCities: [{value:'',label:'Select a City'}], 
  countiesLoading:false,citiesLoading:false 
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case REGISTER_SUCCESS:
      return {
        ...state,
        isLoggedIn: false,
        userRegistered: payload.newuser
      };
    case REGISTER_VEHICLE_SUCCESS:
      return {
        ...state,
        isLoggedIn: false,
        vehicleRegistered: payload.newvehicle
      };
    case REGISTER_FAIL:
      return {
        ...state,
        isLoggedIn: false,
      };
    case REGISTER_VEHICLE_FAIL:
      return {
        ...state,
        isLoggedIn: false,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        user: payload.user,
      };
    case LOGIN_FAIL:
      return {
        ...state,
        isLoggedIn: false,
        user: null,
      };
    case LOGOUT:
      return {
        ...state,
        isLoggedIn: false,
        user: null,
      };
    case START_GET_COUNTIES:
      return {
        ...state,
        countiesLoading: true,
        counties: [{value:'',label:'Select a County'}],
        arizonaCountyCities: [{value:'',label:'Select a City'}],
      };
    case SUCCESS_GET_COUNTIES:
      var temp = [{value:'',label:'Select a County'}];
      for (var index in payload) {
        temp.push({value: payload[index].countyId, label:payload[index].county});
      }
      return {
        ...state,
        countiesLoading: false,
        counties: temp,
      };
    case FAIL_GET_COUNTIES:
      return {
        ...state,
        countiesLoading: false,
        counties: [{value:'',label:'Select a County'}],
        arizonaCountyCities: [{value:'',label:'Select a City'}],
      };
    case START_GET_CITIES:
      return {
        ...state,
        citiesLoading: true,
        arizonaCountyCities: [{value:'',label:'Select a City'}],
      };
    case SUCCESS_GET_CITIES:
      var temp = [{value:'',label:'Select a City'}];
      for (var index in payload) {
        temp.push({value: payload[index].cityId, label:payload[index].city});
      }
      return {
        ...state,
        citiesLoading: false,
        arizonaCountyCities: temp,
      };
    case FAIL_GET_CITIES:
      return {
        ...state,
        citiesLoading: false,
        arizonaCountyCities: [{value:'',label:'Select a City'}],
      };

    default:
      return state;
  }
}