import React, { useEffect, useState } from "react";
import axios from "axios";

import authHeader from "../../services/auth-header";

function EventStatistics() {

    const [registeredEvents, setRegisteredEvents] = useState([]);
    const [todayEvents, setTodayEvents] = useState({});

    useEffect(() => {
        const today = new Date();
        getRegisteredEvents(today.getMonth() + 1, today.getFullYear());
    }, []);

    async function getRegisteredEvents(month, year) {
        let result = await axios.get(process.env.REACT_APP_API_URL + `api/driver/recorded/interviewByMonth/${month}/${year}`, { headers: authHeader() })
        if (result) {
            setRegisteredEvents(result.data);
            getTodayEvents(result.data);
        }
    }

    function getEventsAmount() {
        return registeredEvents.length;
    }

    function getTodayEvents(registeredEvents) {
        const todayDate = new Date();
        const todayDateTime = new Date();
        const tomorrowDate = new Date(todayDate.getFullYear(), todayDate.getMonth(), todayDate.getDate() + 1);
        const todayEvents = {
            minutesRemaining: [],
            hoursRemaining: [],
            inCourse: [],
            total: 0
        };
        todayDate.setHours(0, 0);

        for (const event of registeredEvents) {
            const eventDate = toDateObject(event.bookDate, event.bookTime);
            if (isDateInBetween(todayDate, eventDate, tomorrowDate)) {
                event.timeRemaining = getRemainingTime(eventDate, todayDateTime);
                sortRemainingTime(todayEvents, event);
                todayEvents.total++;
            }
        }
        setTodayEvents(todayEvents);
    }

    function getRemainingTime(event, today) {
        const timeRemaining = event - today;
        return (timeRemaining / (1000 * 60 * 60)) % 24;
    }

    function sortRemainingTime(todayEvents, event) {
        if (event.timeRemaining < 0) {
            todayEvents.inCourse.push(event);
            return;
        }
        if (event.timeRemaining > 0 && event.timeRemaining < 1) {
            todayEvents.minutesRemaining.push(event);
            return;
        }
        todayEvents.hoursRemaining.push(event);
    }

    function getDurationText(duration) {
        if (duration < 0)
            duration = Math.abs(duration);

        const hours = Math.floor(duration),
            minutes = Math.floor((duration - hours) * 60),
            hoursText = getHoursText(hours),
            minutesText = getMinutesText(minutes);
        if (hoursText && minutesText)
            return `${hoursText} & ${minutesText}`;
        if (hoursText && !minutesText)
            return hoursText;
        return minutesText;
    }

    function getHoursText(hours) {
        if (hours <= 0)
            return '';
        if (hours == 1)
            return `1 hour`;
        return `${hours} hours`;
    }

    function getMinutesText(minutes) {
        if (minutes <= 0)
            return '';
        if (minutes == 1)
            return `1 minute`;
        return `${minutes} minutes`;
    }

    // not inclusive for endDate
    function isDateInBetween(startDate, date, endDate) {
        const value = date >= startDate && date < endDate;
        return value;
    }

    function toDateObject(date, time) {
        const dateObj = new Date(date);
        dateObj.setHours(time.substring(0, 2), time.substring(3, 5));
        return dateObj;
    }

    return (
        <a href="/manager/applicants">
            <div className="white z-depth-1 animate fadeLeft delay-1 grey-text text-darken-4" style={{ marginTop: '15px', padding: '15px' }}>
                <div className="row">
                    <div className="col s12 valign-wrapper">
                        <i className="material-icons" style={{ marginRight: '10px' }}>date_range</i>
                        {getEventsAmount() > 0 ?
                            <>There are a total of {getEventsAmount()} appointments scheduled for this month</>
                            :
                            <>There are no appointments scheduled for this month</>
                        }
                    </div>
                </div>
                {getEventsAmount() > 0 &&
                    <div className="row" style={{ marginTop: '13px' }}>
                        <div className="col s12 valign-wrapper">
                            <i className="material-icons" style={{ marginRight: '10px' }}>event_available</i>
                            <span>
                                {todayEvents.total > 1 && <>{todayEvents.total} are</>}
                                {todayEvents.total == 1 && <>One is</>}
                                {todayEvents.total == 0 && <>None of them are</>} happening today
                        </span>
                        </div>
                    </div>
                }
                {todayEvents.total > 0 &&
                    <div style={{ marginLeft: '30px' }}>
                        {todayEvents.inCourse.map(event =>
                            <CustomRow key={event.driverId} icon='all_inclusive'
                                title={`${event.firstName} ${event.lastName}`} text={`ongoing for ${getDurationText(event.timeRemaining)}`}
                                color='red lighten-2' />
                        )}
                        {todayEvents.minutesRemaining.map(event =>
                            <CustomRow key={event.driverId} icon='access_alarm'
                                title={`${event.firstName} ${event.lastName}`} text={`coming up in ${getDurationText(event.timeRemaining)}`}
                                color='green darken-3 white-text' />
                        )}
                        {todayEvents.hoursRemaining.map(event =>
                            <CustomRow key={event.driverId} icon='fast_forward'
                                title={`${event.firstName} ${event.lastName}`} text={`coming up in ${getDurationText(event.timeRemaining)}`}
                                color='blue lighten-2' />
                        )}
                    </div>
                }
            </div>
        </a>
    );
}

function CustomRow(props) {
    return (
        <div className={`row ${props.className}`} style={{ marginTop: '10px' }}>
            <span className="col s12 valign-wrapper">
                <i className="material-icons grey lighten-2 grey-text text-darken-4" style={{ marginRight: '10px', borderRadius: '20px', padding: '4px', fontSize: '1.5em' }}>{props.icon}</i>
                <span className={`${props.color}`} style={{ borderRadius: '20px', marginRight: '10px', padding: '3px 6px', fontWeight: 'bold', fontSize: '0.8em' }}>{props.title}</span>
                <span className="grey-text text-darken-2" style={{ fontSize: '0.8em' }}>{props.text}</span>
            </span>
        </div>
    );
}

export default EventStatistics;