import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../actions/auth";
import platform from 'platform';

function NavBar(props) {

    const { isLoggedIn } = useSelector(state => state.auth);
    const dispatch = useDispatch();

    const logOut = () => {
        dispatch(logout());
    };

    const getSideBarContent = () => {
        if (!props.children) {
            if (!isLoggedIn)
                return (
                    <><li><a href="tel: 602-307-5555" className="waves-effect waves-white btn-flat black-text" style={{ marginBottom: '8px', borderColor: 'black', borderStyle: 'solid', borderWidth: '1px' }}>Call us</a></li>
                        <li><a href="/home">HOME</a></li>
                        <li><a href="/login">LOGIN</a></li>
                        <li><a href="/faq">FAQ</a></li>
                        <li><a href="/info">ABOUT US</a></li>
                        <li><hr /></li>
                        <li><a href="/register-form/0">JOIN US</a></li></>
                );
            return (
                <><li><a href="/">Home</a></li>
                    <li><a href="/dashboard">Dashboard</a></li>
                    <li><a href="/manager">Manager</a></li>
                    <li><a href="/operator">Operator</a></li>
                    <li><a onClick={logOut} href="/" className="waves-effect waves-white btn-flat black-text" style={{ marginBottom: '8px', borderColor: 'black', borderStyle: 'solid', borderWidth: '1px' }}>LOG OUT</a></li></>
            );
        }
        return props.children;
    };

    function getAppDownloadLink() {
        const os = platform.os;
        switch (os.family) {
            case 'Android':
                return props.appDownloadURLs.android;
            case 'iOS':
                return props.appDownloadURLs.ios;
            default:
                return '/#mobile-app';
        }
    }

    return (
        <>
            <link rel="stylesheet" href="/app-assets/css/custom/navbar.css" />
            <div className={`row navbar ${props.size}`}>
                <div className="col s12">
                    <div className="row">
                        <div className="col s12">
                            <nav className="nav-extended z-depth-0" style={{ backgroundColor: 'rgba(0, 0, 0, 0.4)' }}>
                                <div className="nav-wrapper">
                                    <div className="row">
                                        <div className="col s12">
                                            <a href="#" data-target="mobile-demo" className={`sidenav-trigger ${props.children ? '' : 'hide-on-large-only'}`}><i className="material-icons">menu</i></a>
                                            <a className="brand-logo white-text" style={{ display: 'inline-flex', alignItems: 'center', fontVariant: 'small-caps' }} href='/'>
                                                {props.logoURL != null &&
                                                    <img src={`${process.env.REACT_APP_API_URL}${props.logoURL}`} style={{ width: 'auto', height: '30px', marginLeft: '10px', marginRight: '10px', marginTop: '0', marginBottom: '0' }} alt="logo" />
                                                }
                                                {props.companyName}
                                            </a>
                                            <ul id="nav-mobile" className="right hide-on-med-and-down">
                                                <ul className="tabs tabs-transparent">
                                                    {!isLoggedIn
                                                        ? <><li className="tab"><a target="_self" className={props.option == 1 ? 'active' : ''} href="/">Home</a></li>
                                                            <li className="tab"><a target="_self" className={props.option == 2 ? 'active' : ''} href="/login">Login</a></li>
                                                            <li className="tab"><a target="_self" className={props.option == 5 ? 'active' : ''} href="/faq">FAQ</a></li>
                                                            <li className="tab"><a target="_self" className={props.option == 6 ? 'active' : ''} href="/info">About us</a></li></>

                                                        : <><li className="tab"><a target="_self" className={props.option == 1 ? 'active' : ''} href="/">Home</a></li>
                                                            <li className="tab"><a target="_self" className={props.option == 2 ? 'active' : ''} href="/dashboard">Dashboard</a></li>
                                                            <li className="tab"><a target="_self" className={props.option == 3 ? 'active' : ''} href="/manager">Manager</a></li>
                                                            <li className="tab"><a target="_self" className={props.option == 4 ? 'active' : ''} href="/operator">Operator</a></li>
                                                            <li><a onClick={logOut} href="/" className="waves-effect waves-white btn-flat white-text" style={{ marginBottom: '8px', borderColor: 'white', borderStyle: 'solid', borderWidth: '1px' }}>LOG OUT</a></li></>}
                                                </ul>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </nav>
                        </div>
                    </div>
                    {props.size != 'mini' &&
                        <>
                            <div className="row">
                                <div className="col s12 center-align" style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)', fontVariant: 'small-caps' }}>
                                    <p className="white-text title">
                                        {props.title}
                                    </p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col s0 m4" />
                                <div className="col s12 m2 center-align">
                                    <a href={`tel: ${props.phone}`} className="waves-effect waves-white btn-flat black-text white" style={{ marginBottom: '8px', borderColor: 'white', borderStyle: 'solid', borderWidth: '1px', fontWeight: 'bold' }}>Call us</a>
                                </div>
                                <div className="col s12 m2 center-align">
                                    <a href={getAppDownloadLink()} className="waves-effect waves-white btn-flat black-text white" style={{ marginBottom: '8px', borderColor: 'white', borderStyle: 'solid', borderWidth: '1px', fontWeight: 'bold' }}>Download app</a>
                                </div>
                                <div className="col s0 m4" />
                            </div>
                        </>}
                </div>
            </div>

            <ul className="sidenav" id="mobile-demo">
                {getSideBarContent()}
            </ul>
        </>
    );
}

export { NavBar };