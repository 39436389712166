import React, { useState, useEffect } from 'react';

import RegisterDriver from "./RegisterDriver";
import RegisterAssociate from "./RegisterAssociate";
import { useParams } from "react-router-dom";

function RegisterForm(props) {
    let { driverType } = useParams();
    const [registerDriverTab, setRegisterDriverTab] = useState(0);

    useEffect(() => {
        setRegisterDriverTab(+driverType);
    }, [])

    const handleTabSwitch = (event) => {
        setRegisterDriverTab(event.target.dataset.value);
    };

    return (
        <div className="container">
            <div className="row" style={{ marginTop: '4rem' }}>
                <div className="col s12 driver-tab" id='join-us'>
                    <ul className="tabs center-align black-text blue lighten-4" style={{ borderRadius: '2px' }}>
                        <li style={{ backgroundColor: 'transparent' }} className="tab col s6"><a data-value={0} onClick={handleTabSwitch} className={(registerDriverTab == 0 ? 'active' : '') + ' black-text'} href="#swipe-1">Become an Independent Contractor</a></li>
                        <li style={{ backgroundColor: 'transparent' }} className="tab col s6"><a data-value={1} onClick={handleTabSwitch} className={(registerDriverTab != 0 ? 'active' : '') + ' black-text'} href="#swipe-2">Become a Driver</a></li>
                    </ul>
                </div>
            </div>
            <div className="row">
                <div className="col s12 m12 l5">
                    <div className="z-depth-2 card-panel border-radius-2">
                        <h5 className="center-align jobs-title">Start making money and be your own boss, with {props.companyName}!</h5>
                        <p>{props.companyName} is hiring drivers in Phoenix! We are a local transport business, with more than 20 years of experience. If you are thinking of driving for transportation services in Phoenix, join us! we provide all the training you need, plus we have two options for you:</p>
                        <div className="row valign-wrapper">
                            <div className="col s1"><i className="blue-text text-darken-3 material-icons">local_taxi</i></div>
                            <p className="col s11">You can register as an independent contractor, using your own car.</p>
                        </div>
                        <div className="row valign-wrapper">
                            <div className="col s1"><i className="blue-text text-darken-3 material-icons">loyalty</i></div>
                            <p className="col s11">If you don't want to use your own car, you can register as a driver and we provide a car for you to drive, either full or part time.</p>
                        </div>
                        <div className="row valign-wrapper">
                            <div className="col s1"><i className="blue-text text-darken-3 material-icons">mood</i></div>
                            <p className="col s11">With either of the 2 options, earn money taking walks in the city of Phoenix, Tempe, Scottsdale, Mesa, Glendale and its surroundings. Get started today!</p>
                        </div>
                    </div>
                </div>
                <div className="col s12 m12 l7">
                    {registerDriverTab == 0
                        ? <RegisterAssociate />
                        : <RegisterDriver />}
                </div>
            </div>
        </div>
    );
}

export default RegisterForm;