import React from "react";

export default class Validate {
    status = true;
    messageList = [];

    init(field, validationList) {
        const validationKeys = Object.keys(validationList);
        for (const key of validationKeys) {
            const validation = validationList[key];
            let value, message = validation.message;
            switch (key) {
                case 'maxLength':
                    value = validation.value;
                    if (field.length > value)
                        this.setError(message);
                    break;
                case 'minLength':
                    value = validation.value;
                    if (field.length < value)
                        this.setError(message);
                    break;
                case 'integer':
                    if (!Validate.isInt(field))
                        this.setError(message);
                    break;
                case 'decimal':
                    if (!field.match(/^\d+\.?\d*$/))
                        this.setError(message);
                    break;
                case 'not':
                    value = validation.value;
                    if (field == value)
                        this.setError(message);
                    break;
                case 'notEmpty':
                    if (!field)
                        this.setError(message);
                    break;
                case 'notCero':
                    if (field == 0 || field == '0')
                        this.setError(message);
                    break;
                case 'email':
                    if (!field.match(/^\S+@\S+$/))
                        this.setError(message);
                    break;
                case 'maxValue':
                    value = validation.value;
                    if(field > value)
                        this.setError(message);
                    break;
                case 'minValue':
                    value = validation.value;
                    if(field < value)
                        this.setError(message);
                    break;
            }
        }
        return this;
    }

    setError(message) {
        this.messageList.push(message);
        this.status = false;
    }

    getHTML() {
        let messages = <></>;
        for (const message of this.messageList)
            messages = <>{messages}<span>{message}</span><br /></>;
        this.messageList = [];
        return messages;
    }

    static isInt(n) {
        return n % 1 === 0;
    }
}