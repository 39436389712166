import React, { useEffect, useState } from "react";
import ReactModal from 'react-modal';

import axios from "axios";

import Validate from '../../../helpers/validate';
import authHeader from '../../../services/auth-header';
import { TextControl, SelectControl } from '../Controls';

function RolesModal(props) {

    const [memberRoles, setMemberRoles] = useState([]);
    const [customerRoles, setCustomerRoles] = useState([]);
    const [rolesList, setRolesList] = useState([]);
    const [role, setRole] = useState(0);

    const [paymentTypes, setPaymentTypes] = useState([]);
    const [vehicleBrand, setVehicleBrand] = useState('');
    const [vehicleModel, setVehicleModel] = useState('');
    const [vehicleInsurance, setVehicleInsurance] = useState('');
    const [vehicleEmissionTest, setVehicleEmissionTest] = useState('');
    const [vehicleTitle, setVehicleTitle] = useState('');
    const [vehiclePlates, setVehiclePlates] = useState('');
    const [vehicleRegistration, setVehicleRegistration] = useState('');
    const [vehicleColor, setVehicleColor] = useState('');
    const [vehicleCab, setVehicleCab] = useState('');
    const [vehicleType, setVehicleType] = useState('');
    const [paymentType, setPaymentType] = useState('');
    const [errorMessage, setErrorMessage] = useState({});

    useEffect(() => {
        getRoles();
        getCustomerPaymentTypes();
    }, []);

    useEffect(() => {
        if (props.isOpen) {
            getMemberRoles();
            getCustomerRoles();
        }
    }, [props.isOpen])

    async function getRoles() {
        let result = await axios.get(process.env.REACT_APP_API_URL + 'api/catalog/roles/0', { headers: authHeader() })
        if (result)
            setRolesList(result.data);
    }

    async function getCustomerPaymentTypes() {
        let result = await axios.get(process.env.REACT_APP_API_URL + 'api/catalog/paymentTypes', { headers: authHeader() })
        if (result)
            setPaymentTypes(result.data);
    }

    async function getMemberRoles() {
        let result = await axios.get(process.env.REACT_APP_API_URL + `api/admin/rolesByUser/${props.member.memberId}`, { headers: authHeader() })
        if (result)
            setMemberRoles(result.data);
    }

    async function getCustomerRoles() {
        let result = await axios.get(process.env.REACT_APP_API_URL + `api/admin/rolesByCustomer/${props.member.memberId}`, { headers: authHeader() })
        if (result)
            setCustomerRoles(result.data);
    }

    async function handleAddRole() {
        const validate = new Validate();
        validate.status = false;
        const message = {};

        switch (role) {
            case '1': // Manager
            case '2': // Dashboard
            case '3': {// Operator
                validate.status = true;
                await axios.post(process.env.REACT_APP_API_URL + 'api/admin/userRole', {
                    userId: props.member.userId, roleId: role
                }, {
                    headers: authHeader()
                });
                break;
            }
            case '8': { // ADA Senior Cab (B)
                validate.status = true;
                message.paymentType = validate.init(paymentType, {
                    notEmpty: { message: 'A payment type must be selected' }
                }).getHTML();

                if (validate.status) {
                    await axios.post(process.env.REACT_APP_API_URL + 'api/admin/newCustomer', {
                        memberId: props.member.memberId, paymentTypeId: paymentType, customerTypeId: 3
                    }, {
                        headers: authHeader()
                    });
                }
                break;
            }
            case '9': { // Senior Center (Y)
                validate.status = true;
                message.paymentType = validate.init(paymentType, {
                    notEmpty: { message: 'A payment type must be selected' }
                }).getHTML();

                if (validate.status) {
                    await axios.post(process.env.REACT_APP_API_URL + 'api/admin/newCustomer', {
                        memberId: props.member.memberId, paymentTypeId: paymentType, customerTypeId: 4
                    }, {
                        headers: authHeader()
                    });
                }
                break;
            }
            case '4': { // Customer
                validate.status = true;
                message.paymentType = validate.init(paymentType, {
                    notEmpty: { message: 'A payment type must be selected' }
                }).getHTML();

                if (validate.status) {
                    await axios.post(process.env.REACT_APP_API_URL + 'api/admin/newCustomer', {
                        memberId: props.member.memberId, paymentTypeId: paymentType, customerTypeId: 1
                    }, {
                        headers: authHeader()
                    });
                }
                break;
            }
            case '5': { // Driver
                validate.status = true;
                await axios.post(process.env.REACT_APP_API_URL + 'api/admin/userRole', {
                    userId: props.member.userId, roleId: 5
                }, {
                    headers: authHeader()
                });

                await axios.post(process.env.REACT_APP_API_URL + 'api/admin/newDriver', {
                    memberId: props.member.memberId, howFindUs: 'admin',
                    type: 1, comments: 'admin'
                }, {
                    headers: authHeader()
                });
                break;
            }
            case '6': { // Associate
                validate.status = true;
                message.vehicleBrand = validate.init(vehicleBrand, {
                    maxLength: {
                        value: 50,
                        message: 'The maximum length possible of the first name is 50 characters'
                    },
                    minLength: {
                        value: 1,
                        message: 'The minimum length possible of the first name is 1 character'
                    }
                }).getHTML();
                message.vehicleModel = validate.init(vehicleModel, {
                    maxLength: {
                        value: 4,
                        message: 'The maximum length possible of the first name is 4 characters'
                    },
                    minLength: {
                        value: 1,
                        message: 'The minimum length possible of the first name is 1 character'
                    }
                }).getHTML();
                message.vehicleInsurance = validate.init(vehicleInsurance, {
                    maxLength: {
                        value: 150,
                        message: 'The maximum length possible of the first name is 150 characters'
                    },
                    minLength: {
                        value: 1,
                        message: 'The minimum length possible of the first name is 1 character'
                    }
                }).getHTML();
                message.vehicleEmissionTest = validate.init(vehicleEmissionTest, {
                    maxLength: {
                        value: 150,
                        message: 'The maximum length possible of the first name is 150 characters'
                    },
                    minLength: {
                        value: 1,
                        message: 'The minimum length possible of the first name is 1 character'
                    }
                }).getHTML();
                message.vehicleTitle = validate.init(vehicleTitle, {
                    maxLength: {
                        value: 150,
                        message: 'The maximum length possible of the first name is 150 characters'
                    },
                    minLength: {
                        value: 1,
                        message: 'The minimum length possible of the first name is 1 character'
                    }
                }).getHTML();
                message.vehiclePlates = validate.init(vehiclePlates, {
                    maxLength: {
                        value: 150,
                        message: 'The maximum length possible of the first name is 150 characters'
                    },
                    minLength: {
                        value: 1,
                        message: 'The minimum length possible of the first name is 1 character'
                    }
                }).getHTML();
                message.vehicleRegistration = validate.init(vehicleRegistration, {
                    maxLength: {
                        value: 150,
                        message: 'The maximum length possible of the first name is 150 characters'
                    },
                    minLength: {
                        value: 1,
                        message: 'The minimum length possible of the first name is 1 character'
                    }
                }).getHTML();
                message.vehicleColor = validate.init(vehicleColor, {
                    maxLength: {
                        value: 20,
                        message: 'The maximum length possible of the first name is 20 characters'
                    },
                    minLength: {
                        value: 1,
                        message: 'The minimum length possible of the first name is 1 character'
                    }
                }).getHTML();
                message.vehicleCab = validate.init(vehicleCab, {
                    maxLength: {
                        value: 3,
                        message: 'The maximum length possible of the first name is 3 characters'
                    },
                    minLength: {
                        value: 1,
                        message: 'The minimum length possible of the first name is 1 character'
                    },
                    integer: {
                        message: 'The vehicle cab can only be a only be a number'
                    }
                }).getHTML();
                message.vehicleType = validate.init(vehicleType, {
                    maxLength: {
                        value: 50,
                        message: 'The maximum length possible of the first name is 50 characters'
                    },
                    minLength: {
                        value: 1,
                        message: 'The minimum length possible of the first name is 1 character'
                    }
                }).getHTML();

                if (validate.status) {
                    try {
                        let vehicleId = await axios.post(process.env.REACT_APP_API_URL + 'api/admin/newVehicle', {
                            vehicleBrand, vehicleModel,
                            vehicleInsuranceData: vehicleInsurance, vehicleEmissionTest,
                            vehicleRegistration, vehicleTitle,
                            vehiclePlates, vehicleType,
                            color: vehicleColor, cab: vehicleCab
                        }, {
                            headers: authHeader()
                        });
                        vehicleId = vehicleId.data.insertId;

                        await axios.post(process.env.REACT_APP_API_URL + 'api/admin/userRole', {
                            userId: props.member.userId, roleId: 6
                        }, {
                            headers: authHeader()
                        });

                        await axios.post(process.env.REACT_APP_API_URL + 'api/admin/newDriver', {
                            memberId: props.member.memberId, howFindUs: 'admin',
                            type: 2, comments: 'admin', vehicleBrand, vehicleId
                        }, {
                            headers: authHeader()
                        });
                    } catch (error) {
                        const serverError = JSON.parse(error.request.responseText).message;
                        setErrorMessage({ serverError: <span>{serverError}</span> });
                        return;
                    }
                }
                break;
            }
            case '7': { // App Customer
                validate.status = true;
                message.paymentType = validate.init(paymentType, {
                    notEmpty: { message: 'A payment type must be selected' }
                }).getHTML();

                if (validate.status) {
                    await axios.post(process.env.REACT_APP_API_URL + 'api/admin/newCustomer', {
                        memberId: props.member.memberId, paymentTypeId: paymentType, customerTypeId: 2
                    }, {
                        headers: authHeader()
                    });

                    await axios.post(process.env.REACT_APP_API_URL + 'api/admin/userRole', {
                        userId: props.member.userId, roleId: 7
                    }, {
                        headers: authHeader()
                    });
                }
                break;
            }
        }
        setErrorMessage(message);
        if (validate.status) {
            clearControls();
            getMemberRoles();
            getCustomerRoles();
            props.updateTable();
        }
    }

    async function handleRemoveRole(roleId) {
        switch (roleId) {
            case 1: // Manager
            case 2: // Dashboard
            case 3: { // Operator
                await axios.put(process.env.REACT_APP_API_URL + `api/admin/toggleUser_role/${props.member.userId}/${roleId}`, {
                    statusId: 2
                }, {
                    headers: authHeader()
                });
                break;
            }
            case 8: { // ADA Senior Cab (B)
                await axios.put(process.env.REACT_APP_API_URL + `api/admin/toggleCustomer/${props.member.memberId}/3`, {
                    statusId: 2
                }, {
                    headers: authHeader()
                });
                break;
            }
            case 9: { // Senior Center (Y)
                await axios.put(process.env.REACT_APP_API_URL + `api/admin/toggleCustomer/${props.member.memberId}/4`, {
                    statusId: 2
                }, {
                    headers: authHeader()
                });
                break;
            }
            case 4: { // Customer
                await axios.put(process.env.REACT_APP_API_URL + `api/admin/toggleCustomer/${props.member.memberId}/1`, {
                    statusId: 2
                }, {
                    headers: authHeader()
                });
                break;
            }
            case 5: { // Driver
                await axios.put(process.env.REACT_APP_API_URL + `api/admin/toggleUser_role/${props.member.userId}/${roleId}`, {
                    statusId: 2
                }, {
                    headers: authHeader()
                });
                await axios.put(process.env.REACT_APP_API_URL + `api/admin/toggleDriver/${props.member.memberId}`, {
                    statusId: 2
                }, {
                    headers: authHeader()
                });
                break;
            }
            case 6: { // Associate
                await axios.put(process.env.REACT_APP_API_URL + `api/admin/toggleUser_role/${props.member.userId}/${roleId}`, {
                    statusId: 2
                }, {
                    headers: authHeader()
                });
                await axios.put(process.env.REACT_APP_API_URL + `api/admin/toggleAssociate/${props.member.memberId}`, {
                    statusId: 2
                }, {
                    headers: authHeader()
                });
                break;
            }
            case 7: { // App Customer
                await axios.put(process.env.REACT_APP_API_URL + `api/admin/toggleUser_role/${props.member.userId}/${roleId}`, {
                    statusId: 2
                }, {
                    headers: authHeader()
                });
                await axios.put(process.env.REACT_APP_API_URL + `api/admin/toggleCustomer/${props.member.memberId}/2`, {
                    statusId: 2
                }, {
                    headers: authHeader()
                });
                break;
            }
        }
        props.updateTable();
        getCustomerRoles();
        getMemberRoles();
    }

    function handleFocus(event) {
        const element = event.target.id,
            messages = errorMessage;
        messages[element] = null;
        setErrorMessage(messages);
    }

    function clearControls() {
        setVehicleBrand('');
        setVehicleModel('');
        setVehicleInsurance('');
        setVehicleEmissionTest('');
        setVehiclePlates('');
        setVehicleTitle('');
        setVehicleRegistration('');
        setVehicleColor('');
        setVehicleCab('');
        setVehicleType('');
        setPaymentType(0);
        setRole(0);
        setErrorMessage({});
    }

    return (
        <ReactModal isOpen={props.isOpen} style={{ overlay: { zIndex: '999' } }}>
            <div className="container">
                <div className="row">
                    <div className="col s11 left hide-on-small-only" style={{ fontVariant: 'small-caps', fontFamily: 'Raleway', fontSize: '2rem' }}>
                        Roles - {props.member.firstName} {props.member.lastName}
                    </div>
                    <div className="col s1 right">
                        <a onClick={() => {
                            clearControls();
                            props.onClose();
                        }} className="btn-floating waves-effect waves-light black white-text"><i className="material-icons">close</i></a>
                    </div>
                </div>
                <hr />
                <CustomRow>
                    <div className="col s8" style={{ marginBottom: '15px' }}>
                        <SelectControl value={role} inputEventHandler={setRole} focusEventHandler={() => null} id="role" name="role">
                            <option value="0" selected>Select role</option>
                            {rolesList.map((value) => {
                                switch (value.roleId) {
                                    case 8:
                                    case 9:
                                    case 4:
                                        return <option key={value.roleId} value={value.roleId}>{value.name}</option>
                                    default:
                                        return <option key={value.roleId} disabled={!props.member.userId} value={value.roleId}>{value.name}</option>
                                }
                            })}
                        </SelectControl>
                    </div>
                </CustomRow>
                <CustomRow>
                    <div className="col s8">
                        {memberRoles.map((value) => {
                            return <div key={value.roleId} className="chip">{value.name}<a href="#" onClick={() => handleRemoveRole(value.roleId)}><span style={{ marginLeft: '5px' }}>&#10006;</span></a></div>
                        })}
                        {customerRoles.map((value) => {
                            return <div key={value.roleId} className="chip">{value.name}<a href="#" onClick={() => handleRemoveRole(value.roleId)}><span style={{ marginLeft: '5px' }}>&#10006;</span></a></div>
                        })}
                    </div>
                </CustomRow>
                {role == 6 &&
                    <>
                        <CustomRow>
                            <div className="input-field col s8" style={{ marginTop: '35px' }}>
                                <TextControl name="vehicleBrand" id="vehicleBrand" value={vehicleBrand} inputEventHandler={setVehicleBrand} focusEventHandler={handleFocus} maxlength="50" />
                                <label htmlFor="vehicleBrand">Vehicle Brand</label>
                                <span data-assigned="vehicleBrand" className="helper-text red-text text-darken-1">{errorMessage.vehicleBrand}</span>
                            </div>
                        </CustomRow>
                        <CustomRow>
                            <div className="input-field col s8">
                                <TextControl name="vehicleModel" id="vehicleModel" value={vehicleModel} inputEventHandler={setVehicleModel} focusEventHandler={handleFocus} maxlength="4" />
                                <label htmlFor="vehicleModel">Vehicle Model</label>
                                <span data-assigned="vehicleModel" className="helper-text red-text text-darken-1">{errorMessage.vehicleModel}</span>
                            </div>
                        </CustomRow>
                        <CustomRow>
                            <div className="input-field col s8">
                                <TextControl name="vehicleInsurance" id="vehicleInsurance" value={vehicleInsurance} inputEventHandler={setVehicleInsurance} focusEventHandler={handleFocus} maxlength="150" />
                                <label htmlFor="vehicleInsurance">Vehicle Insurance</label>
                                <span data-assigned="vehicleInsurance" className="helper-text red-text text-darken-1">{errorMessage.vehicleInsurance}</span>
                            </div>
                        </CustomRow>
                        <CustomRow>
                            <div className="input-field col s8">
                                <TextControl name="vehicleEmissionTest" id="vehicleEmissionTest" value={vehicleEmissionTest} inputEventHandler={setVehicleEmissionTest} focusEventHandler={handleFocus} maxlength="150" />
                                <label htmlFor="vehicleEmissionTest">Vehicle Emission Test</label>
                                <span data-assigned="vehicleEmissionTest" className="helper-text red-text text-darken-1">{errorMessage.vehicleEmissionTest}</span>
                            </div>
                        </CustomRow>
                        <CustomRow>
                            <div className="input-field col s8">
                                <TextControl name="vehicleTitle" id="vehicleTitle" value={vehicleTitle} inputEventHandler={setVehicleTitle} focusEventHandler={handleFocus} maxlength="150" />
                                <label htmlFor="vehicleTitle">Vehicle Title</label>
                                <span data-assigned="vehicleTitle" className="helper-text red-text text-darken-1">{errorMessage.vehicleTitle}</span>
                            </div>
                        </CustomRow>
                        <CustomRow>
                            <div className="input-field col s8">
                                <TextControl name="vehiclePlates" id="vehiclePlates" value={vehiclePlates} inputEventHandler={setVehiclePlates} focusEventHandler={handleFocus} maxlength="150" />
                                <label htmlFor="vehiclePlates">Vehicle Plates</label>
                                <span data-assigned="vehiclePlates" className="helper-text red-text text-darken-1">{errorMessage.vehiclePlates}</span>
                            </div>
                        </CustomRow>
                        <CustomRow>
                            <div className="input-field col s8">
                                <TextControl name="vehicleRegistration" id="vehicleRegistration" value={vehicleRegistration} inputEventHandler={setVehicleRegistration} focusEventHandler={handleFocus} maxlength="150" />
                                <label htmlFor="vehicleRegistration">Vehicle Registration</label>
                                <span data-assigned="vehicleRegistration" className="helper-text red-text text-darken-1">{errorMessage.vehicleRegistration}</span>
                            </div>
                        </CustomRow>
                        <CustomRow>
                            <div className="input-field col s8">
                                <TextControl name="vehicleColor" id="vehicleColor" value={vehicleColor} inputEventHandler={setVehicleColor} focusEventHandler={handleFocus} maxlength="20" />
                                <label htmlFor="vehicleColor">Vehicle Color</label>
                                <span data-assigned="vehicleColor" className="helper-text red-text text-darken-1">{errorMessage.vehicleColor}</span>
                            </div>
                        </CustomRow>
                        <CustomRow>
                            <div className="input-field col s8">
                                <TextControl name="vehicleCab" id="vehicleCab" value={vehicleCab} inputEventHandler={setVehicleCab} focusEventHandler={handleFocus} maxlength="3" />
                                <label htmlFor="vehicleCab">Vehicle Cab</label>
                                <span data-assigned="vehicleCab" className="helper-text red-text text-darken-1">{errorMessage.vehicleCab}</span>
                            </div>
                        </CustomRow>
                        <CustomRow>
                            <div className="input-field col s8">
                                <TextControl name="vehicleType" id="vehicleType" value={vehicleType} inputEventHandler={setVehicleType} focusEventHandler={handleFocus} maxlength="50" />
                                <label htmlFor="vehicleType">Vehicle Type</label>
                                <span data-assigned="vehicleType" className="helper-text red-text text-darken-1">{errorMessage.vehicleType}</span>
                            </div>
                        </CustomRow>
                    </>
                }
                {(role == 4 || role == 7 || role == 8 || role == 9) &&
                    <>
                        <CustomRow>
                            <div className="input-field col s8">
                                <p>Payment Type</p>
                                <SelectControl id="nsew" value={paymentType} inputEventHandler={setPaymentType} focusEventHandler={handleFocus}>
                                    <option value="0">Select payment type...</option>
                                    {paymentTypes.map((value, index) => <option key={value.paymentTypeId} value={value.paymentTypeId}>{value.name}</option>)}
                                </SelectControl>
                                <span data-assigned="nsew" className="helper-text red-text text-darken-1">{errorMessage.paymentType}</span>
                            </div>
                        </CustomRow>
                    </>
                }
                <CustomRow>
                    <span className="col s8 center-align helper-text red-text text-darken-1">{errorMessage.serverError}</span>
                </CustomRow>
                <CustomRow>
                    <div className="input-field col s8">
                        <a onClick={handleAddRole} style={{ width: '100%', fontFamily: 'Raleway', fontSize: '1rem', backgroundColor: 'black', marginTop: '30px' }} className="waves-effect waves-light btn z-depth-0 white-text">ADD ROLE</a>
                    </div>
                </CustomRow>
            </div>
        </ReactModal>
    );
}

function CustomRow(props) {
    return (
        <div className={`row ${props.className}`}>
            <div className="col s2"></div>
            {props.children}
            <div className="col s2"></div>
        </div>
    );
}

export default RolesModal;