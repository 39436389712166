import React, { useEffect, useRef } from "react";
import Chart from 'chart.js';
import { commonChartOptions } from '../helpers/common-chart-options';

function ValueChart(props) {
    return (
        <div className={`db-card ${props.cardType} z-depth-2 animate fadeLeft delay-${props.animationDelay}`}>
            <div className="header">
                <div className="card-title">
                    <i className="material-icons">{props.icon}</i> <span className="small-caps">{props.title}</span>
                </div>
            </div>
            <div className="main">
                <div className="value">
                    {props.value}
                </div>
                <div className="extra">
                    Currently available for service
                </div>
            </div>
        </div>
    );
}

function BarChart(props) {

    const canvasRef = useRef();

    useEffect(() => {
        let chartValues = [];
        if (props.chartValues)
            chartValues = props.chartValues;

        new Chart(canvasRef.current, {
            type: 'bar',
            options: commonChartOptions,
            data: {
                labels: chartValues.map(() => ''),
                datasets: [{
                    data: chartValues,
                    backgroundColor: 'rgba(255, 255, 255, 0.7)'
                }]
            }
        });
    }, [props.chartValues]);

    return (
        <div className={`db-card ${props.cardType} z-depth-2 animate fadeLeft delay-${props.animationDelay}`}>
            <div className="header">
                <div className="card-controls">
                    <a className='dropdown-trigger' href='#' data-target='dropdown-customers'><i className="white-text material-icons">more_vert</i></a>
                </div>
                <div className="card-title">
                    <i className="material-icons">{props.icon}</i> <span className="small-caps">{props.title}</span>
                </div>
            </div>
            <div className="main">
                <div className="value">
                    {props.value}
                </div>
                <div className="extra">
                    {props.comparison}
                </div>
            </div>
            <div className="footer">
                <div className="chart-container">
                    <canvas ref={canvasRef}></canvas>
                </div>
            </div>

            {/* Dropdown */}
            <ul id={`dropdown-${props.cardType}`} className='dropdown-content center-align'>
                <li><a className="black-text" style={{ fontSize: '0.8rem' }} href="#">Filter by week</a></li>
                <li><a className="black-text" style={{ fontSize: '0.8rem' }} href="#">Filter by month</a></li>
                <li className="divider black-text" tabindex="-1"></li>
                <li><a className="black-text" style={{ fontSize: '0.8rem' }} href="#">Export...</a></li>
            </ul>
        </div>
    );
}

function LineChart(props) {

    const canvasRef = useRef();

    useEffect(() => {
        let chartValues = [];
        if (props.chartValues)
            chartValues = props.chartValues;

        new Chart(canvasRef.current, {
            type: 'line',
            options: commonChartOptions,
            data: {
                labels: chartValues.map((value) => `Day ${value.day}`),
                datasets: [{
                    data: chartValues.map((value) => value.total),
                    backgroundColor: 'rgba(255, 255, 255, 0.7)'
                }]
            }
        });
    }, [props.chartValues]);

    return (
        <div className={`db-card ${props.cardType} z-depth-2 animate fadeLeft delay-${props.animationDelay}`}>
            <div className="header">
                <div className="card-controls">
                    <a className='dropdown-trigger' href='#' data-target='dropdown-customers'><i className="white-text material-icons">more_vert</i></a>
                </div>
                <div className="card-title">
                    <i className="material-icons">{props.icon}</i> <span className="small-caps">{props.title}</span>
                </div>
            </div>
            <div className="footer">
                <div className="chart-container">
                    <canvas ref={canvasRef}></canvas>
                </div>
            </div>

            {/* Dropdown */}
            <ul id={`dropdown-${props.cardType}`} className='dropdown-content center-align'>
                <li><a className="black-text" style={{ fontSize: '0.8rem' }} href="#">Filter by week</a></li>
                <li><a className="black-text" style={{ fontSize: '0.8rem' }} href="#">Filter by month</a></li>
                <li className="divider black-text" tabindex="-1"></li>
                <li><a className="black-text" style={{ fontSize: '0.8rem' }} href="#">Export...</a></li>
            </ul>
        </div>
    );
}

function ScoresTable(props) {

    const getTableRows = () => {
        return props.values.map((value, index) => (
            <tr key={index}>
                <td>{index + 1}</td>
                <td>{value.driver}</td>
                <td>{value.scoreLevel}</td>
            </tr>
        ));
    };

    return (
        <div className={`db-card ${props.cardType} z-depth-2 animate fadeLeft delay-${props.animationDelay}`}>
            <div className="header">
                <div className="card-controls">
                    <a className='dropdown-trigger' href='#' data-target='dropdown-customers'><i className="white-text material-icons">more_vert</i></a>
                </div>
                <div className="card-title">
                    <i className="material-icons">{props.icon}</i> <span className="small-caps">{props.title}</span>
                </div>
            </div>
            <div className="footer">
                <table>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Driver</th>
                            <th>Score</th>
                        </tr>
                    </thead>
                    <tbody>
                        {getTableRows()}
                    </tbody>
                </table>
            </div>

            {/* Dropdown */}
            <ul id={`dropdown-${props.cardType}`} className='dropdown-content center-align'>
                <li><a className="black-text" style={{ fontSize: '0.8rem' }} href="#">Filter by week</a></li>
                <li><a className="black-text" style={{ fontSize: '0.8rem' }} href="#">Filter by month</a></li>
                <li className="divider black-text" tabindex="-1"></li>
                <li><a className="black-text" style={{ fontSize: '0.8rem' }} href="#">Export...</a></li>
            </ul>
        </div>
    );
}

export { ValueChart, BarChart, LineChart, ScoresTable };