export const CREATE_NEW_SERVICE_START = "CREATE_NEW_SERVICE_START";
export const CREATE_NEW_SERVICE_SUCCESS = "CREATE_NEW_SERVICE_SUCCESS";
export const CREATE_NEW_SERVICE_FAIL = "CREATE_NEW_SERVICE_FAIL";

export const GET_ACTIVATED_SERVICES_START = "GET_ACTIVATED_SERVICES_START";
export const GET_ACTIVATED_SERVICES_SUCCESS = "GET_ACTIVATED_SERVICES_SUCCESS";
export const GET_ACTIVATED_SERVICES_FAIL = "GET_ACTIVATED_SERVICES_FAIL";

export const GET_CUSTOMER_BY_PHONE_START = "GET_CUSTOMER_BY_PHONE_START";
export const GET_CUSTOMER_BY_PHONE_SUCCESS = "GET_CUSTOMER_BY_PHONE_SUCCESS";
export const GET_CUSTOMER_BY_PHONE_FAIL = "GET_CUSTOMER_BY_PHONE_FAIL";


export const UPDATE_FIELD_CUSTOMER = "UPDATE_FIELD_CUSTOMER";

export const LOAD_NEW_SERVICE_START = "LOAD_NEW_SERVICE_START";
export const LOAD_NEW_SERVICE_SUCCESS = "LOAD_NEW_SERVICE_SUCCESS";
export const LOAD_NEW_SERVICE_FAIL = "LOAD_NEW_SERVICE_FAIL";

export const GET_AVAILABLES_START = "GET_AVAILABLES_START";
export const GET_AVAILABLES_SUCCESS = "GET_AVAILABLES_SUCCESS";
export const GET_AVAILABLES_FAIL = "GET_AVAILABLES_FAIL";

export const START_GET_COUNTIES = "START_GET_COUNTIES";
export const SUCCESS_GET_COUNTIES = "SUCCESS_GET_COUNTIES";
export const FAIL_GET_COUNTIES = "FAIL_GET_COUNTIES";

export const START_GET_ORIGIN_CITIES = "START_GET_ORIGIN_CITIES";
export const SUCCESS_GET_ORIGIN_CITIES = "SUCCESS_GET_ORIGIN_CITIES";
export const FAIL_GET_ORIGIN_CITIES = "FAIL_GET_ORIGIN_CITIES";

export const START_GET_DESTINATION_CITIES = "START_GET_DESTINATION_CITIES";
export const SUCCESS_GET_DESTINATION_CITIES = "SUCCESS_GET_DESTINATION_CITIES";
export const FAIL_GET_DESTINATION_CITIES = "FAIL_GET_DESTINATION_CITIES";

export const CALCULATE_RATE_START = "CALCULATE_RATE_START";
export const CALCULATE_RATE_SUCCESS = "CALCULATE_RATE_SUCCESS";
export const CALCULATE_RATE_FAIL = "CALCULATE_RATE_FAIL";

export const CREATE_NEW_CUSTOMER_START = "CREATE_NEW_CUSTOMER_START";
export const CREATE_NEW_CUSTOMER_SUCCESS = "CREATE_NEW_CUSTOMER_SUCCESS";
export const CREATE_NEW_CUSTOMER_FAIL = "CREATE_NEW_CUSTOMER_FAIL";

export const GET_TOPIC_COMMENTS_START = "GET_TOPIC_COMMENTS_START";
export const GET_TOPIC_COMMENTS_SUCCESS = "GET_TOPIC_COMMENTS_SUCCESS";
export const GET_TOPIC_COMMENTS_FAIL = "GET_TOPIC_COMMENTS_FAIL";

export const NEW_EMPTY_SERVICE_START = "NEW_EMPTY_SERVICE_START";
export const NEW_EMPTY_SERVICE_SUCCESS = "NEW_EMPTY_SERVICE_SUCCESS";
export const NEW_EMPTY_SERVICE_FAIL = "NEW_EMPTY_SERVICE_FAIL";

export const GET_VEHICLES_START = "GET_VEHICLES_START";
export const GET_VEHICLES_SUCCESS = "GET_VEHICLES_SUCCESS";
export const GET_VEHICLES_FAIL = "GET_VEHICLES_FAIL";

export const GET_BLACKLIST_START = "GET_BLACKLIST_START";
export const GET_BLACKLIST_SUCCESS = "GET_BLACKLIST_SUCCESS";
export const GET_BLACKLIST_FAIL = "GET_BLACKLIST_FAIL";

export const CHANGE_STATUS_SERVICES_START = "CHANGE_STATUS_SERVICES_START";

export const GET_CALLNUMBERSEARCH_START = "GET_CALLNUMBERSEARCH_START";
export const GET_CALLNUMBERSEARCH_SUCCESS = "GET_CALLNUMBERSEARCH_SUCCESS";
export const GET_CALLNUMBERSEARCH_FAIL = "GET_CALLNUMBERSEARCH_FAIL";

export const GET_VEHICLESLASTCALL_START = "GET_VEHICLESLASTCALL_START";
export const GET_VEHICLESLASTCALL_SUCCESS = "GET_VEHICLESLASTCALL_SUCCESS";
export const GET_VEHICLESLASTCALL_FAIL = "GET_VEHICLESLASTCALL_FAIL";

export const GET_ALLACTIVETAXIS_START = "GET_ALLACTIVETAXIS_START";
export const GET_ALLACTIVETAXIS_SUCCESS = "GET_ALLACTIVETAXIS_SUCCESS";
export const GET_ALLACTIVETAXIS_FAIL = "GET_ALLACTIVETAXIS_FAIL";

export const GET_LASTTENSERVECES_START = "GET_LASTTENSERVECES_START";
export const GET_LASTTENSERVECES_SUCCESS = "GET_LASTTENSERVECES_SUCCESS";
export const GET_LASTTENSERVECES_FAIL = "GET_LASTTENSERVECES_FAIL";

export const GET_ALLNUMBERSEARCH_START = "GET_ALLNUMBERSEARCH_START";
export const GET_ALLNUMBERSEARCH_SUCCESS = "GET_ALLNUMBERSEARCH_SUCCESS";
export const GET_ALLNUMBERSEARCH_FAIL = "GET_ALLNUMBERSEARCH_FAIL";

export const UPDATE_FILTER_VALUE = "UPDATE_FILTER_VALUE";

export const ADD_BLACKLIST_START = "ADD_BLACKLIST_START";
export const ADD_BLACKLIST_SUCCESS = "ADD_BLACKLIST_SUCCESS";
export const ADD_BLACKLIST_FAIL = "ADD_BLACKLIST_FAIL";

export const GET_SERVICE_BY_ID_START = "GET_SERVICE_BY_ID_START";
export const GET_SERVICE_BY_ID_SUCCESS = "GET_SERVICE_BY_ID_SUCCESS";
export const GET_SERVICE_BY_ID_FAIL = "GET_SERVICE_BY_ID_FAIL";

export const ASSIGN_DRIVER_START = "ASSIGN_DRIVER_START";
export const ASSIGN_DRIVER_SUCCESS = "ASSIGN_DRIVER_SUCCESS";
export const ASSIGN_DRIVER_FAIL = "ASSIGN_DRIVER_FAIL";

export const CANCEL_SERVICE_START = "CANCEL_SERVICE_START";
export const CANCEL_SERVICE_SUCCESS = "CANCEL_SERVICE_SUCCESS";
export const CANCEL_SERVICE_FAIL = "CANCEL_SERVICE_FAIL";

export const GET_DRIVERS_START = "GET_DRIVERS_START";
export const GET_DRIVERS_SUCCESS = "GET_DRIVERS_SUCCESS";
export const GET_DRIVERS_FAIL = "GET_DRIVERS_FAIL";

export const GET_BLACKLISTR_START = "GET_BLACKLISTR_START";
export const GET_BLACKLISTR_SUCCESS = "GET_BLACKLISTR_SUCCESS";
export const GET_BLACKLISTR_FAIL = "GET_BLACKLISTR_FAIL";

export const ACTIVEDRIVER_START = "ACTIVEDRIVER_START";
export const ACTIVEDRIVER_SUCCESS = "ACTIVEDRIVER_SUCCESS";
export const ACTIVEDRIVER_FAIL = "ACTIVEDRIVER_FAIL";

export const GET_DRIVERINFO_START = "GET_DRIVERINFO_START";
export const GET_DRIVERINFO_SUCCESS = "GET_DRIVERINFO_SUCCESS";
export const GET_DRIVERINFO_FAIL = "GET_DRIVERINFO_FAIL";

export const GET_MESSAGES_START = "GET_MESSAGES_START";
export const GET_MESSAGES_SUCCESS = "GET_MESSAGES_SUCCESS";
export const GET_MESSAGES_FAIL = "GET_MESSAGES_FAIL";

export const UPDATE_PAYMENTTYPE_START = "UPDATE_PAYMENTTYPE_START";
export const UPDATE_PAYMENTTYPE_SUCCESS = "UPDATE_PAYMENTTYPE_SUCCESS";
export const UPDATE_PAYMENTTYPE_FAIL = "UPDATE_PAYMENTTYPE_FAIL";


export const UPDATE_RESERVATION_PAYMENTTYPE_START = "UPDATE_RESERVATION_PAYMENTTYPE_START";
export const UPDATE_RESERVATION_PAYMENTTYPE_SUCCESS = "UPDATE_RESERVATION_PAYMENTTYPE_SUCCESS";
export const UPDATE_RESERVATION_PAYMENTTYPE_FAIL = "UPDATE_RESERVATION_PAYMENTTYPE_FAIL";

export const UPDATE_SERVICEORIGINLOCATION_START = "UPDATE_SERVICEORIGINLOCATION_START";
export const UPDATE_SERVICEORIGINLOCATION_SUCCESS = "UPDATE_SERVICEORIGINLOCATION_SUCCESS";
export const UPDATE_SERVICEORIGINLOCATION_FAIL = "UPDATE_SERVICEORIGINLOCATION_FAIL";

export const UPDATE_RESERVATIONORIGINLOCATION_START = "UPDATE_RESERVATIONORIGINLOCATION_START";
export const UPDATE_RESERVATIONORIGINLOCATION_SUCCESS = "UPDATE_RESERVATIONORIGINLOCATION_SUCCESS";
export const UPDATE_RESERVATIONORIGINLOCATION_FAIL = "UPDATE_RESERVATIONORIGINLOCATION_FAIL";

export const UPDATE_SERVICEDESTINATIONLOCATION_START = "UPDATE_SERVICEDESTINATIONLOCATION_START";
export const UPDATE_SERVICEDESTINATIONLOCATION_SUCCESS = "UPDATE_SERVICELOCATION_SUCCESS";
export const UPDATE_SERVICEDESTINATIONLOCATION_FAIL = "UPDATE_SERVICELOCATION_FAIL";

export const UPDATE_RESERVATIONDESTINATIONLOCATION_START = "UPDATE_RESERVATIONDESTINATIONLOCATION_START";
export const UPDATE_RESERVATIONDESTINATIONLOCATION_SUCCESS = "UPDATE_RESERVATIONDESTINATIONLOCATION_SUCCESS";
export const UPDATE_RESERVATIONDESTINATIONLOCATION_FAIL = "UPDATE_RESERVATIONDESTINATIONLOCATION_FAIL";

export const CLEAR_NEW_SERVICE = "CLEAR_NEW_SERVICE";

export const NEW_RESERVATION_START = "NEW_RESERVATION_START";
export const NEW_RESERVATION_SUCCESS = "NEW_RESERVATION_SUCCESS";
export const NEW_RESERVATION_FAIL = "NEW_RESERVATION_FAIL";

export const NEW_SERVICE_START = "NEW_SERVICE_START";
export const NEW_SERVICE_SUCCESS = "NEW_SERVICE_SUCCESS";
export const NEW_SERVICE_FAIL = "NEW_SERVICE_FAIL";

export const UPDATE_RESERVATION_START = "UPDATE_RESERVATION_START";
export const UPDATE_RESERVATION_SUCCESS = "UPDATE_RESERVATION_SUCCESS";
export const UPDATE_RESERVATION_FAIL = "UPDATE_RESERVATION_FAIL";

export const GET_CUSTOMERTYPES_START = "GET_CUSTOMERTYPES_START";
export const GET_CUSTOMERTYPES_SUCCESS = "GET_CUSTOMERTYPES_SUCCESS";
export const GET_CUSTOMERTYPES_FAIL = "GET_CUSTOMERTYPES_FAIL";

export const GET_PAYMENTYPES_START = "GET_PAYMENTYPES_START";
export const GET_PAYMENTYPES_SUCCESS = "GET_PAYMENTYPES_SUCCESS";
export const GET_PAYMENTYPES_FAIL = "GET_PAYMENTYPES_FAIL";

export const GET_SERVICELASTLOCATION_START = "GET_SERVICELASTLOCATION_START";
export const GET_SERVICELASTLOCATION_SUCCESS = "GET_SERVICELASTLOCATION_SUCCESS";
export const GET_SERVICELASTLOCATION_FAIL = "GET_SERVICELASTLOCATION_FAIL";

export const CANCEL_RESERVATION_START = "CANCEL_RESERVATION_START";
export const CANCEL_RESERVATION_SUCCESS = "CANCEL_RESERVATION_SUCCESS";
export const CANCEL_RESERVATION_FAIL = "CANCEL_RESERVATION_FAIL";

export const SEND_MESSAGE_START = "SEND_MESSAGE_START";
export const SEND_MESSAGE_SUCCESS = "SEND_MESSAGE_SUCCESS";
export const SEND_MESSAGE_FAIL = "SEND_MESSAGE_FAIL";

export const DELETE_MESSAGE_START = "DELETE_MESSAGE_START";
export const DELETE_MESSAGE_SUCCESS = "DELETE_MESSAGE_SUCCESS";
export const DELETE_MESSAGE_FAIL = "DELETE_MESSAGE_FAIL";

export const SEND_MESSAGE_START2 = "SEND_MESSAGE_START2";
export const SEND_MESSAGE_SUCCESS2 = "SEND_MESSAGE_SUCCESS2";
export const SEND_MESSAGE_FAIL2 = "SEND_MESSAGE_FAIL2";

export const RESPONSE_MESSAGE_START = "RESPONSE_MESSAGE_START";
export const RESPONSE_MESSAGE_SUCCESS = "RESPONSE_MESSAGE_SUCCESS";
export const RESPONSE_MESSAGE_FAIL = "RESPONSE_MESSAGE_FAIL";

export const ACK_MESSAGE_START = "ACK_MESSAGE_START";
export const ACK_MESSAGE_SUCCESS = "ACK_MESSAGE_SUCCESS";
export const ACK_MESSAGE_FAIL = "ACK_MESSAGE_FAIL";

export const GET_INCIDENTS_START = "GET_INCIDENTS_START";
export const GET_INCIDENTS_SUCCESS = "GET_INCIDENTS_SUCCESS";
export const GET_INCIDENTS_FAIL = "GET_INCIDENTS_FAIL";

export const GET_LPVEHICLE_START = "GET_LPVEHICLE_START";
export const GET_LPVEHICLE_SUCCESS = "GET_LPVEHICLE_SUCCESS";
export const GET_LPVEHICLE_FAIL = "GET_LPVEHICLE_FAIL";

export const GET_LASTORIGIN_START = "GET_LASTORIGIN_START";
export const GET_LASTORIGIN_SUCCESS = "GET_LASTORIGIN_SUCCESS";
export const GET_LASTORIGIN_FAIL = "GET_LASTORIGIN_FAIL";

export const GET_LASTODESTINATION_START = "GET_LASTODESTINATION_START";
export const GET_LASTODESTINATION_SUCCESS = "GET_LASTODESTINATION_SUCCESS";
export const GET_LASTODESTINATION_FAIL = "GET_LASTODESTINATION_FAIL";

