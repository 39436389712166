import React, { useState, useEffect, useRef, Item } from "react";
import { useDispatch, useSelector } from "react-redux";
import {getVehicleLastPosition, getIncident,updateAck, responsemessage, removemessage, sendmessage,sendmessage2, cancelReservation, getDriverLastPosition, getCustomerTypes,getPaymentTypes, getMessages, getDriverInfo, activeDriver, getBlackListReasons, getavailablesDrivers, cancelService,assignDriver, getServiceInfo, addToBackList, updateFilterValue, getAllActiveTaxis, getLastTenServices, getAllNumberSearch, createNewService, getActivatedServices, getVehicles,getBlacklist, loadNewService,getAvailables } from "../../actions/operator/dashboard";
import NewService from "./newservice";
import EditService from "./editservice";
import socketIOClient from "socket.io-client";
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import { Button, Select } from "react-materialize"
import CreatableSelect from 'react-select/creatable';
import swal from 'sweetalert';
import GoogleMapReact from "google-map-react";
import Geocode from "react-geocode";
import moment from 'moment';
import Moment from 'react-moment';
import { map } from "leaflet";
import { withScriptjs } from "react-google-maps";
import { useLoadScript, LoadScript, GoogleMap, useJsApiLoader, DirectionsRenderer,DirectionsService, Marker } from '@react-google-maps/api';

//import Map from './Map';

const OperatorDashboard = () => {
    const dispatch = useDispatch();

    const [showNewService, setshowNewService] = useState(false);
    const [showEditService, setshowEditService] = useState(false);
    const [showVLCModal, setShowVLCModal] = useState(false);
    const [showCNSModal, setShowCNSModal] = useState(false);
    const [showBlackListModal, setShowBlackListModal] = useState(false);
    const [showMesssagesModal, setShowMesssagesModal] = useState(false);
    const [showMapModal, setShowMapModal] = useState(false);
    const [showServiceModal, setShowServiceModal] = useState(false);
    const [showAssingCabModal, setShowAssingCabModal] = useState(false);
    const [showOfferModal, setShowOfferModal] = useState(false);
    const [showCallDriverModal, setShowCallDriverModal] = useState(false);
    const [showCancelModal, setShowCancelModal] = useState(false);
    const [showIncidentModal, setShowIncidentModal] = useState(false);
    const [showAllDrivers, setShowAllDrivers] = useState(false);

    

    const [blacklistReason, setBlacklistReason] = useState("");
    const [showBlacklistInfoModal, setShowBlacklistInfoModal] = useState(false);
    const [blacklistSelected,setBlacklistSelected] = useState(null);
    const [showDriversInfoModal,setShowDriversInfoModal] = useState(null);
    const [vehicleId,setVehicleId] = useState(null);
    
    const [mapOrigin, setMapOrigin] = useState(null);
    const [mapDestination, setMapDestination] = useState(null);
    const [directionsService1, setDirectionsService1] = useState(null);
    const [directionsDisplay1, setDirectionsDisplay1] = useState(null);
    const [map1, setMap1] = useState(null);
    const [directionsResponse1, setDirectionsResponse1] = useState(null);
    const [statusIdDriver, setStatusIdDriver] = useState(null);


    const [messageSelected, setMessageSelected] = useState(null);
    const [messageText, setMessageText] = useState("");

    const [messageText2, setMessageText2] = useState("");
    const [showMesssagesModal2, setShowMesssagesModal2] = useState(false);
    const [driverMessage, setDriverMessage] = useState(null);
    
    const [vehicleLastCallsCab, setVehicleLastCallsCab] = useState(null);
    
    

    const [places , setPlaces] = useState([]);
    const [center , serCenter] = useState({lat: 33.5722,lng: -112.088});
    const [zoom , setZoom] = useState(11);

    const [cancelReason , setCancelReason] = useState(null);
    const [currentService , setCurrentService] = useState({});
    
    const [selectedCenter , setSelectedCenter] = useState({lat: 33.5722,lng: -112.088});
    const [originCenter , setOriginCenter] = useState({lat: 33.5722,lng: -112.088});
    
    const { lpvehicle,incident, lastServiceLocations, messages, driverSelectedInfo, activedDrivers, availableDrivers, servicesAllInfo, operator, services,vehicles, blacklist, filters, vehuckle, vehicle_last_call, call_number_search,availables } = useSelector(state => state.operator);
    const { user: currentUser } = useSelector((state) => state.auth);
	const { isLoggedIn } = useSelector(state => state.auth);

    const [driverPosition, setDriverPosition] = useState({})

    /* Web Socket */
    const ENDPOINT = "http://localhost:3001/operator";

    
    const [directions , setDirections] = useState(null);

    const countRef = useRef(statusIdDriver);
    //const [mapLoader , setMapLoader] = useState(null);
    //const MapLoader = withScriptjs(Map);
    /*useEffect(() => {
        console.log(currentUser);
        const socket = socketIOClient(ENDPOINT,{query: {token:currentUser.token}});
        socket.on("operator:getServices", data => {
            
            console.log(data);
        });
        return () => socket.disconnect();
    }, []);*/
    
    /*useEffect(() => {
        dispatch(getCustomerTypes());
        dispatch(getPaymentTypes());
        dispatch(getActivatedServices(0,0));
        dispatch(getVehicles(vehicles.statusId));
        dispatch(getBlacklist());
        dispatch(getAllActiveTaxis(statusIdDriver));
        dispatch(getMessages());
    },[]);*/

    useEffect(() => {
        dispatch(getCustomerTypes());
        dispatch(getPaymentTypes());

        /*dispatch(getActivatedServices(0,0));
        dispatch(getVehicles(vehicles.statusId));
        dispatch(getBlacklist());
        //dispatch(getAllActiveTaxis(statusIdDriver));
        dispatch(getAllActiveTaxis(null));
        dispatch(getMessages());*/
        
        var interval = setInterval(() => {
                updateServiceTable()
                dispatch(getVehicles(vehicles.statusId));
                dispatch(getBlacklist());
                //dispatch(getAllActiveTaxis(countRef.current));
                dispatch(getAllActiveTaxis(null));
                dispatch(getMessages());
        }, 2000);
        return () => clearInterval(interval);
      }, []);

    useEffect(() => {
        countRef.current = statusIdDriver;
    },[statusIdDriver]);

    useEffect(() => {
        if(call_number_search != null && call_number_search.items != null){
            console.log("call_number_search.items.length",call_number_search.items.length);
            if(call_number_search.items.length > 0){
                console.log("setShowCNSModal");
                setShowCNSModal(true);
            }else{
                swal("Number not found.");
            }
        }
    },[call_number_search.items]);

    useEffect(() => {
        if( filters && 
            filters.vehicles && 
            filters.vehicles.items && 
            filters.vehicles.items.length > 0 &&
            vehicleLastCallsCab == null){
            setVehicleLastCallsCab(filters.vehicles.items[0]);
        }
    },[filters.vehicles.items]);

    const createNewServiceButton = (element) => {
        setshowNewService(true);
    }
    const closeNewService = (element) => {
        setshowNewService(false);
    }
    const closeEditService = (element) => {
        setshowEditService(false);
    }

    const chunk = (array, size) => {
        const chunked_arr = [];
        for (let i = 0; i < array.length; i++) {
          const last = chunked_arr[chunked_arr.length - 1];
          if (!last || last.length === size) {
            chunked_arr.push([array[i]]);
          } else {
            last.push(array[i]);
          }
        }
        return chunked_arr;
    }

    const loadAssign = (map, maps) => {
        var bounds = new maps.LatLngBounds();
        if (availableDrivers.items.length>0) { 
            for (var i = 0; i < availableDrivers.items.length; i++) {
                if(parseFloat(lastServiceLocations.item.lastLatitude) != NaN && parseFloat(lastServiceLocations.item.lastLongitude) != NaN){
                    bounds.extend({lat:parseFloat(availableDrivers.items[i].lastLatitude),lng:parseFloat(availableDrivers.items[i].lastLongitude)});
                }
            } 
            map.fitBounds(bounds);
        }
        
    }

    const loadAllDrivers = (map, maps) => {
        var bounds = new maps.LatLngBounds();
        if (activedDrivers.items.length>0) { 
            for (var i = 0; i < activedDrivers.items.length; i++) {
                if(activedDrivers.items[i].lastLatitude != null && activedDrivers.items[i].lastLongitude != null)
                    bounds.extend({lat:parseFloat(activedDrivers.items[i].lastLatitude),lng:parseFloat(activedDrivers.items[i].lastLongitude)});
            } 
            map.fitBounds(bounds);
        }
        
    }

    const handleApiLoaded = (map, maps) => {

    }
    
    const updateServiceTable = () => {
        var f1 = filters.status.value.value;
        var f2 = filters.customerType.value.value;

        if(f1 == 0 && f2 == 0){
            // case 0
            dispatch(getActivatedServices(0,0));
        }else if(f1 != 0 && f2 == 0){
            // case 10
            dispatch(getActivatedServices(10,f1));
        }else if(f2 != 0){
            // case 20
            dispatch(updateFilterValue('status',filters.status.items[0]));
            dispatch(getActivatedServices(20,f2));
        }
    };
    const selectDriver = (DriverId,VehicleId) => {
        dispatch(assignDriver(VehicleId,DriverId,currentService.recordId)).then((val) => {
            swal("Driver Assigned successful.");
            setShowAssingCabModal(false);
            dispatch(getActivatedServices(0,0));
        }).catch(() => {
            swal("Error to assign driver.");
        });

    }
    const Vehicle = ({ text }) => {
        return <i  class="material-icons">local_taxi</i>
    };
    const Origin = ({ text }) => {
        return <i class="material-icons">my_location</i>
    };
    
    useEffect(() => {
        if(currentService != null){
            console.log(currentService);
            if(currentService.recordType == 'reservation'){
                dispatch(getServiceInfo(2,currentService.recordId));
            }else{
                dispatch(getServiceInfo(1,currentService.recordId));
            }
        }

    },[currentService]);

    useEffect(() => {
        if(servicesAllInfo.item != null){
            action();
        }
    },[servicesAllInfo.item]);

    useEffect(() => {
        if(showDriversInfoModal==true){
            dispatch(getVehicleLastPosition(vehicleId));
            const interval = setInterval(() => {
                dispatch(getVehicleLastPosition(vehicleId));
            }, 4000);
            return () => clearInterval(interval);
        }
      }, [showDriversInfoModal]);

    useEffect(() => {
        if(servicesAllInfo.item != null && showMapModal==true && servicesAllInfo.item.serviceId != undefined){
            dispatch(getDriverLastPosition(servicesAllInfo.item.serviceId));
            const interval = setInterval(() => {
                dispatch(getDriverLastPosition(servicesAllInfo.item.serviceId));
            }, 4000);
            return () => clearInterval(interval);
        }
      }, [showMapModal]);

    useEffect(() => {
        if(servicesAllInfo.item && lastServiceLocations.item){
            switch(servicesAllInfo.item.status){
                case 'Assigned':
                    if(parseFloat(lastServiceLocations.item.lastLatitude) != NaN && parseFloat(lastServiceLocations.item.lastLongitude) != NaN){
                        setMapOrigin({lat:parseFloat(lastServiceLocations.item.lastLatitude),lng:parseFloat(lastServiceLocations.item.lastLongitude)});
                    }
                    setMapDestination({lat:parseFloat(servicesAllInfo.item.originLatitude),lng:parseFloat(servicesAllInfo.item.originLongitude)});
                break;
                default:
                    setMapDestination({lat:parseFloat(servicesAllInfo.item.destinationLatitude),lng:parseFloat(servicesAllInfo.item.destinationLongitude)});
                    setMapOrigin({lat:parseFloat(servicesAllInfo.item.originLatitude),lng:parseFloat(servicesAllInfo.item.originLongitude)});
                break;
            }
        }

    }, [lastServiceLocations.item]);


    const action = () => {
        switch(currentService.action){
            case 1:
                if(Object.keys(servicesAllInfo.item).length == 0 ){
                    swal("Error to load the service.");
                    return;
                }
                setshowEditService(true);
            break;
            case 2:
                if(Object.keys(servicesAllInfo.item).length == 0 ){
                    swal("Error to load the service.");
                    return;
                }

                console.log("servicesAllInfo.item.originLatitude",servicesAllInfo.item.originLatitude);
                console.log("servicesAllInfo.item.originLongitude",servicesAllInfo.item.originLongitude);
                console.log("servicesAllInfo.item.destinationLatitude",servicesAllInfo.item.destinationLatitude);
                console.log("servicesAllInfo.item.destinationLongitude",servicesAllInfo.item.destinationLongitude);

                let MapO = false;
                let MapD = false;

                if(servicesAllInfo.item.originLatitude != null && 
                    servicesAllInfo.item.originLongitude != null) {
                    console.log("originLatitude true");
                    setMapOrigin({lat:parseFloat(servicesAllInfo.item.originLatitude),lng:parseFloat(servicesAllInfo.item.originLongitude)});
                    MapO = true;
                }else{
                    console.log("originLatitude false");
                    setMapOrigin(null);
                }
                    
                if(servicesAllInfo.item.destinationLatitude != null && servicesAllInfo.item.destinationLongitude != null){
                    setMapDestination({lat:parseFloat(servicesAllInfo.item.destinationLatitude),lng:parseFloat(servicesAllInfo.item.destinationLongitude)});
                    MapD = true;
                }else{
                    setMapDestination(null);
                } 
                
                console.log("servicesAllInfo",servicesAllInfo);
                console.log("mapOrigin",mapOrigin);
                console.log("mapDestination",mapDestination);
                
                if(MapO && MapD){
                    setShowMapModal(true);
                }else{
                    swal("Origin location not avaliable.");
                    setShowMapModal(false);
                }

            break;
            case 3:
                if(Object.keys(servicesAllInfo.item).length == 0 ){
                    swal("Error to load the service.");
                    return;
                }
                if(servicesAllInfo.item != null){
                    if(servicesAllInfo.item.cab == null){
                        if(servicesAllInfo.item.originLatitude && servicesAllInfo.item.originLongitude){
                            setSelectedCenter({lat: parseFloat(servicesAllInfo.item.originLatitude),lng: parseFloat(servicesAllInfo.item.originLongitude)});
                        }else{
                            swal("Origin location not avaliable.");
                        }
                        if(servicesAllInfo.item.originLatitude && servicesAllInfo.item.originLongitude){
                            setOriginCenter({lat: parseFloat(servicesAllInfo.item.originLatitude),lng: parseFloat(servicesAllInfo.item.originLongitude)});
                        }else{
                            swal("Origin location not avaliable.");
                        }
                        dispatch(getavailablesDrivers(currentService.recordId));
                        setShowAssingCabModal(true);
                    }else{
                        swal("There is currently a preselected driver.");
                    }
                }
            break;
            case 4:
                if(Object.keys(servicesAllInfo.item).length == 0 ){
                    swal("Error to load the service.");
                    return;
                }
                setShowOfferModal(true);
            break;
            case 5:
                if(Object.keys(servicesAllInfo.item).length == 0 ){
                    swal("Error to load the service.");
                    return;
                }
                setShowCallDriverModal(true);
            break;
            case 6:
                setShowCancelModal(true);
            break;
            case 7:
                setShowIncidentModal(true);
            break;
        }
    }

    const loadService = (service,type) => {
        console.log("a 2");
        setCurrentService({...service,action:type});
    }

    const MapOrigin = ({ text }) => {
        return <div><i class="material-icons">location_on</i> Origin</div>
    };

    const MapDestination = ({ text }) => {
        return <div><i class="material-icons">location_searching</i> Destination</div>
    };
    
    const Driver = ({ text,color }) => {
        return <div><i class="material-icons" style={{'color':(color?color:'')}} >directions_car</i></div>
    };


    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: "AIzaSyCAoQ5Jml2jW_sOCP0kKFAJLQXBmmuEr-k" 
    })


    useEffect(() => {
        console.log('lastServiceLocations');
        if(servicesAllInfo.item && lastServiceLocations.item && directionsService1){
            switch(servicesAllInfo.item.status){
                case 'On Duty (En route, on way)':
                    if(parseFloat(lastServiceLocations.item.lastLatitude) != NaN && parseFloat(lastServiceLocations.item.lastLongitude) != NaN){
                        setMapOrigin({lat:parseFloat(lastServiceLocations.item.lastLatitude),lng:parseFloat(lastServiceLocations.item.lastLongitude)});
                    }
                    setMapDestination({lat:parseFloat(servicesAllInfo.item.originLatitude),lng:parseFloat(servicesAllInfo.item.originLongitude)});

                    console.log('directionsService1',directionsService1);
                    directionsService1.route({
                        origin: mapOrigin,
                        destination: mapDestination,
                        travelMode: 'DRIVING'
                    }, (response, status) => {
                        if (status === 'OK') {
                            console.log('DRIVING',response);
                            setDirectionsResponse1(response);
                            directionsDisplay1.setDirections(response);
                            directionsDisplay1.setMap(map1);
                            const routePolyline = new window.google.maps.Polyline();
                            routePolyline.setMap(map1);
                        }
                    });

                break;
                case 'In progress (Picked up)':
                    if(parseFloat(lastServiceLocations.item.lastLatitude) != NaN && parseFloat(lastServiceLocations.item.lastLongitude) != NaN){
                        setMapOrigin({lat:parseFloat(lastServiceLocations.item.lastLatitude),lng:parseFloat(lastServiceLocations.item.lastLongitude)});
                    }
                    setMapDestination({lat:parseFloat(servicesAllInfo.item.destinationLatitude),lng:parseFloat(servicesAllInfo.item.destinationLongitude)});

                    console.log('directionsService1',directionsService1);
                    directionsService1.route({
                        origin: mapOrigin,
                        destination: mapDestination,
                        travelMode: 'DRIVING'
                    }, (response, status) => {
                        if (status === 'OK') {
                            console.log('DRIVING',response);
                            setDirectionsResponse1(response);
                            directionsDisplay1.setDirections(response);
                            directionsDisplay1.setMap(map1);
                            const routePolyline = new window.google.maps.Polyline();
                            routePolyline.setMap(map1);
                        }
                    });

                break;
                default:
                    setMapDestination({lat:parseFloat(servicesAllInfo.item.destinationLatitude),lng:parseFloat(servicesAllInfo.item.destinationLongitude)});
                    setMapOrigin({lat:parseFloat(servicesAllInfo.item.originLatitude),lng:parseFloat(servicesAllInfo.item.originLongitude)});
                break;
            }
        }

    }, [lastServiceLocations.item]);

    const setMapF = (args) => {

        console.log("setMapF");

        const map = args.map;
        const maps = args.maps;

        var directionsService3 = new maps.DirectionsService();
        var directionsDisplay3 = new maps.DirectionsRenderer();

        setDirectionsService1(directionsService3);
        setDirectionsDisplay1(directionsDisplay3);
        setMap1(map);

        directionsService3.route({
            origin: mapOrigin,
            destination: mapDestination,
            travelMode: 'DRIVING'
        }, (response, status) => {
            if (status === 'OK') {
                console.log('DRIVING',response);
                setDirectionsResponse1(response);
                directionsDisplay3.setDirections(response);
                directionsDisplay3.setMap(map);
                const routePolyline = new window.google.maps.Polyline();
                routePolyline.setMap(map);
            }
        });
    }

    const setMap = (args) => {

        console.log("setMapF");

        const map = args.map;
        const maps = args.maps;

        var directionsService3 = new maps.DirectionsService();
        var directionsDisplay3 = new maps.DirectionsRenderer();

        setDirectionsService1(directionsService3);
        setDirectionsDisplay1(directionsDisplay3);
        setMap1(map);

        directionsService3.route({
            origin: mapOrigin,
            destination: mapDestination,
            travelMode: 'DRIVING'
        }, (response, status) => {
            if (status === 'OK') {
                console.log('DRIVING',response);
                setDirectionsResponse1(response);
                directionsDisplay3.setDirections(response);
                directionsDisplay3.setMap(map);
                const routePolyline = new window.google.maps.Polyline();
                routePolyline.setMap(map);
            }
        });
    }

  return (
<div class="row" id="operator-dashboard" >
    {showNewService && (<NewService closeButton={closeNewService} setshowNewService={setshowNewService}  /> )}
    {showEditService && (<EditService closeButton={closeEditService} setshowEditService={setshowEditService} service={servicesAllInfo.item} />)}
    <div class="col s12">
        <div class="section" style={{paddingTop:'0px',paddingBottom:'0px'}} >
            <table>
                <tbody>
                    <tr>
                        <td>
                            <a onClick={createNewServiceButton} class="orange darken-3 waves-effect waves-light btn-large">
                                <i class='material-icons left large'>add</i>New Service
                            </a>
                        </td>
                        <td style={{minWidth:'200px'}}>
                            <CreatableSelect
                                placeholder={"Status"}
                                value={filters.status.value}
                                styles={{menu: provided => ({ ...provided, zIndex: 9999 })}}
                                className="countySelect"
                                classNamePrefix="select"
                                isLoading={filters.status.loading}
                                isRtl={false}
                                isSearchable={true}
                                name="color"
                                options={filters.status.items}
                                onChange={(value)=>{
                                    console.log("onchange Status");
                                    dispatch(updateFilterValue('status',value));
                                    updateServiceTable();
                                }}
                            />
                        </td>
                        <td style={{minWidth:'200px'}}>
                            <CreatableSelect
                                placeholder={"Type"}
                                value={filters.customerType.value}
                                styles={{menu: provided => ({ ...provided, zIndex: 9999 })}}
                                className="countySelect"
                                classNamePrefix="select"
                                isLoading={filters.customerType.loading}
                                isRtl={false}
                                isSearchable={true}
                                name="color"
                                options={filters.customerType.items}
                                onChange={(value)=>{
                                    console.log("onchange Status");
                                    dispatch(updateFilterValue('customerType',value));
                                    updateServiceTable();
                                }}
                            />
                        </td>
                        <td>
                            <div class="card gradient-shadow grey lighten-3 mt-1 mb-1">
                                <div class="card-content mt-1 mb-1 " style={{paddingTop:'5px',paddingBottom:'5px'}}>
                                    <table class="table-operator1">
                                        <tbody>
                                            <tr>
                                                <td style={{paddingTop:'2px',paddingBottom:'2px',marginTop:'2px',marginBottom:'2px'}} >
                                                    <div class="input-field" >
                                                        <h6 class="collection-header ">Vehicle Last Calls</h6>
                                                    </div>
                                                </td>
                                                <td style={{paddingTop:'2px',paddingBottom:'2px',marginTop:'2px',marginBottom:'2px',minWidth:'150px'}}>
                                                    <div class="input-field" >
                                                        <CreatableSelect
                                                            placeholder="Cab"
                                                            value={vehicleLastCallsCab}
                                                            styles={{menu: provided => ({ ...provided, zIndex: 9999 })}}
                                                            className="countySelect"
                                                            classNamePrefix="select"
                                                            isLoading={filters.vehicles.loading}
                                                            isRtl={false}
                                                            isSearchable={true}
                                                            name="color"
                                                            options={filters.vehicles.items}
                                                            onChange={(value)=>{
                                                                console.log("onchange vehicleLastCallsCab");
                                                                setVehicleLastCallsCab(value);
                                                            }}
                                                        />
                                                    </div>
                                                </td>
                                                <td style={{paddingTop:'2px',paddingBottom:'2px',marginTop:'2px',marginBottom:'2px',width:'100px'}}>
                                                    <div class="input-field">
                                                        <input id="vlc_last" type="text" class="validate"  value={filters.vehicleLastCallsLast.value} onChange={(event)=>{
                                                                console.log("onchange vehicleLastCallsLast");
                                                                dispatch(updateFilterValue('vehicleLastCallsLast',event.target.value));
                                                            }}/>
                                                        <label for="vlc_last" >Last</label>
                                                    </div>
                                                </td>
                                                <td style={{paddingTop:'2px',paddingBottom:'2px',marginTop:'2px',marginBottom:'2px'}}>
                                                    <div class="input-field" >
                                                        <a class=" orange darken-3 waves-effect waves-light btn" onClick={()=>{
                                                            //console.log();
                                                            dispatch(getLastTenServices(vehicleLastCallsCab.value,filters.vehicleLastCallsLast.value)).then(() => {
                                                                if(vehicle_last_call && vehicle_last_call.items && vehicle_last_call.items.length > 0){
                                                                    setShowVLCModal(true);
                                                                }else{
                                                                    swal("Vehicles not found.");
                                                                }
                                                            }).catch(() => {
                                                                swal("Vehicles not found.");
                                                            });
                                                        }}  >
                                                            <i class="material-icons">search</i>
                                                        </a>
                                                    </div>
                                                            
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </td>
                        <td>
                            <div class="card gradient-shadow grey lighten-3 mt-1 mb-1">
                                <div class="card-content mt-1 mb-1 " style={{paddingTop:'5px',paddingBottom:'5px'}}>
                                    <table class="table-operator1">
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <div class="input-field">
                                                        {/*<pre>{JSON.stringify(call_number_search, null, 2)}</pre>*/}
                                                        <h6 class="collection-header ">Call Number Search</h6>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="input-field" >
                                                        <input id="call_number_search" type="text" class="validate " value={filters.callNumberSearch.value} onChange={(event)=>{
                                                            console.log("onchange callNumberSearch");
                                                            dispatch(updateFilterValue('callNumberSearch',event.target.value));
                                                        }}/>
                                                        <label for="call_number_search" class="">Number</label>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="input-field" >
                                                        <a class="orange darken-3 waves-effect waves-light btn" onClick={()=>{
                                                            dispatch(getAllNumberSearch(filters.callNumberSearch.value)).then(() => {

                                                            }).catch(() => {
                                                                swal("Number not found.");
                                                            });
                                                        }} >
                                                            <i class="material-icons">search</i>
                                                        </a>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </td>
                        
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="section" style={{paddingTop:'0px',paddingBottom:'0px'}}> 
            <div style={{height:'576px',overflow:'auto'}} >
                <table id="services" class="responsive-table highlight pm2px" >
                        <thead>
                            <tr>
                                <th>Id</th>
                                <th>Date</th>
                                <th>Phone</th>
                                <th>Customer</th>
                                <th>Origin</th>
                                <th>Destination</th>
                                <th>Reservation</th>
                                <th>Cab</th>
                                <th>Call Type</th>
                                <th>Status</th>
                                <th>Map</th>
                                <th>Assing cab</th>
                                <th>Ack</th>
                                <th>Incidents</th>
                                <th>Offer</th>
                                <th>Call driver</th>
                                <th>Cancel</th>
                            </tr>
                        </thead>
                        <tbody style={{}}>
                        {services.items.map((service) => (
                            <tr>
                                <td>
                                    {service.id}<i class="material-icons" style={{position:'relative',top:'7px'}} onClick={()=>{
                                        loadService(service,1);
                                    }}>remove_red_eye</i>
                                </td>
                                <td>
                                    <Moment date={service.createdAt} format={'MM-DD-YYYY'}></Moment>
                                </td>
                                <td>
                                    {service.phone}
                                </td>
                                <td>
                                    {service.firstName} {service.lastName}
                                </td>
                                <td>
                                    {service.originLocation} 
                                </td>
                                <td>
                                    {service.destinationLocation}
                                </td>
                                <td>
                                    {service.reservation_date+' '+service.reservation_time}
                                </td>
                                <td>
                                    {service.cab}
                                </td>
                                <td>
                                    {service.call_type}
                                </td>
                                <td>
                                    <div class={"white-text border-round  center"} style={{backgroundColor:service.color}}> 
                                        {service.status} <br/>
                                        <span class="black-text">{service.cronometro.replace('.000000', '')}</span> 
                                    </div>
                                </td>
                                <td class="center" ><i class="material-icons" onClick={()=>{
                                    loadService(service,2);
                                }} >my_location</i></td>
                                <td class="center" ><i class="material-icons" onClick={()=>{
                                    console.log("a 1");
                                    loadService(service,3);
                                }}>local_taxi</i></td>
                                <td class="center" >
                                    {parseInt(service.statusId) != 14 && parseInt(service.statusId) != 3 && (
                                        <i class={"material-icons"}>check</i>
                                    )}
                                    {parseInt(service.statusId) == 14  && (
                                        <i style={{color:'green',fontSize:'3rem'}} class={"material-icons"}>check</i>
                                    )}
                                    {parseInt(service.statusId) == 3  && (
                                        <>
                                            <i style={{color:'green',fontSize:'3rem'}} class={"material-icons"}>check</i>
                                            <i style={{color:'green',fontSize:'3rem'}} class={"material-icons"}>check</i>
                                        </>
                                    )}
                                </td>
                                <td class="center" >
                                    <i style={(service.incidents == 1?{color:'red'}:{cursor:'auto'})} class="material-icons" onClick={()=>{
                                        if(service.incidents == 1){
                                            dispatch(getIncident(service.recordId)).then(()=>{setShowIncidentModal(true)});
                                        }
                                    }}>warning</i>
                                </td>
                                <td class="center" ><i class="material-icons" onClick={()=>{
                                    loadService(service,4);
                                }}>call_split</i></td>
                                <td class="center" ><i class="material-icons" onClick={()=>{
                                    loadService(service,5);
                                }}>phone</i></td>
                                <td class="center" ><i class="material-icons" onClick={()=>{
                                    loadService(service,6);
                                }}>cancel</i></td>
                            </tr>
                        ))}
                        </tbody>
                </table>
            </div>
        </div>
        <div class="section" style={{paddingTop:'0px',paddingBottom:'0px',position:'fixed'}}> 
            <table class="table-operator2">
                <tbody>
                    <tr>
                        <td  style={{paddingTop:'2px',paddingBottom:'2px',marginTop:'2px',marginBottom:'2px',width:'30%'}}>
                            <div class="card gradient-shadow grey lighten-3" style={{height:'200px'}}>
                                <div class="card-content" style={{paddingTop:'5px',paddingBottom:'5px'}}>
                                    <h6 class="collection-header colorcode">
                                        <h6 style={{display:'inline-block'}} > Color Code:</h6>
                                        <span onClick={()=>{setStatusIdDriver(null);}} style={{borderStyle:'solid',backgroundColor:'#62D85A',border:(statusIdDriver == null?'2px solid #6b6f82':'')}} class={'pl-1 pr-1 ml-1 '+(statusIdDriver != null?'white-text':'')} >All</span>
                                        <span onClick={()=>{setStatusIdDriver(12);}} style={{borderStyle:'solid',backgroundColor:'#62D85A',border:(statusIdDriver == 12?'2px solid #6b6f82':'')}} class={'pl-1 pr-1 ml-1 '+(statusIdDriver != 12?'white-text':'')} >Available</span>
                                        <span onClick={()=>{setStatusIdDriver(14);}} style={{borderStyle:'solid',backgroundColor:'#F88D41',border:(statusIdDriver == 14?'2px solid #6b6f82':'')}} class={'pl-1 pr-1 ml-1 cyan '+(statusIdDriver != 14?'white-text':'')} >On Duty</span>
                                        <span onClick={()=>{setStatusIdDriver(20);}} style={{borderStyle:'solid',backgroundColor:'#f89641',border:(statusIdDriver == 20?'2px solid #6b6f82':'')}} class={'pl-1 pr-1 ml-1  '+(statusIdDriver != 20?'white-text':'')} >Assigned</span>
                                        <span onClick={()=>{setStatusIdDriver(null);}} style={{borderStyle:'solid',backgroundColor:'#F88D41',border:(statusIdDriver == null?'2px solid #6b6f82':'')}} class={'pl-1 pr-1 ml-1 grey '+(statusIdDriver != null?'white-text':'')} >Stand By</span>
                                    </h6>
                                    <div class="row">
                                        {/*<pre>{JSON.stringify(statusIdDriver, null, 2)}</pre>*/}
                                        <table class="pm2px">
                                            <tbody> 
                                            {chunk(activedDrivers.items, 6).map((vehicleChuck) => {
                                                    return (<tr>{
                                                        vehicleChuck.map((vehicle) => {
                                                            if(vehicle.driverStatusId == statusIdDriver || statusIdDriver == null){
                                                                return (<td className={"center-align"}><a onClick={()=>{
                                                                    dispatch(getDriverInfo(vehicle.driverId)).then(()=>{
                                                                        setVehicleId(vehicle.driverId);
                                                                        setShowDriversInfoModal(true);
                                                                    }).catch(()=>{});
                                                                }} style={{width:'100%',backgroundColor:vehicle.color}} className={"white-text waves-effect"}><span >D:{vehicle.driverId} C:{vehicle.cab}</span></a></td>);
                                                            }
                                                        })
                                                    }</tr>)
                                                })
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>   
                        </td>
                        <td style={{paddingTop:'2px',paddingBottom:'2px',marginTop:'2px',marginBottom:'2px',width:'5%'}}>
                            <div class="card gradient-shadow grey lighten-3" style={{height:'200px'}}>
                                <div class="card-content" style={{paddingTop:'5px',paddingBottom:'5px'}}>
                                    <div class="row">
                                        <table class="pm2px">
                                            <tbody> 
                                                <tr>
                                                    <td className={"center-align"}>
                                                        <a onClick={()=>{
                                                                setShowAllDrivers(true);
                                                            }} style={{width:'100%'}} className={" white-text waves-effect grey darken-3"} >
                                                        Show All
                                                        </a>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>   
                                    </div>
                                </div>
                            </div> 
                        </td>
                        <td style={{paddingTop:'2px',paddingBottom:'2px',marginTop:'2px',marginBottom:'2px',width:'15%'}}>
                            <div class="card gradient-shadow grey lighten-3" style={{height:'200px'}}>
                                <div class="card-content" style={{paddingTop:'5px',paddingBottom:'5px'}}>
                                    <h6 class="collection-header ">Blacklist<i class="material-icons right" onClick={()=>{
                                        dispatch(getBlackListReasons()).then(()=>{
                                            setShowBlackListModal(true);
                                        }).catch(()=>{});
                                        }} >add</i></h6>
                                    <div class="row">
                                        <table class="pm2px">
                                            <tbody> 
                                            {chunk(blacklist.items, 5).map((blChuck) => {
                                                    return (<tr>{
                                                        blChuck.map((vehicle) => {
                                                            return (<td className={"center-align"}><a onClick={()=>{
                                                                setBlacklistSelected(vehicle);
                                                                setShowBlacklistInfoModal(true);
                                                            }} style={{width:'100%'}} className={" white-text waves-effect grey darken-3"} >{vehicle.driverId}</a></td>);
                                                        })
                                                    }</tr>)
                                                })
                                            }
                                            </tbody>
                                        </table>   
                                    </div>
                                </div>
                            </div> 
                        </td>
                        <td style={{paddingTop:'2px',paddingBottom:'2px',marginTop:'2px',marginBottom:'2px',width:'30%'}}>
                            <div class="card gradient-shadow grey lighten-3" style={{height:'200px'}}>
                                <div class="card-content" style={{paddingTop:'5px',paddingBottom:'5px',height:'200px'}}>
                                    <table id="messsages" class="pm2px responsive-table highlight" style={{height:'165px'}} >
                                        <thead>
                                                <tr>
                                                    <th>
                                                        <i class="material-icons left" onClick={()=>{
                                                            setMessageText2("");
                                                            setShowMesssagesModal2(true);
                                                        }} >add</i>
                                                    </th>
                                                    <th>From</th>
                                                    <th>To</th>
                                                    <th>Message</th>
                                                    <th>Answer</th>
                                                    <th>Actions 
                                                    </th>
                                                </tr>
                                        </thead>
                                        <tbody>
                                                {messages.messages.map((message) => (
                                                    <tr>
                                                        <td>
                                                            {message.sign == 'in' && (<><i class="material-icons" style={{color:'green'}} >arrow_forward</i></>)}
                                                            {message.sign == 'out' && (<><i class="material-icons" style={{color:'red'}}>arrow_back</i></>)}                                                        
                                                        </td>
                                                        <td>{message.memberIdFrom}</td>
                                                        <td>{message.memberIdTo}</td>
                                                        <td>{message.message}</td>
                                                        <td>{message.answer}</td>
                                                        <td>
                                                            <i class="material-icons" onClick={() => {
                                                                swal({
                                                                    title: "Are you sure?",
                                                                    text: "You will not be able to recover this message!",   
                                                                    type: "warning",   
                                                                    buttons: true,
                                                                    dangerMode: true,
                                                                    className:'blacklistinfoswal'
                                                                }).then(
                                                                    function(willDelete){
                                                                        if (willDelete) {
                                                                            dispatch(removemessage(message.messageId)).then(()=>{
                                                                                swal("Deleted!", "The message has been deleted.", "success"); 
                                                                            });
                                                                        }
                                                                    }
                                                                );
                                                            }} >delete</i>
                                                            {message.sign == 'in' && (<i class="material-icons" onClick={()=>{
                                                                
                                                                dispatch(updateAck(message.messageId)).then(()=>{
                                                                    swal("Updated!", "The message has been updated.", "success"); 
                                                                });
                                                            }} style={{color:(message.ack == 1?'green':'')}} >remove_red_eye</i>)}
                                                            {message.sign == 'in' && (<i class="material-icons" onClick={()=>{
                                                                    setMessageText("");
                                                                    setMessageSelected(message);
                                                                    setShowMesssagesModal(true);
                                                            }} >reply</i>)}
                                                        </td>
                                                    </tr>
                                                ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <Modal open={showBlackListModal} onClose={()=>{setShowBlackListModal(false);}} classNames={{modal: 'blacklistmodal'}}  >
    <div class="row blacklistmodal">
        <h5>Add to Blacklist</h5>
        <form class="col s12">
            <div class="row">
                <div class="input-field col s12">
                    <CreatableSelect
                        placeholder="Driver"
                        value={filters.activedDrivers.value}
                        styles={{menu: provided => ({ ...provided, zIndex: 9999999 })}}
                        className="blacklistmodaldriver"
                        classNamePrefix="blacklistmodaldriver"
                        isLoading={filters.activedDrivers.loading}
                        isRtl={false}
                        isSearchable={true}
                        name="color"
                        options={filters.activedDrivers.items}
                        onChange={(value)=>{
                            console.log("onchange blackListCab");
                            dispatch(updateFilterValue('activedDrivers',value));
                        }}
                        />
                </div>
                <div class="input-field col s12">
                    <CreatableSelect
                        placeholder="Reason"
                        value={filters.blackListReason.value}
                        styles={{menu: provided => ({ ...provided, zIndex: 9999999 })}}
                        className="blacklistmodalreason"
                        classNamePrefix="blacklistmodalreason"
                        isLoading={filters.blackListReason.loading}
                        isRtl={false}
                        isSearchable={true}
                        name="color"
                        options={filters.blackListReason.items}
                        onChange={(value)=>{
                            console.log("onchange blackListReason");
                            dispatch(updateFilterValue('blackListReason',value));
                        }}
                        />
                </div>

                <div class="input-field  col s12" >
                    <a class="orange darken-3 waves-effect waves-light btn" onClick={()=>{

                        //driverId,blackListReasonId
                        /*console.log("filters.activedDrivers",filters.activedDrivers);
                        console.log("filters.blackListReason",filters.blackListReason);*/

                        dispatch(addToBackList(filters.activedDrivers.value.value,filters.blackListReason.value.value)).then(()=>{
                            swal("Driver added to blacklist");
                            dispatch(getBlacklist());
                            dispatch(getAllActiveTaxis(statusIdDriver));
                            setShowBlackListModal(false);
                        }).catch(()=>{
                            swal("Error to add to blacklist.");
                        });
                        }} >
                        Save
                    </a>
                </div>
            </div>
        </form>
    </div>
  
    </Modal>
    
    
    <Modal open={showMesssagesModal} onClose={()=>{setShowMesssagesModal(false);}} >
    <div class="row">
            <h6>Message: {messageSelected != null && ( <span>{messageSelected.message}</span> )}</h6>
            <div class="col s12">
                <div class="row">
                    <form class="col s12">
                        <div class="row">
                            <div class="input-field col s12">
                            <textarea id="textarea2" class="materialize-textarea" value={messageText} onChange={(event)=>{
                                console.log("onchange messageText");
                                setMessageText(event.target.value);
                            }}></textarea>
                            <label for="textarea2">Text</label>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="row">
                    <div class="col s12">
                        <a class="waves-effect waves-light btn-large mb-1" onClick={()=>{
                            dispatch(responsemessage(messageText,messageSelected.messageId)).then(()=>{
                                setShowMesssagesModal(false);               
                            }).catch();
                        }}><i class='material-icons left'>send</i> Send</a>
                    </div>
                </div>
            </div>
        </div>
    </Modal>
    <Modal open={showMesssagesModal2} onClose={()=>{setShowMesssagesModal2(false);}} >
    <div class="row">
            
            <div class="col s12">
                <div class="row">
                    <form class="col s12">
                        <div class="row">
                            <div class="input-field col s6">
                                <h6>Message:</h6>
                                <textarea id="textarea2" class="materialize-textarea" value={messageText2} onChange={(event)=>{
                                    setMessageText2(event.target.value);
                                }}></textarea>
                            </div>
                            <div class="input-field col s6">
                                <h6>Driver:</h6>
                                <CreatableSelect
                                    placeholder="Cab"
                                    value={driverMessage}
                                    styles={{menu: provided => ({ ...provided, zIndex: 9999 })}}
                                    className="countySelect"
                                    classNamePrefix="select"
                                    isLoading={filters.vehicles.loading}
                                    isRtl={false}
                                    isSearchable={true}
                                    name="color"
                                    options={filters.vehicles.items}
                                    onChange={(value)=>{
                                        console.log("onchange vehicleLastCallsCab");
                                        setDriverMessage(value);
                                    }}
                                />
                            </div>
                        </div>
                    </form>
                </div>
                <div class="row">
                    <div class="col s12">
                        <a class="waves-effect waves-light btn-large mb-1" onClick={()=>{
                            dispatch(sendmessage2(messageText2,driverMessage.value)).then(()=>{
                                setShowMesssagesModal2(false);               
                            }).catch();
                        }}><i class='material-icons left'>send</i> Send</a>
                    </div>
                </div>
            </div>
        </div>
    </Modal>

    <Modal open={showVLCModal} onClose={()=>{setShowVLCModal(false);}} classNames={{modal: 'vehiclelastcalls'}} >
        <div class="row">
            <h6>Vehicle Last calls</h6>
            <table>
                <thead>
                    <tr>
                        <th>Id</th>
                        <th>Customer</th>
                        <th>Phone</th>
                        <th>Origin Location</th>
                        <th>Destination Location</th>
                        <th>PickupDateTime</th>
                        <th>DropDateTime</th>
                        <th>Final Rate</th>
                        <th>Final Distance</th>
                        <th>Final Time</th>
                        <th>Status</th>
                        <th>Created At</th>
                    </tr>
                </thead>
                <tbody>
                    {vehicle_last_call && vehicle_last_call.items && vehicle_last_call.items.map((record) => (
                    <tr>
                        <td>{record.serviceId}</td>
                        <td>{record.customer}</td>
                        <td>{record.phone}</td>
                        <td>{record.originLocation}</td>
                        <td>{record.destinationLocation}</td>
                        <td>{record.pickupDateTime}</td>
                        <td>{record.dropDateTime}</td>
                        <td>{record.finalRate}</td>
                        <td>{record.finalDistance}</td>
                        <td>{record.finalTime}</td>
                        <td>{record.status}</td>
                        <td>{record.createdAt}</td>
                    </tr>
                    ))}
                </tbody>
            </table>
        </div>
    </Modal>
    <Modal open={showCNSModal} onClose={()=>{setShowCNSModal(false);}} classNames={{modal: 'showphone'}} >
        <div class="row">
            <h6>Call Number Search</h6>
            <table>
                <thead>
                    <tr>
                        <th>Id</th>
                        <th>Status</th>
                        <th>Origin Location</th>
                        <th>Destination Location</th>
                        <th>PickupDateTime</th>
                        <th>DropDateTime</th>
                        <th>Standard</th>
                        <th>Payment</th>
                        <th>Driver</th>
                        <th>Cab</th>
                        <th>Vehicle Plates</th>
                        <th>Final Rate</th>
                        <th>Notes</th>
                        <th>Score To Driver</th>
                        <th>DriverId</th>
                    </tr>
                </thead>
                <tbody>
                    {call_number_search && call_number_search.items && call_number_search.items.map((record) => (
                    <tr>
                        <td>{record.serviceId}</td>
                        <td>{record.status}</td>
                        <td>{record.originLocation}</td>
                        <td>{record.destinationLocation}</td>
                        <td>{record.pickupDateTime}</td>
                        <td>{record.dropDateTime}</td>
                        <td>Standard</td>
                        <td>{record.payment}</td>
                        <td>{record.driver}</td>
                        <td>{record.cab}</td>
                        <td>{record.vehiclePlates}</td>
                        <td>{record.finalRate}</td>
                        <td>{record.notes}</td>
                        <td>{record.scoreToDriver}</td>
                        <td>{record.driverId}</td>
                    </tr>
                    ))}
                </tbody>
            </table>
        </div>
    </Modal>
    <Modal open={showMapModal} onClose={()=>{setShowMapModal(false);}} >
        <div class="row">
            {/*<pre>{JSON.stringify(directionsService1, null, 2)}</pre>*/}
            <div class="col s12">
                { directionsResponse1 && (<div>Distance: {directionsResponse1.routes[0].legs[0].distance.text} -- Duration: {directionsResponse1.routes[0].legs[0].duration.text}</div>)}
            </div>
            <div class="col s12">
                <div class="" style={{ height: '110vh', width: '95vh' }}>
                <GoogleMapReact
                    bootstrapURLKeys="AIzaSyCAoQ5Jml2jW_sOCP0kKFAJLQXBmmuEr-k"
                    defaultCenter={mapOrigin}
                    center={mapOrigin}
                    defaultZoom={10}
                    zoom={10}
                    yesIWantToUseGoogleMapApiInternals
                    onGoogleApiLoaded={setMapF} >
                        {lastServiceLocations &&
                        lastServiceLocations.item &&
                        lastServiceLocations.item.lastLatitude && 
                        lastServiceLocations.item.lastLongitude &&
                        (<Driver lat={lastServiceLocations.item.lastLatitude} lng={lastServiceLocations.item.lastLongitude}/>)}
                </GoogleMapReact>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col s12">
            {/*<pre>{JSON.stringify(lastServiceLocations, null, 2)}</pre>*/}
            {/*<pre>{JSON.stringify(servicesAllInfo.item, null, 2)}</pre>*/}
            {/*<pre>{JSON.stringify(directions, null, 2)}</pre>*/}
            </div>
        </div>
    </Modal>
    <Modal open={showServiceModal} onClose={()=>{setShowServiceModal(false);}} >
        <div class="row">
            <div class="col s12">
                <table class="responsive-table highlight">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Value</th>
                        </tr>
                    </thead>
                    <tbody>
                        {(servicesAllInfo.item != null) && Object.entries(servicesAllInfo.item).map((value) => (
                            <tr>
                                <td>{value[0]}</td>
                                <td>{value[1]}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    </Modal>
    <Modal open={showAssingCabModal} onClose={()=>{setShowAssingCabModal(false);}} >
        <div class="row">
            <h6>Assing Cab</h6>
            <div class="col s12" >
            {/*<pre>{JSON.stringify(availableDrivers, null, 2)}</pre>*/}
                <table class="subscription-table responsive-table highlight">
                    <thead>
                            <tr>
                                <th>DriverId</th>
                                <th>Cab</th>
                                <th>Distancia</th>
                                <th>First Name</th>
                                <th>Last Name</th>
                                <th>Phone</th>
                                <th>Score</th>
                                <th></th>
                                <th></th>
                            </tr>
                    </thead>
                    <tbody>
                            {availableDrivers.items.map((marker) => (
                                <tr>
                                    <td>{marker.driverId}</td>
                                    <td>{marker.cab}</td>
                                    <td>{parseFloat(marker.distancia).toFixed(2)}</td>
                                    <td>{marker.firstName}</td>
                                    <td>{marker.lastName}</td>
                                    <td>{marker.phone}</td>
                                    <td>{marker.scoreLevel}</td>
                                    <td><i class="material-icons" onClick={()=>{ 
                                        if(parseFloat(marker.lastLatitude) != NaN && parseFloat(marker.lastLongitude) != NaN){
                                            setSelectedCenter({lat: parseFloat(marker.lastLatitude),lng: parseFloat(marker.lastLongitude)})
                                        }
                                    }}>search</i></td>
                                    <td><i class="material-icons" onClick={() => { selectDriver(marker.driverId,marker.vehicleId); }}  >send</i></td>
                                </tr>
                            ))}
                    </tbody>
                </table>
            </div>
            <div class="col s12">
                <div class="" style={{ height: 500, width: '100%', display: 'flex', flexFlow: 'row nowrap', justifyContent: 'center', padding: 0 }}>
                    <GoogleMapReact
                        bootstrapURLKeys={{ key: 'AIzaSyCAoQ5Jml2jW_sOCP0kKFAJLQXBmmuEr-k' }}
                        defaultCenter={selectedCenter}
                        center={selectedCenter}
                        defaultZoom={15.25}
                        onClick={(event) => {}}
                        onGoogleApiLoaded={({ map, maps }) => loadAssign(map, maps)}>
                            <Origin lat={originCenter.lat} lng={originCenter.lng}/> 
                            {availableDrivers.items.map((marker) => {
                                if(parseFloat(marker.lastLatitude) != NaN && parseFloat(marker.lastLongitude) != NaN){
                                    console.log();
                                    return <Vehicle lat={marker.lastLatitude} lng={marker.lastLongitude} />
                                }
                            })}
                    </GoogleMapReact>
                </div>
            </div>
        </div>
    </Modal>
    <Modal open={showBlacklistInfoModal} onClose={()=>{setShowBlacklistInfoModal(false);}} >
        <div class="row">
            <h6>Blacklist Info <i class="material-icons" style={{position:'relative',top:'2px',left:'5px'}} onClick={()=>{
                swal({
                    title: "Are you sure?",
                    text: "The driver will be actived! ",
                    icon: "warning",
                    buttons: true,
                    dangerMode: true,
                    className:'blacklistinfoswal',
                }).then((willDelete) => {
                    if (willDelete) {
                        dispatch(activeDriver(blacklistSelected.driverId)).then(()=>{
                            swal("The driver is actived.", {icon: "success",});
                            setShowBlacklistInfoModal(false);
                            dispatch(getBlacklist());
                            dispatch(getAllActiveTaxis(statusIdDriver));
                        }).catch(()=>{});
                    }
                });
                /**/
            }} >lock_outline</i></h6>
            <div class="col s12">
                <table class="responsive-table highlight">
                    <thead>
                            <tr>
                                <th>Name</th>
                                <th>Value</th>
                            </tr>
                    </thead>
                    <tbody>
                        {(blacklistSelected != null) && Object.entries(blacklistSelected).map((value) => (
                            <tr>
                                <td>{value[0]}</td>
                                <td>{value[1]}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            
            </div>
        </div>
    </Modal>
    <Modal open={showOfferModal} onClose={()=>{setShowOfferModal(false);}} >
        <div class="row">
            <h6>Offer</h6>
            <div class="col s12">

            </div>
        </div>
    </Modal>
    <Modal open={showCallDriverModal} onClose={()=>{setShowCallDriverModal(false);}} >
        <div class="row">
            <h6>Call Driver</h6>

        </div>
    </Modal>
    <Modal open={showCancelModal} onClose={()=>{setShowCancelModal(false);}} >
        <div class="row">
            <h6>Cancel</h6>
            <div class="col s12">
            <div class="row">
                <form class="col s12">
                    <div class="row">
                        <div class="input-field col s12">
                        <textarea id="textarea2" class="materialize-textarea" value={cancelReason} onChange={(event)=>{
                            console.log("onchange cancelReason");
                            setCancelReason(event.target.value);
                        }}></textarea>
                        <label for="textarea2">Reason</label>
                        </div>
                    </div>
                </form>
            </div>
            <div class="row">
                <div class="col s12">
                    <a class="waves-effect waves-light btn-large mb-1" onClick={()=>{
                        if(currentService.recordType == 'reservation'){
                            dispatch(cancelReservation(currentService.recordId,cancelReason)).then(() => {
                                swal("Reservation canceled successful.");
                                setCancelReason("");
                                dispatch(getActivatedServices(0,0));
                                setShowCancelModal(false);
                            }).catch(() => {
                                swal("Error to cancel the reservation.");
                                setCancelReason("");
                                setShowCancelModal(false);
                            });
                        }else{
                            dispatch(cancelService(currentService.recordId,cancelReason)).then(() => {
                                swal("Service canceled successful.");
                                setCancelReason("");
                                dispatch(getActivatedServices(0,0));
                                setShowCancelModal(false);
                            }).catch(() => {
                                swal("Error to cancel the service.");
                                setCancelReason("");
                                setShowCancelModal(false);
                            });
                        }
                    }}><i class='material-icons left'>send</i> Save</a>
                </div>
            </div>
            </div>
        </div>
    </Modal>
    <Modal open={showDriversInfoModal} onClose={()=>{setShowDriversInfoModal(false);}}classNames={{modal: 'showDriversInfoModal'}} >
        <div class="row">
            <h6>Driver Info</h6>
            <div class="col s4">
                <table class="responsive-table highlight">
                    <thead>
                            <tr>
                                <th>Name</th>
                                <th>Value</th>
                            </tr>
                    </thead>
                    <tbody>
                        {(driverSelectedInfo.item != null) && Object.entries(driverSelectedInfo.item).map((value) => (
                            <tr>
                                <td>{value[0]}</td>
                                <td>{value[1]}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div class="col s8">
                {/*<pre>{JSON.stringify(lpvehicle, null, 2)}</pre>*/}
                <div class="" style={{ height: 500, width: '100%', display: 'flex', flexFlow: 'row nowrap', justifyContent: 'center', padding: 0 }}>
                    {lpvehicle.item != null && lpvehicle.item.lastLatitude != null && lpvehicle.item.lastLongitude != null && 
                    (<GoogleMapReact
                        bootstrapURLKeys={{key:'AIzaSyCAoQ5Jml2jW_sOCP0kKFAJLQXBmmuEr-k'}}
                        defaultCenter={{lat:parseFloat(lpvehicle.item.lastLatitude),lng:parseFloat(lpvehicle.item.lastLongitude)}}
                        center={{lat:parseFloat(lpvehicle.item.lastLatitude),lng:parseFloat(lpvehicle.item.lastLongitude)}}
                        defaultZoom={12}
                        onClick={(event) => {}}
                        onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}>
                        <Vehicle lat={parseFloat(lpvehicle.item.lastLatitude)} lng={parseFloat(lpvehicle.item.lastLongitude)} />
                    </GoogleMapReact>
                    )}
                    {lpvehicle.item == null || lpvehicle.item.lastLatitude == null && (<div>Location not avaliable</div>)}
                </div>
            </div>
        </div>
    </Modal>
    <Modal open={showIncidentModal} onClose={()=>{setShowIncidentModal(false);}} >
        <div class="row">
            <h6>Incidents</h6>
            <div class="col s12">
                <table class="responsive-table highlight">
                    <thead>
                            <tr>
                                <th>Name</th>
                                <th>Value</th>
                            </tr>
                    </thead>
                    <tbody>
                        {(incident != null) && (incident.item) && Object.entries(incident.item).map((value) => (
                            <tr>
                                <td>{value[0]}</td>
                                <td>{value[1]}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            
            </div>
        </div>
    </Modal>
    <Modal open={showAllDrivers} onClose={()=>{setShowAllDrivers(false);}}classNames={{modal: 'showAllDrivers'}}>
        <div class="row">
            <h6>Drivers</h6>
            <div class="col s12">
                {/*activedDrivers.items*/}
                <div class="" style={{ height: 800, width: '100%', display: 'flex', flexFlow: 'row nowrap', justifyContent: 'center', padding: 0 }}>
                    {activedDrivers.items != null && 
                    (<GoogleMapReact
                        bootstrapURLKeys="AIzaSyCAoQ5Jml2jW_sOCP0kKFAJLQXBmmuEr-k"
                        defaultCenter={{lat: 33.5722,lng: -112.088}}
                        center={{lat: 33.5722,lng: -112.088}}
                        defaultZoom={10}
                        zoom={10}
                        onGoogleApiLoaded={({ map, maps }) => loadAllDrivers(map, maps)} >
                        {activedDrivers.items.map((marker) => {
                            if(marker.lastLatitude != null && marker.lastLongitude != null){
                                return (<Driver lat={marker.lastLatitude} lng={marker.lastLongitude} color={marker.color} />)
                            }
                        })}
                    </GoogleMapReact>)}
                </div>
            </div>
        </div>
    </Modal>


    
</div>
  );
};

export default OperatorDashboard;