import axios from "axios";
import authHeader from "../auth-header";

const createNewService = () => {
	return axios.get(process.env.REACT_APP_API_URL + "api/driver/recorded/listassociate/2/6", { headers: authHeader() })
	.then((response) => {
    	return response;
  	});
};
const getActivatedServices = (Case,Switch) => {
	return axios.get(process.env.REACT_APP_API_URL + "api/operator/serviceboard/"+Case+"/"+Switch, { headers: authHeader() })
	.then((response) => {
    	return response;
  	});
};

const getVehicles = (statusId) => {
	return axios.get(process.env.REACT_APP_API_URL + "api/operator/allstatusvehicles/"+statusId, { headers: authHeader() })
	.then((response) => {
    	return response;
  	});
};
const getBlacklist = () => {
	return axios.get(process.env.REACT_APP_API_URL + "api/operator/blacklist/", { headers: authHeader() })
	.then((response) => {
    	return response;
  	});
};
const getAllActiveTaxis = (statusId) => {
	return axios.get(process.env.REACT_APP_API_URL + "api/operator/allstatusvehicles/"+(statusId?statusId:""), { headers: authHeader() })
	.then((response) => {
    	return response;
  	});
};
const getLastTenServices = (cab,last) => {
	return axios.get(process.env.REACT_APP_API_URL + "api/operator/lasttenservices/"+cab+"/"+last, { headers: authHeader() })
	.then((response) => {
    	return response;
  	});
};
const getAllNumberSearch = (phone) => {
	return axios.get(process.env.REACT_APP_API_URL + "api/operator/allnumbersearch/"+phone, { headers: authHeader() })
	.then((response) => {
    	return response;
  	});
};
const getServiceInfo = (serviceId) => {
	return axios.get(process.env.REACT_APP_API_URL + "api/service/viewservice/"+serviceId, { headers: authHeader() })
	.then((response) => {
    	return response;
  	});
};
const getReservationInfo = (serviceId) => {
	return axios.get(process.env.REACT_APP_API_URL + "api/service/detailsView/"+serviceId, { headers: authHeader() })
	.then((response) => {
    	return response;
  	});
};

const getavailablesDrivers = (serviceId) => {
	return axios.get(process.env.REACT_APP_API_URL + "api/service/available/"+serviceId, { headers: authHeader() })
	.then((response) => {
    	return response;
  	});
};


const addToBackList = (driverId,blackListReasonId) => {
	return axios({
		method: 'post',
		url: process.env.REACT_APP_API_URL + "api/operator/blacklist/",
		headers: authHeader(),
		data: {		
			driverId,
			blackListReasonId
		}
	  }).then((response) => {
    	return response;
  	});
};

const assignDriver = (vehicleId,driverId,serviceId) => {
	return axios({
		method: 'put',
		url: process.env.REACT_APP_API_URL + "api/operator/preassigndriver/"+vehicleId+"/"+driverId+"/"+serviceId+"/",
		headers: authHeader(),
		data: {}
	  }).then((response) => {
    	return response;
  	});
};

const cancelService = (serviceId,reason) => {
	return axios({
		method: 'put',
		url: process.env.REACT_APP_API_URL + "api/operator/cancelservice/"+serviceId,
		headers: authHeader(),
		data: {cancelReason:reason}
	  }).then((response) => {
    	return response;
  	});
};
const cancelReservation = (reservationId,reason) => {
	return axios({
		method: 'put',
		url: process.env.REACT_APP_API_URL + "api/operator/cancelreservation/"+reservationId,
		headers: authHeader(),
		data: {cancelReason:reason}
	  }).then((response) => {
    	return response;
  	});
};



const activeDriver = (driverId) => {
	return axios({
		method: 'put',
		url: process.env.REACT_APP_API_URL + "api/operator/outblacklist/"+driverId,
		headers: authHeader(),
		data: {}
	  }).then((response) => {
    	return response;
  	});
};

const getBlackListReasons = () => {
	return axios.get(process.env.REACT_APP_API_URL + "api/catalog/blacklistreasons", { headers: authHeader() })
	.then((response) => {
    	return response;
  	});
};

const getDriverInfo = (driverId) => {
	return axios.get(process.env.REACT_APP_API_URL + "api/driver/recorded/"+driverId, { headers: authHeader() })
	.then((response) => {
    	return response;
  	});
};

const getMessages = () => {
	return axios.get(process.env.REACT_APP_API_URL + "api/messages/activemessages/", { headers: authHeader() })
	.then((response) => {
    	return response;
  	});
};

const getCustomerTypes = () => {
	return axios.get(process.env.REACT_APP_API_URL + "api/catalog/customertypes/", { headers: authHeader() })
	.then((response) => {
    	return response;
  	});
};

const getPaymentTypes = () => {
	return axios.get(process.env.REACT_APP_API_URL + "api/catalog/paymenttypes/", { headers: authHeader() })
	.then((response) => {
    	return response;
  	});
};

const getIncident = (serviceId) => {
	return axios.get(process.env.REACT_APP_API_URL + "api/mobile/appincidentsbyservice/"+serviceId+"/1", { headers: authHeader() })
	.then((response) => {
    	return response;
  	});
};


const getDriverLastPosition = (serviceId) => {
	return axios.get(process.env.REACT_APP_API_URL + "api/service/getlastlocationvehicle/"+serviceId, { headers: authHeader() })
	.then((response) => {
    	return response;
  	});
};
const updateServicePaymentType = (serviceId,paymentTypeId) => {
	return axios({
		method: 'put',
		url: process.env.REACT_APP_API_URL + "api/operator/updpaymenttype/"+serviceId,
		headers: authHeader(),
		data: {paymentTypeId:paymentTypeId}
	  }).then((response) => {
    	return response;
  	});
};
const updateReservationPaymentType = (reservationId,paymentTypeId) => {
	return axios({
		method: 'put',
		url: process.env.REACT_APP_API_URL + "api/operator/editreservationpaymenttype/"+reservationId,
		headers: authHeader(),
		data: {paymentTypeId:paymentTypeId}
	  }).then((response) => {
    	return response;
  	});
};


const updateReservationOriginLocation = (reservationId,location,lat,lng,ltv,ltt) => {
	return axios({
		method: 'put',
		url: process.env.REACT_APP_API_URL + "api/operator/editreservationorigin/"+reservationId,
		headers: authHeader(),
		data: {
			originLocation:location,
			originLatitude:lat,
			originLongitude:lng,
			locationType:ltv,
			locationTypeValue:ltt
		}
	  }).then((response) => {
    	return response;
  	});
};


const updateServiceOriginLocation = (serviceId,location,lat,lng,ltv,ltt) => {
	return axios({
		method: 'put',
		url: process.env.REACT_APP_API_URL + "api/operator/updateorigin/"+serviceId,
		headers: authHeader(),
		data: {
			originLocation:location,
			originLatitude:lat,
			originLongitude:lng,
			locationType:ltv,
			locationTypeValue:ltt
		}
	  }).then((response) => {
    	return response;
  	});
};

const updateReservationDestinationLocation = (reservationId,location,lat,lng) => {
	return axios({
		method: 'put',
		url: process.env.REACT_APP_API_URL + "api/operator/editreservationdestination/"+reservationId,
		headers: authHeader(),
		data: {
			destinationLocation:location,
			destinationLatitude:lat,
			destinationLongitude:lng
		}
	  }).then((response) => {
    	return response;
  	});
};


const updateServiceDestinationLocation = (serviceId,location,lat,lng) => {
	return axios({
		method: 'put',
		url: process.env.REACT_APP_API_URL + "api/operator/updatedestination/"+serviceId,
		headers: authHeader(),
		data: {
			destinationLocation:location,
			destinationLatitude:lat,
			destinationLongitude:lng
		}
	  }).then((response) => {
    	return response;
  	});
};
 
const sendmessage2 = (message,to) => {
	return axios({
		method: 'post',
		url: process.env.REACT_APP_API_URL + "api/messages/sendByDriverId/"+to,
		headers: authHeader(),
		data: {
			message:message,
		}
	  }).then((response) => {
    	return response;
  	});
};

const sendmessage = (message,to,from) => {
	return axios({
		method: 'post',
		url: process.env.REACT_APP_API_URL + "api/messages/send/"+to,
		headers: authHeader(),
		data: {
			message:message,
			from:from
		}
	  }).then((response) => {
    	return response;
  	});
};

const responsemessage = (message,messageId) => {
	return axios({
		method: 'post',
		url: process.env.REACT_APP_API_URL + "api/messages/response/"+messageId,
		headers: authHeader(),
		data: {
			message:message
		}
	  }).then((response) => {
    	return response;
  	});
};


const removemessage = (messageId) => {
	return axios({
		method: 'delete',
		url: process.env.REACT_APP_API_URL + "api/messages/"+messageId,
		headers: authHeader(),
		data: {}
	  }).then((response) => {
    	return response;
  	});
};

const updateAck = (messageId) => {
	return axios({
		method: 'put',
		url: process.env.REACT_APP_API_URL + "api/messages/updateack/"+messageId,
		headers: authHeader(),
		data: {}
	  }).then((response) => {
    	return response;
  	});
};

const getVehicleLastPosition = (serviceId) => {
	return axios.get(process.env.REACT_APP_API_URL + "api/service/getlastlocationvehicle/"+serviceId, { headers: authHeader() })
	.then((response) => {
    	return response;
  	});
};




export default {
	createNewService,
	getActivatedServices,
	getVehicles,
	getBlacklist,
	getAllActiveTaxis,
	getLastTenServices,
	getAllNumberSearch,
	addToBackList,
	getServiceInfo,
	getReservationInfo,
	assignDriver,
	cancelService,
	getavailablesDrivers,
	getBlackListReasons,
	activeDriver,
	getDriverInfo,
	getMessages,
	updateServicePaymentType,
	updateReservationPaymentType,
	updateReservationOriginLocation,
	updateServiceOriginLocation,
	updateReservationDestinationLocation,
	updateServiceDestinationLocation,
	getCustomerTypes,
	getPaymentTypes,
	getDriverLastPosition,
	cancelReservation,
	sendmessage,
	sendmessage2,
	removemessage,
	responsemessage,
	updateAck,
	getIncident,
	getVehicleLastPosition,
};
