import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import axios from "axios";
import ReactModal from 'react-modal';

import authHeader from "../../services/auth-header";
import Table from '../Table';
import { TextControl, SelectControl } from './Controls';
import CreateTransactionModal from "./modals/CreateTransactionModal";
import EditTransactionModal from "./modals/EditTransactionModal";

ReactModal.setAppElement('#root');

function Transactions() {

    const { user: currentUser } = useSelector((state) => state.auth);

    const [transactions, setTransactions] = useState([]);
    const [currentTransaction, setCurrentTransaction] = useState([]);

    const [statusFilter, setStatusFilter] = useState(1);

    const [tablePage, setTablePage] = useState(0);
    const [tableSearchValue, setTableSearchValue] = useState('');
    const [tableSearchFields, setTableSearchFields] = useState(['description', 'conceptType', 'userName']);

    const [isCreateModalOpened, openCreateModal] = useState(false);
    const [isEditModalOpened, openEditModal] = useState(false);

    function isUserAllowed() {
        if (currentUser) {
            if (!currentUser.roles.includes("ROLE_MANAGER"))
                return <Redirect to="/login" />;
        }
        else
            return <Redirect to="/login" />;
    }
    isUserAllowed();

    useEffect(() => {
        getTransactions();
    }, [statusFilter]);

    useEffect(() => {
        setTablePage(0);
    }, [tableSearchValue, tableSearchFields]);

    async function getTransactions() {
        let result = await axios.get(process.env.REACT_APP_API_URL + `api/mobile/appConceptTrans/${statusFilter}`, { headers: authHeader() })
        if (result)
            setTransactions(result.data);
    }

    async function handleStatusChange(transaction, statusId) {
        statusId = (!statusId) ? 2 : 1;
        try {
            await axios.put(process.env.REACT_APP_API_URL + `api/admin/toggleTransaction/${transaction.tcId}`, {
                statusId
            }, {
                headers: authHeader()
            });
            getTransactions();
        } catch (error) {
            getTransactions();
        }
    }

    function getTransactionRow(transaction) {
        return (
            <tr key={transaction.tcId}>
                <td className="center-align">{transaction.description}</td>
                <td className="center-align">{transaction.conceptType}</td>
                <td className="center-align">{transaction.userName}</td>
                <td className="center-align">
                    <div className="switch">
                        <label>
                            Inactive
                            <input type="checkbox" checked={transaction.statusId == 1 ? true : false} onChange={(event) => handleStatusChange(transaction, event.target.checked)} />
                            <span className="lever"></span>
                            Active
                        </label>
                    </div>
                </td>
                <td className="center-align">
                    <a onClick={() => {
                        setCurrentTransaction(transaction);
                        openEditModal(true);
                    }} href="#modal-edit" className="btn-floating waves-effect waves-light black white-text">
                        <i className="material-icons">create</i>
                    </a>
                </td>
            </tr>
        );
    }

    return (
        <div className="container">
            <div className="row valign-wrapper">
                <div className="hide-on-small-only col m9" style={{ fontVariant: 'small-caps', fontFamily: 'Raleway', fontSize: '3rem' }}>
                    Transactions
                </div>
                <a href="#add-vehicle-member" onClick={() => openCreateModal(true)} className="col s12 m3 waves-effect waves-light btn z-depth-0 white-text" style={{ fontFamily: 'Raleway', fontSize: '1rem', backgroundColor: 'black', marginTop: '10px', marginRight: '10px' }}>
                    ADD TRANSACTION
                </a>
            </div>
            <hr />
            <div className="row" style={{ padding: '20px 0px' }}>
                <div className="input-field col s8">
                    <TextControl id="tableSearchValue" value={tableSearchValue} inputEventHandler={setTableSearchValue} focusEventHandler={() => null} />
                    <label htmlFor="tableSearchValue">Search</label>
                </div>
                <div className="input-field col s4">
                    <SelectControl value={statusFilter} inputEventHandler={setStatusFilter} focusEventHandler={() => null}>
                        <option value="1" selected>Active</option>
                        <option value="2">Inactive</option>
                    </SelectControl>
                </div>
            </div>
            <div className="row z-depth-1 white" style={{ padding: '10px', margin: '20px 0px' }}>
                <Table
                    style={{ tableLayout: 'auto' }}
                    tableHead={
                        <thead>
                            <tr className="center-align">
                                <th className="center-align">Description</th>
                                <th className="center-align">Concept Type</th>
                                <th className="center-align">User name</th>
                                <th className="center-align">Status</th>
                                <th className="center-align">Edit</th>
                            </tr>
                        </thead>
                    }
                    tableBody={
                        (rows) => <tbody>{rows}</tbody>}
                    tableFoot={null}
                    row={getTransactionRow}
                    data={transactions}
                    searchValue={tableSearchValue}
                    searchFields={tableSearchFields}
                    show={10}
                    page={tablePage}
                    pagination={(pageRowAmount) => {
                        return (
                            <div className="row" style={{ marginTop: '20px' }}>
                                <div className="col s12 left">
                                    <a onClick={() => setTablePage(tablePage - 1)} disabled={!tablePage} className="waves-effect waves-black btn-flat">Previous</a>
                                    {(tablePage > 1 &&
                                        <>
                                            <a onClick={() => setTablePage(0)} className="waves-effect waves-black btn-flat">1</a>
                                            <span> ... </span>
                                        </>)}
                                    {(tablePage > 0 &&
                                        <a onClick={() => setTablePage(tablePage - 1)} className="waves-effect waves-black btn-flat">{tablePage}</a>)}
                                    <a disabled={true} className="waves-effect waves-black btn-flat">{tablePage + 1}</a>
                                    {(tablePage + 1 < pageRowAmount &&
                                        <a onClick={() => setTablePage(tablePage + 1)} className="waves-effect waves-black btn-flat">{tablePage + 2}</a>)}
                                    {(tablePage + 2 < pageRowAmount &&
                                        <>
                                            <span> ... </span>
                                            <a onClick={() => setTablePage(pageRowAmount - 1)} className="waves-effect waves-black btn-flat">{pageRowAmount}</a>
                                        </>)}
                                    <a onClick={() => setTablePage(tablePage + 1)} disabled={!(tablePage + 1 < pageRowAmount)} className="waves-effect waves-black btn-flat">Next</a>
                                </div>
                            </div>
                        );
                    }}
                />
            </div>

            <CreateTransactionModal isOpen={isCreateModalOpened} onClose={() => openCreateModal(false)} updateTable={getTransactions} />
            <EditTransactionModal isOpen={isEditModalOpened} onClose={() => openEditModal(false)} updateTable={getTransactions} currentTransaction={currentTransaction} />
        </div>
    );
}
export default Transactions;