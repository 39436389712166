import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import axios from "axios";
import ReactModal from 'react-modal';

import authHeader from "../../services/auth-header";
import Table from '../Table';
import { TextControl, SelectControl } from './Controls';
import CreateVehicleModal from './modals/CreateVehicleModal';
import EditVehicleModal from './modals/EditVehicleModal';
import DetailsVehicleModal from './modals/DetailsVehicleModal';

ReactModal.setAppElement('#root');

function Vehicles() {

    const { user: currentUser } = useSelector((state) => state.auth);

    const [vehicles, setVehicles] = useState([]);
    const [currentVehicle, setCurrentVehicle] = useState({});

    const [statusFilter, setStatusFilter] = useState(1);
    const [driverFilter, setDriverFilter] = useState(2);
    const [cabFilter, setCabFilter] = useState(0);

    const [tablePage, setTablePage] = useState(0);
    const [tableSearchValue, setTableSearchValue] = useState('');
    const [tableSearchFields, setTableSearchFields] = useState(['vehicleBrand', 'vehicleModel']);

    const [isCreateModalOpened, openCreateModal] = useState(false);
    const [isDetailsModalOpened, openDetailsModal] = useState(false);
    const [isEditModalOpened, openEditModal] = useState(false);

    function isUserAllowed() {
        if (currentUser) {
            if (!currentUser.roles.includes("ROLE_MANAGER"))
                return <Redirect to="/login" />;
        }
        else
            return <Redirect to="/login" />;
    }
    isUserAllowed();

    useEffect(() => {
        getVehicles();
    }, []);

    useEffect(() => {
        getVehicles();
    }, [statusFilter, cabFilter, driverFilter])

    useEffect(() => {
        setTablePage(0);
    }, [tableSearchValue, tableSearchFields, driverFilter, statusFilter, cabFilter]);

    async function getVehicles() {
        let cabNumberFilter = cabFilter;
        if (cabFilter == '')
            cabNumberFilter = 0;

        let result = await axios.get(process.env.REACT_APP_API_URL + `api/admin/viewVehicles/${statusFilter}/${cabNumberFilter}/${driverFilter}`, { headers: authHeader() })
        if (result)
            setVehicles(result.data);
    }

    function getVehicleTypeClass(type, driverId) {
        if (!driverId)
            return '';
        if (type == 'D')
            return 'green lighten-3';
        return 'blue lighten-3';
    }

    function getVehicleRow(vehicle) {
        return (
            <tr key={vehicle.vehicleId}>
                <td className="center-align">
                    <div className={getVehicleTypeClass(vehicle.type, vehicle.driverId)} style={{ borderRadius: '5px' }}>
                        {vehicle.driverId ? vehicle.type : ''}
                    </div>
                </td>
                <td className="center-align">{vehicle.cab}</td>
                <td className="center-align">{vehicle.vehicleBrand} — {vehicle.vehicleModel}</td>
                <td className="center-align">{vehicle.firstName ? `${vehicle.firstName} — ${vehicle.lastName}` : 'None'}</td>
                <td className="center-align">
                    <div className="switch">
                        <label>
                            Inactive
                            <input type="checkbox" checked={vehicle.statusId == 1 ? true : false} onChange={(event) => handleStatusChange(vehicle, event.target.checked)} />
                            <span className="lever"></span>
                            Active
                        </label>
                    </div>
                </td>
                <td className="center-align">
                    <a onClick={() => {
                        setCurrentVehicle(vehicle);
                        openDetailsModal(true);
                    }} href="#modal-details" className="btn-floating waves-effect waves-light black white-text">
                        <i className="material-icons">remove_red_eye</i>
                    </a>
                </td>
                <td className="center-align">
                    <a onClick={() => {
                        setCurrentVehicle(vehicle);
                        openEditModal(true);
                    }} href="#modal-edit" className="btn-floating waves-effect waves-light black white-text">
                        <i className="material-icons">create</i>
                    </a>
                </td>
            </tr>
        );
    }

    async function handleStatusChange(vehicle, statusId) {
        statusId = (!statusId) ? 2 : 1;
        try {
            await axios.put(process.env.REACT_APP_API_URL + `api/admin/toggleVehicle/${vehicle.vehicleId}`, {
                statusId
            }, {
                headers: authHeader()
            });
            getVehicles();
        } catch (error) {
            getVehicles();
        }
    }

    return (
        <div className="container">
            <div className="row valign-wrapper">
                <div className="hide-on-small-only col m9" style={{ fontVariant: 'small-caps', fontFamily: 'Raleway', fontSize: '3rem' }}>
                    Vehicles
                </div>
                <a href="#add-vehicle-member" onClick={() => openCreateModal(true)} className="col s12 m3 waves-effect waves-light btn z-depth-0 white-text" style={{ fontFamily: 'Raleway', fontSize: '1rem', backgroundColor: 'black', marginTop: '10px', marginRight: '10px' }}>
                    ADD VEHICLE
                </a>
            </div>
            <hr />
            <div className="row" style={{ padding: '20px 0px' }}>
                <div className="input-field col s12">
                    <TextControl id="tableSearchValue" value={tableSearchValue} inputEventHandler={setTableSearchValue} focusEventHandler={() => null} />
                    <label htmlFor="tableSearchValue">Search</label>
                </div>
            </div>
            <div className="row">
                <div className="col s12">
                    <div className="row">
                        <div className="col s4 center-align">
                            <span>Status</span>
                        </div>
                        <div className="col s4 center-align">
                            <span>Driver</span>
                        </div>
                        <div className="col s4 center-align">
                            <span>Cab Number</span>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col s4">
                            <SelectControl value={statusFilter} inputEventHandler={setStatusFilter} focusEventHandler={() => null}>
                                <option value="1" selected>Active</option>
                                <option value="2" selected>Inactive</option>
                            </SelectControl>
                        </div>
                        <div className="col s4">
                            <SelectControl value={driverFilter} inputEventHandler={setDriverFilter} focusEventHandler={() => null}>
                                <option value="2" selected>All</option>
                                <option value="0" selected>Assigned to a driver</option>
                                <option value="1" selected>Yet to be assigned</option>
                            </SelectControl>
                        </div>
                        <div className="col s4">
                            <TextControl value={cabFilter} inputEventHandler={setCabFilter} focusEventHandler={() => null} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="row z-depth-1 white" style={{ padding: '10px', margin: '20px 0px' }}>
                <Table
                    style={{ tableLayout: 'auto' }}
                    tableHead={
                        <thead>
                            <tr className="center-align">
                                <th className="center-align">Type</th>
                                <th className="center-align">Cab #</th>
                                <th className="center-align">Description</th>
                                <th className="center-align">Driver</th>
                                <th className="center-align">Status</th>
                                <th className="center-align">Details</th>
                                <th className="center-align">Edit</th>
                            </tr>
                        </thead>
                    }
                    tableBody={
                        (rows) => <tbody>{rows}</tbody>}
                    tableFoot={null}
                    row={getVehicleRow}
                    data={vehicles}
                    searchValue={tableSearchValue}
                    searchFields={tableSearchFields}
                    show={10}
                    page={tablePage}
                    pagination={(pageRowAmount) => {
                        return (
                            <div className="row" style={{ marginTop: '20px' }}>
                                <div className="col s12 left">
                                    <a onClick={() => setTablePage(tablePage - 1)} disabled={!tablePage} className="waves-effect waves-black btn-flat">Previous</a>
                                    {(tablePage > 1 &&
                                        <>
                                            <a onClick={() => setTablePage(0)} className="waves-effect waves-black btn-flat">1</a>
                                            <span> ... </span>
                                        </>)}
                                    {(tablePage > 0 &&
                                        <a onClick={() => setTablePage(tablePage - 1)} className="waves-effect waves-black btn-flat">{tablePage}</a>)}
                                    <a disabled={true} className="waves-effect waves-black btn-flat">{tablePage + 1}</a>
                                    {(tablePage + 1 < pageRowAmount &&
                                        <a onClick={() => setTablePage(tablePage + 1)} className="waves-effect waves-black btn-flat">{tablePage + 2}</a>)}
                                    {(tablePage + 2 < pageRowAmount &&
                                        <>
                                            <span> ... </span>
                                            <a onClick={() => setTablePage(pageRowAmount - 1)} className="waves-effect waves-black btn-flat">{pageRowAmount}</a>
                                        </>)}
                                    <a onClick={() => setTablePage(tablePage + 1)} disabled={!(tablePage + 1 < pageRowAmount)} className="waves-effect waves-black btn-flat">Next</a>
                                </div>
                            </div>
                        );
                    }}
                />
            </div>

            <CreateVehicleModal isOpen={isCreateModalOpened} onClose={() => openCreateModal(false)} updateTable={getVehicles} />
            <EditVehicleModal isOpen={isEditModalOpened} onClose={() => openEditModal(false)} updateTable={getVehicles} vehicle={currentVehicle} />
            <DetailsVehicleModal isOpen={isDetailsModalOpened} onClose={() => openDetailsModal(false)} updateTable={getVehicles} vehicle={currentVehicle} />
        </div>
    );
}
export default Vehicles;