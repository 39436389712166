import React, { useState, useEffect } from 'react';
import axios from 'axios';

import Table from '../Table';
import { TextControl, SelectControl } from './Controls';
import authHeader from "../../services/auth-header";
import Validate from '../../helpers/validate';

function VehiclePlateExpiration() {

    const [vehicles, setVehicles] = useState([]);
    const [loadingState, setLoadingState] = useState(true);
    const [sortBy, setSortBy] = useState(0);
    const [expiresInDays, setExpiresInDays] = useState(10);
    const [today] = useState(new Date());

    const [tablePage, setTablePage] = useState(0);
    const [tableSearchValue, setTableSearchValue] = useState('');
    const [tableSearchFields, setTableSearchFields] = useState(['cab', 'vehiclePlates', 'vehicleType', 'expiration']);

    useEffect(() => {
        setLoadingState(true);
        getAllVehicles();
    }, [sortBy, expiresInDays])

    async function getAllVehicles() {
        const result = await axios.get(process.env.REACT_APP_API_URL + 'api/admin/viewAllVehicles', { headers: authHeader() });
        if (result) {
            const data = result.data;
            initSorting(data);
            setLoadingState(false);
        }
    }

    function initSorting(data) {
        if (!sortBy) {
            loopThroughAsync(data, getClosestToExpire, (result) => {
                setVehicles(result);
            });
            return;
        }
        // if (!Validate.isInt(expiresInDays)) {
        //     setVehicles([]);
        //     return;
        // }
        // loopThroughAsync(data, getExpiresInSetDays, (result) => {
        //     setVehicles(result);
        // });
    }

    function loopThroughAsync(data, fn, callback, filteredData = [], lastIndex = 0) {
        const endIndex = lastIndex + 10;
        for (; lastIndex < endIndex; lastIndex++) {
            const element = data[lastIndex];
            if (!element) {
                callback(filteredData);
                return;
            }
            if (fn(element))
                filteredData.push(element);
            if (lastIndex == (endIndex - 1))
                setTimeout(() => loopThroughAsync(data, fn, callback, filteredData, lastIndex));
        }
    }

    function getClosestToExpire(element) {
        if (element.plateExpiration) {
            const plateExpiration = new Date(element.plateExpiration);
            element.timeRemaining = (plateExpiration - today);
            element.daysRemaining = element.timeRemaining / (60 * 60 * 24 * 1000);
            element.plateExpirationDate = plateExpiration.toISOString().substring(0, 10);
            return true;
        }
        return false;
    }

    // function getExpiresInSetDays(element) {

    // }

    function getVehicleRow(vehicle) {
        return (
            <tr key={vehicle.vehicleId}>
                <td className="center-align">
                    <div className={vehicle.vehicleType == 'D' ? 'green lighten-3' : 'blue lighten-3'} style={{ borderRadius: '5px' }}>
                        {vehicle.vehicleType}
                    </div>
                </td>
                <td className="center-align">{vehicle.cab}</td>
                <td className="center-align">{vehicle.vehiclePlates}</td>
                <td className="center-align">
                    {vehicle.plateExpirationDate} {getPlateExpirationText(vehicle)}
                </td>
            </tr>
        );
    }

    function getPlateExpirationText(element) {
        const daysRemaining = element.daysRemaining;
        if (daysRemaining <= 0) {
            if (daysRemaining > -1 && daysRemaining <= 0) {
                element.expiration = 'expires today';
                return <Text color='yellow lighten-2'>Expires today</Text>;
            }
            if (daysRemaining > -2 && daysRemaining <= -1) {
                element.expiration = 'expired yesterday';
                return <Text color='orange lighten-2'>Expired yesterday</Text>;
            }
            element.expiration = `Expired ${Math.floor(daysRemaining * -1)} days ago`;
            return <Text color='red lighten-1'>Expired {Math.floor(daysRemaining * -1)} days ago</Text>;
        }
        // daysRemaining > 0
        if (daysRemaining > 0 && daysRemaining <= 1) {
            element.expiration = 'expires tomorrow';
            return <Text color='blue lighten-2'>Expires tomorrow</Text>;
        }
        element.expiration = `Expires in ${Math.floor(daysRemaining)} days`;
        return <Text color='green lighten-2'>Expires in {Math.floor(daysRemaining)} days</Text>;
    }

    return (

        <div className="white z-depth-1 animate fadeLeft delay-1 grey-text text-darken-4" style={{ marginTop: '15px', padding: '15px' }}>
            <div className="row" style={{ padding: '20px 0px' }}>
                <div className="input-field col s12">
                    <TextControl id="tableSearchValue" value={tableSearchValue} inputEventHandler={setTableSearchValue} focusEventHandler={() => null} />
                    <label htmlFor="tableSearchValue">Search</label>
                </div>
            </div>
            {/* <div className="row">
                <div className="col s12">
                    <div className="row">
                        <div className="col s6 center-align">
                            <span>Sort by</span>
                        </div>
                        <div className="col s6 center-align">
                            <span>Days</span>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col s6">
                            <SelectControl value={sortBy} inputEventHandler={setSortBy} focusEventHandler={() => null}>
                                <option value="0" selected>Closest to expire</option>
                                <option value="1">Expires in set amount of days</option>
                            </SelectControl>
                        </div>
                        <div className="col s6">
                            <input disabled={sortBy == 0} type="text" value={expiresInDays} onChange={(event) => setExpiresInDays(event.target.value)} />
                        </div>
                    </div>
                </div>
            </div> */}
            {loadingState == true &&
                <div class="progress">
                    <div class="indeterminate"></div>
                </div>
            }
            {loadingState == false &&
                <div style={{ padding: '10px', margin: '20px 0px' }}>
                    <Table
                        style={{ tableLayout: 'auto' }}
                        tableHead={
                            <thead>
                                <tr className="center-align">
                                    <th className="center-align">Type</th>
                                    <th className="center-align">Cab #</th>
                                    <th className="center-align">Plates</th>
                                    <th className="center-align">Expiration</th>
                                </tr>
                            </thead>
                        }
                        tableBody={
                            (rows) => <tbody>{rows}</tbody>}
                        tableFoot={null}
                        row={getVehicleRow}
                        data={vehicles}
                        searchValue={tableSearchValue}
                        searchFields={tableSearchFields}
                        show={30}
                        page={tablePage}
                        pagination={(pageRowAmount) => {
                            return (
                                <div className="row" style={{ marginTop: '20px' }}>
                                    <div className="col s12 left">
                                        <a onClick={() => setTablePage(tablePage - 1)} disabled={!tablePage} className="waves-effect waves-black btn-flat">Previous</a>
                                        {(tablePage > 1 &&
                                            <>
                                                <a onClick={() => setTablePage(0)} className="waves-effect waves-black btn-flat">1</a>
                                                <span> ... </span>
                                            </>)}
                                        {(tablePage > 0 &&
                                            <a onClick={() => setTablePage(tablePage - 1)} className="waves-effect waves-black btn-flat">{tablePage}</a>)}
                                        <a disabled={true} className="waves-effect waves-black btn-flat">{tablePage + 1}</a>
                                        {(tablePage + 1 < pageRowAmount &&
                                            <a onClick={() => setTablePage(tablePage + 1)} className="waves-effect waves-black btn-flat">{tablePage + 2}</a>)}
                                        {(tablePage + 2 < pageRowAmount &&
                                            <>
                                                <span> ... </span>
                                                <a onClick={() => setTablePage(pageRowAmount - 1)} className="waves-effect waves-black btn-flat">{pageRowAmount}</a>
                                            </>)}
                                        <a onClick={() => setTablePage(tablePage + 1)} disabled={!(tablePage + 1 < pageRowAmount)} className="waves-effect waves-black btn-flat">Next</a>
                                    </div>
                                </div>
                            );
                        }}
                    />
                </div>
            }
        </div>
    );
}

function Text(props) {
    return <span className={`black-text ` + props.color} style={{ fontSize: '0.8em', marginLeft: '7px', borderRadius: '15px', padding: '6px' }}>{props.children}</span>;
}

export default VehiclePlateExpiration;