import React, { useEffect, useState } from "react";
import ReactModal from 'react-modal';

import axios from "axios";

import Validate from '../../../helpers/validate';
import authHeader from '../../../services/auth-header';
import { TextControl, SelectControl } from '../Controls';

function EditVoucherCodeModal(props) {

    const [responseCode, setResponseCode] = useState('');
    const [responseMessage, setResponseMessage] = useState('');
    const [responseDef, setResponseDef] = useState('');
    const [portalResponse, setPortalResponse] = useState('');
    const [errorMessage, setErrorMessage] = useState({});

    useEffect(() => {
        if (props.isOpen) {
            setResponseCode(props.voucherCode.responseCode);
            setResponseMessage(props.voucherCode.responseMessage);
            setResponseDef(props.voucherCode.responseDef);
            setPortalResponse(props.voucherCode.portalResponse);
        }
    }, [props.isOpen])

    async function handleSubmit() {
        const validate = new Validate();
        const message = {};
        message.responseCode = validate.init(responseCode, {
            maxLength: {
                value: 2,
                message: 'The maximum length possible is 2 characters'
            },
            minLength: {
                value: 1,
                message: 'The minimum length possible of the description is 1 character'
            }
        }).getHTML();
        message.responseMessage = validate.init(responseMessage, {
            maxLength: {
                value: 254,
                message: 'The maximum length possible is 254 characters'
            },
            minLength: {
                value: 1,
                message: 'The minimum length possible of the description is 1 character'
            }
        }).getHTML();
        message.responseDef = validate.init(responseDef, {
            maxLength: {
                value: 254,
                message: 'The maximum length possible is 254 characters'
            },
            minLength: {
                value: 1,
                message: 'The minimum length possible of the description is 1 character'
            }
        }).getHTML();
        message.portalResponse = validate.init(portalResponse, {
            maxLength: {
                value: 254,
                message: 'The maximum length possible is 254 characters'
            },
            minLength: {
                value: 1,
                message: 'The minimum length possible of the description is 1 character'
            }
        }).getHTML();
        setErrorMessage(message);

        if (validate.status) {
            try {
                await axios.put(process.env.REACT_APP_API_URL + `api/admin/updateVoucherResponseCode/${props.voucherCode.vrcId}`, {
                    responseCode, responseMessage, responseDef, portalResponse
                }, {
                    headers: authHeader()
                });

                clearControls();
                props.updateTable();
                props.onClose();
            } catch (error) {
                const serverError = JSON.parse(error.request.responseText).message;
                setErrorMessage({ serverError: <span>{serverError}</span> });
            }
        }
    }

    function handleFocus(event) {
        const element = event.target.id,
            messages = errorMessage;
        messages[element] = null;
        setErrorMessage(messages);
    }

    function clearControls() {
        setResponseCode();
        setResponseMessage();
        setResponseDef();
        setPortalResponse();
        setErrorMessage({});
    }

    return (
        <ReactModal isOpen={props.isOpen} style={{ overlay: { zIndex: '999' } }}>
            <div className="container">
                <div className="row">
                    <div className="col s11 left hide-on-small-only" style={{ fontVariant: 'small-caps', fontFamily: 'Raleway', fontSize: '2rem' }}>
                        Edit Voucher Code
                    </div>
                    <div className="col s1 right">
                        <a onClick={() => {
                            clearControls();
                            props.onClose();
                        }} className="btn-floating waves-effect waves-light black white-text"><i className="material-icons">close</i></a>
                    </div>
                </div>
                <hr />
                <CustomRow>
                    <div className="input-field col s8" style={{ marginTop: '35px' }}>
                        <TextControl id="responseCode" name="responseCode" value={responseCode} inputEventHandler={setResponseCode} focusEventHandler={handleFocus} maxlength="100" />
                        <label className="active" htmlFor="responseCode">Response Code</label>
                        <span data-assigned="responseCode" className="helper-text red-text text-darken-1">{errorMessage.responseCode}</span>
                    </div>
                </CustomRow>
                <CustomRow>
                    <div className="input-field col s8" style={{ marginTop: '35px' }}>
                        <TextControl id="responseMessage" name="responseMessage" value={responseMessage} inputEventHandler={setResponseMessage} focusEventHandler={handleFocus} maxlength="100" />
                        <label className="active" htmlFor="responseMessage">Response Message</label>
                        <span data-assigned="responseMessage" className="helper-text red-text text-darken-1">{errorMessage.responseMessage}</span>
                    </div>
                </CustomRow>
                <CustomRow>
                    <div className="input-field col s8" style={{ marginTop: '35px' }}>
                        <TextControl id="responseDef" name="responseDef" value={responseDef} inputEventHandler={setResponseDef} focusEventHandler={handleFocus} maxlength="100" />
                        <label className="active" htmlFor="responseDef">Response Def</label>
                        <span data-assigned="responseDef" className="helper-text red-text text-darken-1">{errorMessage.responseDef}</span>
                    </div>
                </CustomRow>
                <CustomRow>
                    <div className="input-field col s8" style={{ marginTop: '35px' }}>
                        <TextControl id="portalResponse" name="portalResponse" value={portalResponse} inputEventHandler={setPortalResponse} focusEventHandler={handleFocus} maxlength="100" />
                        <label className="active" htmlFor="portalResponse">Portal Response</label>
                        <span data-assigned="portalResponse" className="helper-text red-text text-darken-1">{errorMessage.portalResponse}</span>
                    </div>
                </CustomRow>
                <CustomRow>
                    <span className="helper-text red-text text-darken-1">{errorMessage.serverError}</span>
                </CustomRow>
                <CustomRow>
                    <div className="input-field col s8">
                        <a onClick={handleSubmit} style={{ width: '100%', fontFamily: 'Raleway', fontSize: '1rem', backgroundColor: 'black', marginTop: '30px' }} className="waves-effect waves-light btn z-depth-0 white-text">SAVE & CLOSE</a>
                    </div>
                </CustomRow>
            </div>
        </ReactModal>
    );
}

function CustomRow(props) {
    return (
        <div className={`row ${props.className}`}>
            <div className="col s2"></div>
            {props.children}
            <div className="col s2"></div>
        </div>
    );
}

export default EditVoucherCodeModal;