import React, { useState, useEffect, useRef, Item } from "react";
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import { useDispatch, useSelector } from "react-redux";
import swal from 'sweetalert';
import { capitalize } from "../../helpers/functions";
import CreatableSelect from 'react-select/creatable';
import { Select,  DatePicker, TimePicker, ProgressBar,Button,Tabs,Tab } from "react-materialize"
import Form from "react-validation/build/form";
import Input from 'react-validation/build/input';
import { getLastOrigin, getLastDestination, updateServiceDestinationLocation, updateReservationDestinationLocation, updateServiceOriginLocation, updateReservationOriginLocation, updateServicePaymentType, updateReservationPaymentType,  createEmptyService, getTopicsComments, createNewCustomer,loadCustomerByPhone,updateField,getAvailables,saveService,getOriginArizonaCitiesByCounty,getDestinationArizonaCitiesByCounty, getArizonaCounties, getNewServiceRate } from "../../actions/operator/dashboard";
import GoogleMapReact from "google-map-react";
import Geocode from "react-geocode";
import moment from 'moment';

const OperatorNewService = (props) => {
    const dispatch = useDispatch();
    
    const { lastDestination, lastOrigin, filters, servicePaymentTypeUpdated, newServiceRate,newServiceRateLoading,customer,counties,countiesLoading,arizonaCountyCitiesOrigin,arizonaCountyCitiesDestination,citiesLoadingOrigin,citiesLoadingDestination } = useSelector(state => state.operator);
    const [customerType , setCustomerType] = useState([
        {value:1,label:'Standard'},
        {value:2,label:'App'},
        {value:3,label:'ADA Senior Cab (B)'},
        {value:4,label:'Senior Center (Y)'}
    ]);
    const [paymentType , setPaymentType] = useState([
        {value:0,label:'Select a payment type'},
        {value:1,label:'Cash'},
        {value:2,label:'Debit/credit card'},
        {value:3,label:'Retired yellow card'},
        {value:4,label:'Retired blue card'},
        {value:5,label:'Paypal'}
    ]);
    const [locationTypes , setLocationTypes] = useState([
        {value:1,label:'Home'},
        {value:2,label:'Apt'},
        {value:3,label:'Office'},
        {value:4,label:'Trailer'},
        {value:5,label:'Hospital'},
        {value:6,label:'Clinic'},
        {value:7,label:'Business'},
        {value:8,label:'Exterior'},
        {value:9,label:'Hotel'},
    ]);

    const [locationType , setLocationType] = useState({value:1,label:'Home'});

    const [selectCitiesOrigin , setSelectCitiesOrigin] = useState(null);
    const [selectCitiesDestination , setSelectCitiesDestination] = useState(null);
    const [selectCountyOrigin , setSelectCountyOrigin] = useState(null);
    const [selectCountyDestination , setSelectCountyDestination] = useState(null);
    const [selectLastOrigin , setSelectLastOrigin] = useState(null);
    const [selectLastDestination , setSelectLastDestination] = useState(null);
    const { user: currentUser } = useSelector((state) => state.auth);
    const { isLoggedIn } = useSelector(state => state.auth);

    /* select multiples zipcodes */

    const [currentMZModal , setCurrentMZModal] = useState(false);
    const [currentMZFunction , setCurrentMZFunction] = useState(null);
    const [currentMZOptions , setCurrentMZOptions] = useState([]);

    /* select multiples zipcodes */


    const [item,setItem] = useState({});

    useEffect(() => {

        dispatch(getArizonaCounties());
        dispatch(getTopicsComments());

        Geocode.setApiKey("AIzaSyCAoQ5Jml2jW_sOCP0kKFAJLQXBmmuEr-k");
        Geocode.setLanguage("en");
        Geocode.setRegion("us");

//https://maps.googleapis.com/maps/api/geocode/xml?address=1600+Amphitheatre+Parkway,+Mountain+View,+CA&key=AIzaSyCAoQ5Jml2jW_sOCP0kKFAJLQXBmmuEr-k

        //console.log(Geocode);

        //Geocode.setLocationType("ROOFTOP");
        Geocode.enableDebug();
        
        

        let itemTemp = {...props.service};
        console.log('itemTemp',itemTemp);

        dispatch(getLastOrigin(itemTemp.customerId));
        dispatch(getLastDestination(itemTemp.customerId));

        if('Reservation' in props.service){
            let pud = new Date(props.service.pickupDate);
            let put = props.service.pickupTime;
            itemTemp.type = 2;
            itemTemp.pickupDateData = pud;
            itemTemp.pickupTimeData = put;
        }else{
            itemTemp.type = 1;
            itemTemp.pickupDateData = null;
            itemTemp.pickupTimeData = null;
        }

        itemTemp.customerTypeIdValue = customerType.filter((val)=>{
            if(val.value == props.service.customerTypeId){
                return val;
            }
        });

        itemTemp.paymentTypeIdValue = paymentType.filter((val)=>{
            if(val.value == props.service.paymentTypeId){
                return val;
            }
        });

        if(itemTemp.locationType != null){
            let locationTypeTemp = locationTypes.filter((val)=>{
                if(val.value == itemTemp.locationType){
                    return val;
                }
            });
            setLocationType((locationTypeTemp.length>0?locationTypeTemp[0]:locationTypes[0]));
        }else{
            setLocationType(locationTypes[0]);
        }

        if(itemTemp.locationTypeValue == null){
            itemTemp.locationTypeValue = '';
        }

        var ol = parseLocation(itemTemp.originLocation);
        var dl = parseLocation(itemTemp.destinationLocation);

        itemTemp.olStreet = ol.street;
        itemTemp.olNumber = ol.number;
        itemTemp.olNsew = ol.nsew;
        itemTemp.olZipcode = ol.zipcode;
        itemTemp.olCity = ol.city;
        itemTemp.olCityId = ol.cityId;
        itemTemp.olCounty = ol.county;
        itemTemp.olCountyId = ol.countyId;
        itemTemp.olState = ol.state;
        itemTemp.olStateId = ol.stateId;
        itemTemp.olCountry = ol.country;
        itemTemp.olCountryId = ol.countryId;

        itemTemp.dlStreet = dl.street;
        itemTemp.dlNumber = dl.number;
        itemTemp.dlNsew = dl.nsew;
        itemTemp.dlZipcode = dl.zipcode;
        itemTemp.dlCity = dl.city;
        itemTemp.dlCityId = dl.cityId;
        itemTemp.dlCounty = dl.county;
        itemTemp.dlCountyId = dl.countyId;
        itemTemp.dlState = dl.state;
        itemTemp.dlStateId = dl.stateId;
        itemTemp.dlCountry = dl.country;
        itemTemp.dlCountryId = dl.countryId;

        setItem(itemTemp);
    },[]);
    
    const parseLocation = (location) => {
        var response = {}
        response.street = "";
        response.number = "";
        response.nsew = "";
        response.zipcode = "";
        response.city = "";
        response.cityId = "";
        response.county = "";
        response.countyId = "";
        response.state = "Arizona";
        response.stateId = 3;
        response.country = "USA";
        response.countryId = 35;

        if(location){
            var t1 = location.split(',').map(l=>l.trim());
            if(t1.length == 5){
                var t2 = /^(.+)[ ]([N|S|E|W])[ ](.+)$/g.exec(t1[0]);
                if(t2 != null && t2.length == 4){
                    response.number = t2[1];
                    response.nsew = t2[2];
                    response.street = t2[3];
                }
                response.city = t1[1];
                response.county = t1[2];
                response.zipcode = t1[4];
            }else if(t1.length == 6){
                var t2 = /^(.+)[ ]([N|S|E|W])[ ](.+)$/g.exec(t1[0]);
                if(t2 != null && t2.length == 4){
                    response.number = t2[1];
                    response.nsew = t2[2];
                    response.street = t2[3];
                }
                response.city = t1[1];
                response.county = t1[2];
                response.zipcode = t1[4];
            }
        }
        return response;
    }


    useEffect(() => {
         if(counties.length > 8){
            let selectedOCounty = counties.filter((county)=>{return (county.label == item.olCounty);});
            if(selectedOCounty.length == 1){
                setSelectCountyOrigin(selectedOCounty[0]);
                setItem({...item,olCountyId:selectedOCounty[0].value});
                dispatch(getOriginArizonaCitiesByCounty(selectedOCounty[0].value));
            }

            let selectedDCounty = counties.filter((county)=>{return (county.label == item.dlCounty);});
            if(selectedDCounty.length == 1){
                setSelectCountyDestination(selectedDCounty[0]);
                setItem({...item,dlCountyId:selectedDCounty[0].value});
                dispatch(getDestinationArizonaCitiesByCounty(selectedDCounty[0].value));
            }
        }

    },[counties]);

    useEffect(() => {
        if(arizonaCountyCitiesDestination.length > 17){
            let selectedDCity = arizonaCountyCitiesDestination.filter((city)=>{return (city.label == item.dlCity);});
            if(selectedDCity.length == 1){
                setSelectCitiesDestination(selectedDCity[0]);
                setItem({...item,dlCityId:selectedDCity[0].value});
            }
        }
    },[arizonaCountyCitiesDestination]);

    useEffect(() => {
        if(arizonaCountyCitiesOrigin.length > 17){
            let selectedOCity = arizonaCountyCitiesOrigin.filter((city)=>{return (city.label == item.olCity);});
            if(selectedOCity.length == 1){
                setSelectCitiesOrigin(selectedOCity[0]);
                setItem({...item,olCityId:selectedOCity[0].value});
            }
        }
    },[arizonaCountyCitiesOrigin]);


    useEffect(() => {
        if(arizonaCountyCitiesOrigin.length > 17){
            let selectedOCity = arizonaCountyCitiesOrigin.filter((city)=>{return (city.label == item.olCity);});
            if(selectedOCity.length == 1){
                setSelectCitiesOrigin(selectedOCity[0]);
                setItem({...item,olCityId:selectedOCity[0].value});
            }
        }
    },[customer.originCountyLabel]);

    useEffect(() => {
        if(arizonaCountyCitiesOrigin.length > 17){
            let selectedOCity = arizonaCountyCitiesOrigin.filter((city)=>{return (city.label == item.olCity);});
            if(selectedOCity.length == 1){
                setSelectCitiesOrigin(selectedOCity[0]);
                setItem({...item,olCityId:selectedOCity[0].value});
            }
        }
    },[customer.originCityLabel]);


    const getZipCode = (n,o,s,r) => {

        let address = n+" "+o+" "+s;

        var zipCodesCount = 0;
        var zipCodes = {};

        console.log("address",address);

        Geocode.fromAddress(address).then(
            response => {
                
                if(response != null && response.results != null && response.results.length > 0){
                    for (const r in response.results){
                        for(const a in response.results[r].address_components){
                            if(response.results[r].address_components[a].types.includes("postal_code")){
                                zipCodes[response.results[r].address_components[a].long_name] = {zipcode:response.results[r].address_components[a].long_name,value:response.results[r]}
                                zipCodesCount += 1;
                            }
                        }
                    }
                }

                if(zipCodesCount == 0){
                    swal("Invalid address.");
                    return;
                }
                if(zipCodesCount == 1){
                    swal("The postalcode "+Object.keys(zipCodes)[0]);
                    r(Object.keys(zipCodes)[0]);
                    return;
                }else{
                    setCurrentMZFunction(()=>r);
                    setCurrentMZOptions(zipCodes);
                    setCurrentMZModal(true);
                }

                console.log('zipCodes',zipCodes);
                console.log('zipCodesCount',zipCodesCount);

                // 1234 S Washington St

            },
            error => {
                swal("Invalid address.");
                return;
            }
        );
    }

return (
    
    <Modal open={true} onClose={props.closeButton} classNames={{modal: 'editservice'}}>
        <Modal open={currentMZModal} onClose={()=>{setCurrentMZModal(false);}} classNames={{modal: ''}}>
            <div class="row">
                <h6>ZipCodes</h6>
                <div class="col s12">
                    <table class="responsive-table highlight">
                        <thead>
                            <tr>
                                <th>Address</th>
                                <th>ZipCode</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {(currentMZOptions != undefined) && Object.values(currentMZOptions).map((value) => (
                                <tr>
                                    <td>{value.value.formatted_address}</td>
                                    <td>{value.zipcode}</td>
                                    <td>                        
                                        <a class="waves-effect waves-light btn-large mb-1" onClick={()=>{
                                            currentMZFunction(value.zipcode);
                                            setCurrentMZModal(false);
                                        }}><i class='material-icons left'>send</i></a>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                            </div>
                <div class="col s12">
                    
                    {/*<div class="" style={{ height: 800, width: '100%', display: 'flex', flexFlow: 'row nowrap', justifyContent: 'center', padding: 0 }}>
                        {activedDrivers.items != null && 
                        (<GoogleMapReact
                            bootstrapURLKeys="AIzaSyCAoQ5Jml2jW_sOCP0kKFAJLQXBmmuEr-k"
                            defaultCenter={{lat: 33.5722,lng: -112.088}}
                            center={{lat: 33.5722,lng: -112.088}}
                            defaultZoom={10}
                            zoom={10}
                            onGoogleApiLoaded={({ map, maps }) => loadAllDrivers(map, maps)} >
                            {activedDrivers.items.map((marker) => {
                                if(marker.lastLatitude != null && marker.lastLongitude != null){
                                    return (<Driver lat={marker.lastLatitude} lng={marker.lastLongitude} color={marker.color} />)
                                }
                            })}
                        </GoogleMapReact>)}
                        </div>*/}
                </div>
            </div>
        </Modal>

        <h4>Edit Service {item.id}</h4>
        {/*<pre>{JSON.stringify(item, null, 2)}</pre>*/}
        <div class="row">
            <Form class="col s12">
                {item.type == 2 && (<div class="row">
                    <div class="input-field col s3">
                        <input id="reservationdate" type="text" class="validate" value={item.pickupDate} disabled />
                        <label for="reservationdate" class="active" >Reservation Date</label>
                    </div>
                    <div class="input-field col s3">
                        <input id="reservationtime" type="text" class="validate" value={item.pickupTime} disabled />
                        <label for="reservationtime" class="active" >Reservation Time</label>
                    </div>
                </div>)}
                <div class="row">
                    <div class="card">
                        <div class="card-content">
                            <span class="card-title">Personal</span>
                            <div class="row">
                                <div class="input-field col s6">
                                    <input id="phone" class="col s12" type="tel" maxlength="10" value={props.service.phone} disabled />
                                    <label for="phone" class="active" >Phone</label>
                                </div>
                                {/*<pre>{JSON.stringify(item.customerTypeIdValue, null, 2)}</pre>*/}
                                <CreatableSelect 
                                    isDisabled={true}
                                    placeholder={"Customer Type"}
                                    value={item.customerTypeIdValue}
                                    styles={{menu: provided => ({ ...provided, zIndex: 999999 })}}
                                    className="input-field col s6"
                                    classNamePrefix="select"
                                    defaultValue={item.customerTypeIdValue}
                                    isRtl={false}
                                    name="customertype"
                                    options={customerType}
                                    onChange={(value)=>{
                                        console.log("onchange customertypeId");
                                    }}
                                />
                                
                                {/*<pre>{JSON.stringify(item.paymentTypeIdValue, null, 2)}</pre>*/}
                                <CreatableSelect 
                                    name={"Payment Type"}
                                    placeholder={"Payment Type"}
                                    value={item.paymentTypeIdValue}
                                    styles={{menu: provided => ({ ...provided, zIndex: 999999 })}}
                                    className="input-field col s4"
                                    classNamePrefix="select"
                                    defaultValue={item.paymentTypeIdValue}
                                    isRtl={false}
                                    name="customertype"
                                    options={paymentType}
                                    onChange={(value)=>{
                                        console.log("onchange paymentTypeId");
                                        setItem({...item,paymentTypeIdValue:value});
                                    }}
                                />
                                <div class="input-field col s4">
                                    <input id="firstname" type="text" class="validate" defaultValue={item.firstName} disabled />
                                    <label for="firstname" class="active" >First name</label>
                                </div>
                                <div class="input-field col s4">
                                    <input id="lastname" type="text" class="validate" defaultValue={item.lastName} disabled />
                                    <label for="lastname" class="active" >Last name</label>
                                </div>
                            </div>

                        </div>
                        <div class="card-action">
                            <div class="row">
                                <div class="col s12">
                                    <a class="waves-effect waves-light btn-large mb-1" onClick={()=>{
                                        console.log("item.paymentTypeIdValue.value",item.paymentTypeIdValue.value);
                                        if(item.type == 1){
                                            dispatch(updateServicePaymentType(item.serviceId,item.paymentTypeIdValue.value)).then(()=>{
                                                swal("The payment type was updated success.");
                                            }).catch(()=>{
                                                swal("Error to update the payment type.");
                                            });
                                        }else{
                                            dispatch(updateReservationPaymentType(item.reservationId,item.paymentTypeIdValue.value)).then(()=>{
                                                swal("The payment type was updated success.");
                                            }).catch(()=>{
                                                swal("Error to update the payment type.");
                                            });
                                        }
                                    }}><i class='material-icons left'>payment</i> Save Payment Type </a>
                                    {servicePaymentTypeUpdated.loading && (<div class="col s12"><ProgressBar /></div>)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*<pre>{JSON.stringify(props.service, null, 2)}</pre>*/}
                {/*<pre>{JSON.stringify(item, null, 2)}</pre>*/}
                <div class="row">
                    <div class="card">
                        <div class="card-content">
                            <div class="col s12 m12 l6">
                                <span class="card-title">Origin</span>
                                <div class="row">
                                    <div class="col s12">
                                        <CreatableSelect
                                            placeholder={"Last Address"}
                                            value={selectLastOrigin}
                                            styles={{menu: provided => ({ ...provided, zIndex: 9999 })}}
                                            className="countySelect"
                                            classNamePrefix="select"
                                            isLoading={lastOrigin.loading}
                                            isRtl={false}
                                            isSearchable={true}
                                            name="color"
                                            options={lastOrigin.items}
                                            onChange={(value)=>{
                                                setSelectLastOrigin(value);
                                                var lparse = parseLocation(value.value);
                                                console.log('lparse',lparse);

                                                let t = item;

                                                t.olNumber = lparse.number;
                                                t.olNsew = lparse.nsew;
                                                t.olStreet = lparse.street;
                                                t.olZipcode = lparse.zipcode;

                                                setItem(t);

                                            }}
                                        />
                                    </div>
                                </div>
                                <br/>
                                <div class="row">
                                    <div class="input-field col s2">
                                        <input id="originNumber" type="text" class="validate" value={item.olNumber} onChange={(event) => {
                                            setItem({...item,olNumber:event.target.value});
                                        }}/>
                                        <label for="originNumber" class="active" >Number</label>
                                    </div>
                                    <div class="input-field col s2">
                                        <select style={{display:'block'}} id="originNSB" onChange={(event)=>{
                                                setItem({...item,olNsew:event.target.value});
                                            }}
                                        >
                                            <option value='N' selected={item.olNsew=='N'?'selected':''} >N</option>
                                            <option value='S' selected={item.olNsew=='S'?'selected':''} >S</option>
                                            <option value='E' selected={item.olNsew=='E'?'selected':''} >E</option>
                                            <option value='W' selected={item.olNsew=='W'?'selected':''} >W</option>
                                        </select>
                                        <label for="originNSB" class="active" >N,S,E,W</label>
                                    </div>
                                    <div class="input-field col s5">
                                        <input id="originStreet" type="text" class="validate" value={item.olStreet} onChange={(event) => {
                                            setItem({...item,olStreet:event.target.value});
                                        }}/>
                                        <label for="originStreet" class="active" >Street</label>
                                    </div>
                                    <div class="input-field col s3">
                                        <input id="originPostalcode" type="text" class="validate col s6 active" value={item.olZipcode} onChange={(event) => {
                                            setItem({...item,olZipcode:event.target.value});
                                        }}
                                        
                                        /> 
                                        <label for="originPostalcode" class="active" >Zip Code</label>
                                        <a class="waves-effect waves-light btn col s6" onClick={()=>{
                                            getZipCode(item.olNumber,item.olNsew,item.olStreet,(zip)=>{
                                                setItem({...item,olZipcode:zip});
                                            });
                                        }} >
                                            <i class="material-icons">search</i>
                                        </a>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="input-field col s4">
                                        <CreatableSelect
                                            placeholder={"Location Type"}
                                            value={locationType}
                                            styles={{menu: provided => ({ ...provided, zIndex: 9999 })}}
                                            className="countySelect"
                                            classNamePrefix="select"
                                            isLoading={false}
                                            isRtl={false}
                                            isSearchable={true}
                                            name="color"
                                            options={locationTypes}
                                            onChange={(value)=>{
                                                console.log("onchange locationType");
                                                setLocationType(value);
                                            }}
                                        />
                                    </div>
                                    <div class="input-field col s4">
                                        <input id="locationTypeValue" type="text" class="validate" value={item.locationTypeValue} onChange={(event) => {
                                            setItem({...item,locationTypeValue:event.target.value});
                                        }}/>
                                        <label for="locationTypeValue" class="active" >Value</label>
                                    </div>
                                    {/*<div class="input-field col s4">
                                        <CreatableSelect
                                            placeholder={"Origin County"}
                                            value={selectCountyOrigin}
                                            styles={{menu: provided => ({ ...provided, zIndex: 9999 })}}
                                            className="countySelect"
                                            classNamePrefix="select"
                                            isLoading={countiesLoading}
                                            isRtl={false}
                                            isSearchable={true}
                                            name="color"
                                            options={counties}
                                            onChange={(value)=>{
                                                console.log("onchange selectCountyOrigin");
                                                setItem({...item,olCountyId:value.value});
                                                setItem({...item,olCounty:value.label});
                                                setSelectCountyOrigin(value);
                                                dispatch(getOriginArizonaCitiesByCounty(value.value));
                                            }}
                                        />
                                    </div>*/}
                                    <div class="input-field col s4">
                                        <CreatableSelect 
                                            placeholder={"Origin City"}
                                            value={selectCitiesOrigin}
                                            styles={{menu: provided => ({ ...provided, zIndex: 9999 })}}
                                            className="countySelect"
                                            classNamePrefix="select"
                                            isLoading={citiesLoadingOrigin}
                                            isRtl={false}
                                            isSearchable={true}
                                            name="color"
                                            options={arizonaCountyCitiesOrigin}
                                            onChange={(value)=>{
                                                console.log("onchange selectCitiesOrigin");

                                                setItem({...item,olCityId:value.value});
                                                setItem({...item,olCity:value.label});

                                                setSelectCitiesOrigin(value);
                                            }}
                                        />
                                    </div>
                                </div>
                                <div class="row">
                                    <a class="waves-effect waves-light btn-large mb-1" href="#" onClick={()=>{

                                        if(!item.olNumber){
                                            swal("The Origin number is required.");
                                            return;
                                        }
                                        if(!item.olStreet){
                                            swal("The Origin street is required.");
                                            return;
                                        }
                                        if(!item.olZipcode){
                                            swal("The Origin zip code is required.");
                                            return;
                                        }

                                        var oLocation = item.olNumber+' '+
                                                        item.olNsew+' '+
                                                        item.olStreet+', '+
                                                        item.olCity+', '+
                                                        item.olCounty+', '+
                                                        item.olState+', '+
                                                        item.olZipcode+', '+
                                                        item.olCountry

                                        Geocode.fromAddress(oLocation).then((response) => {
                                            if(response.results.length > 0){
                                                const { lat , lng } = response.results[0].geometry.location;

                                                /*const directionsService = new maps.DirectionsService();
                                                const directionsDisplay = new maps.DirectionsRenderer();
                                                directionsService.route({
                                                    origin: mapOrigin,
                                                    destination: mapDestination,
                                                    travelMode: 'DRIVING'
                                                }, (response, status) => {
                                                    if (status === 'OK') {
                                                        console.log('DRIVING',response);
                                                        directionsDisplay.setDirections(response);
                                                        directionsDisplay.setMap(map);
                                                        const routePolyline = new window.google.maps.Polyline();
                                                        routePolyline.setMap(map);
                                                    }
                                                });*/

                                                if(lat == null || lng == null){
                                                    swal("The address is not valid");
                                                    return;
                                                }

                                                console.log('locationType.value',locationType.value);
                                                console.log('item.locationTypeValue',item.locationTypeValue);

                                                if(item.type == 1){
                                                    dispatch(updateServiceOriginLocation(item.serviceId,oLocation,lat,lng,locationType.value,item.locationTypeValue)).then(()=>{
                                                        swal("Service updated success.");
                                                    }).catch(()=>{
                                                        swal("Error to save the service.");
                                                    });
                                                }else{
                                                    dispatch(updateReservationOriginLocation(item.reservationId,oLocation,lat,lng,locationType.value,item.locationTypeValue)).then(()=>{
                                                        swal("Reservation updated success.");
                                                    }).catch(()=>{
                                                        swal("Error to save the reservation.");
                                                    });
                                                }
                                            }

                                        },error => {swal(error);});

                                    }} ><i class='material-icons left'>location_on</i> Update Origin Location</a>
                                    { newServiceRateLoading && (<ProgressBar />)}
                                </div>
                            </div>
                            <div class="col s12 m12 l6">
                                <span class="card-title">Destination</span>
                                
                                <div class="row">
                                    <div class="col s12">
                                        <CreatableSelect
                                            placeholder={"Last Address"}
                                            value={selectLastDestination}
                                            styles={{menu: provided => ({ ...provided, zIndex: 9999 })}}
                                            className="countySelect"
                                            classNamePrefix="select"
                                            isLoading={lastDestination.loading}
                                            isRtl={false}
                                            isSearchable={true}
                                            name="color"
                                            options={lastDestination.items}
                                            onChange={(value)=>{
                                                setSelectLastDestination(value);
                                                var lparse = parseLocation(value.value);

                                                console.log('lparse',lparse);

                                                let t = item;

                                                t.dlNumber = lparse.number;
                                                t.dlNsew = lparse.nsew;
                                                t.dlStreet = lparse.street;
                                                t.dlZipcode = lparse.zipcode;

                                                setItem(t);
                                            }}
                                        />
                                    </div>
                                </div>
                                <br/>
                                <div class="row">
                                    <div class="input-field col s2">
                                        <input id="destinationNumber" type="text" class="validate" value={item.dlNumber} onChange={(event) => {
                                            setItem({...item,dlNumber:event.target.value});
                                        }}/>
                                        <label for="destinationNumber" class="active" >Number</label>
                                    </div>
                                    <div class="input-field col s2">
                                        <select style={{display:'block'}} id="destinationNSB" onChange={(event)=>{
                                                setItem({...item,dlNsew:event.target.value});
                                            }}
                                        >
                                            <option value='N' selected={item.dlNsew=='N'?'selected':''} >N</option>
                                            <option value='S' selected={item.dlNsew=='S'?'selected':''} >S</option>
                                            <option value='E' selected={item.dlNsew=='E'?'selected':''} >E</option>
                                            <option value='W' selected={item.dlNsew=='W'?'selected':''} >W</option>
                                        </select>
                                        <label for="destinationNSB" class="active" >N,S,E,W</label>
                                    </div>
                                    <div class="input-field col s5">
                                        <input id="destinationStreet" type="text" class="validate" value={item.dlStreet} onChange={(event) => {
                                            setItem({...item,dlStreet:event.target.value});
                                        }}/>
                                        <label for="destinationStreet" class="active" >Street</label>
                                    </div>
                                    <div class="input-field col s3">
                                        <input id="destinationPostalcode" type="text" class="validate col s6" value={item.dlZipcode} onChange={(event) => {
                                            setItem({...item,dlZipcode:event.target.value});
                                        }}/>
                                        <label for="destinationPostalcode" class="active" >Zip Code</label>
                                        <a class="waves-effect waves-light btn col s6" onClick={()=>{
                                            getZipCode(item.dlNumber,item.dlNsew,item.dlStreet,(zip)=>{
                                                setItem({...item,dlZipcode:zip});
                                            });
                                        }} >
                                            <i class="material-icons">search</i>
                                        </a>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="input-field col s1">
                                        <label>USA</label>
                                    </div>
                                    <div class="input-field col s1">
                                        <label>Arizona</label>
                                    </div>
                                    {/*<div class="input-field col s5">
                                        <CreatableSelect
                                            placeholder={"Destination County"}
                                            value={selectCountyDestination}
                                            styles={{menu: provided => ({ ...provided, zIndex: 9999 })}}
                                            className="countySelect"
                                            classNamePrefix="select"
                                            isLoading={countiesLoading}
                                            isRtl={false}
                                            isSearchable={true}
                                            name="color"
                                            options={counties}
                                            onChange={(value)=>{
                                                console.log("onchange selectCountyDestination");

                                                setItem({...item,dlCountyId:value.value});
                                                setItem({...item,dlCounty:value.label});

                                                setSelectCountyDestination(value);
                                                dispatch(getDestinationArizonaCitiesByCounty(value.value));
                                            }}
                                        />
                                        </div>*/}
                                    <div class="input-field col s5">
                                        <CreatableSelect
                                            placeholder={"Destination City"}
                                            value={selectCitiesDestination}
                                            styles={{menu: provided => ({ ...provided, zIndex: 9999 })}}
                                            className="countySelect"
                                            classNamePrefix="select"
                                            isLoading={citiesLoadingDestination}
                                            isRtl={false}
                                            isSearchable={true}
                                            name="color"
                                            options={arizonaCountyCitiesDestination}
                                            onChange={(value)=>{
                                                console.log("onchange selectCitiesDestination");

                                                setItem({...item,dlCityId:value.value});
                                                setItem({...item,dlCity:value.label});

                                                setSelectCitiesDestination(value);

                                            }}
                                        />
                                    </div>
                                </div>
                                <div class="row">
                                    <a class="waves-effect waves-light btn-large mb-1" href="#" onClick={()=>{

                                        if(!item.dlNumber){
                                            swal("The Destination number is required.");
                                            return;
                                        }
                                        if(!item.dlStreet){
                                            swal("The Destination street is required.");
                                            return;
                                        }
                                        if(!item.dlZipcode){
                                            swal("The Destination zip code is required.");
                                            return;
                                        }
                                        var dLocation = item.dlNumber+' '+
                                                        item.dlNsew+' '+
                                                        item.dlStreet+', '+
                                                        item.dlCity+', '+
                                                        item.dlCounty+', '+
                                                        item.dlState+', '+
                                                        item.dlZipcode +', '+
                                                        item.dlCountry

                                        Geocode.fromAddress(dLocation).then((response) => {
                                            if(response.results.length > 0){
                                                const { lat , lng } = response.results[0].geometry.location;
                                                if(lat == null || lng == null){
                                                    swal("The address is not valid");
                                                    return;
                                                }

                                                if(item.type == 1){
                                                    dispatch(updateServiceDestinationLocation(item.serviceId,dLocation,lat,lng)).then(()=>{

                                                        swal("Service updated success.");
                                                    }).catch(()=>{
                                                        swal("Error to save the service.");
                                                    });
                                                    
                                                }else{
                                                    dispatch(updateReservationDestinationLocation(item.reservationId,dLocation,lat,lng)).then(()=>{
                                                        swal("Reservation updated success.");
                                                    }).catch(()=>{
                                                        swal("Error to save the reservation.");
                                                    });
                                                }
                                            }

                                    },error => {swal(error);});

                                    }} ><i class='material-icons left'>location_on</i> Update Destination Location</a>
                                    { newServiceRateLoading && (<ProgressBar />)}
                                </div>
                            </div>
                    </div>
                    <div class="card-action">

                    </div>
                    </div>
                </div>
                {/*<div class="row">
                    <div class="card">
                        <div class="card-content">
                            <span class="card-title">Comments</span>
                            <div class="row" >
                            {newServiceTopicComments.length == 0 && (<div class="progress"><div class="indeterminate"></div></div>)}
                            {newServiceTopicComments.length > 0 && newServiceTopicComments.map((comment,index) => {
                                return (<div class="col s4">
                                    <div class="">
                                        <label>
                                            <input type="checkbox" defaultChecked={false} onChange={(event) => {
                                                if(event.target.checked == true){
                                                    setComments({...comments,["comment"+comment.adinfServiceTopicId]: {id:comment.adinfServiceTopicId,text:''}});
                                                }else{
                                                    let c = {...comments};
                                                    delete c["comment"+comment.adinfServiceTopicId];
                                                    setComments(c);
                                                }
                                                
                                            }}/>
                                            <span >{capitalize(comment.name)}</span>
                                        </label>
                                    </div>
                                    <div class="input-field">
                                        <input type="text" class="validate"  onChange={(event) => {
                                            setComments({...comments,["comment"+comment.adinfServiceTopicId]: {id:comment.adinfServiceTopicId,text:event.target.value}});
                                        }}/>
                                    </div>
                                </div>
                            )})}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="card">
                        <div class="card-content">
                            <span class="card-title">Vehicle Information</span>
                                {props.service.cab != '' && (<div class="row">
                                    <div class="input-field col s3">
                                        <input id="cab" type="email" class="validate" value={props.service.cab} onChange={(event) => {
                                                dispatch(updateField('cab',event.target.value));
                                                }} />
                                        <label for="cab">Cab</label>
                                    </div>
                                    <div class="input-field col s4">
                                        <input id="assignatedDriver" type="email" class="validate" value={props.service.assignatedDriver} onChange={(event) => {
                                                dispatch(updateField('assignatedDriver',event.target.value));
                                                }} />
                                        <label for="assignatedDriver">Assignated driver</label>
                                    </div>
                                    <div class="input-field col s5">
                                        <input id="vehicleFeatures" type="email" class="validate" value={props.service.vehicleFeatures} onChange={(event) => {
                                                dispatch(updateField('vehicleFeatures',event.target.value));
                                                }} />
                                        <label for="vehicleFeatures">Vehicle features</label>
                                    </div>
                                </div>)}
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="card">
                        <div class="card-content">
                        <span class="card-title">Estimate Rate</span>
                        <p>
                            {newServiceRate}
                        </p>
                        </div>
                        <div class="card-action">
                            <a class="waves-effect waves-light btn mb-1 mr-1" href="#" onClick={() => { setShowRoadmap(true); }} >View Route Map</a>
                        </div>
                    </div>
                </div>*/}
                <div class="row">
                    <div class="col s12">
                        <a class="waves-effect waves-light btn-large mb-1" onClick={()=>{props.closeButton()}}><i class='material-icons left'>close</i> Close</a>
                    </div>
                </div>
            </Form>
        </div>
</Modal>

);


};

export default OperatorNewService;