import React, { useEffect, useState } from "react";
import ReactModal from 'react-modal';

import axios from "axios";

import Validate from '../../../helpers/validate';
import authHeader from '../../../services/auth-header';
import { TextControl, SelectControl } from '../Controls';

ReactModal.setAppElement('#root');

function EditModal(props) {

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [number, setNumber] = useState('');
    const [nsew, setNSEW] = useState('');
    const [street, setStreet] = useState('');
    const [zipCode, setZipCode] = useState('');
    const [countyId, setCountyId] = useState('');
    const [cityId, setCityId] = useState('');
    const [errorMessage, setErrorMessage] = useState({});

    const [counties, setCounties] = useState([]);
    const [cities, setCities] = useState([]);

    useEffect(() => {
        getCounties();
    }, []);

    useEffect(() => {
        if (countyId)
            getCities(countyId);
    }, [countyId]);

    useEffect(() => {
        if (props.isOpen) {
            setFirstName(props.member.firstName);
            setLastName(props.member.lastName);
            setNumber(props.member.number);
            setNSEW(props.member.nsew);
            setStreet(props.member.street);
            setZipCode(props.member.zipCode);
            setCountyId(props.member.countyId);
            setCityId(props.member.cityId);
        }
    }, [props.isOpen]);

    async function fetch(route, httpVerb, data, config) {
        try {
            const result = await axios[httpVerb](process.env.REACT_APP_API_URL + route, data, config);
            return result;
        } catch (error) {
            return null;
        }
    }

    async function getCounties() {
        const result = await fetch('api/catalog/counties', 'get');
        if (result)
            setCounties(result.data);
    }

    async function getCities(countyId) {
        const result = await fetch(`api/catalog/cities/${countyId}`, 'get');
        if (result)
            setCities(result.data);
    }

    const handleSubmit = async () => {
        const validate = new Validate();
        const message = {};

        message.firstName = validate.init(firstName, {
            maxlength: {
                value: 100,
                message: 'The maximum length possible of the first name is 100 characters'
            },
            minLength: {
                value: 1,
                message: 'The minimum length possible of the first name is 1 character'
            }
        }).getHTML();
        message.lastName = validate.init(lastName, {
            maxlength: {
                value: 100,
                message: 'The maximum length possible of the last name is 100 characters'
            },
            minLength: {
                value: 1,
                message: 'The minimum length possible of the last name is 1 character'
            }
        }).getHTML();
        message.number = validate.init(number, {
            maxlength: {
                value: 8,
                message: 'The maximum length possible of the number is 8 characters'
            },
            minLength: {
                value: 1,
                message: 'The minimum length possible of the number is 1 character'
            },
            integer: { message: 'Only numbers are accepted' }
        }).getHTML();
        message.street = validate.init(street, {
            maxlength: {
                value: 100,
                message: 'The maximum length possible of the street is 100 characters'
            },
            minLength: {
                value: 1,
                message: 'The minimum length possible of the street is 1 character'
            }
        }).getHTML();
        message.zipCode = validate.init(zipCode, {
            maxlength: {
                value: 5,
                message: 'The maximum length possible of the zip code is 5 characters'
            },
            minLength: {
                value: 1,
                message: 'The minimum length possible of the zip code is 1 character'
            }
        }).getHTML();
        message.nsew = validate.init(nsew, {
            notEmpty: { message: 'A NSEW must be selected' }
        }).getHTML();
        message.countyId = validate.init(countyId, {
            notEmpty: { message: 'A county must be selected' }
        }).getHTML();
        message.cityId = validate.init(cityId, {
            notEmpty: { message: 'A city must be selected' }
        }).getHTML();
        setErrorMessage(message);

        if (validate.status) {
            try {
                await axios.put(process.env.REACT_APP_API_URL + `api/admin/updateMember/${props.member.memberId}`, {
                    firstName, lastName, number, nsew, street,
                    zipCode, countyId, cityId
                }, {
                    headers: authHeader()
                });
                props.member.firstName = firstName;
                props.member.lastName = lastName;
                props.member.number = number;
                props.member.nsew = nsew;
                props.member.street = street;
                props.member.zipCode = zipCode;
                props.member.countyId = countyId;
                props.member.cityId = cityId;

                clearControls();
                props.onClose();
                props.updateTable();
            }
            catch (error) {
                setErrorMessage({ server: <span>{error}</span> });
                clearControls();
                props.onClose();
                props.updateTable();
            }
        }
    };

    function handleFocus(event) {
        const element = event.target.id,
            messages = errorMessage;
        messages[element] = null;
        setErrorMessage(messages);
    }

    function clearControls() {
        setFirstName('');
        setLastName('');
        setNumber('');
        setNSEW('');
        setStreet('');
        setZipCode('');
        setCountyId('');
        setCityId('');
        setErrorMessage({});
    }

    return (
        <ReactModal isOpen={props.isOpen} style={{ overlay: { zIndex: '999' } }}>
            <div className="container">
                <div className="row">
                    <div className="col s11 left hide-on-small-only" style={{ fontVariant: 'small-caps', fontFamily: 'Raleway', fontSize: '2rem' }}>
                        Edit Customer - {props.member.firstName} {props.member.lastName}
                    </div>
                    <div className="col s1 right">
                        <a onClick={() => {
                            clearControls();
                            props.onClose();
                        }} className="btn-floating waves-effect waves-light black white-text"><i className="material-icons">close</i></a>
                    </div>
                </div>
                <hr />
                <CustomRow>
                    <div className="input-field col s8">
                        <TextControl id="firstName" value={firstName} inputEventHandler={setFirstName} focusEventHandler={handleFocus} maxlength="100" />
                        <label className="active" htmlFor="firstName">Fist Name</label>
                        <span data-assigned="firstName" className="helper-text red-text text-darken-1">{errorMessage.firstName}</span>
                    </div>
                </CustomRow>
                <CustomRow>
                    <div className="input-field col s8">
                        <TextControl id="lastName" value={lastName} inputEventHandler={setLastName} focusEventHandler={handleFocus} maxlength="100" />
                        <label className="active" htmlFor="lastName">Last Name</label>
                        <span data-assigned="lastName" className="helper-text red-text text-darken-1">{errorMessage.lastName}</span>
                    </div>
                </CustomRow>
                <CustomRow>
                    <div className="input-field col s8">
                        <input id="phone" value={props.member.phone} type="text" disabled />
                        <label className="active" htmlFor="phone">Phone number</label>
                    </div>
                </CustomRow>
                <CustomRow>
                    <div className="input-field col s8">
                        <input id="email" value={props.member.email} type="text" disabled />
                        <label className="active" htmlFor="email">Email</label>
                    </div>
                </CustomRow>
                <CustomRow>
                    <div className="input-field col s8">
                        <TextControl id="number" value={number} inputEventHandler={setNumber} focusEventHandler={handleFocus} maxlength="8" />
                        <label className="active" htmlFor="number">Number</label>
                        <span data-assigned="number" className="helper-text red-text text-darken-1">{errorMessage.number}</span>
                    </div>
                </CustomRow>
                <CustomRow>
                    <div className="input-field col s8">
                        <p>NSEW</p>
                        <SelectControl id="nsew" value={nsew} inputEventHandler={setNSEW} focusEventHandler={handleFocus}>
                            <option value="0">Select NSEW...</option>
                            <option value="N">North</option>
                            <option value="S">South</option>
                            <option value="W">West</option>
                            <option value="E">East</option>
                        </SelectControl>
                        <span data-assigned="nsew" className="helper-text red-text text-darken-1">{errorMessage.nsew}</span>
                    </div>
                </CustomRow>
                <CustomRow>
                    <div className="input-field col s8">
                        <TextControl id="street" value={street} inputEventHandler={setStreet} focusEventHandler={handleFocus} maxlength="100" />
                        <label className="active" htmlFor="street">Street</label>
                        <span data-assigned="street" className="helper-text red-text text-darken-1">{errorMessage.street}</span>
                    </div>
                </CustomRow>
                <CustomRow>
                    <div className="input-field col s8">
                        <TextControl id="zipCode" value={zipCode} inputEventHandler={setZipCode} focusEventHandler={handleFocus} maxlength="5" />
                        <label className="active" htmlFor="zipCode">Zip Code</label>
                        <span data-assigned="zipCode" className="helper-text red-text text-darken-1">{errorMessage.zipCode}</span>
                    </div>
                </CustomRow>
                <CustomRow>
                    <div className="input-field col s8">
                        <p>County</p>
                        <SelectControl id="countyId" value={countyId} inputEventHandler={setCountyId} resetEventHandler={setCityId} focusEventHandler={handleFocus}>
                            <option value="0">Select county...</option>
                            {counties.map((value, index) => <option key={value.countyId} value={value.countyId}>{value.county}</option>)}
                        </SelectControl>
                        <span data-assigned="countyId" className="helper-text red-text text-darken-1">{errorMessage.countyId}</span>
                    </div>
                </CustomRow>
                <CustomRow>
                    <div className="input-field col s8">
                        <p>City</p>
                        <SelectControl id="cityId" value={cityId} inputEventHandler={setCityId} focusEventHandler={handleFocus}>
                            <option value="0">Select city...</option>
                            {cities.map((value, index) => <option key={value.cityId} value={value.cityId}>{value.city}</option>)}
                        </SelectControl>
                        <span data-assigned="countyId" className="helper-text red-text text-darken-1">{errorMessage.cityId}</span>
                    </div>
                </CustomRow>
                <CustomRow>
                    <div className="input-field col s8">
                        <div className="helper-text red-text text-darken-1">
                            <p>{errorMessage.firstName}</p>
                            <p>{errorMessage.lastName}</p>
                            <p>{errorMessage.number}</p>
                            <p>{errorMessage.nsew}</p>
                            <p>{errorMessage.countyId}</p>
                            <p>{errorMessage.cityId}</p>
                        </div>
                    </div>
                </CustomRow>
                <CustomRow>
                    <div className="input-field col s8">
                        <a onClick={handleSubmit} style={{ width: '100%', fontFamily: 'Raleway', fontSize: '1rem', backgroundColor: 'black', marginTop: '30px' }} className="waves-effect waves-light btn z-depth-0 white-text">SAVE & CLOSE</a>
                    </div>
                </CustomRow>
            </div>
        </ReactModal>
    );
}

function CustomRow(props) {
    return (
        <div className={`row ${props.className}`}>
            <div className="col s2"></div>
            {props.children}
            <div className="col s2"></div>
        </div>
    );
}

export default EditModal;