import {
  GET_ALL_APPLICANTS_ASSOCIATES_START,
  GET_ALL_APPLICANTS_ASSOCIATES_SUCCESS,
  GET_ALL_APPLICANTS_ASSOCIATES_FAIL,
  GET_ALL_APPLICANTS_DRIVERS_START,
  GET_ALL_APPLICANTS_DRIVERS_SUCCESS,
  GET_ALL_APPLICANTS_DRIVERS_FAIL,

  SAVE_DATE_DRIVERS_START,
  SAVE_DATE_DRIVERS_SUCCESS,
  SAVE_DATE_DRIVERS_FAIL,
    
  SAVE_DATE_ASSOCIATES_START,
  SAVE_DATE_ASSOCIATES_SUCCESS,
  SAVE_DATE_ASSOCIATES_FAIL,

  SAVE_APPLICANTS_START,
  SAVE_APPLICANTS_SUCCESS,
  SAVE_APPLICANTS_FAIL,

  GET_DATE_TODAY_START,
  GET_DATE_TODAY_SUCCESS,
  GET_DATE_TODAY_FAIL,

  GET_EVENTS_BY_MONTH_START,
  GET_EVENTS_BY_MONTH_SUCCESS,
  GET_EVENTS_BY_MONTH_FAIL,

  GET_USERS_START,
  GET_USERS_SUCCESS,
  GET_USERS_FAIL,


} from "../../actions/manager/types";
import moment from 'moment';

const initialState = {
  applicants_associates:{
    loading:false,
    applicants:[],
    count:0,
    message:""
  },
  applicants_drivers:{
    loading:false,
    applicants:[],
    count:0,
    message:""
  },
  applicants_events:{
    loading:false,
    applicants:[],
    events:[],
    count:0,
    message:""
  },
  interview_month_events:{
    loading:false,
    applicants:[],
    events:[],
    count:0,
    message:""
  },
  users:{
    loading:false,
    applicants:[],
    events:[],
    count:0,
    message:""
  },
  saveDateLoading:false,
  saveApplicantsLoading:false
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_ALL_APPLICANTS_ASSOCIATES_START:
      var a = {...state};
      a.applicants_associates.loading = true;
      a.applicants_associates.applicants = [];
      a.applicants_associates.count = 0;
      a.applicants_associates.message = '';
      return a;
    case GET_ALL_APPLICANTS_ASSOCIATES_SUCCESS:
      var a = {...state};
      a.applicants_associates.loading = false;
      a.applicants_associates.applicants = payload;
      a.applicants_associates.count = payload.length;
      a.applicants_associates.message = '';
      return a;
    case GET_ALL_APPLICANTS_ASSOCIATES_FAIL:
      var a = {...state};
      a.applicants_associates.loading = false;
      a.applicants_associates.applicants = [];
      a.applicants_associates.count = 0;
      a.applicants_associates.message = payload;
      return a;
    case GET_ALL_APPLICANTS_DRIVERS_START:
      var a = {...state};
      a.applicants_drivers.loading = true;
      a.applicants_drivers.applicants = [];
      a.applicants_drivers.count = 0;
      a.applicants_drivers.message = '';
      return a;
    case GET_ALL_APPLICANTS_DRIVERS_SUCCESS:
      var a = {...state};
      a.applicants_drivers.loading = false;
      a.applicants_drivers.applicants = payload;
      a.applicants_drivers.count = payload.length;
      a.applicants_drivers.message = '';
      return a;
    case GET_ALL_APPLICANTS_DRIVERS_FAIL:
      var a = {...state};
      a.applicants_drivers.loading = false;
      a.applicants_drivers.applicants = [];
      a.applicants_drivers.count = 0;
      a.applicants_drivers.message = payload;
      return a;
    case SAVE_DATE_DRIVERS_START:
      return {
        ...state,
        saveDateLoading: true
      };
    case SAVE_DATE_DRIVERS_SUCCESS:
      return {
        ...state,
        saveDateLoading: false
      };
    case SAVE_DATE_DRIVERS_FAIL:
      return {
        ...state,
        saveDateLoading: false
      };
    case SAVE_DATE_ASSOCIATES_START:
      return {
        ...state,
        saveDateLoading: true
      };
    case SAVE_DATE_ASSOCIATES_SUCCESS:
      return {
        ...state,
        saveDateLoading: false
      };
    case SAVE_DATE_ASSOCIATES_FAIL:
      return {
        ...state,
        saveDateLoading: false
      };
    case SAVE_APPLICANTS_START:
      return {
        ...state,
        saveApplicantsLoading: false
      };
    case SAVE_APPLICANTS_SUCCESS:
      return {
        ...state,
        saveApplicantsLoading: false
      };
    case SAVE_APPLICANTS_FAIL:
      return {
        ...state,
        saveApplicantsLoading: false
      };
    case GET_DATE_TODAY_START:
      var a = {...state};
      a.applicants_events.loading = true;
      a.applicants_events.applicants = [];
      a.applicants_events.count = 0;
      a.applicants_events.message = '';
      return a;
    case GET_DATE_TODAY_SUCCESS:
      var a = {...state};
      a.applicants_events.loading = false;
      a.applicants_events.applicants = payload;
      a.applicants_events.events = [];
      for (let index in payload) {
        var bookDate = moment(payload[index]['bookDate']);
        let oDate = moment(bookDate.format('yyyy-MM-DD')+' '+payload[index]['bookTime']);
        a.applicants_events.events[index] = {};
        a.applicants_events.events[index]['title'] = payload[index]['firstName']+' '+payload[index]['lastName'];
        a.applicants_events.events[index]['start'] = oDate.format();
        a.applicants_events.events[index]['end'] = oDate.add(60,'minutes');
        a.applicants_events.events[index]['applicant'] = payload[index];
      }
      a.applicants_events.count = payload.count;
      a.applicants_events.message = '';
      return a;
    case GET_DATE_TODAY_FAIL:
      var a = {...state};
      a.applicants_events.loading = false;
      a.applicants_events.applicants = [];
      a.applicants_events.count = 0;
      a.applicants_events.message = payload;
      return a;

      case GET_EVENTS_BY_MONTH_START:
        var a = {...state};
        a.interview_month_events.loading = true;
        a.interview_month_events.applicants = [];
        a.interview_month_events.count = 0;
        a.interview_month_events.message = '';
        return a;
      case GET_EVENTS_BY_MONTH_SUCCESS:
        var a = {...state};
        a.interview_month_events.loading = false;
        a.interview_month_events.applicants = payload;
        a.interview_month_events.events = [];
        for (let index in payload) {
          var bookDate = moment(payload[index]['bookDate']);
          let oDate = moment(bookDate.format('yyyy-MM-DD')+' '+payload[index]['bookTime']);
          a.interview_month_events.events[index] = {};
          a.interview_month_events.events[index]['title'] = payload[index]['firstName']+' '+payload[index]['lastName'];
          a.interview_month_events.events[index]['start'] = oDate.format();
          a.interview_month_events.events[index]['end'] = oDate.add(60,'minutes');
          a.interview_month_events.events[index]['applicant'] = payload[index];
        }
        a.interview_month_events.count = payload.count;
        a.interview_month_events.message = '';
        return a;
      case GET_EVENTS_BY_MONTH_FAIL:
        var a = {...state};
        a.interview_month_events.loading = false;
        a.interview_month_events.applicants = [];
        a.interview_month_events.count = 0;
        a.interview_month_events.message = payload;
        return a;

    default:
      return state;
  }
}