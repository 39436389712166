import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { isEmail } from "validator";
import { ProgressBar, Icon } from "react-materialize"
import Select from 'react-select'
import { register, getArizonaCitiesByCounty } from "../actions/auth";

const required = (value) => {
	if (!value) {
		return (
			<div className="alert alert-danger" role="alert">
				This field is required!
			</div>
		);
	}
};

const validEmail = (value) => {
	if (!isEmail(value)) {
		return (
			<div className="alert alert-danger" role="alert">
				This is not a valid email.
			</div>
		);
	}
};

const vusername = (value) => {
	if (value.length < 3 || value.length > 20) {
		return (
			<div className="alert alert-danger" role="alert">
				The username must be between 3 and 20 characters.
			</div>
		);
	}
};

const vpassword = (value) => {
	if (value.length < 6 || value.length > 40) {
		return (
			<div className="alert alert-danger" role="alert">
				The password must be between 6 and 40 characters.
			</div>
		);
	}
};

const Register = () => {
	const form = useRef();
	const checkBtn = useRef();
	const selectCities = useRef();
	const selectCounties = useRef();
	const [firstname, setFirstname] = useState("");
	const [lastname, setLastname] = useState("");
	const [email, setEmail] = useState("");
	const [phone, setPhone] = useState("");
	const [howfindus, setHowfindus] = useState("");
	const [number, setNumber] = useState("");
	const [nsew, setNSEW] = useState("");
	const [street, setStreet] = useState("");
	const [zipCode, setZipCode] = useState("");
	const [successful, setSuccessful] = useState(false);
	const [loading, setLoading] = useState(false);
	const [workExperience, setWorkExperience] = useState("");

	const [cityId, setcityId] = useState(null);

	const { message } = useSelector(state => state.message);
	const { counties, arizonaCountyCities, countiesLoading, citiesLoading } = useSelector(state => state.auth);
	const dispatch = useDispatch();

	const nsewValues = [{ value: '', label: 'Select NSEW' }, { value: 'N', label: 'North' },
	{ value: 'S', label: 'South' }, { value: 'W', label: 'West' }, { value: 'E', label: 'East' }];

	const onChangeFirstname = (e) => {
		const firstname = e.target.value;
		setFirstname(firstname);
	};

	const onChangeLastname = (e) => {
		const lastname = e.target.value;
		setLastname(lastname);
	};

	const onChangeEmail = (e) => {
		const email = e.target.value;
		setEmail(email);
	};

	const onChangePhone = (e) => {
		const phone = e.target.value;
		setPhone(phone);
	};

	const onChangeHowfindus = (e) => {
		const howfindus = e.target.value;
		setHowfindus(howfindus);
	};

	const onChangeNumber = (e) => {
		const number = e.target.value;
		setNumber(number);
	};

	const onChangeStreet = (e) => {
		const street = e.target.value;
		setStreet(street);
	};

	const onChangeZipCode = (e) => {
		const zipCode = e.target.value;
		setZipCode(zipCode);
	};

	function onWorkExperienceChange(event) {
		const value = event.target.value;
		setWorkExperience(value);
	}

	const handleRegister = (e) => {
		e.preventDefault();
		setLoading(true);
		setSuccessful(false);

		form.current.validateAll();

		if (checkBtn.current.context._errors.length === 0) {
			dispatch(register(1, firstname, lastname, email, phone, howfindus, number, nsew.value, street, zipCode, cityId.value, workExperience))
				.then((response) => {
					setSuccessful(true);
					setLoading(false);
				})
				.catch(() => {
					setSuccessful(false);
					setLoading(false);
				});
		} else {
			setLoading(false);
		}
	};

	useEffect(() => {
		dispatch(getArizonaCitiesByCounty(8)).then(() => { }).catch(() => { });
		selectCities.current.select.setValue({ value: 55, label: 'Phoenix' });
	}, []);

	return (

		<div className="z-depth-2 card-panel border-radius-2 register-card">
			{successful && (
				<div className="card-alert card green">
					<div className="card-content white-text">
						<p>Driver Added Successful. We will contact you for more details</p>
					</div>
					<button type="button" className="close white-text" data-dismiss="alert" aria-label="Close">
						<span aria-hidden="true">×</span>
					</button>
				</div>
			)}
			<Form onSubmit={handleRegister} ref={form} className="login-form">
				{!successful && (
					<div>
						<div className="row">
							<div className="input-field col s12">
								<h5 className="ml-4">Register as a Driver</h5>
								<p className="ml-4">And join our community now!</p>
							</div>
						</div>
						<div className="row margin">
							<div className="input-field col s12">
								<i className="material-icons prefix pt-2">person_outline</i>
								<input id="firstname" type="text" value={firstname} onChange={onChangeFirstname} />
								<label for="firstname" className="center-align">First Name</label>
							</div>
						</div>
						<div className="row margin">
							<div className="input-field col s12">
								<i className="material-icons prefix pt-2">person_outline</i>
								<input id="lastname" type="text" value={lastname} onChange={onChangeLastname} />
								<label for="lastname" className="center-align">Last Name</label>
							</div>
						</div>
						<div className="row margin">
							<div className="input-field col s1">
								<i className="material-icons prefix pt-2" style={{ top: '1.0rem' }}>home</i>
							</div>
							<div className="input-field col s11" style={{ marginLeft: '-35px' }} >
								<Select
									ref={selectCities}
									className="countySelect"
									classNamePrefix="select"
									defaultValue={0}
									isClearable={true}
									isRtl={false}
									isSearchable={true}
									name="color"
									options={arizonaCountyCities}
									onChange={(value) => {
										setcityId(value);
									}}
								/>
							</div>
						</div>
						<div className="row margin">
							<div className="col s8">
								<div className="input-field">
									<i className="material-icons prefix pt-2">home</i>
									<input id="street" type="text" value={street} onChange={onChangeStreet} />
									<label for="street" className="center-align">Street</label>
								</div>
							</div>
							<div className="col s4">
								<div className="input-field">
									<i className="material-icons prefix pt-2">local_post_office</i>
									<input id="zipCode" type="text" value={zipCode} onChange={onChangeZipCode} />
									<label for="zipCode" className="center-align">Zip Code</label>
								</div>
							</div>
						</div>
						<div className="row margin">
							<div className="col s6">
								<div className="input-field">
									<i className="material-icons prefix pt-2">assignment</i>
									<input id="number" type="text" value={number} onChange={onChangeNumber} />
									<label for="number" className="center-align">Street number</label>
								</div>
							</div>
							<div className="col s6">
								<div className="input-field">
									<Select
										className="countySelect"
										classNamePrefix="select"
										defaultValue={{ value: '', label: 'Select NSEW' }}
										isClearable={true}
										isRtl={false}
										isSearchable={true}
										name="color"
										options={nsewValues}
										onChange={(value) => {
											setNSEW(value);
										}}
									/>
								</div>
							</div>
						</div>
						<div className="row margin">
							<div className="input-field col s12">
								<i className="material-icons prefix pt-2">mail_outline</i>
								<input id="email" type="email" value={email} onChange={onChangeEmail} />
								<label for="email">Email</label>
							</div>
						</div>
						<div className="row margin">
							<div className="input-field col s12">
								<i className="material-icons prefix pt-2">smartphone</i>
								<input id="phone" type="text" value={phone} onChange={onChangePhone} />
								<label for="phone">Phone</label>
							</div>
						</div>
						<div class="row margin">
							<div class="input-field col s12">
								<i className="material-icons prefix pt-2">work</i>
								<input id="workExperience" type="text" value={workExperience} onChange={onWorkExperienceChange} />
								<label for="workExperience">Work Experience</label>
								<span className="helper-text">Tell us about your work experience, e.g., current job, referrals, personal history, etc.</span>
							</div>
						</div>
						<div className="row margin">
							<div className="input-field col s12">
								<i className="material-icons prefix pt-2">search</i>
								<input id="howfindus" type="text" value={howfindus} onChange={onChangeHowfindus} />
								<label for="howfindus">Tell us how you found out about us</label>
							</div>
						</div>
						<div className="row">
							<div className="col s12">
								{message && (
									<div className="card-alert card red">
										<div className="card-content white-text">
											<p>{message}</p>
										</div>
										<button type="button" className="close white-text" data-dismiss="alert" aria-label="Close">
											<span aria-hidden="true">×</span>
										</button>
									</div>
								)}
							</div>
						</div>
						<div className="row">
							<div className="input-field col s12">
								<button disabled={loading} type="submit" className="btn waves-effect waves-light border-round gradient-45deg-purple-deep-orange col s12">Register</button>
								<CheckButton style={{ display: "none" }} ref={checkBtn} />
							</div>
						</div>

						<div id="modal1" className="modal">
							<div className="modal-content">
								<div className="preloader-wrapper big active">
									<div className="spinner-layer spinner-blue-only">
										<div className="circle-clipper left">
											<div className="circle"></div>
										</div>
										<div className="gap-patch">
											<div className="circle"></div>
										</div>
										<div className="circle-clipper right">
											<div className="circle"></div>
										</div>
									</div>
								</div>
							</div>
						</div>

						{loading && (

							<div id="modal1" className="modal">
								<div className="modal-content">
									<div className="preloader-wrapper big active">
										<div className="spinner-layer spinner-blue-only">
											<div className="circle-clipper left">
												<div className="circle"></div>
											</div>
											<div className="gap-patch">
												<div className="circle"></div>
											</div>
											<div className="circle-clipper right">
												<div className="circle"></div>
											</div>
										</div>
									</div>
								</div>
							</div>
						)}
					</div>
				)}

			</Form>
			<link rel="stylesheet" type="text/css" href="/app-assets/css/pages/register.css" />
		</div>
	);
};

export default Register;