import { combineReducers } from "redux";
import auth from "./auth";
import message from "./message";
import manager from "./manager/manager";
import operator from "./operator/index";

export default combineReducers({
  auth,
  message,
  manager,
  operator,
});